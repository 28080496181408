.member_landing_page_logo_div {
    right: 170px;
}

@media screen and (max-width: 730px) {
    .member_landing_page_logo_div {
        right: 150px;
    }

    .MembershipDownloadPamphletLabelImageOnly {
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .member_landing_page_logo_div {
        right: 120px;
    }

    .MembershipDownloadPamphletMain {
        flex-direction: column;
        gap: 10px;
    }

}

@media screen and (max-width: 450px) {
    .member_landing_page_logo_div {
        right: 100px;
    }
}

@media screen and (max-width: 410px) {
    .member_landing_page_logo_div {
        right: 80px;
    }
}

.member_benefits_list_of_benefits_top_div_grid {
    display: grid;
    grid-template-columns: 40% 15% 15% 15% 15%;
    justify-items: center;
    align-items: center;
    row-gap: 20px;
}

.member_benefits_list_of_benefits_top_list {
    justify-self: left;
}

.member_benefits_list_of_benefits_top_buy_button {
    text-decoration: none;
    background-color: #AD2225;
    color: white;
    padding: 15px 30px;
    max-width: 175px;
    text-align: center;
    border: 0px;
}

.MembershipDownloadPamphletLabel {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: fit-content;
    flex-direction: row;
}