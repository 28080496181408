.ExclusiveMemberContentExpertsWholeDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    width: 100%;
}

.ExclusiveMemberContentExpertsSingleCardText {
    background-color: #AC282E;
    margin-top: -8px;
    padding: 10px;
    width: 160px;
}

.ExclusiveMemberContentExpertsAllCards {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    flex-wrap: wrap;
}