.home-page-top-three-services-top-div{
    text-align: center;
    display: grid;
    /* align-items: stretch; */
    grid-template-columns: auto auto auto;
    grid-column-gap: 0px;
    column-gap: 15px;
    row-gap: 10px;
    margin-right: 20px;
    margin-left: 20px;
    justify-content: center;
    align-items: stretch;
}

.homepage_service_cards_button{
    /* background-color: #AD2225; */
    width: 70%;
    padding: 15px;
    position: absolute;
    left: 10%;
    bottom: 20px;
}

.homepage_service_cards_button_link{
    text-decoration: none;
    padding: 15px 25%;
    background-color: #D82027
}

.home-page-top-three-services-div {
    height: 100%;
    /* align-items: center;
    display: flex;
    justify-content: center;
    text-align: center; */
}

.home-page-top-three-services-rectangle{
    position: relative;
    height: 100%;
    max-width: 400px;
    padding: 30px 35px;
    background-color: white;
    box-shadow: 0px 6px 27px rgba(82, 82, 82, 0.3);
    /* border-radius: 2px; */
}

/* 
.home-page-top-three-services-rectangle{
    position: relative;
    width: 350px;
    height: 100%;
background: #FFFFFF;
box-shadow: 0px 4px 27px rgba(206, 155, 155, 0.1);

} */


.home-page-top-three-services-icons{
    height: 100px;
    display:block;
    margin:auto;
}

.home-page-top-three-services-descrip{
    line-height: 22px;
    font-size: 16px;
    margin: auto;
    text-align: center;
    text-align: left;
    color: #4F4F4F;
}

/* .home-page-top-three-services-button {
    margin: 4%;
} */

@media screen and (max-width: 1450px) {
   
    .home-page-top-three-services-top-div{
        text-align: center;
        display: grid;
        grid-template-columns: auto auto;
        grid-column-gap: 0px;
        column-gap: 20px;
        row-gap: 80px;
        justify-content: space-evenly;
    }

    #home-page-top-three-services-advisory-div{
        position: relative;
        left: 62%;
    }
   
}

@media screen and (max-width: 1450px) {
    #home-page-top-three-services-advisory-div{
        left: 55%;
    }
}

@media screen and (max-width: 1000px) {
   
    .home-page-top-three-services-top-div{
        text-align: center;
        display: grid;
        grid-template-columns: auto ;
        grid-column-gap: 0px;
        column-gap: 0px;
        justify-content: center;
        padding-left: 10%;
        padding-right: 10%;
    }
    #home-page-top-three-services-advisory-div{
        left: 0px;
    }
   
}

@media screen and (max-width: 650px) {
   
    .home-page-top-three-services-top-div{
        text-align: center;
        display: grid;
        grid-template-columns: auto ;
        grid-column-gap: 0px;
        column-gap: 0px;
        justify-content: center;
        padding-left: 5%;
        padding-right: 5%;
    }

    .homepage_service_cards_button_link{
        padding: 15px 7%;  
    }
 
   
}

