.data_center_training_individual_course_contact_form_div{
    position: absolute; 
    top: 250px; 
    right: 15%;
    z-index: 3;
}

.Testimonial_Individual_training_course{
    background-color: #F5F5F5;
    padding-bottom: 30px;
}

@media screen and (max-width: 1250px) {
.data_center_training_individual_course_contact_form_div{
    margin-left: 37%;
    position: relative;
    top: 0px; 
    right: 0px;
}
}

@media screen and (max-width: 1250px) {
.data_center_training_individual_course_contact_form_div{
    margin-left: 37%;
    position: relative;
    top: 0px; 
    right: 0px;
}
}

@media screen and (max-width: 1050px) {
.data_center_training_individual_course_contact_form_div{
    margin-left: 34%;
}
}
@media screen and (max-width: 880px) {
.data_center_training_individual_course_contact_form_div{
    margin-left: 31%;
}
}
@media screen and (max-width: 800px) {
.data_center_training_individual_course_contact_form_div{
    margin-left: 29%;
}
}
@media screen and (max-width: 710px) {
.data_center_training_individual_course_contact_form_div{
    margin-left: 26%;
}
}
@media screen and (max-width: 650px) {
.data_center_training_individual_course_contact_form_div{
    margin-left: 24%;
}
}
@media screen and (max-width: 600px) {
.data_center_training_individual_course_contact_form_div{
    margin-left: 21%;
}
}
@media screen and (max-width: 560px) {
.data_center_training_individual_course_contact_form_div{
    margin-left: 18%;
}
}
@media screen and (max-width: 510px) {
.data_center_training_individual_course_contact_form_div{
    margin-left: 15%;
}
}
@media screen and (max-width: 480px) {
.data_center_training_individual_course_contact_form_div{
    margin-left: 12%;
}
}
@media screen and (max-width: 450px) {
.data_center_training_individual_course_contact_form_div{
    margin-left: 10%;
}
}
@media screen and (max-width: 430px) {
.data_center_training_individual_course_contact_form_div{
    margin-left: 13%;
}
}
@media screen and (max-width: 400px) {
.data_center_training_individual_course_contact_form_div{
    margin-left: 11%;
}
}
@media screen and (max-width: 390px) {
.data_center_training_individual_course_contact_form_div{
    margin-left: 9%;
}
}
@media screen and (max-width: 340px) {
.data_center_training_individual_course_contact_form_div{
    margin-left: 10%;
}
}
@media screen and (max-width: 300px) {
.data_center_training_individual_course_contact_form_div{
    margin-left: 5%;
}
}