.individual_training_syllabus_all_day_div{
    display: flex;
    flex-wrap: wrap;
    /* grid-template-columns: 40% 40%; */
    row-gap: 50px;
    column-gap: 50px;
    justify-content: space-between;
    margin-top: 40px;
    /* padding-bottom: 100px; */
}

.individual_training_syllabus_day_div{
    padding: 20px 35px;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    height: fit-content;
    width: 40%;
}

@media screen and (max-width: 1357px) {
    .individual_training_syllabus_day_div{
        width: 35%;
    }
}

@media screen and (max-width: 933px) {
    .individual_training_syllabus_day_div{
        width: 100%;
    }
}