.latam_league_top_div {
    height: 480px;
    background-image: url("../../../../resources/LATAM_SOCCER_TOP.png"), url("../../../../resources/football_player_header.jpg");
    background-size: cover;
    background-position: right;
    margin-bottom: 15px;
}

.latam_league_top_text_div{
  display: flex;
  flex-direction: column;
  padding-top: 150px;
  gap: 20px;
}

.latam_league_top_larg_title {
    font-size: 64px;
    line-height: 75px;
}

#latam_league_top_larg_title_red {
    color: #AD2225;
    font-weight: 1000;
}

.latam_league_breadcrumb_country_flag_button {
    cursor: pointer;
    margin-left: 20px;
    padding: 0px 5px;
    padding-top: 2px;
    border-radius: 2px;
}


@media screen and (max-width: 1550px) {
    .latam_league_top_div {
    height: 420px;
}


.latam_league_top_larg_title {
    font-size: 60px;
    line-height: 67px;
}
}

@media screen and (max-width: 1250px) {
    .latam_league_top_div {
    height: 360px;
}
.latam_league_top_text_div{
  padding-top: 130px;
}
.latam_league_top_larg_title {
    font-size: 52px;
}
}

@media screen and (max-width: 850px) {
    .latam_league_top_div {
    height: 345px;
}
.latam_league_top_larg_title {
    font-size: 48px;
    line-height: 55px;
}
}
@media screen and (max-width: 420px) {
.latam_league_top_larg_title {
    font-size: 45px;
}
}
@media screen and (max-width: 380px) {
    .latam_league_top_div {
    height: 320px;
}
.latam_league_top_larg_title {
    font-size: 40px;
    line-height: 50px;
}
}
@media screen and (max-width: 330px) {
.latam_league_top_larg_title {
    font-size: 39px;
}
}