.create_account_top_div_frame {
    display: grid;
    grid-template-columns: 40% 20% 40%;
    justify-items: center;
    align-items: center;
}

.create_account_google_and_facebook_login_div {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media screen and (max-width: 1050px) {
    .create_account_top_div_frame {
        grid-template-columns: 100%;
        gap: 20px
    }
}