.user_portal_indiv_course_exam_img_title_div {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-top: 30px;
    color: #006197;
    font-size: 19px;
}

.user_portal_indiv_course_exam_detail_div {
    margin: 30px 0px;
    display: flex;
    justify-content: space-between;
    gap: 15px;
    padding: 10px 30px;
    background-color: #F7F7F7;
    color: black;
}

.user_portal_indiv_course_exam_detail_indiv_div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.user_portal_indiv_course_exam_detail_retake_span {
    color: #969696;
}

.user_portal_indiv_course_exam_take_div {
    width: fit-content;
}

.user_portal_indiv_course_exam_take_div a {
    text-decoration: none;
}

.user_portal_indiv_course_exam_take_div p {
    padding: 7px 70px;
    border: 1px solid #006197;
    color: #006197;
    transition: .3s;
}

.user_portal_indiv_course_exam_take_div p:hover {
    color: white;
    border: 1px solid #006197;
    background-color: #006197;
}

@media screen and (max-width: 1350px) {
    .user_portal_indiv_course_exam_detail_div {
        display: grid;
        grid-template-columns: 45% 45%;
        gap: 0px;
    }
}

@media screen and (max-width: 500px) {
    .user_portal_indiv_course_exam_detail_div {
        grid-template-columns: 100%;
    }
}