.data_center_training_body_des_icon_text_top_div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    gap: 20px;
    padding: 20px 40px;
    background-color: rgba(249, 249, 249, 1);
}

.data_center_training_body_des_icon_text_top_div h2 {
    font-size: 30px;
    margin: 0px;
    margin-top: 20px;
    text-align: left;
}

.data_center_training_body_des_icon_text_top_div p {
    margin-bottom: 0px;
    text-align: left;
    font-size: 20px;
    line-height: 30px;
}

.data_center_training_body_des_for_who_p {
    margin-top: 40px;
    font-size: 30px;
}

.data_center_training_2_body_description_p {
    font-size: 20px;
    line-height: 30px;
}

.data_center_training_body_des_for_who_icon_text_top_div {
    display: grid;
    grid-template-columns: 15% 15% 15% 15% 15% 15%;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 20px 0px;
    text-align: center;
}

.data_center_training_roadmap_img_div {
    position: relative;
    max-width: 800px;
    width: 800px;
    aspect-ratio: 1.5;
    margin: auto;
    margin-top: 20px;
}

.data_center_training_body_right_small_window {
    display: none;
}

.data_center_training_body_right_small_window_inner_div {
    max-width: 340px;
}

@media screen and (max-width: 1550px) {
    .data_center_training_body_des_for_who_icon_text_top_div {
        grid-template-columns: 17% 17% 17% 17% 17%;
    }
}

@media screen and (max-width: 1400px) {
    .data_center_training_body_des_for_who_icon_text_top_div {
        grid-template-columns: 12% 12% 12% 12% 12%;
    }
}

@media screen and (max-width: 1200px) {
    .data_center_training_body_right_small_window {
        margin-top: 40px;
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 700px) {
    .data_center_training_body_des_for_who_icon_text_top_div {
        grid-template-columns: 20% 20% 20% 20%;
    }

    .data_center_training_body_des_for_who_icon_text_top_div p {
        font-size: 15px;
    }
}

@media screen and (max-width: 600px) {
    .data_center_training_body_des_for_who_icon_text_top_div {
        grid-template-columns: 20% 20% 20%;
    }

    .data_center_training_body_des_icon_text_top_div h2 {
        font-size: 26px;
    }

    .data_center_training_body_des_icon_text_top_div p {
        font-size: 18px;
    }

    .data_center_training_body_des_for_who_p {
        margin-top: 25px;
        font-size: 26px;
    }

    .data_center_training_2_body_description_p {
        font-size: 18px;
    }
}

@media screen and (max-width: 400px) {
    .data_center_training_body_des_for_who_icon_text_top_div {
        grid-template-columns: 40% 40%;
    }
}