.DigitalEconomyWhy_whole_whole{
    background-image:url("../../../resources/DE_Initiatives.jpg");
    height: 500px;
    background-size: cover;
    background-position: center;
    padding-bottom: 20px;
}

.DigitalEconomyWhy_Point_text{
    font-size: 17px;
}

@media screen and (max-width: 1300px) {
    .DigitalEconomyWhy_whole_whole{
        height: 561px;     
    }
}

@media screen and (max-width: 781px) {
    .DigitalEconomyWhy_whole_whole{
        padding-bottom: 80px;
    }
}

.DigitalEconomyWhy_whole_div{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

@media screen and (max-width: 781px) {
    .DigitalEconomyWhy_whole_whole{
        padding-bottom: 80px;
    }
}

.DigitalEconomyWhy_header_small{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    margin-bottom: 0px;
}

.DigitalEconomyWhy_header_large{
    margin-top: 0px;
    font-size: 30px;
    width: 730px;
    
}

@media screen and (max-width: 1023px) {
    .DigitalEconomyWhy_header_large{
        width: 100%;

    }
}

@media screen and (max-width: 1023px) {
    .DigitalEconomyWhy_header_large{
        font-size: 25px;
    }
}

.DigitalEconomyWhy_Point_whole{
    display: grid;
    grid-template-columns: auto auto;
    justify-content: left;
    align-items: center;
    gap: 10px;
}

.DigitalEconomyWhy_all_points_1p5{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.DigitalEconomyWhy_all_points{
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    gap: 30px;
}

@media screen and (max-width: 620px) {
    .DigitalEconomyWhy_all_points{
        display: grid;
        grid-template-columns: auto;
        justify-content: space-between;
        gap: 0px;
    }
}

@media screen and (max-width: 410px) {
    .DigitalEconomyWhy_header_small{
       font-size: 14px;
    }
    .DigitalEconomyWhy_header_large{
        font-size: 20px;
    }
    .DigitalEconomyWhy_Point_text{
        font-size: 15px;
    }
}

@media screen and (max-width: 317px) {
    .DigitalEconomyWhy_whole_div{
        gap: 3px;
    }
    .DigitalEconomyWhy_header_small{
       font-size: 13px;
    }
    .DigitalEconomyWhy_header_large{
        font-size: 19px;
    }
    .DigitalEconomyWhy_Point_text{
        font-size: 15px;
    }
}


@media screen and (max-width: 300px) {
    .DigitalEconomyWhy_whole_div{
        gap: 0px;
    }
    .DigitalEconomyWhy_header_small{
       font-size: 13px;
    }
    .DigitalEconomyWhy_header_large{
        font-size: 18px;
    }
    .DigitalEconomyWhy_Point_text{
        font-size: 14px;
    }
}