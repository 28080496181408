.Certification_list_top-div{
    display: flex;
    justify-content: center;
    height: 500px;
    background-image: url("../../../../resources/DataCenterTrainingClassColor.png"),url("../../../../resources/certifications_banner.jpg");
    background-size: cover;
    background-position: center;
}

.Certification_list_top_header{
    text-align: center;
    padding-top: 200px;
    font-size: 40px;
    max-width: 500px;
}