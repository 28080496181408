.atPlayTopBannerIndiv {
    padding-top: 20px;
    background-image: url("../../resources/Group 6339 (10)-min.png");
    background-size: cover;
    background-position: center;
    height: 510px;
    padding-bottom: 50px;
    margin: 0px;
}

.atPlayPageNewMainWholeDiv {
    background-image: url("../../resources/Group 6341 1.png");
    background-size: cover;
    /* background-position: center; */
    background-attachment: fixed;
}

.symposiaLandingTopBannerIndivTextDiv h1 {
    font-size: 42px;
}

.symposiaLandingTopBannerIndivTextDiv h3 {
    font-size: 26px;
    line-height: 35px;
}

@media screen and (max-width: 1250px) {
    .symposiaLandingTopBannerIndivTextDiv h1 {
        font-size: 40px;
        line-height: 45px;
        margin-bottom: 15px;
    }

    .symposiaLandingTopBannerIndivTextDiv h3 {
        font-size: 23px;
        line-height: 30px;
    }
}

@media screen and (max-width: 1000px) {
    .symposiaTopBannerLogoImgDiv {
        display: none;
    }
}

@media screen and (max-width: 700px) {
    .symposiaLandingTopBannerIndivTextDiv h1 {
        font-size: 38px;
    }

    .symposiaLandingTopBannerIndivTextDiv h3 {
        font-size: 20px;
    }
}

@media screen and (max-width: 450px) {
    .symposiaLandingTopBannerIndivTextDiv h1 {
        font-size: 38px;
        line-height: 45px;
        margin-bottom: 30px;
        margin-top: 20px;
    }

    .symposiaLandingTopBannerIndivTextDiv h3 {
        font-size: 23px;
    }

    .symposiaTopBannerIndivHeaderh3_2 {
        display: none;
    }
}