.WhyTakeThisCourse_whole_div {
    padding-top: 50px;
    height: 550px;
    /* background-color: red; */
}

.WhyTakeThisCourse_course_detail {
    display: flex;
    gap: 25px;
    justify-content: space-between;
    width: 60%;
    padding-bottom: 50px;
    min-width: 605px;
}

/* Top indicators */
.WhyTakeThisCourse_course_detail_whole {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.WhyTakeThisCourse_course_detail_title {
    display: flex;
    align-items: center;
    gap: 7px;
    color: #616161;
}

.WhyTakeThisCourse_course_why_points_whole_div {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    /* width: 430px; */
    max-width: 800px;
}

.WhyTakeThisCourse_course_why_points_indiv {
    display: flex;
    align-items: center;
    gap: 10px;
}

.whyTakeThisCourse_course_why_points_col1_div {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.whyTakeThisCourse_course_why_points_col2_div {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.WhyTakeThisCourse_course_why_points_indiv_text_only {
    width: 300px;
}

@media screen and (max-width: 1535px) {
    .WhyTakeThisCourse_course_why_points_whole_div {
        max-width: 650px;
    }

    .WhyTakeThisCourse_course_why_points_indiv_text_only {
        width: 200px;
    }
}

@media screen and (max-width: 1431px) {
    .WhyTakeThisCourse_course_why_points_whole_div {
        max-width: 550px;
    }

    .WhyTakeThisCourse_course_detail {
        display: flex;
        flex-wrap: wrap;
        width: 500px;
        min-width: unset;
    }
}

@media screen and (max-width: 1300px) {
    .WhyTakeThisCourse_course_why_points_whole_div {
        max-width: 500px;
    }
}

@media screen and (max-width: 1250px) {
    .WhyTakeThisCourse_course_why_points_whole_div {
        max-width: unset;
    }

    .WhyTakeThisCourse_course_detail {
        width: 100%;
    }

    .WhyTakeThisCourse_course_why_points_indiv_text_only {
        width: 100%;
    }
}

@media screen and (max-width: 460px) {
    .WhyTakeThisCourse_course_why_points_whole_div {
        flex-wrap: wrap;
        /* justify-content: center; */
        gap: 20px;
    }

    .WhyTakeThisCourse_whole_div {
        height: 820px;
    }
}

@media screen and (max-width: 370px) {
    .WhyTakeThisCourse_whole_div {
        height: 900px;
    }
}