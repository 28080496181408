.user_portal_indiv_course_syllabus_div {
    margin-top: 50px;
}

.user_portal_indiv_course_syllabus_day_div {
    display: grid;
    align-items: center;
    justify-content: space-between;
}

.user_portal_indiv_course_syllabus_day_indiv_div {
    text-align: center;
    cursor: default;
    background-color: #D9D9D9;
    color: #7D7D7D;
    height: 100%;
}

#user_portal_indiv_course_syllabus_day_0 {
    background-color: #006197;
    color: white;
}

.user_portal_indiv_course_syllabus_detail_top_div {
    background-color: #F0F0F0;
    padding: 40px 80px;
}

.user_portal_indiv_course_syllabus_day_detail_div {
    display: grid;
    grid-template-columns: 45% 45%;
    justify-content: space-between;
}

.user_portal_indiv_course_syllabus_day_detail_title_div {
    display: flex;
    align-items: center;
    gap: 20px;
    color: #006197;
}

.user_portal_indiv_course_syllabus_day_detail_title_div h5 {
    font-size: 18px;
    margin: 0px;
}

.user_portal_indiv_course_syllabus_day_h6 {
    font-size: 16px;
    margin: 0px;
}

.user_portal_indiv_course_syllabus_day_detail_bullet_div {
    position: relative;
    left: 20px;
}

@media screen and (max-width: 1000px) {
    .user_portal_indiv_course_syllabus_detail_top_div {
        padding: 40px 50px;
    }
}

@media screen and (max-width: 800px) {
    .user_portal_indiv_course_syllabus_detail_top_div {
        padding: 30px 30px;
    }
}

@media screen and (max-width: 700px) {

    .user_portal_indiv_course_syllabus_day_detail_div {
        display: grid;
        grid-template-columns: 100%;
        justify-content: space-between;
    }
}