.webinar_new_top_div {
    background-position: center;
    background-position-y: bottom;
    background-size: cover;
    height: 550px;
}

.webinar_new_top_text_img_div {
    padding-top: 100px;
    display: flex;
    gap: 50px;
    justify-content: space-between;
    align-items: center;
}

.webinar_new_top_text_div {
    max-width: 500px;
}

.webinar_new_top_text_div h1 {
    font-size: 50px;
}

.webinar_new_top_text_div h2 {
    font-size: 30px;
}

.webinar_new_top_img_div {
    position: relative;
    top: 100px;
    min-width: 450px;
    max-width: 450px;
    height: 400px;
    overflow: hidden;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 24px -8px #000000;
}

.webinar_new_top_upcoming_web_p {
    font-size: 24px;
}

@media screen and (max-width: 900px) {
    .webinar_new_top_img_div {
        display: none;
    }
}