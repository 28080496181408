.Webinar_AboutWebinars_Whole_div{
    display: flex;
    gap: 40px;
    align-items: center;
}

.Webinar_AboutWebinars_img{
    min-width: 350px;
}

.Webinar_Upcoming_Webinar_whole_div_sec{
    padding-bottom: 50px;
    padding-top: 30px;    
    background-color: #FCF4F4;
}
@media screen and (max-width: 1250px) {
    .Webinar_AboutWebinars_Whole_div{
        flex-direction: column;
        align-items: center;
    }
}
@media screen and (max-width: 396px) {
    .Webinar_AboutWebinars_img{
        min-width: unset;
    }
}  
