.JoinTheEliteV2_leftSide {
    padding-top: 20px;
    width: 47%;
}

.JoinTheEliteV2_wholeSec {
    display: flex;
    gap: 6%;
    align-items: center;
    justify-content: space-between;
}

.JoinTheEliteV2_picture {
    position: relative;
    width: 40%;
    aspect-ratio: 1.78;
}

.JoinTheEliteV2_button {
    background-color: #AD2225;
    color: white;
    border: 0;
    width: 200px;
    height: 50px;
    font-size: 14px;
    cursor: pointer;
}

.JoinTheEliteV2_button:hover {
    opacity: 0.9;
}

.JoinTheEliteV2_button:active {
    opacity: 1;
}

@media screen and (max-width: 1535px) {
    .JoinTheEliteV2_picture {
        width: 47%;
    }
}

@media screen and (max-width: 1200px) {
    .JoinTheEliteV2_picture {
        display: none;
    }

    .JoinTheEliteV2_leftSide {
        width: 100%;
    }

}