.standards_page_get_involved_top_div {
    background-color: #FCF4F4;
    padding-top: 3%;
    padding-bottom: 4%;
}

.standards_page_get_involved_smaller_div {
    gap: 3%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}

.standards_page_get_involved_smaller_h4 {
    font-size: 30px;
}

.standards_page_get_involved_smaller_div_image {
    position: relative;
    min-width: 40%;
    max-width: 40%;
    aspect-ratio: 1.5;
}


.standards_page_get_involved_top_div a {
    position: relative;
    top: 20px;
    background-color: #AD2225;
    padding: 15px;
}

.standards_page_get_involved_smaller_card_div {
    margin-top: 10%;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: space-between;
    gap: 3%;
}

.standards_page_get_involved_smaller_card_individual_div {
    background-color: white;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    padding: 5%;
    text-align: center;
}

@media screen and (max-width: 1500px) {
    .standards_page_get_involved_smaller_div_image {
        min-width: 370px;
        max-width: 370px;

    }
}

@media screen and (max-width: 1350px) {
    .standards_page_get_involved_smaller_div_image {
        min-width: 300px;
        max-width: 300px;
    }
}

@media screen and (max-width: 1150px) {
    .standards_page_get_involved_smaller_div {
        flex-direction: column;
    }

    .standards_page_get_involved_smaller_card_div {
        grid-template-columns: auto auto;
        gap: 10%;
    }

    .standards_page_get_involved_top_div {
        padding-bottom: 10%;
    }
}

@media screen and (max-width: 950px) {
    .standards_page_get_involved_smaller_div_image {
        display: none;
    }

    .standards_page_get_involved_smaller_card_div {
        gap: 5%;
    }
}

@media screen and (max-width: 750px) {
    .standards_page_get_involved_smaller_card_div {
        margin-top: 70px;
        grid-template-columns: 70%;
        justify-content: center;
    }

    .standards_page_get_involved_top_div {
        padding-bottom: 20%;
    }
}

@media screen and (max-width: 500px) {
    .standards_page_get_involved_smaller_card_div {
        grid-template-columns: 75%;
    }

    .standards_page_get_involved_top_div {
        padding-bottom: 27%;
    }
}

@media screen and (max-width: 400px) {
    .standards_page_get_involved_smaller_card_div {
        grid-template-columns: 80%;
    }

    .standards_page_get_involved_top_div {
        padding-bottom: 35%;
    }
}

@media screen and (max-width: 350px) {
    .standards_page_get_involved_smaller_card_div {
        grid-template-columns: 85%;
    }

    .standards_page_get_involved_top_div {
        padding-bottom: 40%;
    }
}