.latam_football_league_register_top_div{
    margin-top: 30px;
    display: flex;
    gap: 10%;
    margin-bottom: 70px;
}

.latam_football_league_register_two_div{
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    padding: 3% 5%;
    padding-bottom: 40px;
}

.latam_football_league_register_heading{
    display: flex;
    gap: 20px;
}

.latam_football_league_register_heading h3{
    font-size: 28px;
}

.latam_football_league_register_two_div p{
    font-size: 17px;
    line-height: 25px;
    margin-bottom: 30px;
}

.latam_football_league_register_button{
    text-decoration: none;
    padding: 15px 25px;
    margin-bottom: 50px;
}
#latam_football_league_register_button_player{
    background-color: #AD2225;
}
#latam_football_league_register_button_sponsor{
    background-color: #212121;
}


@media screen and (max-width: 800px) {
.latam_football_league_register_top_div{
    margin-left: 40px;
}
}

@media screen and (max-width: 600px) {
.latam_football_league_register_top_div{
    margin-left: 20px;
}
}
@media screen and (max-width: 500px) {
.latam_football_league_register_top_div{
    margin-left: 10px;
    margin-right: 50px;
}
}

@media screen and (max-width: 1000px) {
.latam_football_league_register_top_div {
    flex-direction: column;
    gap: 50px;
}
}