.data_center_training_2_top_div {
    margin-top: 40px;
    display: flex;
    gap: 120px;
    justify-content: space-between;
}

@media screen and (max-width: 1200px) {
    .data_center_training_body_right {
        display: none;
    }

    .data_center_training_2_top_div {
        display: inherit;
    }
}

@media screen and (max-width: 600px) {
    #data_center_training_2_top_div_id {
        margin: 0px 40px;
    }
}

@media screen and (max-width: 450px) {
    #data_center_training_2_top_div_id {
        margin: 0px 20px;
    }
}