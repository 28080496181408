.research_price_range_section_top_div {
    margin-top: 50px;
    margin-bottom: 50px;
}

.research_price_range_section_header_div {
    background-color: #D6D6D6;
    display: grid;
    grid-template-columns: 70% 25%;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
}

.research_price_range_section_header_div h2 {
    font-size: 30px;
}

.research_price_range_section_header_price_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
}

.research_price_range_section_header_plus_minus {
    cursor: pointer;
}

.research_price_range_section_header_price_div p {
    font-size: 30px;
    white-space: nowrap;
}

.research_price_range_section_detail_and_img_div {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 30px;
    flex-wrap: wrap;
    row-gap: 50px;
}

.research_price_range_section_purchas_or_quote_button {
    text-decoration: none;
    border: 0px;
    border-radius: 10px;
    background-color: #BB2027;
    color: white;
    font-size: 20px;
    padding: 15px 40px;
    cursor: pointer;
}

.research_price_range_section_detail_price {
    font-size: 26px;
    margin-bottom: 40px;
}

.research_price_range_section_detail_price_value {
    font-size: 30px;
}

.research_price_range_section_detail_indiv_div {
    display: flex;
    align-items: center;
    gap: 15px;
}

.research_price_range_section_detail_indiv_div p {
    font-size: 20px;
    width: 90%;
    line-height: 25px;
}

.research_price_range_section_detail_div {
    width: 60%;
}

@media screen and (max-width: 1450px) {
    .research_price_range_section_header_div {
        grid-template-columns: 100%;
    }
}

@media screen and (max-width: 850px) {
    .research_price_range_section_detail_div {
        width: 100%;
    }
}