.ShareExperienceForm {
    display: flex;
    height: fit-content;
    flex-direction: column;
    gap: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);

}

.ShareExperience {
    background-color: #898989;
    color: #ffffff;
    border-radius: 0px;
    font-size: 16px;
    padding: 20px;
    margin-top: 25px;
    width: 100%;
}

.ShareExperienceDescriptionLabel {
    display: flex;
    gap: 15px;
    flex-direction: column;
    margin: 10px;
}

#ShareExperienceIDDescription {
    height: 200px;
    font-size: 15px;

}

.ShareExperienceSubmitButton {
    background-color: #AD2005;
    font-family: Saira;
    color: white;
    width: 100px;
    height: 30px;
    cursor: pointer;
    margin: 10px;
    border: 0px;
}