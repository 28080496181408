.MembershipCommunityMainDis_main_logo_size {
    width: 100px;
    height: 65px;
}

.MembershipCommunityMainPageTable_margin {
    margin-top: 30px;
}

.MembershipCommunityMainDis_virtcalline {
    margin-left: 30px;
    margin-right: 30px;
    border-left: 2px solid #ABABAB;
    height: 90px;
}

.MembershipCommunityMain_h1_ {
    font-size: 26px;
}

.MembershipCommunityMainDis_pagepara_margin {
    display: flex;
    margin-bottom: 25px;
    align-items: center;
    line-height: 1.8;

}

@media screen and (max-width: 680px) {
    .MembershipCommunityMainDis_main_logo_size {
        width: 85px;
        height: 55px;
    }

    .MembershipCommunityMainDis_virtcalline {

        height: 65px;
    }

    .MembershipCommunityMain_h1_ {
        font-size: 20px;
    }

    .MembershipCommunityMainDis_pagepara_margin {
        font-size: 14px;

    }

}

@media screen and (max-width: 320px) {
    .MembershipCommunityMainDis_main_logo_size {

        display: none;
    }

    .MembershipCommunityMainDis_virtcalline {

        display: none;
    }

    .MembershipCommunityMain_h1_ {
        font-size: 12px;
        margin-bottom: 1px;
    }

    .MembershipCommunityMainDis_pagepara_margin {
        font-size: 10px;

    }

}