.member_top_benefits_indiv_benefit_top_div {
    padding: 50px 0px;
    display: flex;
    gap: 300px;
    align-items: center;
}

.member_top_benefits_indiv_benefit_name {
    font-size: 24px;
}

.member_top_benefits_indiv_benefit_img_div {
    min-width: 200px;
}

.member_top_benefits_indiv_benefit_text_div p {
    line-height: 30px;
    font-size: 17px;
}

#member_top_benefits_indiv_last_benefit {
    flex-direction: row-reverse;
}

.member_top_benefits_design_div_1 {
    background: linear-gradient(to bottom right, #F1F1F1 50%, white 50%);
    height: 300px;
}

.member_top_benefits_design_div_red_1 {
    height: 50%;
    background: linear-gradient(to top right, #AD2225 50%, #F1F1F1 50%);
}

.member_top_benefits_design_div_red_bottom_1 {
    height: 50%;
    background: linear-gradient(to bottom right, #AD2225 50%, white 50%);

}


.member_top_benefits_design_div_2 {
    background: linear-gradient(to bottom right, white 50%, #F1F1F1 50%);
    height: 300px;
}

.member_top_benefits_design_div_red_2 {
    height: 50%;
    background: linear-gradient(to bottom right, white 50%, #AD2225 50%);
}

.member_top_benefits_design_div_red_bottom_2 {
    height: 50%;
    background: linear-gradient(to top right, #F1F1F1 50%, #AD2225 50%);

}

.member_top_benefits_design_div_3 {

    background-image: url("../../../resources/CSOS.png"), url('../../../resources/daniele-levis-pelusi-Vo6A7rwmAJk-unsplash 2.png');
    background-size: cover;
    background-position: center;
    height: 390px;
    margin-top: 0px;
}

@media screen and (max-width: 1250px) {
    .member_top_benefits_indiv_benefit_top_div {
        gap: 150px;
    }
}

@media screen and (max-width: 1250px) {
    .member_top_benefits_indiv_benefit_top_div {
        flex-direction: column;
        align-items: center;
        gap: 50px;
    }

    #member_top_benefits_indiv_last_benefit {
        flex-direction: column;
    }
}