.payment_course_top_div_margin {
    width: 1200px;
    margin: auto;
}

.payment_course_top_payment_header_div {
    position: absolute;
    top: 120px;
    left: 200px;
    font-size: 36px;
}

.payment_course_top_breadcrum {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    font-size: 25px;
}

.payment_course_select_course {
    height: 30px;
    /* border: 1px solid gray; */
    border: 0px;
    border-radius: 5px;
    color: white;
    padding: 0px 20px;
    width: 250px;
    background-color: #5bc0de;
}

.payment_course_select_course_event_div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 50px;
}

.payment_course_event_select_3_perc_p {
    margin-top: 20px;
    font-size: 13px;
}

.payment_course_event_select_course_list_div {
    display: grid;
    grid-template-columns: 60% 13% 10% 15%;
    gap: 20px;
    align-items: center;
    justify-items: start;
    justify-content: space-between;
}

.payment_course_select_course_quantity_input {
    width: 40px;
    height: 30px;
}

.payment_course_select_event_div {
    display: flex;
    gap: 15px;
    align-items: center;
    max-width: 350px;
}

.payment_course_select_event_next_button {
    background-color: #032c4b;
    /* background-color: #5469d4; */
    /* border-radius: 15px; */
    border: 0px;
    cursor: pointer;
    padding: 15px 20px;
    margin-top: 40px;
    border: 1px solid #032c4b;
    width: 100%;
    max-width: 400px;
    /* transition: background-color .5s, color .5s; */


    font-size: 20px;
    font-family: 'Tahoma';
    font-weight: 400;
}

.payment_course_select_event_next_button:hover {
    background-color: white;
    color: #032c4b;
}

.payment_course_event_select_course_list_total_div {
    display: grid;
    grid-template-columns: 65% 10% 25%;
    width: 100%;
}

.payment_course_event_select_order_header_diver {
    border: 2px solid rgba(174, 174, 174, 0.328);
    width: 100%;
    margin-top: 5px;
    margin-bottom: 10px;
}

.payment_course_event_select_order_verticle_diver {
    border: 1px solid rgba(174, 174, 174, 0.328);
    height: 100%;
    width: 0px;
    justify-self: center;
}

.payment_course_event_order_detail_div {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(174, 174, 174, 0.328);
}

#payment_course_event_order_total_div {
    border-bottom: 4px solid rgba(174, 174, 174, 0.328);
}


.payment_course_select_event_delete_div {
    cursor: pointer;
    font-size: 18px;
    color: black;
    margin-left: 30px;
}

.payment_course_information_header {
    font-size: 30px;
}

.payment_course_information_top_div {
    margin: auto;
    width: 50%;
    margin-top: 50px;
}

.payment_course_payment_order_contact_us_divider {
    height: 1px;
    width: 80%;
    margin: auto;
    background-color: rgba(167, 167, 167, 0.45);
    margin-top: 50px;
    margin-bottom: 100px;
}

.payment_course_contact_us_header_p {
    font-size: 30px;
    line-height: 40px;
}

.payment_course_contact_us_a {
    text-decoration: none;
    color: black;
}

.payment_course_contact_us_div {
    margin-top: 70px;
    margin-bottom: 50px;
    width: 300px;
    height: 200px;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: box-shadow .2s;
}

.payment_course_contact_us_div:hover {
    box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, .2);
}

.payment_course_email_us_smaller_div {
    padding-top: 30px;
}

.payment_course_email_us_smaller_div p {
    font-size: 21px;
}

.payment_course_email_p {
    color: #005aa2;
    font-size: 20px;
}

.payment_course_select_event_next_button_choose_event_hover_alert {
    position: absolute;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.2);
    background-color: white;
    border: 1px solid gray;
    border-radius: 10px;
    padding: 2px 10px;
    left: -200px;
    top: 39px;
}

.payment_course_select_event_next_button_choose_event_hover_alert_triangle {
    position: absolute;
    z-index: 2;
    top: 62px;
    left: -23px;
    background-color: white;
    border-top: 1px solid gray;
    border-right: 1px solid gray;
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
}

#finish_form_message_div {
    display: none;
    position: fixed;
    top: 170px;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    background-color: rgb(65, 65, 65);
    padding: 10px 20px;
    border-radius: 4px;
    color: rgb(201, 201, 201);

    z-index: 10;
}

.payment_checkout_form_cart_payment_method_div {
    background-color: #f1f1f1;
    border-radius: 10px;
    padding: 5px 25px;
    margin-bottom: 20px;
}

.payment_checkout_form_cart_payment_method_div:hover p {
    color: gray;
}

.payment_checkout_form_cart_payment_method_div p {
    font-size: 20px;
    transition: .2s;
}

#payment_method_ach_wire {
    margin-top: 30px;
}

.payment_method_open {
    display: initial;
}

.payment_method_close {
    display: none;
}

.payment_checkout_form_cart_item_top_div {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    padding: 40px 40px;
    padding-bottom: 60px;
    border-radius: 7px;
}

.payment_checkout_form_cart_item_top_inner_div {
    width: 100%;
}

.generate_invoice_buttn {
    margin-top: 30px;
    background-color: #5469d4;
    color: white;
    border: 0px;
    border-radius: 4px;
    width: 100%;
    padding: 15px;
    font-size: 18px;
    cursor: pointer;
}

.generate_invoice_buttn_disable {
    margin-top: 30px;
    background-color: #adb7e9;
    color: white;
    border: 0px;
    border-radius: 4px;
    width: 100%;
    padding: 15px;
    font-size: 18px;
}

.payment_checkout_form_cart_item_ach_wire_po_input {
    background-color: #f1f1f1;
    border: 0px;
    padding: 15px 20px;
    border-radius: 10px;
    font-size: 16px;
    width: calc(100% - 40px);
}

#terms_conditions_check_alert {
    display: none;
    position: fixed;
    top: 170px;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    background-color: rgb(65, 65, 65);
    padding: 10px 20px;
    border-radius: 4px;
    color: rgb(201, 201, 201);

    z-index: 10;
}

#generate_invoice_spinner {
    display: none;
    background-color: rgba(128, 128, 128, 0.357);
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;

    z-index: 10;
}

#generate_invoice_spinner_inner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

#generate_invoice_all_done {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: green;
    padding: 50px;
    background-color: white;
    border-radius: 10px;
}

#generate_invoice_error {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #AD2225;
    padding: 50px;
    background-color: white;
    border-radius: 10px;
}

@media screen and (max-width: 1250px) {
    .payment_course_top_div_margin {
        width: 1000px;
    }
}

@media screen and (max-width: 1100px) {
    .payment_course_top_div_margin {
        width: 850px;
    }

    .payment_course_event_select_course_list_total_div {
        grid-template-columns: 100%;
        gap: 20px;
    }

    .payment_course_select_event_next_button_choose_event_hover_alert {
        left: 315px;
        top: 113px;
    }

    .payment_course_select_event_next_button_choose_event_hover_alert_triangle {
        top: 105px;
        left: 400px;
        transform: rotate(-45deg);
    }
}

@media screen and (max-width: 900px) {
    .payment_course_top_div_margin {
        width: 700px;
    }

    .payment_course_information_top_div {
        width: 70%;
    }

    .payment_course_event_select_course_list_div {
        grid-template-columns: 50% 13% 10% 15%;
    }

    .payment_course_top_payment_header_div {
        font-size: 32px;
    }

    .payment_course_select_event_next_button_choose_event_hover_alert {
        left: 240px;
    }

    .payment_course_select_event_next_button_choose_event_hover_alert_triangle {
        left: 325px;
    }
}

@media screen and (max-width: 750px) {
    .payment_course_top_div_margin {
        width: 500px;
    }

    .payment_course_information_top_div {
        width: 80%;
    }

    .payment_course_select_event_img_div {
        display: none;
    }

    .payment_course_top_payment_header_div {
        font-size: 26px;
        left: 150px;
    }

    .payment_course_select_course {
        width: 200px;
    }

    .payment_course_select_event_next_button_choose_event_hover_alert {
        left: 140px;
    }

    .payment_course_select_event_next_button_choose_event_hover_alert_triangle {
        left: 225px;
    }
}

@media screen and (max-width: 550px) {
    .payment_course_top_div_margin {
        width: 400px;
    }

    .payment_course_top_breadcrum {
        font-size: 21px;
        gap: 5px;
    }

    .payment_course_event_select_course_list_div {
        grid-template-columns: 35% 20% 12% 20%;
        gap: 10px;
    }

    .payment_course_event_select_course_list_div p {
        font-size: 14px;
    }

    .payment_course_select_course_quantity_input {
        width: 25px;
        height: 25px;
    }

    .payment_course_top_payment_header_div {
        left: 100px;
        top: 160px;
        font-size: 24px;
    }

    .payment_course_select_course {
        width: 115px;
    }

    .payment_course_select_event_next_button_choose_event_hover_alert {
        left: 85px;
    }

    .payment_course_select_event_next_button_choose_event_hover_alert_triangle {
        left: 170px;
    }
}

@media screen and (max-width: 450px) {
    .payment_course_top_div_margin {
        width: 350px;
    }

    .payment_course_top_payment_header_div {
        left: 30px;
        font-size: 22px;
    }

    .payment_course_select_event_next_button_choose_event_hover_alert {
        left: 60px;
    }

    .payment_course_select_event_next_button_choose_event_hover_alert_triangle {
        left: 145px;
    }
}