.MembershipForumDetailMiddleSectionWhole {
    background-color: #F5F5F5;
    margin-bottom: 50px;
}

.MembershipForumDetailMiddleSectionInner {
    display: grid;
    grid-template-columns: 40% 60%;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    padding-top: 50px;
    padding-bottom: 50px;
}

@media screen and (max-width: 600px) {
    .MembershipForumDetailMiddleSectionInner {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        justify-content: space-between;
        padding-top: 50px;
        padding-bottom: 50px;
    }

}