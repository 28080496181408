.LoyaltyMembershipBenefitsCountUpwholeDiv {
    background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url('../../../../resources/Mask group234.jpg');

    background-position: center;
    height: fit-content;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 50px;
}