.home_page_du_events_top_div {
    margin-top: 50px;
    margin-bottom: 70px;
}

.home_page_du_events_speekers_top_div {
    display: grid;
    grid-template-columns: 30% 65%;
    justify-content: space-between;
    align-items: center;
}

.home_page_du_events_theme_indiv_div {
    background-size: cover;
    border-radius: 10px;
    width: 300px;
    height: 200px;
    cursor: pointer;
}

.home_page_du_events_theme_indiv_div:hover {
    overflow: hidden;
    background: rgba(29, 37, 113, 0.042);
    border-radius: 10px;
}

.home_page_du_events_theme_indiv_div_world {
    background: url('../../../resources/Mask group (4).png');
    background-size: cover;
}

.home_page_du_events_theme_indiv_div_world:hover {
    background: -webkit-linear-gradient(rgba(29, 38, 113, 0.8), rgba(195, 55, 100, 0.8)), url('../../../resources/Mask group (4).png');
    background: linear-gradient(rgba(29, 37, 113, 0.042), rgba(29, 38, 113, 0.6)), url('../../../resources/Mask group (4).png');
    background-size: cover;
}

.home_page_du_events_theme_indiv_div_syposia {
    background: linear-gradient(rgba(29, 37, 113, 0.311), rgba(29, 37, 113, 0.287)), url('../../../resources/regional.png');
    background-size: cover;
}

.home_page_du_events_theme_indiv_div_syposia:hover {
    background: -webkit-linear-gradient(rgba(29, 38, 113, 0.8), rgba(195, 55, 100, 0.8)), url('../../../resources/regional.png');
    background: linear-gradient(rgba(29, 37, 113, 0.042), rgba(29, 38, 113, 0.6)), url('../../../resources/regional.png');
    background-size: cover;
}

.home_page_du_events_theme_indiv_div_boardroom {
    background: url('../../../resources/Mask group (5).png');
    background-size: cover;
}

.home_page_du_events_theme_indiv_div_boardroom:hover {
    background: -webkit-linear-gradient(rgba(29, 38, 113, 0.8), rgba(195, 55, 100, 0.8)), url('../../../resources/Mask group (5).png');
    background: linear-gradient(rgba(29, 37, 113, 0.042), rgba(29, 38, 113, 0.6)), url('../../../resources/Mask group (5).png');
    background-size: cover;
}

.home_page_du_events_theme_indiv_div_charrettes {
    background: url('../../../resources/Mask group (6).png');
    background-size: cover;
}

.home_page_du_events_theme_indiv_div_charrettes:hover {
    background: -webkit-linear-gradient(rgba(29, 38, 113, 0.8), rgba(195, 55, 100, 0.8)), url('../../../resources/Mask group (6).png');
    background: linear-gradient(rgba(29, 37, 113, 0.042), rgba(29, 38, 113, 0.6)), url('../../../resources/Mask group (6).png');
    background-size: cover;
}

@media screen and (max-width: 1050px) {
    .home_page_du_events_speekers_top_div {
        grid-template-columns: 100%;
        gap: 50px;
    }
}