.AuditContact_Form_indiv_item_list{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;
}



.AuditContact_Form_indiv_item_list select{
    border: none;
    outline: none;
    height: 350px;
    /* overflow-x: scroll */
}
.AuditContact_Form_indiv_item_list select option:checked { 
    background-color: white; 
    color: #AD2225;
}


.AuditContact_Form_indiv_item_list select option{
    /* display: inline-block; */
    margin: 10px 10px;
    outline: none;
}

.AuditContact_Form_indiv_item_list select option::before {
  content: "\2610";
  font-size: 20px;
  width: 1.3em;
  text-align: center;
  display: inline-block;
}
.AuditContact_Form_indiv_item_list select option:checked::before {
  content: "\2611";
  font-size: 20px;
  color: #AD2225;
}
