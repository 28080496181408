.Training_stats_whole_div{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 50px;
    padding-bottom: 50px;
    flex-wrap: wrap;
    gap: 30px;
}

.Training_stats_box{
    display: flex;
    flex-direction: column;
    align-items: center;
    -moz-box-shadow: 0 0 3px #ccc;
    -webkit-box-shadow: 0 0 3px #ccc;
    box-shadow: 0 0 20px #ccc;
    width: 345px;
    height: 268px;
    justify-content: center;
    gap: 5%;
}

.Training_stats_red_stats{
    font-size: 30px;
}

.Training_stats_bottom_texts{
    font-size: 18px;
}