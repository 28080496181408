#global_research_price_top_banner_h1 {
    font-size: 48px;
}

#global_research_price_top_banner_h3 {
    font-size: 30px;
}

@media screen and (max-width: 1100px) {
    #global_research_price_top_banner_h1 {
        font-size: 40px;
    }

    #global_research_price_top_banner_h3 {
        font-size: 26px;
    }
}

@media screen and (max-width: 700px) {
    #global_research_price_top_banner_h1 {
        font-size: 36px;
    }

    #global_research_price_top_banner_h3 {
        font-size: 22px;
    }
}

@media screen and (max-width: 400px) {
    #global_research_price_top_banner_h1 {
        font-size: 32px;
    }

    #global_research_price_top_banner_h3 {
        font-size: 20px;
    }
}