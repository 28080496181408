.symposia_individual_events_subscribe_top_div {
    padding-bottom: 40px;
    padding-top: 10px;
}

.symposia_individual_events_subscribe_inner_div {
    display: grid;
    grid-template-columns: 60% 10%;
    justify-content: space-between;
    align-items: center;
}

.symposia_individual_events_subscribe_register_button {
    border: 0px;
    background-color: #4C0067;
    cursor: pointer;
    border-radius: 5px;
    width: 150px;
}

#symposia_individual_events_subscribe_register_p {
    font-size: 16px;
}

.symposia_individual_events_subscribe_top_div h2 {
    font-size: 26px;
}

.symposia_individual_events_subscribe_top_div p {
    font-size: 18px;
}

.symposia_individual_events_subscribe_top_div input {
    border: 0px;
    border-bottom: 1px dotted white;
    background-color: transparent;
    height: 30px;
    font-size: 18px;
}

@media screen and (max-width: 835px) {

    .symposia_individual_events_subscribe_inner_div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        gap: 50px;

    }
}