.training_get_in_touch_form_all_div_top {
    width: 100%;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    background-color: #F4F4F4;
}

.training_get_in_touch_form_top_head_div {
    background-color: #006197;
    text-align: center;
    padding: 1px 10px;
}

.training_get_in_touch_form_all_inputs_div {
    margin-top: 20px;
}

.training_get_in_touch_form_individual_input {
    width: 90%;
    max-width: 90%;
    min-width: 90%;
    height: 25px;
    padding: 10px;
    margin-top: 10px;
    font-family: Saira;
    font-size: 18px;
}

.training_get_in_touch_form_individual_input::placeholder {
    font-family: Saira;
    font-size: 14px;
}

.training_get_in_touch_form_submit_button {
    font-size: 17px;
}

.training_get_in_touch_form_country_select {
    height: 50px;
    margin-top: 10px;
    font-family: Saira;
    font-size: 17px;
    width: 310px;
}

.certification_get_in_touch_form_country_select {
    height: 50px;
    margin-top: 10px;
    font-family: Saira;
    font-size: 17px;
    width: 405px;
}

@media screen and (max-width: 1350px) {
    .training_get_in_touch_form_country_select {
        width: 275px;
    }
}

@media screen and (max-width: 600px) {
    .certification_get_in_touch_form_country_select {
        width: 315px;
    }
}

@media screen and (max-width: 450px) {
    .certification_get_in_touch_form_country_select {
        width: 245px;
    }
}