.MembershipSetUpCallOuterDiv {
    border: solid 1px #ABABAB;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 20px;
    border-radius: 5px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.MembershipSetUpCallNameDiv {
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.MembershipSetUpCallPostDiv {
    margin-top: 10px;
    margin-bottom: 20px;
}

.MembershipSetUpCallTexttDiv {
    margin-top: 10px;
}

.MembershipMainSetUpCallButton {
    border: solid 1px #AD2225;
    padding: 10px;
    background-color: white;
    cursor: pointer;
}

.MembershipMainSetUpCallButton:hover {
    background-color: #ABABAB;
    color: white;
}

.MembershipMainSetUpCallButton:active {
    background-color: black;
    color: white;
}

@media screen and (max-width: 731px) {
    .MembershipSetUpCallOuterDiv {
        flex-direction: column;
    }
}