.DigitalEconomyBottom_WholeDiv{
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 30px;
}

.DigitalEconomyBottom_Whole_whole{
    background-color: #F5F5F5;
}

.DigitalEconomyBottom_Buttons{
    display: grid;
    grid-template-columns: 30% 30% 30%;
    justify-content: space-between;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.DigitalEconomyBottom_Button{
    display: flex;
    border: none;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 78px;
    -moz-box-shadow: 0 0 3px #ccc;
    -webkit-box-shadow: 0 0 3px #ccc;
    box-shadow: 0 0 20px #ccc;
    /* background-color: white; */
    cursor: pointer;
    font-size: 14px;
}

.DigitalEconomyBottom_Button:hover{
    background-color: whitesmoke;
}

.DigitalEconomyBottom_Button:active{
    background-color: white;
}
.DigitalEconomyBottom_Text_header{
    font-size: 20px;
}

.DigitalEconomyBottom_Text{
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    background-color: white;
    -moz-box-shadow: 0 0 3px #ccc;
    -webkit-box-shadow: 0 0 3px #ccc;
    box-shadow: 0 0 20px #ccc;
    padding-top: 0px;
    margin-top: 0px;
    padding: 20px;
    padding-left: 80px;
    padding-right: 80px;
}

.DigitalEconomyBottom_Text_img{
width: 30%;
}
.DigitalEconomyBottom_Text_div{
    width: 60%;
}

.DigitalEconomyBottom_Text_Text{
    text-align: justify;
}

@media screen and (max-width: 1400px) {
    .DigitalEconomyBottom_Button_symbol{
        display: none;
    }
}

@media screen and (max-width: 1105px) {
    .DigitalEconomyBottom_Buttons{
        display: flex;
        /* flex-direction: column; */
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 20px;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }

    .DigitalEconomyBottom_Button{
        width: 250px;
    }

    .DigitalEconomyBottom_Text_Text{
        text-align: left;
    }

    .DigitalEconomyBottom_Text{
        padding: 20px;
        padding-left: 40px;
        padding-right: 40px;
    }
    
}


@media screen and (max-width: 1600px) {
    .DigitalEconomyBottom_Text_img{
        width: 40%;
    }
    .DigitalEconomyBottom_Text_div{
            width: 50%;
    }
}

@media screen and (max-width: 1220px) {
    .DigitalEconomyBottom_Text_img{
        width: 100%;
    }
    .DigitalEconomyBottom_Text_div{
            width: 100%;
    }

    .DigitalEconomyBottom_Text{
        display: flex;
        flex-direction: column;
        gap: 30px;
        align-items: center;
        flex-wrap: unset;
        /* justify-content: space-between; */
    }
}



@media screen and (max-width: 742px) {

    .DigitalEconomyBottom_Buttons{
        display: flex;
        flex-direction: column;
        /* flex-wrap: wrap; */
        justify-content: space-around;
        gap: 20px;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }

    .DigitalEconomyBottom_Button{
        width: unset;
    }

    .DigitalEconomyBottom_Button_symbol{
        display:block;
    }
    
}


@media screen and (max-width: 400px) {

    .DigitalEconomyBottom_Text{
        padding-left: 20px;
        padding-right: 20px;
    }
    
}

