.symposia_main_event_components_symposioms_top_div {
    background-color: #0D0C0C;
}

.symposia_main_event_components_symposioms {
    margin: 0px 10%;
    padding-top: 50px;
    margin-bottom: 30px;
}

.symposia_main_event_components_symposioms h2 {
    font-size: 28px;
    margin: 0px;
}

.symposia_main_event_components_symposioms_img_txt_header {
    position: relative;
}

.symposia_main_event_components_symposioms_world_top_div {
    background-color: #0D0C0C;
}

.symposia_main_event_components_symposioms_world_bg_img {
    position: absolute;
    left: 40%;
    width: 400px;
}

.symposia_main_event_components_studio_top_div {
    background: linear-gradient(180deg, #0D0C0C 0%, #34492D 100%);
}

.symposia_main_event_components_studios_bg_img {
    position: absolute;
    width: 300px;
}

.symposia_main_event_components_plays_top_div {
    background: linear-gradient(180deg, #354A2D 0%, #475243 100%);
}

.symposia_main_event_components_plays_bg_img {
    position: absolute;
    right: 0px;
    width: 400px;
}

.symposia_main_event_components_first_bg_img {
    position: absolute;
    width: 500px;
    /* bottom: 0px; */
}

.SymposiacountUpWholeOuterDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    /* gap: 100px; */
}


.SymposiacountUpEachSmallDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.SymposiacountUpEachSmallDivOuter {
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.symposia_main_event_components_executive_top_div {
    background: linear-gradient(180deg, #475243 0.92%, rgba(71, 82, 67, 0.19) 41.51%, #3A6B76 100%), linear-gradient(180deg, #475243 19.65%, #3A6B76 100%);
}

.symposia_main_event_components_charretts_bg_img {
    position: absolute;
    left: 40%;
    width: 400px;
}

.symposia_main_event_components_charrettes_top_div {
    background: linear-gradient(180deg, #3A6B76 0%, #125B6B 100%);
}

.symposia_main_event_components_edu_exclu_top_div {
    background: linear-gradient(180deg, #135C6C 0%, #0B1832 100%);
}

.symposia_main_event_studios {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    gap: 20px;
}


@media screen and (max-width: 1050px) {

    .symposia_main_event_components_charretts_bg_img,
    .symposia_main_event_components_plays_bg_img,
    .symposia_main_event_components_symposioms_world_bg_img {
        width: 300px;
    }

    .symposia_main_event_components_studios_bg_img {
        width: 250px;
    }
}

@media screen and (max-width: 850px) {

    .symposia_main_event_components_charretts_bg_img,
    .symposia_main_event_components_plays_bg_img,
    .symposia_main_event_components_symposioms_world_bg_img {
        width: 250px;
    }

    .symposia_main_event_components_studios_bg_img {
        width: 200px;
    }
}

@media screen and (max-width: 650px) {

    .symposia_main_event_components_charretts_bg_img,
    .symposia_main_event_components_plays_bg_img,
    .symposia_main_event_components_symposioms_world_bg_img {
        width: 200px;
    }

    .symposia_main_event_components_studios_bg_img {
        width: 180px;
    }
}

@media screen and (max-width: 500px) {

    .symposia_main_event_components_charretts_bg_img,
    .symposia_main_event_components_plays_bg_img,
    .symposia_main_event_components_symposioms_world_bg_img,
    .symposia_main_event_components_studios_bg_img {
        width: 150px;
    }

    .symposia_main_event_components_symposioms h2 {
        font-size: 20px;
    }
}

@media screen and (max-width: 330px) {
    .SymposiacountUpWholeOuterDiv {

        flex-direction: column;
    }
}