.data_center_training_indiv_courses_detail_inner_div {
    padding: 20px 40px;
    display: grid;
    grid-template-columns: 65% 2% 25%;
    justify-content: space-between;
    gap: 15px;
}

.data_center_training_indiv_courses_detail_inner_div p {
    font-size: 21px;
    line-height: 32px;
}

.data_center_training_indiv_courses_detail_img_text_div {
    display: flex;
    align-items: center;
    gap: 30px;
}

.data_center_training_indiv_courses_detail_img_text_div p {
    color: rgba(147, 40, 38, 1);
    font-size: 28px;
    line-height: 40px;
    font-weight: 700;
}

.data_center_training_indiv_courses_detail_img_text_divider {
    border-bottom: 1px solid rgba(215, 215, 215, 1);
    margin: 10px 0px;
}

.data_center_training_indiv_courses_detail_learn_more_div {
    width: fit-content;
}

.data_center_training_indiv_courses_detail_learn_more_div a {
    text-decoration: none;
}

.data_center_training_indiv_courses_detail_learn_more_div p {
    color: rgba(0, 97, 151, 1);
    font-weight: 800;
}

.data_center_training_indiv_courses_detail_attributes_div {
    display: flex;
    align-items: center;
    gap: 20px;
}

.data_center_training_indiv_courses_detail_attributes_div p {
    margin: 0px;
}

.data_center_training_indiv_courses_detail_attributes_divider {
    border-bottom: 1px solid rgba(215, 215, 215, 1);
    margin: 15px 0px;
}

.data_center_training_indiv_courses_detail_attributes_title {
    color: rgba(0, 97, 151, 1);
}

.data_center_training_indiv_courses_detail_divider {
    border-right: 1px solid rgba(215, 215, 215, 1);
}

.data_center_training_indiv_courses_detail_book_now_div {
    width: 100%;
    text-align: center;
    margin: auto;
    margin-top: 40px;
}

.data_center_training_indiv_courses_detail_book_now_div a {
    text-decoration: none;
}

.data_center_training_indiv_courses_detail_book_now_div p {
    border: 1px solid rgba(0, 97, 151, 1);
    color: rgba(0, 97, 151, 1);
    width: 100%;
    padding: 10px 0px;
    transition: .2s;
}

.data_center_training_indiv_courses_detail_book_now_div p:hover {
    border: 1px solid rgba(0, 97, 151, 1);
    background-color: rgba(0, 97, 151, 1);
    color: white;
}

.data_center_training_indiv_courses_img_div {
    min-width: 70px;
}

.data_center_training_indiv_courses_header_title_1 {
    font-size: 30px;
    line-height: 45px;
    margin: 17px 0px;
    text-align: left;
}

@media screen and (max-width: 1500px) {
    .data_center_training_indiv_courses_detail_inner_div {
        grid-template-columns: 100%;
    }

    .data_center_training_indiv_courses_detail_divider {
        border-bottom: 1px solid rgba(215, 215, 215, 1);
        border-right: 0px;
        margin: 20px 0px;
    }

    .data_center_training_indiv_courses_detail_attributes_top_div {
        display: grid;
        grid-template-columns: 40% 5% 40%;
        justify-content: space-between;
        row-gap: 30px;
    }

    .data_center_training_indiv_courses_detail_attributes_divider_2 {
        display: none;
    }

    .data_center_training_indiv_courses_detail_attributes_divider {
        border-bottom: 0px;
        border-right: 1px solid rgba(215, 215, 215, 1);
        margin: 0px;
    }

    .data_center_training_indiv_courses_detail_book_now_div {
        margin-top: 0px;
    }
}

@media screen and (max-width: 600px) {
    .data_center_training_indiv_courses_header_title_1 {
        font-size: 26px;
    }

    .data_center_training_indiv_courses_detail_img_text_div {
        display: none;
    }

    .data_center_training_indiv_courses_detail_img_text_divider {
        display: none;
    }

    .data_center_training_indiv_courses_detail_img_text_div p {
        font-size: 24px;
    }

    .data_center_training_indiv_courses_detail_attributes_top_div {
        display: grid;
        grid-template-columns: 100%;
        justify-content: space-between;
        row-gap: 10px;
    }


    .data_center_training_indiv_courses_detail_attributes_divider {
        border-bottom: 1px solid rgba(215, 215, 215, 1);
        border-right: 0px;
    }

    .data_center_training_indiv_courses_detail_attributes_divider_2 {
        display: initial;
    }
}