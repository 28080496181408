.user_dashboard_home_certifications_all_cert {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    max-width: 900px;
}

.user_dashboard_home_certifications_indiv_cert {
    width: 250px;
    position: relative;
}

.user_dashboard_home_certifications_indiv_cert_img_div {
    width: 100%;
    position: relative;
}

.user_dashboard_home_certifications_indiv_cert_img {
    width: 100%;
}

.user_dashboard_home_certifications_indiv_cert_img_badge {
    position: absolute;
    right: -20px;
    bottom: -20px;
    max-width: 90px;
    height: 90px;
}

.user_dashboard_home_certifications_indiv_cert_download_share {
    display: grid;
    justify-content: space-between;
    align-items: center;
    grid-template-columns: 53% 42%;
    margin: 30px 0px;
    margin-bottom: 0px;
}

.user_dashboard_home_certifications_indiv_cert_download {
    width: 100%;
    text-align: center;
    border: 1px solid #006197;
    cursor: pointer;
    display: flex;
    gap: 7px;
    justify-content: center;
    align-items: center;
    transition: .4s;
}

.user_dashboard_home_certifications_indiv_cert_download p {
    margin: 5px 0px;
    color: #006197;
    font-size: 14px;
}

.user_dashboard_home_certifications_indiv_cert_share {
    width: 100%;
    text-align: center;
    border: 1px solid #006197;
    background-color: #006197;
    cursor: pointer;
    display: flex;
    gap: 7px;
    justify-content: center;
    align-items: center;
    transition: .4s;
}

.user_dashboard_home_certifications_indiv_cert_share p {
    margin: 5px 0px;
    color: white;
    font-size: 14px;
}

.user_dashboard_home_certifications_indiv_cert_date_div {
    background-color: #F1F1F1;
    padding: 5px 10px;
    margin-top: 20px;
    text-align: start;
}

.user_dashboard_home_certifications_indiv_cert_date_div p {
    font-size: 14px;
    margin: 0px;
    color: #232323;
    line-height: 25px;
}

@media screen and (max-width: 1050px) {
    .user_dashboard_home_certifications_indiv_cert {
        width: 220px;
    }
}

@media screen and (max-width: 900px) {
    .user_dashboard_home_certifications_indiv_cert {
        width: 200px;
    }
}

@media screen and (max-width: 820px) {
    .user_dashboard_home_certifications_indiv_cert {
        width: 180px;
    }
}

@media screen and (max-width: 750px) {
    .user_dashboard_home_certifications_indiv_cert {
        width: 220px;
    }
}

@media screen and (max-width: 680px) {
    .user_dashboard_home_certifications_indiv_cert {
        width: 200px;
    }
}

@media screen and (max-width: 640px) {
    .user_dashboard_home_certifications_indiv_cert {
        width: 220px;
    }
}