.certification_2_0_professional_cert_top_div {
    margin-top: 50px;
    background-color: #F9F9F9;
    padding: 20px 40px;
    overflow: hidden;
    /* max-width: 100%; */
}

.certification_2_0_professional_cert_header_div {
    display: flex;
    align-items: center;
    gap: 20px;
    width: fit-content;
}

.certification_2_0_professional_cert_header_div_img {
    position: relative;
    width: 100%;
    aspect-ratio: 6;
    object-fit: contain;
}

.certification_2_0_professional_cert_header_a {
    text-decoration: none;
    width: fit-content;
    color: black;
}