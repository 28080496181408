.individual_course_2_course_review_indiv_top_div {
    position: relative;
}

.individual_course_2_course_review_indiv_div {
    margin: 50px 0px;
    display: flex;
    overflow: hidden;
    gap: 50px;
}

.individual_course_2_course_review_carousel {
    display: grid;
}

.individual_course_2_course_review_img_div {
    width: 100px;
}

.individual_course_2_course_review_detail {
    text-align: left;
    width: 80%;
}

.individual_course_2_course_review_comment {
    font-style: italic;
}

.individual_course_2_course_review_title_company {
    color: #006197;
}

@media screen and (max-width: 650px) {
    .individual_course_2_course_review_img_div {
        display: none;
    }

    .individual_course_2_course_review_detail {
        width: 100%;
    }
}