.certification_2_0_top_div {
    background-image: url('../../../resources/shutterstock_2019238490 [Converted] 2-min.png');
    background-size: cover;
    height: 500px;
}

.certification_2_0_top_text_img_div {
    padding-top: 150px;
    display: flex;
    gap: 50px;
    justify-content: space-between;
    align-items: center;
}

.certification_2_0_top_text_div {
    max-width: 500px;
}

.certification_2_0_top_text_div h1 {
    font-size: 50px;
}

.certification_2_0_top_img_div {
    position: relative;
    min-width: 400px;
    min-height: 250px;
    max-width: 400px;
    max-height: 250px;
}

@media screen and (max-width: 850px) {
    .certification_2_0_top_text_img_div {
        display: flex;
        flex-direction: column;
        gap: 0px;
    }

    .certification_2_0_top_text_div h1 {
        margin-bottom: 0px;
    }

    .certification_2_0_top_text_span {
        display: none;
    }
}