.individual_news_div {
    margin-top: 150px;
    margin-bottom: 50px;
    display: flex;
}

.individual_news_div2 {
    margin-left: 5%;
    width: 70%;
}

.individual_news_idca_news{
    display: flex;
    width: 25%;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px;
    margin-top: 30px;
    padding: 1.5%;
    height: fit-content;
}

@media screen and (max-width: 1050px) {
    .individual_news_idca_news{
        display: none;
    }
    .individual_news_div2 {
        margin-left: 0%;
        width: 100%;
    }
}

