.intellegenceResourceIndivInfoBox {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    margin: auto;
    flex-wrap: wrap;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    padding: 15px;
}

.intellegenceResourceIndivWhole {
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    margin-bottom: 50px;
    padding: 20px;
}

.intellegenceResourceIndivInfoBoxSingle {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.intellegenceResourceIndivTags {
    display: flex;
    gap: 20px;
}

.intellegenceResourceIndivFirstPart {
    display: flex;
    justify-content: space-between;
    gap: 50px;
}

.intellegenceResourceIndivFirstPartImage {
    max-width: 200px;
    width: 200px;
    position: relative;
    top: 25px;
}

@media screen and (max-width: 1300px) {
    .intellegenceResourceIndivFirstPartImage {
        width: 150px;
        position: relative;
        top: 25px;
    }
}

@media screen and (max-width: 1100px) {
    .intellegenceResourceIndivFirstPartImage {
        display: none;
    }
}