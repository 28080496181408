.Testimonial_Row {
    width: 70%;
    /* Try setting this to 400px or something */
    display: table;
    margin: auto;
}

.Testimonial_Cell {
    display: table-cell;
}

.IDCA_Testimonial_Card_Carousel {
    width: 70%;

}


.Testimonial_home_whole_div {
    background-image: url('../../../resources/Testimonial Background.png');
    padding-bottom: 50px;
}

.homepage_testimonial_title_div {
    background-color: rgba(29, 74, 172, 1);
    width: fit-content;
    padding: 10px 40px;
    line-height: 0px;
    margin: 0px;
}

.homepage_testimonial_title_div p {
    margin: 0px;
}

@media screen and (max-width: 1061px) {

    .IDCA_Testimonial_Card_Carousel {
        width: 80%;
    }
}

@media screen and (max-width: 916px) {

    .IDCA_Testimonial_Card_Carousel {
        width: 90%;
    }
}

@media screen and (max-width: 817px) {

    .IDCA_Testimonial_Card_Carousel {
        width: 95%;
    }
}

.Testimonial_p {
    width: 30%;
    font-size: 30px;
    /* or 133% */
    align-items: center;
    text-align: center;
    /* Gray 900 */
    color: #212121;
    margin-top: 0px;
}

@media screen and (max-width: 1306px) {

    .Testimonial_p {
        width: 40%;
    }
}


@media screen and (max-width: 981px) {

    .Testimonial_p {
        width: 50%;
    }
}

@media screen and (max-width: 785px) {

    .Testimonial_p {
        width: 60%;
    }
}

@media screen and (max-width: 600px) {

    .Testimonial_p {
        width: 80%;
    }
}

@media screen and (max-width: 490px) {

    .Testimonial_p {
        width: 90%;
    }
}

.Testimonial-header_hp_whole {
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-items: center;
    justify-content: center;
}

.Testimonial-head {
    font-size: 17px;
    line-height: 250%;
    align-items: center;
    /* Primary Color */
    color: #AD2225;
    margin-bottom: 0px;
    padding-bottom: 0px;
}