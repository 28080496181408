.news_subsection_div{
    display: grid;
    grid-template-columns: 45% 45%;
    justify-content: space-between;
}

@media screen and (max-width: 1300px) {
    .news_subsection_div{
    display: grid;
    grid-template-columns: 47% 47%;
    justify-content: space-between;
}
}


@media screen and (max-width: 780px) {
    .news_subsection_div{
    display: grid;
    grid-template-columns: 100%;
    justify-content: space-between;
}
}


@media screen and (max-width: 550px) {
    .news_subsection_div{
    display: grid;
    /* grid-template-columns: 90%; */
    justify-content: space-between;
}
}