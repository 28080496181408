.indiv_course_2_register_course_div {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.indiv_course_2_register_course_a {
    text-decoration: none;
    width: 80%;
}

.indiv_course_2_register_course_text_div {
    background-color: #006197;
    text-align: center;
}

.indiv_course_2_register_course_text_div p {
    padding: 10px;
}