.standard_features_top_div{
    margin-bottom: 3%;
}
.standards_featured_explanaiton{
    font-size: 18px; 
    line-height: 28px; 
    word-spacing: 8px;
}
.standards_featured_all_div{
    margin-top: 3%;
    display: grid;
    grid-template-columns: 32% 32% 32%;
    gap: 2%;
    justify-content: space-between;
    align-items: center;
}
.standards_featured_individual_div{
    padding: 10% 7%;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1); 
    word-spacing: 6px;
    align-self: stretch;
}
.standards_featured_individual_header_div{
    text-align: center;
}
.standards_featured_layer_img{
    width: 100%;
}

@media screen and (max-width: 1170px) {
.standards_featured_all_div{
    grid-template-columns: 45% 45%;
}
.standard_features_top_div{
    margin-bottom: 10%;
}
}
@media screen and (max-width: 800px) {
.standards_featured_all_div{
    grid-template-columns: 47% 47%;
}
.standards_featured_explanaiton{
    font-size: 17px; 
    line-height: 26px; 
    word-spacing: 6px;
}
.standard_features_top_div{
    margin-bottom: 13%;
}
}
@media screen and (max-width: 700px) {
.standards_featured_all_div{
    grid-template-columns: 70%;
    justify-content: center;
}
.standards_featured_explanaiton{
    font-size: 16px; 
    line-height: 24px; 
    word-spacing: 5px;
}
.standard_features_top_div{
    margin-bottom: 48%;
}
}
@media screen and (max-width: 590px) {
.standard_features_top_div{
    margin-bottom: 55%;
}
}
@media screen and (max-width: 530px) {
.standard_features_top_div{
    margin-bottom: 65%;
}
}
@media screen and (max-width: 460px) {
.standard_features_top_div{
    margin-bottom: 70%;
}
}
@media screen and (max-width: 420px) {
.standards_featured_all_div{
    grid-template-columns: 80%;
}
.standards_featured_explanaiton{
    font-size: 15px; 
    line-height: 23px; 
    word-spacing: 3px;
}
.standard_features_top_div{
    margin-bottom: 77%;
}
}
@media screen and (max-width: 380px) {
.standards_featured_all_div{
    grid-template-columns: 90%;
}
.standard_features_top_div{
    margin-bottom: 88%;
}
}
@media screen and (max-width: 350px) {
.standard_features_top_div{
    margin-bottom: 95%;
}
}
@media screen and (max-width: 320px) {
.standard_features_top_div{
    margin-bottom: 110%;
}
}