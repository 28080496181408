.user_portal_all_courses_upcoming_classes_all_div {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    align-items: center;
}

.user_portal_all_courses_upcoming_classes_carousel_page_div {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    padding-bottom: 30px;
}

.user_portal_all_courses_upcoming_classes_indiv_div {
    width: 300px;
    border: 2px solid #DDDDDD;
    border-radius: 10px;
    padding: 15px 20px;
}

.user_portal_all_courses_upcoming_classes_indiv_img__title_div {
    display: flex;
    gap: 20px;
    align-items: center;
}

.user_portal_all_courses_upcoming_classes_indiv_img__title_div p {
    font-size: 18px;
    color: #006197;
    font-weight: bold;
    text-align: left;
}

.user_portal_all_courses_upcoming_classes_indiv_img_div {
    position: relative;
    height: 50px;
    min-width: 50px;
}

.user_portal_all_courses_upcoming_classes_indiv_all_detail_div {
    margin-bottom: 15px;
}

.user_portal_all_courses_upcoming_classes_indiv_detail_div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.user_portal_all_courses_upcoming_classes_indiv_detail_div p {
    margin: 8px 0px;
}