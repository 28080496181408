.IndividualEventPartnersWholeDiv {
    background: linear-gradient(180deg, rgba(184, 184, 184, 0.56) 17.28%, rgba(255, 255, 255, 0.338) 100%);
    padding: 10px;
    margin-top: 50px;
}

.individual_event_du_partners_logo_map_div {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    margin-bottom: 40px;
}

.individual_event_du_partners_logo_indiv_div {
    position: relative;
    height: 120px;
    width: 120px;
    overflow: hidden;
}