.Dashboard_Right_Pannel_whole {
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    width: 90%;
    margin-bottom: 20px;
    margin-left: 20px;
}

.right_pannel_certificate_image {
    width: 30%;
    max-width: 500px;
    height: 30%;
    max-height: 500px;
}

.Dashboard_Right_Pannel_Certificate {
    max-width: 25%;
    margin: 20px;
    text-decoration: none;
}

@media screen and (max-width: 1500px) {
    .Dashboard_Right_Pannel_Certificate {
        max-width: 40%;
        margin: 20px;
    }
}

@media screen and (max-width: 1070px) {
    .Dashboard_Right_Pannel_Certificate {
        max-width: 60%;
        margin: 20px;
    }
}

@media screen and (max-width: 850px) {
    .Dashboard_Right_Pannel_Certificate {
        max-width: 80%;
        margin: 20px;
    }
}

@media screen and (max-width: 650px) {
    .Dashboard_Right_Pannel_Certificate {
        max-width: 100%;
        margin: 10px;
    }
}