.home_page_upcoming_events_invidividual_top_div {
    width: 280px;
    margin-bottom: 70px;
}

.home_page_upcoming_events_invidividual_img {
    position: relative;
    width: 100%;
    height: 150px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    overflow: hidden;
}

.home_page_upcoming_events_invidividual_type_div {
    background-color: #1D4AAC;
    margin: 0px;
    height: 35px;
    width: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.home_page_upcoming_events_invidividual_type_div p {
    position: relative;
    left: 18px;
    top: 5px;
}

.home_page_upcoming_events_invidividual_top_div a {
    position: relative;
    top: 10px;
    text-decoration: none;
    color: #621885;
    border: 1px solid #621885;
    border-radius: 10px;
    padding: 5px 40px;
}

.home_page_upcoming_events_invidividual_top_div a:hover {
    color: white;
    background-color: #621885;
}

.home_page_upcoming_events_invidividual_location_div {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-bottom: 10px;
}