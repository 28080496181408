.individ_courses_2_margin {
    margin: 0px 150px;
}

.individual_courses_2_body_top_div {
    margin-top: 40px;
    display: flex;
    gap: 120px;
    justify-content: space-between;
}

.individual_courses_2_body_right_div {
    min-width: 380px;
    max-width: 380px;
    position: relative;
    top: -108px;
}

.individual_courses_2_body_description_p {
    font-size: 18px;
    line-height: 25px;
}

#individual_courses_2_body_description_p_outer_id {
    display: none;
}

@media screen and (max-width: 1350px) {
    .individ_courses_2_margin {
        margin: 0px 100px;
    }

    .individual_courses_2_body_top_div {
        gap: 100px;
    }

    .individual_courses_2_body_right_div {
        min-width: 340px;
        max-width: 340px;
        top: -84px;
    }
}

@media screen and (max-width: 1000px) {
    .individ_courses_2_margin {
        margin: 0px 50px;
    }

    .individual_courses_2_body_top_div {
        gap: 50px;
    }

    .individual_courses_2_body_right_div {
        min-width: 340px;
        max-width: 340px;
        top: -84px;
    }
}


@media screen and (max-width: 850px) {

    #individual_courses_2_body_description_p_outer_id {
        display: inherit;
    }

    #individual_courses_2_body_description_p_id {
        display: none;
    }

    .individual_courses_2_body_description_p {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .individual_courses_2_body_top_div {
        flex-direction: column;
        flex-flow: column-reverse;
        gap: 20px;
    }

    .individual_courses_2_body_right_div {
        position: unset;
        /* top: 0px; */
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        gap: 60px;
        min-width: 100%;
    }

    .individual_courses_2_body_get_in_touch_div {
        width: 340px;
    }
}

@media screen and (max-width: 750px) {
    .individual_courses_2_body_right_top_div {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 0px;
    }

    .individual_courses_2_body_right_div {
        display: flex;
        flex-direction: column;
        align-items: center;
        /* min-width: 380px;
        max-width: 380px;
        width: 380px; */
        gap: 0px;
    }

    .individual_courses_2_body_get_in_touch_div {
        width: 340px;
    }
}