.CoursesSectionTraining_AllTraining{
    background-color: #FAFAFA;
    height: 700px;
}

.CoursesSectionTraining_AllTrainingCarousel{
    width: 70%;
    z-index: 0;
}

@media screen and (max-width: 1550px) {
   
    .CoursesSectionTraining_AllTrainingCarousel{
        width: 90%;
    }
}

@media screen and (max-width: 1417px) {
   
    .CoursesSectionTraining_AllTrainingCarousel{
        width: 80%;
    }
}

@media screen and (max-width: 1157px) {
   
    .CoursesSectionTraining_AllTrainingCarousel{
        width: 90%;
    }
}

@media screen and (max-width: 1073px) {
   
    .CoursesSectionTraining_AllTrainingCarousel{
        width: 80%;
    }
}

@media screen and (max-width: 865px) {
   
    .CoursesSectionTraining_AllTrainingCarousel{
        width: 85%;
    }
}