.home_page_standards_div{
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    column-gap: 10%;
}

.Audit_AuditAndStandards_left_side_image{
    min-width: 300px;
    aspect-ratio: 2/2.5;
    max-height: 400px
}

@media screen and (max-width: 950px) {
    .home_page_standards_div{
    display: flex;
    flex-direction:column-reverse;
    align-items: center;
    row-gap: 30px;
}
}