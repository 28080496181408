.audit_data_center_phase_text_button_div {
    display: flex;
    gap: 4%;
    justify-content: space-between;
    align-items: center;
}

.audit_data_center_phase_text_button_div a {
    display: flex;
}


.dc_audit_phases_whole_div {
    display: flex;
    gap: 40px;

}

.dc_audit_phases_phases_img {
    position: relative;
    padding-top: 50px;
    position: sticky;
    min-width: 540px;
    height: 400px;
    top: 100px;
}


@media screen and (max-width: 1350px) {
    .dc_audit_phases_whole_div {
        flex-direction: column;
        align-items: center;
    }

    .dc_audit_phases_phases_img {
        padding-top: 50px;
        /* position: unset; */
        /* min-width: 70%;
        max-width: 70%; */
        min-width: 600px;
        height: 400px;
        /* height: 30%; */
        top: 100px;
    }

    .audit_data_center_phase_text_button_div {
        display: flex;
        flex-direction: column;
        gap: 50px;
    }

    #audit_phase_div_num_2 {
        width: 100vw;
    }
}

@media screen and (max-width: 1000px) {
    .dc_audit_phases_phases_img {
        min-width: 80%;
        max-width: 80%;
    }
}

@media screen and (max-width: 770px) {
    .dc_audit_phases_phases_img {
        min-width: 90%;
        max-width: 90%;
        height: 350px;
    }
}

@media screen and (max-width: 550px) {
    .dc_audit_phases_phases_img {
        min-width: 100%;
        max-width: 100%;
        height: 290px;
    }
}