/* New */

.digital_economy_2024_related_media_indiv_title_h6 {
    font-size: 22px;
    color: #006197;
    margin: 0px;
}

.digital_economy_2024_related_media_header_div {
    margin: 70px 0px;
}

.digital_economy_2024_related_media_indiv_top_div {
    margin: 50px 0px;
}

.digital_economy_2024_related_media_indiv_detail_div {
    display: flex;
    gap: 70px;
}

.digital_economy_2024_related_media_indiv_detail_img_div {
    position: relative;
    min-width: 250px;
    max-width: 250px;
    height: 160px;
    overflow: hidden;
    border-radius: 20px;
}

.digital_economy_2024_related_media_indiv_detail_text {
    font-size: 18px;
    line-height: 27px;
    margin: 0px;
}

@media screen and (max-width: 1000px) {
    .digital_economy_2024_related_media_indiv_title_h6 {
        font-size: 20px;
        line-height: 30px;
    }

    .digital_economy_2024_related_media_indiv_detail_text {
        font-size: 17px;
        line-height: 25px;
    }

    .digital_economy_2024_related_media_indiv_detail_img_div {
        min-width: 220px;
        max-width: 220px;
        height: 140px;
    }

    .digital_economy_2024_related_media_indiv_detail_div {
        gap: 40px;
    }
}

@media screen and (max-width: 750px) {
    .digital_economy_2024_related_media_indiv_detail_img_div {
        min-width: 220px;
        max-width: 220px;
        height: 140px;
    }

    .digital_economy_2024_related_media_indiv_detail_div {
        gap: 30px;
        flex-direction: column-reverse;
        align-items: center;
    }
}

/* Old */

.Whole_div_DEInitiatives {
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px
}

.DEInitiative_div_whole img {
    width: 100%;
}

.DEInitiative_div_whole {
    display: flex;
    flex-direction: column;
    width: 400px;
    -moz-box-shadow: 0 0 3px #ccc;
    -webkit-box-shadow: 0 0 3px #ccc;
    box-shadow: 0 0 20px #ccc;
}


.Whole_div_DEInitiative_text_only {
    padding: 20px;
    padding-top: 0px;
    font-size: 16px;

}

.Whole_div_DEInitiative_header_only {
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.DEInitiative_readmore_Button {
    color: #AD2225;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding-left: 0px;
}

.DEInitiative_readmore_Button:hover {
    opacity: 0.7;
}

.DEInitiative_readmore_Button:active {
    opacity: 1.0;
}


.DEInitiative_Close_Button {
    color: #AD2225;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.DEInitiative_Close_Button:hover {
    opacity: 0.7;
}

.DEInitiative_Close_Button:active {
    opacity: 1.0;
}


.DEInitiative_readmore_text_class {
    display: none;
}

@media screen and (max-width: 1771px) {
    .Whole_div_DEInitiatives {
        gap: 80px
    }
}

@media screen and (max-width: 1258px) {
    .Whole_div_DEInitiatives {
        gap: 30px
    }
}

@media screen and (max-width: 1186px) {
    .Whole_div_DEInitiatives {
        gap: 20px
    }
}

@media screen and (max-width: 450px) {
    .DEInitiative_div_whole {
        width: 350px;
    }
}

@media screen and (max-width: 400px) {
    .DEInitiative_div_whole {
        width: 300px;
    }
}

@media screen and (max-width: 335px) {
    .DEInitiative_div_whole {
        width: 250px;
    }

    .Whole_div_DEInitiative_text_only {
        font-size: 14px;
    }
}