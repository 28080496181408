.digital_economy_2024_method_top_div {
    margin-top: 70px;
    margin-bottom: 70px;
    background-color: #F5F5F5;
    color: #959FB2;
    min-height: 400px;
    max-height: 400px;
}

.digital_economy_2024_method_top_inner_div {
    position: relative;
    top: -50px;
    display: flex;
    gap: 200px;
    justify-content: space-between;
    align-items: center;
}

.digital_economy_2024_method_header_h3 {
    font-size: 38px;
    color: #959FB2;
}

.digital_economy_2024_method_text_p {
    font-size: 20px;
    line-height: 35px;
}

.digital_economy_2024_method_img_div {
    position: relative;
    min-width: 800px;
    max-width: 800px;
    height: 500px;
}

@media screen and (max-width: 1600px) {
    .digital_economy_2024_method_top_div {
        min-height: 300px;
        max-height: 300px;
    }

    .digital_economy_2024_method_top_inner_div {
        gap: 50px;
    }

    .digital_economy_2024_method_header_h3 {
        font-size: 34px;
        margin: 0px;
    }

    .digital_economy_2024_method_text_p {
        font-size: 19px;
        line-height: 30px;
        margin-bottom: 0px;
    }

    .digital_economy_2024_method_img_div {
        min-width: 600px;
        max-width: 600px;
        height: 400px;
    }
}

@media screen and (max-width: 1250px) {
    .digital_economy_2024_method_top_div {
        min-height: 300px;
        max-height: 1000px;
        padding: 30px 0px;
        padding-top: 40px;
    }

    .digital_economy_2024_method_top_inner_div {
        top: 0px;
    }

    .digital_economy_2024_method_img_div {
        min-width: 500px;
        max-width: 500px;
        height: 350px;
    }
}

@media screen and (max-width: 1050px) {
    .digital_economy_2024_method_top_inner_div {
        gap: 20px;
        flex-direction: column;
    }
}

@media screen and (max-width: 600px) {
    .digital_economy_2024_method_header_h3 {
        font-size: 32px;
        line-height: 45px;
    }

    .digital_economy_2024_method_text_p {
        font-size: 18px;
        line-height: 27px;
    }

    .digital_economy_2024_method_img_div {
        min-width: 350px;
        max-width: 350px;
        height: 240px;
    }
}