.Indiv_Webinar_Guest_Webinar_List{
    display: grid;
    grid-template-columns: auto auto;
    gap: 50px;
    justify-content: center;
}

@media screen and (max-width: 1500px) {
    .Indiv_Webinar_Guest_Webinar_List{
        display: grid;
        grid-template-columns: auto;
        row-gap: 50px;
        justify-content:center;
    }    
}