.all_div_admin_member_margin {
    margin-left: 8%;
    box-shadow: 0px 9.43923px 63.7148px rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 50px 100px;
    margin-bottom: 50px;
}

.member_corp_admin_list_of_members_div {
    border-radius: 10px;
    display: grid;
    grid-template-columns: 25% 25% 25% 10% 10%;
    justify-content: space-between;
}

.member_corp_admin_list_of_members_edit_button {
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 5px 10px;
    height: fit-content;
    width: fit-content;
}

.member_corp_admin_list_of_members_edit_options {
    background-color: white;
    padding: 0px 10px;
    box-shadow: 0px 9.43923px 63.7148px rgba(0, 0, 0, 0.1);
}

.member_corp_admin_list_of_members_edit_options p {
    font-size: 14px;
    line-height: 10px;
    cursor: pointer;
}