.Certification_List_Main_Card_whole_div{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    column-gap: 30px;
    margin-top: 25px;
    margin-bottom: 25px;
    padding: 15px;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
    height: fit-content;
}


.Certification_List_Main_right_Side{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.Certification_List_Main_image{
    min-width: 300px;
}

.Certification_List_Main_title_header{
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
}

.Certification_List_Main_menu{
    display: flex;
    width: 20px;
    align-items: center;
    padding-top: 10px;
    column-gap: 30px;
}

.Certification_List_Main_button{
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.Certification_List_Main_button:hover{
opacity: 0.7;
}

.Certification_List_Main_button:active{
    opacity: 1;
    }

.Certification_List_Main_right_Side_title{
    padding-top: 0px;
    margin-top: 0px;
}

@media screen and (max-width: 1097px) {
    .Certification_List_Main_image{
        min-width: 250px;
    }
}

@media screen and (max-width: 930px) {
    .Certification_List_Main_image{
        display: none;
    }
}
/*   
@media screen and (max-width: 700px) {
    .Certification_List_Main_image{
        display: none;
    }
  } */
  