.member_admin_individual_mem_top_div {
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    padding: 50px 150px;
}

.member_admin_individual_mem_user_info {
    display: grid;
    grid-template-columns: 40% 40%;
    justify-content: space-between;
}

.member_admin_individual_mem_user_info p {
    color: #4F4F4F;
    font-size: 18px;
    border-bottom: 1px solid gray;
}

.member_admin_individual_mem_header_withe_edit_button {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.member_admin_individual_mem_edit_button {
    background-color: white;
    border: 1px solid gray;
    padding: 10px 20px;
    font-size: 17px;
    cursor: pointer;
}

.member_admin_individual_mem_save_button {
    background-color: #AD2225;
    border: 1px solid gray;
    padding: 10px 20px;
    font-size: 17px;
    cursor: pointer;
    margin-right: 10px;
}

.member_admin_individual_mem_user_info_edit_prev_role {
    background-color: #4F4F4F;
    color: white;
    width: fit-content;
    padding: 5px 10px;
    cursor: pointer;
}

.member_admin_individual_mem_user_info_edit_role_div {
    display: flex;
    gap: 20px;
    row-gap: 0px;
    flex-wrap: wrap;
}