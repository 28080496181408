/* New */

.digital-economy-top-div {
  height: 530px;
  background-image: url("../../../resources/DigitalEconomyTop_color.png"), url("../../../resources/DigitalEconomyTop.png");
  /* background-size: 100% 530px; */
  background-size: cover;
  /* background-attachment: fixed; */
  background-position: center;
  margin-bottom: 15px;
}

.home-page-digital-economy-div-top-most-image-div {
  /* position: relative;
  top: 85px; */
  height: 530px;
  background-size: cover;
  background-position: center;
}

.digital_economy_2024_top_header {
  padding-top: 150px;
}

.digital_economy_2024_top_breadcrum_div {
  display: flex;
  gap: 10px;
  align-items: center;
  color: #5CC7FF;
  margin: 0px;
  cursor: default;
  padding: 0px;
  font-size: 18px;
}

.digital_economy_2024_top_breadcrum_div a {
  text-decoration: none;
}

.digital_economy_2024_top_breadcrum_div a p:hover {
  transition: .3s;
  color: rgb(221, 221, 221);
}

.digital_economy_2024_top_header_text h1 {
  color: #5CC7FF;
  margin-bottom: 0px;
  font-size: 40px;
}

.digital_economy_2024_top_header_text h2 {
  margin-top: 0px;
  font-size: 50px;
}

@media screen and (max-width: 800px) {
  .digital_economy_2024_top_header_text h1 {
    font-size: 35px;
  }

  .digital_economy_2024_top_header_text h2 {
    margin-top: 20px;
    font-size: 40px;
    line-height: 50px;
  }
}

@media screen and (max-width: 500px) {
  .digital_economy_2024_top_header_text h1 {
    font-size: 30px;
  }

  .digital_economy_2024_top_header_text h2 {
    margin-top: 25px;
    font-size: 34px;
    line-height: 45px;
  }
}

/* Old */

#myVideoDigitalEconomy::-webkit-media-controls {
  display: none !important;
}

/* Style the list */
ul.digital-economy-breadcrumb {
  margin-left: 15%;
  padding-left: 0px;
  margin-bottom: 30px;
  text-align: left;
}

@media screen and (max-width: 625px) {
  ul.digital-economy-breadcrumb {
    margin-left: 10%;
  }
}

@media screen and (max-width: 500px) {
  ul.digital-economy-breadcrumb {
    margin-left: 3.5%;
  }

  #myVideoDigitalEconomy {
    display: none;
  }
}

/* Display list items side by side */
ul.digital-economy-breadcrumb li {
  display: inline;
  font-size: 14px;
}

/* Add a slash symbol (/) before/behind each list item */
ul.digital-economy-breadcrumb li+li:before {
  padding: 8px;
  color: black;
  content: "/\00a0";
}

/* Add a color to all links inside the list */
ul.digital-economy-breadcrumb li a {
  color: #AD2225;
  text-decoration: none;
}

/* Add a color on mouse-over */
ul.digital-economy-breadcrumb li a:hover {
  /* color: #01447e; */
  opacity: 0.7;
  text-decoration: underline;
}