.loyalty_who_willearn_point_top_div {
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: 20% 70%;
    justify-content: space-between;
    align-items: center;
}

.loyalty_who_willearn_point_img {
    position: relative;
    width: 200px;
    height: 200px;
}

@media screen and (max-width: 1060px) {
    .loyalty_who_willearn_point_top_div {
        grid-template-columns: 20% 70%;
    }

    .loyalty_who_willearn_point_img {
        width: 150px;
    }
}

@media screen and (max-width: 900px) {
    .loyalty_who_willearn_point_top_div {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        gap: 30px;
    }

    .loyalty_who_willearn_point_img {
        width: 100px;
    }
}