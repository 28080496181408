.GlobalMembershipBenefitsHeader {
    display: flex;
    align-items: center;
    justify-content: center;
}



.GlobalMembershipBenefitsAllItems {
    margin-top: 50px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.GlobalMembershipBenefitsRow {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    align-items: flex-start;
}

.GlobalMembershipBenefitsIndiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
}

.GlobalMembershipBenefitsIndivText {
    width: 70%;
    text-align: center;
}

.GlobalMembershipBenefitsCardsSection {
    display: flex;
    gap: 20px;
    flex-direction: column;
    margin-bottom: 30px;
}

.GlobalMembershipBenefitsCardsSectionSingleSection {}

.GlobalMembershipBenefits_cardflex {
    background-color: #F1F1F1;
    padding: 20px;
    height: 100%;
}

.GlobalMembershipBenefitsCardsSectionSingleCard {
    display: flex;
    gap: 30px;
    align-items: center;

}

.GlobalMembershipBenefitsCardsSectionSingleCardText {
    text-align: left;
    padding: 20px;
}


@media screen and (max-width: 1550px) {
    .GlobalMembershipBenefitsCardsSection {
        flex-direction: column;
    }
}

@media screen and (max-width: 998px) {
    .GlobalMembershipBenefitsCourselIndivtext {
        padding: 20px;
    }
}

@media screen and (max-width: 882px) {
    .GlobalMembershipBenefitsCardsSectionSingleCard {
        flex-direction: column;
    }

    .GlobalMembershipBenefits_cardflex {
        width: 100%;
    }
}


@media screen and (max-width: 733px) {
    .GlobalMembershipBenefitsRow {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
    }

    .GlobalMembershipBenefitsAllItems {

        flex-direction: row;
        gap: 50px;
        justify-content: space-around;
        align-items: center;
    }



}