.CommunitySkillsBoardSignUpNow {
    display: flex;
    background-color: #A81017;
    height: fit-content;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    margin-top: 70px;
    margin-bottom: 70px;
    gap: 50px;
}