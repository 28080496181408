.IntelligenceResoucesSingleWhitepaper:nth-child(odd) {
    background-color: #F1F1F1;
}

.IntelligenceResoucesSingleWhitepaper {
    padding: 30px;

}

.IntelligenceResoucesSortBy {
    display: flex;
    gap: 20px;
    padding-left: 30px;
}

.IntelligenceResoucesSortByOptions {
    display: flex;
}

.IntelligenceResoucesSortByOptionsButton {
    background-color: transparent;
    border: none;
    font-size: 15px;
}

.IntelligenceResoucesSortByOptionsButton:hover {
    color: #616161;
    cursor: pointer;
}

.IntelligenceResoucesCompeleteDivParent {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.IntelligenceResoucesListOfWhitePapers {
    width: 85%;
}

.IntelligenceResoucesSearchClearButton {
    width: 150px;
    height: 30px;
    border: none;
    background-color: #AD2005;
    margin-top: 20px;
    font-size: 15px;
}

@media screen and (max-width: 849px) {
    .IntelligenceResoucesListOfWhitePapers {
        width: 100%;
    }

    .IntelligenceResoucesCompeleteDivParent {
        flex-direction: column;
        gap: 20px;
    }

    .IntelligenceResoucesSearchClearButton {
        width: 150px;
    }
}