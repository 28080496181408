.MembershipCommunityLocalGathering_virtcal_line_ {
    margin-left: 30px;
    margin-right: 30px;
    border-left: 2px solid #ABABAB;
    height: 90px;
}

.MembershipCommunityLocalGathering_icon {
    size: 55;
}

.MembershipCommunityLocalGathering_h4_ {
    font-size: 16px;
}

.MembershipCommunityLocalGathering_h1_ {
    font-size: 30px;
}

.MembershipCommunityLocalGathering_para {
    text-align: center;
    line-height: 1.8;
    font-size: 16px;
}

@media screen and (max-width: 680px) {
    .MembershipCommunityLocalGathering_virtcal_line_ {
        height: 60px;
    }

    .MembershipCommunityLocalGathering_h4_ {
        font-size: 12px;
    }

    .MembershipCommunityLocalGathering_h1_ {
        font-size: 20px;
    }

    .MembershipCommunityLocalGathering_para {
        font-size: 14px;
    }
}

@media screen and (max-width: 320px) {
    .MembershipCommunityLocalGathering_virtcal_line_ {
        display: none;
    }

    .MembershipCommunityLocalGathering_icon {
        display: none;
    }

    .MembershipCommunityLocalGathering_h4_ {
        font-size: 8px;
    }

    .MembershipCommunityLocalGathering_h1_ {
        font-size: 14px;
    }

    .MembershipCommunityLocalGathering_para {
        font-size: 10px;
    }

    .MembershipCommunityLocalGathering_icon {
        display: none;
    }
}