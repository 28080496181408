.homepage_upcoming_webinar_top_div {
    background-image: url('../../../resources/Rectangle 1295.png');
    background-position: center;
    background-size: cover;
    padding: 60px 0px;
    padding-bottom: 10px;
}

.homepage_upcoming_webinar_title_line_div {
    border-bottom: 1px solid white;
}

.homepage_upcoming_webinar_title_div {
    background-color: white;
    padding: 10px 20px;
    padding-right: 100px;
    width: fit-content;
    margin-bottom: 0px;
}

.homepage_upcoming_webinar_title_div p {
    margin: 0px;
    color: rgba(13, 60, 151, 1);
}

.homepage_upcoming_webinar_contect_top_div {
    display: flex;
    align-items: top;
    gap: 40px;
}

.homepage_upcoming_webinar_image_div {
    min-width: 350px;
}

.homepage_upcoming_webinar_title {
    font-size: 32px;
    margin-bottom: 10px;
}

.homepage_upcoming_webinar_register_now {
    text-decoration: none;
}

.homepage_upcoming_webinar_register_now p {
    border: 1px solid white;
    width: fit-content;
    color: white;
    padding: 10px 40px;
    border-radius: 5px;
    transition: 0.2s;
}

.homepage_upcoming_webinar_register_now p:hover {
    border: 1px solid white;
    width: fit-content;
    color: rgba(13, 60, 151, 1);
    padding: 10px 40px;
    border-radius: 5px;
    background-color: white;
}

.homepage_upcoming_webinar_contect_top_div p {
    font-size: 20px;
    line-height: 25px;
}

@media screen and (max-width: 1200px) {
    .homepage_upcoming_webinar_image_div {
        min-width: 300px;
    }
}

@media screen and (max-width: 1000px) {
    .homepage_upcoming_webinar_image_div {
        display: none;
    }
}

@media screen and (max-width: 500px) {
    .homepage_upcoming_webinar_title {
        font-size: 26px;
    }

    .homepage_upcoming_webinar_title_line_div {
        display: none;
    }

    .homepage_upcoming_webinar_title_div {
        padding: 10px 20px;
    }

    .homepage_upcoming_webinar_contect_top_div p {
        font-size: 18px;
        line-height: 25px;
    }
}

@media screen and (max-width: 430px) {
    .homepage_upcoming_webinar_title {
        font-size: 24px;
        width: fit-content;
        width: 80%;
    }

    .homepage_upcoming_webinar_contect_top_div p {
        max-width: 80%;
    }

    .homepage_upcoming_webinar_top_div {
        overflow: hidden;
    }
}