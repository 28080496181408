.main-audit-contact-us-form-top-text {
    position: absolute;
    top: 20px;
    bottom: 20px;
    left: 60px;
    font-size: 30px;
    text-align: center;
}

.data-center-contact-us-form-header-div {
    padding-bottom: 20px;
}