.admin_pannel_coupon_all_coupon_list_div {
    margin: 0px 50px;
    width: 100%;
}

.admin_pannel_coupon_all_coupon_list_individual_div {
    display: grid;
    grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    height: fit-content;
}