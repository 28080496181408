.admin_event_list_individual_course_top_div {
    margin: 20px;
}

.admin_event_list_individual_course_div {
    display: grid;
    grid-template-columns: 15% 15% 10% 30% 15% 15%;
    align-items: center;
    padding: 0px 10px;
    border-radius: 10px;
}

.admin_event_list_individual_course_div_header {
    display: grid;
    grid-template-columns: 15% 15% 10% 30% 10% 10% 10%;
    align-items: center;
    padding: 0px 10px;
    border-radius: 10px;
}

.admin_event_list_outer_div {
    display: grid;
    grid-template-columns: 95% 5%;
    align-items: center;
    padding: 0px 10px;
    border-radius: 10px;
}

.admin_event_list_individual_course_div_0 {
    background-color: rgba(220, 220, 220, 0.491);
}

.admin_event_list_filter_top_div {
    display: flex;
    justify-content: space-around;
}

.admin_event_list_delete_button {
    height: 25px;
    font-size: 15px;
    text-align: center;
    background-color: transparent;
    cursor: pointer;
}