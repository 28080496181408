.webinar_new_description_first_webinar_top_div {
    margin-top: 50px;
    display: flex;
    gap: 100px;
}

.webinar_new_description_div h3 {
    font-size: 22px;
    margin-top: 0px;
}

.webinar_new_description_div p {
    font-size: 18px;
    margin-top: 0px;
}

.webinar_new_description_div span {
    font-size: 20px;
}

.webinar_new_first_webinar_top_div {
    min-width: 700px;
    height: fit-content;
    background-color: #F4F4F4;
    position: relative;
    padding: 20px 40px;
    padding-bottom: 40px;
}

.webinar_new_first_webinar_top_feature_div {
    background-color: #6840C0;
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 0px 20px;
    font-size: 16px;
}

.webinar_new_first_webinar_top_event_top_div {
    display: flex;
    gap: 30px;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.webinar_new_first_webinar_top_event_text_div {
    width: 400px;
}

.webinar_new_first_webinar_top_event_text_div h4 {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 800;
    color: #006197;
}


.webinar_new_first_webinar_top_event_description_div {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.webinar_new_first_webinar_register_div {
    text-decoration: none;
    color: #006197;
    border: 1px solid #006197;
    padding: 10px 40px;
}

.webinar_new_first_webinar_top_event_icon_text_div {
    display: flex;
    gap: 15px;
    align-items: center;
}

.webinar_new_first_webinar_top_event_icon_text_div p {
    margin: 10px 0px;
}

.webinar_new_first_webinar_top_event_img_div {
    position: relative;
    overflow: hidden;
    border-radius: 30px;
    height: 180px;
}

.webinar_new_first_webinar_top_event_img_div p {
    position: absolute;
    top: 0px;
    right: 15px;
    background-color: #BB2027;
    padding: 2px 15px;
    border-radius: 10px;
}

@media screen and (max-width: 2000px) {
    .webinar_new_first_webinar_top_div {
        min-width: 600px;
    }

    .webinar_new_first_webinar_top_event_text_div {
        width: 300px;
    }
}

@media screen and (max-width: 1600px) {
    .webinar_new_description_first_webinar_top_div {
        gap: 50px;
    }

    .webinar_new_first_webinar_top_div {
        min-width: 420px;
    }

    .webinar_new_first_webinar_top_event_text_div {
        width: 400px;
    }

    .webinar_new_first_webinar_top_event_img_div {
        display: none;
    }
}

@media screen and (max-width: 1300px) {
    .webinar_new_first_webinar_top_div {
        min-width: 370px;
    }

    .webinar_new_first_webinar_top_event_text_div {
        width: 370px;
    }
}

@media screen and (max-width: 1150px) {
    .webinar_new_first_webinar_top_div {
        min-width: 300px;
    }
}

@media screen and (max-width: 1050px) {
    .webinar_new_first_webinar_top_div {
        display: none;
    }
}