.digital_economy_2024_initiative_heading_div {
    display: grid;
    justify-content: space-between;
    align-items: center;
    grid-template-columns: 30% 30% 30%;
    text-align: center;
}

.digital_economy_2024_initiative_heading_divider {
    width: 100%;
    border-bottom: 1px solid #959FB2;
}

.digital_economy_2024_initiative_text_img_div {
    display: flex;
    gap: 200px;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 20px;
}

.digital_economy_2024_initiative_text_p {
    color: #006197;
    font-size: 24px;
    line-height: 35px;
}

.digital_economy_2024_initiative_list_all_div {
    display: flex;
    gap: 100px;
}

.digital_economy_2024_initiative_list_indiv_div {
    display: flex;
    gap: 15px;
    align-items: center;
}

.digital_economy_2024_initiative_list_indiv_text {
    font-size: 18px;
    margin: 10px 0px;
}

.digital_economy_2024_initiative_img_div {
    position: relative;
    width: 400px;
    height: 400px;
}

@media screen and (max-width: 1300px) {
    .digital_economy_2024_initiative_heading_div {
        grid-template-columns: 20% 50% 20%;
    }

    .digital_economy_2024_initiative_text_img_div {
        gap: 70px;
    }

    .digital_economy_2024_initiative_list_all_div {
        gap: 30px;
    }
}

@media screen and (max-width: 1150px) {
    .digital_economy_2024_initiative_heading_div {
        grid-template-columns: 100%;
        text-align: left;
    }

    .digital_economy_2024_initiative_heading_divider {
        display: none;
    }

    .digital_economy_2024_initiative_text_p {
        font-size: 20px;
        line-height: 30px;
    }

    .digital_economy_2024_initiative_text_img_div {
        gap: 50px;
    }

    .digital_economy_2024_initiative_list_all_div {
        gap: 20px;
    }

    .digital_economy_2024_initiative_list_indiv_text {
        font-size: 17px;
        margin: 7px 0px;
        line-height: 26px;
    }
}

@media screen and (max-width: 800px) {
    .digital_economy_2024_initiative_text_p {
        font-size: 20px;
        line-height: 30px;
    }

    .digital_economy_2024_initiative_text_img_div {
        gap: 30px;
    }

    .digital_economy_2024_initiative_list_all_div {
        gap: 15px;
    }

    .digital_economy_2024_initiative_list_indiv_text {
        font-size: 16px;
        line-height: 23px;
    }
}

@media screen and (max-width: 700px) {
    .digital_economy_2024_initiative_text_p {
        font-size: 19px;
        line-height: 26px;
    }

    .digital_economy_2024_initiative_text_img_div {
        gap: 30px;
        flex-direction: column;
        align-items: center;
    }

    .digital_economy_2024_initiative_list_all_div {
        gap: 0px;
        flex-direction: column;
    }

    .digital_economy_2024_initiative_img_div {
        width: 250px;
        height: 250px;
    }
}