#idca_partners_logo {
    position: relative;
    width: 50%;
    aspect-ratio: 1;
}

.idca_partners_carousel {
    margin-left: 5%;
    margin-right: 5%;
    padding-top: 0px;
    margin-top: 0px;
    padding-bottom: 30px;
}

.idca_partners_refer-head-HP-outer-div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.idca_partners_refer-head {
    display: flex;
    align-items: center;
    gap: 5px;
}

.idca_partners_refer_p {
    margin-top: 0px;
}