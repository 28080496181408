.user_portal_right_course_book_div {
    margin: auto;
    margin-top: 20px;
    width: 190px;
}

.user_portal_right_course_book_img_div {
    width: 100%;
    height: 170px;
    position: relative;
}

.user_portal_right_course_book_carousel {
    height: 280px;
    padding: 0px;
    margin: 0px;
}

@media screen and (max-width: 1350px) {
    .user_portal_right_course_book_div {
        width: 150px;
    }

    .user_portal_right_course_book_img_div {
        height: 140px;
    }
}

@media screen and (max-width: 1200px) {
    .user_portal_right_course_book_div {
        width: 100%;
    }

    .user_portal_right_course_book_img_div {
        height: 130px;
    }
}

@media screen and (max-width: 750px) {
    .user_portal_right_course_book_div {
        width: 200px;
    }

    .user_portal_right_course_book_img_div {
        height: 150px;
    }
}