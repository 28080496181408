.standards-download-top-div{
    height: 450px;
    background-image: url("../../../resources/Group 4875-min.png");
    background-size: cover;
    background-position: center;
    margin-bottom: 15px;
}

.Contact-us-breadcrumb{
    padding-left: 0px;
    margin-left: 15%;
    display: flex;
    flex-wrap: wrap;
}

/* Style the list */
ul.Contact-us-breadcrumb {
  margin-bottom: 30px;

}

/* Display list items side by side */
ul.Contact-us-breadcrumb li {
  display: inline;
  font-size: 14px;
  
}

/* Add a slash symbol (/) before/behind each list item */
ul.Contact-us-breadcrumb li+li:before {
  padding: 8px;
  color: black;
  content: "/\00a0";
  
}

/* Add a color to all links inside the list */
ul.Contact-us-breadcrumb li a {
  color: #AD2225;
  text-decoration: none;
  
}

/* Add a color on mouse-over */
ul.Contact-us-breadcrumb li a:hover {
  /* color: #01447e; */
  opacity: 0.7;
  text-decoration: underline;
}

