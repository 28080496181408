.video-card-class{
    width: 450px;
    height: 320px;
}

@media screen and (max-width: 605px) {
    .video-card-class{
        width: 400px;
        height: 285px;
    }
}

@media screen and (max-width: 430px) {
    .video-card-class{
        width: 380px;
        height: 250px;
    }
}
@media screen and (max-width: 396px) {
    .video-card-class{
        width: 330px;
        height: 220px;
    }
}  

@media screen and (max-width: 355px) {
    .video-card-class{
        width: 300px;
        height: 200px;
    }
}  