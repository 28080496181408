.login_and_create_account_top_div_frame {
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    background-color: white;
    padding: 50px 100px;
}

.login_and_create_account_top_div {
    display: grid;
    grid-template-columns: 40% 20% 40%;
    justify-items: center;
    align-items: center;
}

.login_to_account_pass_div {
    position: relative;
}

.login_to_account_pass_hidden {
    position: relative;
    top: -25px;
    float: right;
}

.login_to_account_login_div {
    width: 100%;
}

.login_to_account_login_button {
    text-align: center;
    font-size: 17px;
}

.login_to_create_account_line_div {
    border-left: 2px solid #616161;
    height: 300px;
}

.login_google_and_facebook_login_div {
    width: fit-content;
    margin: auto;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.login_facebook_login_div {
    display: flex;
    width: fit-content;
    cursor: pointer;
}

.login_facebook_logo_div {
    background-color: #2b5b937c;
    padding: 12px;
    padding-left: 16px;
    padding-right: 16px;
}

.login_account_facebook_button {
    background-color: #2B5C93;
    border: none;
    width: 192px;
    cursor: pointer;
    font-size: 15px;
}

.login_create_account_button_right {
    padding: 15px 70px;
}

.login_create_account_button_logo_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 70px;
}

@media screen and (max-width: 1300px) {
    .login_create_account_button_right {
        padding: 15px 50px;
    }
}

@media screen and (max-width: 1150px) {
    .login_create_account_button_right {
        padding: 15px 30px;
    }
}

@media screen and (max-width: 1050px) {
    .login_and_create_account_top_div {
        grid-template-columns: 100%;
        gap: 20px
    }

    .login_to_create_account_line_div {
        height: 20px;
        border-bottom: 2px solid #616161;
        border-left: 0px;
        width: 200px;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 500px) {
    .login_create_account_button_right {
        padding: 15px 20px;
        font-size: 15px;
    }
}

@media screen and (max-width: 400px) {
    .login_create_account_button_right {
        padding: 10px 15px;
        font-size: 14px;
        margin: auto;
    }

    .login_create_account_button_logo_div {
        width: max-content;
        align-content: center;
    }
}