.member_upcoming_event_date_icon_text {
    position: absolute;
    top: 19px;
    left: 19px;
    text-align: center;
}

.member_upcoming_event_register_button {
    background-color: #5A5A5A;
    border: 0px;
    padding: 5px 20px;
    cursor: pointer;
    font-size: 14px;
    text-decoration: none;
}

.user_dash_education_event_header_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user_dash_education_event_view_all_button {
    text-decoration: none;
    background-color: #AD2225;
    padding: 7px 15px;
    font-size: 14px;
}

.user_dash_education_event_view_all_button:hover {
    background-color: #ab4b4d;
}


/* was at 990px */
@media screen and (max-width: 900px) {
    .user_dash_education_event_header_div {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 40px;
        gap: 20px;
    }
}