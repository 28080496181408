.services_card {
    height: 352px; /* Should be removed. Only for demonstration */
    width: 500px;
    padding-top: 0.5px;
    padding-left: 10px;
    background-size: cover;
    margin-bottom: 70px;
  }
  
  .services_label{
       /* Rectangle 12 */
      width: 120px;
      height: 20px;
      /* Primary Color */
      background: #AD2225;
      border-radius: 50px;
      text-align: center;
      padding: 15px 0px;
      color: white;
      font-size: 14px;
   }
  .services_description{
      width: 80%;
      height: fit-content;
      background: white;
      margin-top: 220px;
      padding-top:  10px;
      padding-left: 30px;
      padding-right: 10px;
      padding-bottom: 20px;
      font-size: 20px;
  }
  
  .services_read_more{
      background-color: transparent;
      background-repeat: no-repeat; 
      border: none; 
      cursor: pointer;
      overflow: hidden;
      outline: none;
      color: #AD2225;
      text-align:left;
      text-decoration: none;
  }
  
  .one-edge-shadow {
      -webkit-box-shadow: 0 8px 6px -6px black;
         -moz-box-shadow: 0 8px 6px -6px black;
              box-shadow: 0 8px 6px -6px black;
  }
  

  @media screen and (max-width: 1535px) {
  
    .services_card {
      height: 300px; /* Should be removed. Only for demonstration */
      width: 396px;
    }
     
     .services_description{
        margin-top: 175px;
        font-size: 18px;
        padding-top:  1px;
        padding-left: 20px;
        height: 95px;
        padding-bottom: 25px;
      }
    }


  @media screen and (max-width: 1235px) {
  
    .services_card {
      height: 265px; /* Should be removed. Only for demonstration */
      width: 350px;
    }
     
     .services_description{
        margin-top: 125px;
        font-size: 16px;
        padding-top:  1px;
        padding-left: 20px;
        height: 80px;
        padding-bottom: 25px;
      }
    }
 
  
  @media screen and (max-width: 546px) {
  .services_card {
    height: 227.27px; /* Should be removed. Only for demonstration */
    width: 300px;
  }
   
   .services_description{
      margin-top: 100px;
      font-size: 13px;
      height: 75px;
    }
  }

  @media screen and (max-width: 380px) {
    .services_card {
      height: 192px; /* Should be removed. Only for demonstration */
      width: 250px;
    }
     
     .services_description{
        margin-top: 70px;
        font-size: 12px;
        height: 85px;
      }
    }
    
  
  