.standards_featured_explanaiton {
    margin-top: 40px;
}

.standard_grading_system_individual_grade_close {
    text-align: center;
    padding: 30px;
}

.standard_grading_system_individual_grade_open {
    padding: 7%;
    text-align: left;
}

.standard_grading_system_individual_grade_open_text {
    font-size: 15px;
}

.standard_grading_system_individual_grade_open_level_p {
    font-size: 28px;
}

@media screen and (max-width: 1150px) {
    .standard_grading_system_all_div {
        flex-direction: column;
    }

    .standard_grading_system_individual_grade_close {
        padding: 20px;
        padding-left: 8%;
        display: flex;
        align-items: center;
        gap: 7%;
    }

    .standard_grading_system_individual_grade_close h4 {
        font-size: 20px;
        line-height: 10px;
    }
}

@media screen and (max-width: 400px) {
    .standard_grading_system_individual_grade_open_level_p {
        font-size: 25px;
    }
}

@media screen and (max-width: 330px) {
    .standard_grading_system_individual_grade_open_level_p {
        font-size: 23px;
    }
}