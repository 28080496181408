.audit_data_center_phase_text_button_div{
    display: flex;
    gap: 4%;
    justify-content: space-between;
    align-items: center;
}
.audit_data_center_phase_text_button_div a{
    display: flex;
}


.it_audit_phases_whole_div{
    display: flex;
    gap: 40px;

}
.it_audit_phases_phases_img{
    padding-top: 50px;
    position: sticky;
    min-width: 50%;
    max-width: 50%;
    height: 30%;
    top: 100px;
}


@media screen and (max-width: 1350px) {
    .it_audit_phases_whole_div{
        flex-direction: column;
        align-items: center;
    }
    .it_audit_phases_phases_img{
        padding-top: 50px;
        position: unset;
        max-width: 70%;
        min-width: 70%;
        /* height: 30%; */
        top: 100px;
    }
    .audit_data_center_phase_text_button_div{
        display: flex;
        flex-direction: column;
        gap: 50px;
    }
    #audit_phase_div_num_2{
        width: 100vw;
    }
}

@media screen and (max-width: 1000px) {
    .it_audit_phases_phases_img{
        min-width: 80%;
        max-width: 80%;

    }
}
@media screen and (max-width: 770px) {
    .it_audit_phases_phases_img{
        min-width: 90%;
        max-width: 90%;

    }
}
@media screen and (max-width: 550px) {
    .it_audit_phases_phases_img{
        min-width: 100%;
        max-width: 100%;

    }
}