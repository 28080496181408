.Standard_About_Video_full_div{
    /* background-image: url("../../../resources/about_our_courses_bg.png"); */
    /* height: 500px; */
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* -webkit-flex-wrap: wrap; */
    /* flex-wrap: wrap; */
    flex-direction: row;
    margin-bottom: 12%;
}

#Standard_About_Video_iframe{
    order: 2;
    width: 40%;
    height: 320px;
    min-width: 400px;
    max-width: 600px;
}

.Standard_About_Video_Text_section{
    order: 1;
    width: 30%;
    font-size: 16px;
    margin-right: 50px;
    min-width: 500px;
    color: #303030;
}
.Standard_About_Video_Text_section_header{
    display: flex;
    flex-direction: row;
    row-gap: 20px;
    font-size: 20px;
    color: black;
    align-items: center;
}


.Standard_About_Video_Text_section_large_header{
    font-size: 25px;
}

.checkBoxTextStandard_About_Video{
    width: 12px;
}

.header-line{
    padding-bottom: 4px;
}
.Standard_About_Video_Text_section_header_text{
    font-size: 17px;
}

@media screen and (max-width: 1357px) {
   
.Standard_About_Video_Text_section{
    margin-left: 0px;
    width: 100%;
}

    #Standard_About_Video_iframe{

        width: 550px;
    }
    
    .Standard_About_Video_full_div{
        flex-direction: column;
    /* width: 100%; */
    justify-content: center;
    margin-bottom: 18%;

}
    
    .Standard_About_Video_Text_section_large_header{
    text-align: center;
    }
    
    .Standard_About_Video_Text_section_header{
    display: none;
    }

    .Standard_About_Video_Text_section_header_text{
        width: 100%;
        margin-left: 0px;
    }

}

@media screen and (max-width: 1050px) {
    .Standard_About_Video_full_div{
    margin-bottom: 20%;
    }
}

@media screen and (max-width: 890px) {
    .Standard_About_Video_full_div{
    margin-bottom: 8%;
    }
}

@media screen and (max-width: 675px) {
    #Standard_About_Video_iframe{
        width: 100%;
        margin-right: 0px;
        margin-left: 0px;
    }
} 

@media screen and (max-width: 572px) {

    #Standard_About_Video_iframe{
        /* width: 90%; */
        height: 250px;
        margin-right: 0px;
        margin-left: 0px;
    }
    
    .Standard_About_Video_Text_section{
        order: 1;
        width: 90%;
        font-size: 16px;
        color: #303030;
        min-width: 0px;
        margin-right: 0px;
        padding: 0px;
    }
   
} 

@media screen and (max-width: 437px) {

    #Standard_About_Video_iframe{
        min-width: 0px;
        height: 200px;
    }
    
    .Standard_About_Video_Text_section{
        order: 1;
        font-size: 15px;
        color: #303030;
        min-width: 0px;
        margin-right: 0px;
        padding: 0px;
    }

    .checkBoxTextStandard_About_VideoText{
        font-size: 14px;
    }

   
} 
