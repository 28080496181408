.ForumRecentPostsCarusel {
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    height: fit-content;
    margin-top: 75px;
    width: 100%;
    margin-bottom: 50px;
}

.ForumRecentPostsCaruselAndCategoriesCarusel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media screen and (max-width: 906px) {
    .ForumRecentPostsCaruselAndCategoriesCarusel {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .ForumRecentPostsCarusel {
        width: 100%
    }
}

.ForumCategoriesButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ForumCategoriesButton {
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 50px;
    border: none;
    font-size: 14px;
    text-align: center;
    border-radius: 4px;
}


.ForumRecentPostsCard {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;

}

.ForumRecentPostsCardContent {
    text-align: left;
    font-size: 14px;
}

.ForumRecentPostsCardContent a {
    text-decoration: none;
    color: #AD2225;
}

.ForumRecentPostsCardOpenDiscussionButton:hover {
    background-color: #ab4b4d;
}

.ForumRecentPostsCardContent a:visited {
    text-decoration: none;
    color: #d08385;
}

.FeaturedIntelCardButtons {
    display: flex;
    align-items: center;
}

.ForumRecentPostsCardOpenDiscussionButton {
    background-color: #AD2225;
    color: white;
    padding: 10px;
    width: 150px;
    cursor: pointer;
    margin-top: 20px;
    padding: 10px;
}

.FeaturedIntelCardDownloadButton:hover {
    background-color: #ab4b4d;
}

.ForumRecentPostsCardUserOpenLink {
    color: #424242;
}