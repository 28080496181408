.indiv_course_2_download_borch_top_div {
    margin-top: 40px;
    background-color: #F1F1F1;
    width: 100%;
    padding-bottom: 10px;
}

.indiv_course_2_download_borch_img_text_div {
    position: relative;
    top: -20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.indiv_course_2_download_borch_img_div {
    width: 100%;
    display: flex;
    justify-content: center;
}

.indiv_course_2_download_borch_img_inner_div {
    position: relative;
    width: 250px;
    height: 150px;
}

.indiv_course_2_download_borch_text {
    margin: 0px 10px;
    color: #006197;
    font-size: 18px;
}

.indiv_course_2_download_no_borch_img_inner_div {
    height: 50px;
}

@media screen and (max-width: 850px) {
    .indiv_course_2_download_borch_img_inner_div {
        width: 180px;
        height: 100px;
    }
}