.IndividualBoardMemeberBioSection{
    display: grid;
    grid-template-columns: 250px 70%;
    gap: 10%
}

.about_us_people_card_board_class{
  min-width: 250px;
  max-width: 250px;
}

.IndividualBoardMemeberWholeDiv{
    padding-top: 180px;
}

/* Style the list */
ul.individual-memeberg-breadcrumb {
    margin-left: 0px;
    padding-left: 0px;
    margin-bottom: 30px;
  }
  
  /* Display list items side by side */
  ul.individual-memeberg-breadcrumb li {
    display: inline;
    font-size: 14px;
  }
  
  /* Add a slash symbol (/) before/behind each list item */
  ul.individual-memeberg-breadcrumb li+li:before {
    padding: 8px;
    color: black;
    content: "/\00a0";
  }
  
  /* Add a color to all links inside the list */
  ul.individual-memeberg-breadcrumb li a {
    color: #AD2225;
    text-decoration: none;
  }
  
  /* Add a color on mouse-over */
  ul.individual-memeberg-breadcrumb li a:hover {
    opacity: 0.7;
    text-decoration: underline;
  }

@media screen and (max-width: 1000px) {
    .IndividualBoardMemeberBioSection{
        gap: 5%
    }
}


@media screen and (max-width: 900px) {
    .IndividualBoardMemeberBioSection{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
