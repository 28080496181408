.training-top-div {
  height: 530px;
  width: 100%;
  background-color: black;
  margin-bottom: 15px;
}

.training_top_image_dc {
  background-image: url("../../../resources/Data center training page.png");
  /* background-size: 100% 530px; */
  background-size: cover;
  /* background-attachment: fixed; */
  background-position: center;
  background-position-y: top;
  width: 100%;
  height: 530px;
}

.training_top_image_cloud {
  background-image: url("../../../resources/CloudTrainingTop.svg");
  /* background-size: 100% 530px; */
  background-size: cover;
  /* background-attachment: fixed; */
  background-position: center;
  background-position-y: top;
  width: 100%;
  height: 530px;
}

.training_top_image_cs {
  background-image: url("../../../resources/CyberSecurityTrainingTop.svg");
  /* background-size: 100% 530px; */
  background-size: cover;
  /* background-attachment: fixed; */
  background-position: center;
  background-position-y: top;
  width: 100%;
  height: 530px;
}

.training_top_dc_section {
  display: flex;
  flex-direction: column;
  padding-top: 200px;
  gap: 20px;
}

.training_top_larg_title {
  font-size: 40px;
  margin-top: 0px;
}

.training_top_larg_read_more_button {
  border: none;
  background-color: #AD2225;
  width: 151px;
  height: 50px;
  font-size: 14px;
  cursor: pointer;
}

.training_top_larg_read_more_button_link {
  width: 151px;

}

.training_top_larg_read_more_button:hover {
  opacity: 0.9;
}

.training_top_larg_read_more_button:active {
  opacity: 1.0;
}

@media screen and (max-width: 783px) {
  .training_top_larg_title {
    font-size: 30px;
  }

  .training_top_dc_section {
    gap: 30px;
  }
}

@media screen and (max-width: 625px) {
  ul.training-breadcrumb {
    margin-left: 10%;
  }
}

@media screen and (max-width: 500px) {
  ul.training-breadcrumb {
    margin-left: 3.5%;
  }
}

@media screen and (max-width: 400px) {
  .training_top_larg_title {
    font-size: 25px;
  }

  .training_top_dc_section {
    gap: 30px;
  }
}

@media screen and (max-width: 337px) {
  .training_top_larg_title {
    font-size: 20px;
  }

  .training_top_dc_section {
    gap: 30px;
  }
}






/* Style the list */
ul.training-breadcrumb {
  margin-left: 15%;
  margin-bottom: 30px;
  padding-left: 0px;
}

/* Display list items side by side */
ul.training-breadcrumb li {
  display: inline;
  font-size: 14px;
}

/* Add a slash symbol (/) before/behind each list item */
ul.training-breadcrumb li+li:before {
  padding: 8px;
  color: black;
  content: "/\00a0";
}

/* Add a color to all links inside the list */
ul.training-breadcrumb li a {
  color: #AD2225;
  text-decoration: none;
}

/* Add a color on mouse-over */
ul.training-breadcrumb li a:hover {
  /* color: #01447e; */
  opacity: 0.7;
  text-decoration: underline;
}