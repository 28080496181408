.symposiaTopBannerIndiv {
    padding-top: 20px;
    background-image: url("../../../resources/Group 6339-min.png");
    /* background-image: url("../../../resources/Rectangle 826.png"); */
    background-size: cover;
    background-position: center;
    height: fit-content;
    padding-bottom: 50px;
}

.IndividualEventsNewMainWholeDiv {
    /* background: linear-gradient(180deg, rgb(6, 31, 132) 0%, rgba(121, 121, 122, 0.7) 100%); */
    background-image: url("../../../resources/IndividualEventBackgroundImage.png");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.IndividualSymposiaServingTheWorld {
    background: linear-gradient(180deg, rgba(63, 40, 39, 0.7) 0%, rgba(10, 10, 10, 0.7) 100%);
    padding-top: 25px;
}

.IndividualSymposiaServingTheWorldHeader {
    font-size: 30px;
}

.symposiaTopBannerIndivHeader {
    font-size: 40px;
    margin-bottom: 0px;
    margin-top: 0px;
}

.symposiaTopBannerIndivHeaderh3_1 {
    line-height: 30px;
    margin-top: 10px;
}

.individual_events_get_involved_form_indiv_field_input {
    border: 0px;
    border-bottom: 1px solid black;
    font-size: 15px;
}

.individual_du_event_sponsors_top_div {
    width: 600px;
}

@media screen and (max-width: 800px) {
    .individual_du_event_sponsors_top_div {
        width: 500px;
    }
}

@media screen and (max-width: 600px) {
    .individual_du_event_sponsors_top_div {
        width: 400px;
    }
}

@media screen and (max-width: 450px) {
    .individual_du_event_sponsors_top_div {
        width: 300px;
    }
}

@media screen and (max-width: 385px) {
    .IndividualSymposiaServingTheWorldHeader {
        font-size: 25px;
    }

    .symposiaTopBannerIndivHeader {
        font-size: 30px;
    }
}

@media screen and (max-width: 385px) {
    .IndividualSymposiaServingTheWorldHeader {
        font-size: 20px;
    }
}