.individual_courses_2_upcoming_events_top_div {
    box-shadow: 0px 0px 46px -5px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-top: 30px;
}

.individual_courses_2_upcoming_events_header_div {
    padding: 10px;
    border-bottom: 1px solid #D7D7D7;
}

.individual_courses_2_upcoming_events_header_p {
    font-size: 22px;
    color: #006197;
    text-align: center;
}

.individual_courses_2_upcoming_events_all_events_top_div {
    margin: 0px 20px;
}

.individual_courses_2_upcoming_events_all_events_inner_div {
    display: grid;
    grid-template-columns: 30% 5% 60%;
    justify-content: space-between;
}

.individual_courses_2_upcoming_events_all_events_date_div {
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.individual_courses_2_upcoming_events_all_events_date_div p {
    margin: 0px;
    color: #006197;
    padding: 5px 10px;
}

.individual_courses_2_upcoming_events_indiv_1 {
    background-color: #F9F9F9;
}

.individual_courses_2_upcoming_events_all_events_date_0 {
    background-color: #F9F9F9;
    border-radius: 5px;
}

.individual_courses_2_upcoming_events_all_events_date_1 {
    background-color: white;
    border-radius: 5px;
}

.individual_courses_2_upcoming_events_indiv_date_location_divide {
    margin: auto;
    border-left: 1px solid #606060a3;
    height: 50%;
}

.individual_courses_2_upcoming_events_all_events_div {
    max-height: 180px;
    overflow: auto;
}

.individual_courses_2_upcoming_events_all_events_div a {
    text-decoration: none;
}

.individual_courses_2_upcoming_events_all_event_button_top_div {
    display: flex;
    justify-content: center;
    margin: 20px;
    padding-bottom: 20px;
}


.individual_courses_2_upcoming_events_all_event_button_div a {
    background-color: #006197;
    border: 1px solid #006197;
    padding: 7px 40px;
    text-decoration: none;
    color: white;
    transition: .2s;
}

.individual_courses_2_upcoming_events_all_event_button_div a:hover {
    background-color: white;
    color: #006197;
}

@media screen and (max-width: 750px) {
    .individual_courses_2_upcoming_events_top_div {
        min-width: 340px;
    }
}

@media screen and (max-width: 450px) {
    .individual_courses_2_upcoming_events_top_div {
        min-width: 320px;
    }
}