/* .home-page-digital-economy-div{ */
/* background-image: url("../../../resources/HomePageFirstImg.png");
    opacity: 1;
    background-size: 100% 671px;
    height: 671px;
    background-size: cover;
    background-attachment: fixed;
    background-position: center; */

/* } */

.home-page-digital-economy-top-div-image-div {
    /* background-attachment: fixed; */
    background-position: center;
    height: 700px;
    background-size: cover;
}

#myVideo::-webkit-media-controls {
    display: none !important;
}

.home-page-digital-economy-div-mobile-top-image {
    display: none;
}

.home-page-digital-economy-div video {
    object-fit: cover;
    width: 100vW;
    height: 670px;
    position: center;
    z-index: 1;
}

.home-page-digital-economy-div-text-button {
    text-align: left;
    position: absolute;
    top: 220px;
    margin-left: 22%;
    color: #FFFFFF;
}

/* .home-page-digital-economy-div-main-div{
} */

.home-page-digital-economy-div-main-text {
    width: 65%;
    font-size: 40px;
    line-height: 57px;
    margin: 27px 0px;
}

.Button_Arrow_bHeight_sWidthFill {
    /* margin: auto; */
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    align-items: center;
    padding: 18px 0px;

    width: 138px;
    height: 50px;

    text-decoration: none;
    /* Primary Color */

    background: #D82027;
}

.Button_Arrow_bHeight_sWidthFill:hover {
    background: #b30f15;
}

.home_page_digital_economy_carousel_second_div_top {
    background-image: url('../../../resources/home-slider.jpg');
    /* background-attachment: fixed; */
    background-position: center;
    height: 625px;
    background-size: cover;
}

.home_page_digital_economy_carousel_second_div_inner {
    padding-top: 150px;
    text-align: left;
    width: 40%;
}

.home_page_digital_economy_carousel_second_div_top_title {
    color: #00fffe;
    font-size: 30px;
    line-height: 45px;
    margin-bottom: 10px;
}

.home_page_digital_economy_carousel_second_div_main_title {
    font-size: 30px;
    line-height: 40px;
}

.home_page_digital_economy_carousel_second_div_date {
    display: flex;
    align-items: center;
    gap: 15px;
}

.home_page_digital_economy_carousel_second_div_a {
    text-decoration: none;
    color: #b69c17;
    border: 1px solid #b69c17;
    border-radius: 5px;
    padding: 10px 40px;
}

.home_page_digital_economy_carousel_second_div_a:hover {
    background-color: #b69c1765;
    color: white;
}

@media screen and (max-width: 1250px) {
    .home_page_digital_economy_carousel_second_div_inner {
        width: 50%;
    }

    .home_page_digital_economy_carousel_second_div_top {
        background-image: url('../../../resources/cybersecurity-webinar-bg.jpg');
        /* background-attachment: fixed; */
        background-position: center;
        height: 625px;
        background-size: cover;
    }
}

/* @media screen and (max-width: 950px) {
    .home_page_digital_economy_carousel_second_div_top_title {
        display: none;
    }
} */

@media screen and (max-width: 550px) {
    .home_page_digital_economy_carousel_second_div_inner {
        width: 70%;
    }

    .home_page_digital_economy_carousel_second_div_main_title {
        font-size: 28px;
        line-height: 35px;
    }
}

@media screen and (max-width: 1100px) {
    .home-page-digital-economy-div-main-text {
        width: 70%;
    }
}

@media screen and (max-width: 870px) {
    .home-page-digital-economy-div-main-text {
        width: 75%;
        font-size: 36px;
    }
}

@media screen and (max-width: 660px) {
    .home-page-digital-economy-div-text-button {
        top: 180px;
    }

    .home-page-digital-economy-div-main-text {
        width: 80%;
    }
}

@media screen and (max-width: 500px) {

    .home-page-digital-economy-div-text-button {
        margin-left: 14%;
    }

    #myVideo {
        display: none;
    }

    .home-page-digital-economy-div-mobile-top-image {
        display: unset;
    }
}

@media screen and (max-width: 450px) {
    .home-page-digital-economy-div-main-text {
        width: 85%;
        font-size: 34px;
    }
}

@media screen and (max-width: 380px) {
    .home-page-digital-economy-div-main-text {
        font-size: 30px;
    }
}