@media screen and (max-width: 650px) {
    .latam_football_cup_about_video_iframe {
        width: 400px;
        height: 350px;
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 500px) {
    .latam_football_cup_about_video_iframe {
        width: 350px;
        height: 300px;
    }
}

@media screen and (max-width: 450px) {
    .latam_football_cup_about_video_iframe {
        width: 250px;
        height: 200px;
    }
}