.Cyber-training-top-div {
  height: 500px;
  /* background-image: url("../../../resources/CyberSecurityTrainingTop.svg"); */
  /* background-size: cover;
    background-position: center; */
  background-position-y: top;
  margin-bottom: 15px;
}

/* Style the list */
.Cyber-training-breadcrumb {
  margin-left: 10%;
  padding-left: 0px;
  margin-bottom: 30px;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
}

/* Display list items side by side */
ul.Cyber-training-breadcrumb li {
  display: inline;
  font-size: 14px;

}

/* Add a slash symbol (/) before/behind each list item */
ul.Cyber-training-breadcrumb li+li:before {
  padding: 8px;
  color: black;
  content: "/\00a0";

}

/* Add a color to all links inside the list */
ul.Cyber-training-breadcrumb li a {
  color: #AD2225;
  text-decoration: none;

}

/* Add a color on mouse-over */
ul.Cyber-training-breadcrumb li a:hover {
  /* color: #01447e; */
  opacity: 0.7;
  text-decoration: underline;
}