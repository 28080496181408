.data-center-contact-us-form-div {
    width: 377px;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    background-color: white;
    /* margin-right: 100px; */
}

.data-center-contact-us-form-header-div {
    position: relative;

}

.data-center-contact-us-form-top-bg-image {
    width: 100%;
    max-width: 100%;
}

.data-center-contact-us-form-top-text {
    position: absolute;
    top: 40px;
    left: 20px;
    font-size: 30px;
}

.data-center-contact-us-form-div-form {
    display: flex;
    flex-direction: column;
    margin: 30px;
    margin-top: 0px;
}

.data-center-contact-us-form-two-col-div {
    display: flex;
    gap: 7%;
    justify-content: space-between;
}

.data-center-contact-us-form-input-label {
    margin-top: 30px;
    line-height: 0px;
}

.data-center-contact-us-form-input-box {
    border-top: transparent;
    border-left: transparent;
    border-right: transparent;
    outline: none;
    height: 25px;
    font-size: 16px;
    width: 100%;
}


/* .data-center-contact-us-form-input-box::click{

    border-top: transparent;
    border-left: transparent;
    border-right: transparent;
    height: 20px;
    background: chocolate;
} */

#data-center-contact-us-form-submit-button {
    cursor: pointer;
    width: 288px;
    height: 50px;
    background: #AD2225;
    border: transparent;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
}

.data-center-contact-us-form-submit-button {
    cursor: pointer;
    width: 288px;
    height: 50px;
    background: #AD2225;
    border: transparent;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
}

.data-center-contact-us-form-consent-div {
    display: flex;
    align-content: center;
}

.data-center-contact-us-form-consent-checkbox {
    cursor: pointer;
    margin-top: 30px;
    width: 18px;
    height: 18px;
    margin-right: 15px;
    background: rgb(143, 65, 65);
}

.data-center-contact-us-form-consent-checkbox:checked {
    background: rgb(143, 65, 65);
}

.data-center-contact-us-form-consent-label {
    margin-top: 10px;
}

@media screen and (max-width: 1550px) {
    .data-center-contact-us-form-two-input-box {
        width: 120px;
    }

    .data-center-contact-us-form-div {
        width: 340px;
    }
}

@media screen and (max-width: 430px) {
    #data-center-contact-us-form-submit-button {
        width: 240px;
    }

    .data-center-contact-us-form-submit-button {
        width: 240px;
    }

    .data-center-contact-us-form-two-col-div {
        flex-direction: column;
    }

    .data-center-contact-us-form-input-box {
        width: 100%;
    }

    .data-center-contact-us-form-top-text {
        position: absolute;
        top: 40px;
        left: 20px;
        font-size: 26px;
    }

    .data-center-contact-us-form-input-label {
        font-size: 13px;
    }

    .data-center-contact-us-form-div {
        width: 300px;
    }
}

@media screen and (max-width: 370px) {

    #data-center-contact-us-form-submit-button {
        width: 220px;
    }

    .data-center-contact-us-form-submit-button {
        width: 220px;
    }

    .data-center-contact-us-form-div {
        width: 280px;
    }

    .data-center-contact-us-form-top-bg-image {
        height: 200px;
    }
}

@media screen and (max-width: 340px) {
    #data-center-contact-us-form-submit-button {
        width: 190px;
    }

    .data-center-contact-us-form-submit-button {
        width: 190px;
    }

    .data-center-contact-us-form-div {
        width: 250px;
    }
}