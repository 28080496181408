.DashboardAnnouncementAdminWholeDiv {
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    height: fit-content;
    align-items: center;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 50px;
    margin-bottom: 50px;

}

.DashboardAnnouncementAdminFormDesscription {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

#DashboardAnnouncementAdminFormDescriptionID {
    height: 200px;
}

.DashboardAnnouncementAdminFormSubmitButton {
    background-color: #AD2005;
    color: white;
    width: 150px;
    height: 30px;
}

.DashboardAnnouncementAdminFormSearchDiv {
    display: flex;
    margin-top: 10px;
    margin-bottom: 20px;
    align-items: center;
}