.latam_football_cup_result_seminar_photos_photo_div_1 {
    display: grid;
    grid-template-columns:
        15% 15% 15% 15% 15% 15%;
    justify-content: space-between;
    margin-bottom: 20px;
}

.latam_football_cup_result_seminar_photos_photo_div_2 {
    display: grid;
    grid-template-columns:
        20% 20% 20% 20%;
    justify-content: space-between;
    margin-bottom: 50px;
}

@media screen and (max-width: 1050px) {
    .latam_football_cup_result_seminar_photos_photo_div_1 {
        grid-template-columns:
            30% 30% 30%;
        row-gap: 20px;
    }

    .latam_football_cup_result_seminar_photos_photo_div_2 {
        grid-template-columns:
            30% 30% 30%;
        row-gap: 20px;
    }
}