.admin_course_list_top_div {
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    padding: 50px;
}

.admin_event_attendee_list_div {
    width: auto;
    display: grid;
    grid-template-columns: 30% 30% 20% 20%;
    gap: 2%;
    align-items: center;
    padding: 0px 10px;
    border-radius: 10px;
}



.admin_event_detail_input {
    border: 1px solid #ccc;
    font-family: inherit;
    font-size: inherit;
    padding: 1px 6px;
    background-color: #ccc;
}

.admin_event_detail_input_edit {
    border: 1px solid #ccc;
    font-family: inherit;
    font-size: inherit;
    padding: 1px 6px;
}

.admin_course_add_user_to_course_divider_div {
    width: 100%;
    height: 1px;
    background-color: black;
    margin-bottom: 50px;
}

.individual_user_add_course_button_center_class {
    background-color: #AD2225;
    color: white;
    border: 0px;
    border-radius: 10px;
    padding: 0px 20px;
    font-size: 15px;
    cursor: pointer;
}

.individual_user_add_course_button_center_class:hover {
    background-color: #ad2224d9;
}

.admin_course_add_existing_nonexisting_user_to_course_divider_div {
    width: 60%;
    height: 2px;
    background-color: rgba(0, 0, 0, 0.227);
    margin: 50px 0px;
    align-self: center;
}

.admin_course_add_user_top_div {
    flex-direction: column;
    justify-content: space-between;
    padding: 0px 40px;
    align-items: center;
}

.admin_course_add_existing_user_top_div {
    width: 100%;
    border: 1px dotted gray;
    border-radius: 10px;
    padding: 20px 40px;
    /* background-color: rgb(198, 198, 198); */
}

.admin_course_add_new_user_top_div {
    /* width: 100%; */
    border: 1px dotted gray;
    border-radius: 10px;
    padding: 20px 40px;
    display: flex;
    justify-content: center;
}

.individual_user_add_existing_course_button_center_class {
    background-color: transparent;
    border: 0px;
    border-radius: 5px;
}

.admin_event_detail_sponsors_div {
    position: relative;
    object-fit: contain;
    width: 150px;
    height: 150px;
}

.admin_event_detail_sponsors_delete_div {
    position: absolute;
    top: -10px;
    right: -17px;
    cursor: pointer;
}

.admin_course_add_att_next_div {
    border: 1px solid #AD2225;
    background-color: #AD2225;
    border-radius: 10px;
    cursor: pointer;
    width: 150px;
    height: 52px;
    margin-top: 40px;
    color: white;
    text-align: center;
    transition: .2s;
}

.admin_course_add_att_next_div:hover {
    border: 1px solid #AD2225;
    background-color: white;
    color: #AD2225;
}

#add_user_event_spinner {
    display: none;
    background-color: rgba(128, 128, 128, 0.357);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    z-index: 100;
}

#adding_attendee_all_done {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: green;
    padding: 50px;
    background-color: white;
    border-radius: 10px;
}

#adding_attendee_error {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #AD2225;
    padding: 50px;
    background-color: white;
    border-radius: 10px;
}