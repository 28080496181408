.individual_event_page_top_div {
    background-image: url('../../resources//Data center training page.png');
    background-size: cover;
    height: 700px;
}

.individual_event_top_title {
    font-size: 36px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}

.individual_event_top_date {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}

.individual_event_testimonial_div {
    background-image: url('../../resources/blue-idca-banner.jpg');
    background-size: cover;
}

.individual_event_testimonial_text {
    width: 100%;
    text-align: center;
    padding: 5% 15%;
}

.individual_event_testimonial_img {
    /* object-fit: cover; */
    width: 900px;
    height: 300px;
    position: relative;
}

.individual_event_detail_sponsors_div {
    position: relative;
    object-fit: contain;
    width: 200px;
    height: 200px;
}

.individual_event_top_register_now_button_a {
    text-decoration: none;
    margin: auto;
}

.individual_event_top_register_now_button_p {
    background-color: #AD2225;
    border: 1px solid #AD2225;
    padding: 15px 50px;
    margin: 0px;
    font-size: 20px;
    border-radius: 15px;
    transition: 0.3s;
}

.individual_event_top_register_now_button_p:hover {
    background-color: transparent;
    border: 1px solid white;
    color: white;
    padding: 15px 50px;
    margin: 0px;
    font-size: 20px;
}

@media screen and (max-width: 1550px) {
    .individual_event_testimonial_text {
        padding: 2% 10%;
    }
}

@media screen and (max-width: 1350px) {
    .individual_event_testimonial_text {
        font-size: 20px;
        padding: 5% 10%;
    }

    .individual_event_testimonial_img {
        width: 600px;
        height: 250px;
    }
}

@media screen and (max-width: 1250px) {
    .individual_event_testimonial_img {
        width: 600px;
        height: 250px;
    }
}

@media screen and (max-width: 1100px) {
    .individual_event_testimonial_text {
        /* font-size: 18px; */
        padding: 5% 8%;
    }
}

@media screen and (max-width: 950px) {
    .individual_event_testimonial_text {
        /* font-size: 17px; */
        padding: 5% 5%;
    }
}

@media screen and (max-width: 750px) {
    .individual_event_testimonial_text {
        /* font-size: 16px; */
        line-height: 23px;
        padding: 5% 5%;
    }
}

@media screen and (max-width: 650px) {
    .individual_event_testimonial_img {
        display: none;
    }

    .individual_event_testimonial_div {
        margin-top: 30px;
    }

    .individual_event_top_title {
        font-size: 30px;
        line-height: 40px;
        margin-top: 30px;
    }

    .individual_event_top_time_span {
        display: none;
    }
}

@media screen and (max-width: 450px) {
    .individual_event_testimonial_text {
        /* font-size: 15px; */
        line-height: 21px;
        padding: 5% 5%;
    }

    .individual_event_testimonial_div {
        margin-top: 45px;
    }

    .individual_event_top_title {
        font-size: 28px;
    }
}

@media screen and (max-width: 360px) {
    .individual_event_top_title {
        font-size: 26px;
    }
}

@media screen and (max-width: 300px) {
    .individual_event_top_date {
        line-height: 24px;
    }
}