.symposiaTopBanner {
  padding-top: 20px;
  background-image: url("../../../resources/SymposiaTop.png");
  background-size: cover;
  background-position: center;
  height: fit-content;
  min-height: 500px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.symposiaTopBannerHeader {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-top: 110px;
}


.symposiaTopBannerHeaderText {
  font-size: 40px;
}

.symposiaTopBannerlowerText {
  font-size: 18px;
  line-height: 1.4;
}

.symposiaTopBannerLogoImgDiv {
  min-width: 220px;
}

.symposiaAttendeeListButton {
  text-align: center;
  width: 170px;
  padding: 15px;
  background-color: #AD2225;
  border: none;
  font-size: 15px;
  cursor: pointer;
}

.symposiaHeaderButton {
  display: flex;
  gap: 50px;
}

.symposiaBecomeSpeakerButton {
  width: 200px;
  padding: 15px;
  background-color: transparent;
  border: 1px solid white;
  font-size: 15px;
  cursor: pointer;
}

.symposiaBecomeSpeakerButton:active {
  background-color: rgb(0, 0, 0);
}

.symposiaAttendeeListButton:active {
  background-color: rgb(0, 0, 0);
}

.symposiaServingTheWorld {
  background: linear-gradient(180deg, #3F2827 0%, #0D0C0C 100%);
}


.symposiaServingTheWorldInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
}


@media screen and (max-width: 935px) {

  .symposiaTopBannerLogoImgDiv {
    display: none;
  }

  .symposiaHeaderButton {
    flex-direction: column;
    gap: 10px;
  }
}