.symposiaExecutiveBoardroomTopBannerIndiv {
    padding-top: 20px;
    background-image: url("../../resources/Group 6339 (2)-min.png");
    background-size: cover;
    background-position: center;
    height: 510px;
    padding-bottom: 50px;
    margin: 0px;
}

.ExecutiveBoardroomsNewMainWholeDiv {
    background-image: url("../../resources/Group 6386 (2) 1.png");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}