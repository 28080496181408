.Testimonial_new_carousel_About_Us{
    width: 100%;
}

.Testimonial-new-head_About_Us{
    padding-top: 20px;
    font-family: Saira;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 250%;
    color: #D82027;
}

.Testimonial_new_p_About_Us{
    font-family: Saira;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    color: #212121;
}

