.meet_greet_top_div_img {
    background-image: url('../../resources/idca-dc-1.jpg');
    background-size: cover;
    background-position: center;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 100px;
}

.meet_greet_form_class {
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.15);
    padding: 40px 50px;
    width: 600px;
}

.meet_greet_form_class select {
    width: 175px;
}

@media screen and (max-width: 500px) {
    .meet_greet_form_class {
        width: 270px;
    }

    .meet_greet_form_class input {
        width: 100px;
    }

    .meet_greet_form_class select {
        width: 100px;
    }
}