.Dashboard_Left_Pannel_Button {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding-left: 5px;
    width: 100%;
    height: 30px;
    gap: 20px;
    background-color: transparent;
    border: 0px;
    cursor: pointer;
    font-size: 15px;
    color: black
}

.Dashboard_Whole_Div {
    margin-top: 150px;
    display: flex;
}

.Dashboard_Left_Pannel_Profile_Pic {
    border-radius: 50%;
    aspect-ratio: 1;
}

.Dashboard_Left_Pannel_Profile_Pic_cursor {
    cursor: pointer;
}

.Dashboard_Left_Pannel_Button:hover {
    color: #AD2225;
}

.Dashboard_Left_Pannel_buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.Dashboard_Left_Pannel_whole {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 200px;
    height: fit-content;
    padding: 20px;
    background-color: white;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    z-index: 20;
}

#dashboard_left_pannel_button_and_menu_div_id {
    display: flex;
    z-index: 10;
}

.dashboard_left_pannel_expond_button_div {
    cursor: pointer;
    min-width: 50px;
    height: 100px;
    background-color: #AD2225;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}