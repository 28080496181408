#Testimonial_new_img{
  width: 40%;
  aspect-ratio: 1;
  object-fit:cover;
  border-radius: 50%;
  min-width: 80px;
}

.Testimonial_new_body{
  display: grid;
  grid-template-columns: 60% 20%;
  justify-content: space-between;
  width: 100%;
  gap: 20%;
  padding-left: 0%;
  padding-right: 5%;
  align-items: center;
}

.Testimonial_new_person{
  justify-self: end;
  text-align: center;
}

.Testimonial_new_comment{
  text-align: left;
  padding-bottom: 30px;
}


@media screen and (max-width: 1411px) {
  .Testimonial_new_body{
    padding-left: 5%;
    padding-right: 0%;
  }

  .Testimonial_new_body{
    grid-template-columns: 60% 30%;
    gap: 10%;
  }
  
}


@media screen and (max-width: 639px) {
  .Testimonial_new_body{
    padding-right: 2%;
  }
}

@media screen and (max-width: 575px) {
  .Testimonial_new_body{
    padding-right: 4%;
  }
}

@media screen and (max-width: 650px) {
  .Testimonial_new_body{
    grid-template-columns: auto;
    gap: 5%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  #Testimonial_new_img{
    width: 20%;
  }
  .Testimonial_new_comment{
    margin-left: 5%;
    margin-right: 5%;
    text-align: center;
  }
  .Testimonial_new_person{
        padding-bottom: 20%;
        align-self: center;
        width: 100%;
  }
  
}