.news-letter-sub-div {
    height: 114px;
    background: #AD2225;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.news-letter-sub-div-icon-text {
    margin-left: 200px;
    display: flex;
    align-items: center;
    position: relative;
}

.news-letter-sub-mail-icon {
    /* position: relative; */
    /* top: 10px; */
}

.news-letter-sub-mail-text-div {
    display: inline-block;
    position: relative;
    margin-left: 22px;
}

.news-letter-sub-email-input {

    margin-right: 200px;
}

.news-letter-sub-email-input-button {
    width: 493px;
    height: 61px;
    position: relative;
}

.news-letter-sub-email-input-email {
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
    border-radius: 1px;
    border-color: transparent;
    outline: none;
    padding-left: 10px;
}

.news-letter-sub-email-input-submit {
    position: absolute;
    top: 10px;
    right: 0px;
    border-radius: 1px;
    /* z-index: 2; */
    border: none;
    height: 44px;
    width: 100px;
    cursor: pointer;
    color: #FFFFFF;
    background-color: #500306;
    transform: translateX(2px);
}

@media screen and (max-width: 1600px) {
    .news-letter-sub-div-icon-text {
        margin-left: 150px;
    }

    .news-letter-sub-email-input {
        margin-right: 150px;

    }
}

@media screen and (max-width: 1400px) {
    .news-letter-sub-div-icon-text {
        margin-left: 125px;
    }

    .news-letter-sub-email-input {
        margin-right: 125px;
    }

    .news-letter-sub-email-input-button {
        width: 400px;
    }
}

@media screen and (max-width: 1200px) {

    .news-letter-sub-mail-text-div {
        font-size: 14px;
    }

    .news-letter-sub-email-input-button {
        width: 350px;
    }
}

@media screen and (max-width: 1150px) {
    .news-letter-sub-div-icon-text {
        margin-left: 100px;
    }

    .news-letter-sub-email-input {
        margin-right: 100px;
    }
}

@media screen and (max-width: 975px) {
    .news-letter-sub-div {
        height: 175px;
        flex-direction: column;
    }

    .news-letter-sub-div-icon-text {
        margin: auto;
    }

    .news-letter-sub-email-input-button {
        width: 400px;
    }

    .news-letter-sub-email-input {
        margin: auto;
        position: relative;
        bottom: 10px;
    }
}

@media screen and (max-width: 550px) {
    .news-letter-sub-mail-icon {
        width: 40px;
    }

    .news-letter-sub-mail-text-div {
        font-size: 14px;
    }

    .news-letter-sub-email-input-button {
        width: 350px;
    }
}

@media screen and (max-width: 450px) {
    .news-letter-sub-mail-icon {
        display: none;
    }

    .news-letter-sub-mail-text-div {
        font-size: 12px;
    }

    .news-letter-sub-email-input-button {
        width: 260px;
    }

    .news-letter-sub-mail-text-div {
        margin-left: 10px;
    }

    .news-letter-sub-email-input-submit {
        top: 15px;
        height: 35px;
        width: 75px;
    }
}