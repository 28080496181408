.home_page_top_banner_training_top_div {
    background-image: url('../../../resources/Group 7448 (1).jpg');
    background-size: cover;
    height: 700px;
    text-align: left;
}

.home_page_top_banner_standard_div {
    background-image: url('../../../resources/Group 427319869 (1) (1).png');
    background-size: cover;
    background-position: center;
    background-position-y: top;
    height: 700px;
    text-align: left;
}

.home_page_top_banner_standard_text_img_div {
    display: flex;
    align-items: flex-start;
    gap: 50px;
}

#hp_tp_bn_stand_learn_more_div {
    margin-top: 40px;
}

.home_page_top_banner_standard_img_div {
    min-width: 250px;
    max-width: 250px;
}

.home_page_top_banner_standard_inner_div {
    padding-top: 180px;
}

.home_page_top_banner_sub_title_img_div {
    display: flex;
    justify-content: flex-start;
    gap: 30px;
    align-items: center;
}

.home_page_top_banner_sub_title_img_icon_div {
    width: 100px;
}

#hp_tp_bn_stand_sub_title {
    color: rgba(255, 199, 9, 1);
}

.home_page_top_banner_audit_div {
    background-image: url('../../../resources/Mask group (8) (1).png');
    background-size: cover;
    background-position: center;
    background-position-y: top;
    height: 700px;
    text-align: left;
}

.home_page_top_banner_audit_img_div {
    position: relative;
    max-width: 900px;
    height: 105px;
}

#hp_tp_bn_audit_learn_more {
    margin-top: 50px;
}

.home_page_top_banner_digit_econ_div {
    background-image: url('../../../resources/Mask group (9) (1).png');
    background-size: cover;
    background-position: center;
    background-position-y: top;
    height: 700px;
    text-align: left;
}

.home_page_top_banner_digit_econ_inner_div {
    padding-top: 220px;
}

.home_page_top_banner_digit_econ_learn_more_a {
    font-size: 18px;
    text-decoration: none;
    color: rgba(134, 237, 255, 1);
    border-bottom: 1px solid rgba(134, 237, 255, 1);
    transition: .2s;
    min-width: fit-content;
}

.home_page_top_banner_digit_econ_learn_more_a:hover {
    color: rgba(134, 237, 255, 0.485);
    border-bottom: 1px solid rgba(134, 237, 255, 0.485);
}

.home_page_top_banner_cert_div {
    background-image: url('../../../resources/Mask group (6) (1).png');
    background-size: cover;
    background-position: center;
    background-position-y: top;
    height: 700px;
    text-align: left;
}

.home_page_top_banner_cert_inner_div {
    padding-top: 150px;
}

.home_page_top_banner_cert_title_p {
    margin-top: 0px;
}

.home_page_top_banner_cert_texts_p {
    color: rgba(255, 255, 255, 1);
    font-size: 21px;
    line-height: 35px;
}

.home_page_top_banner_cert_link_imgs_div {
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 70px;
}

.home_page_top_banner_cert_imgs_div {
    width: 800px;
    display: flex;
    align-items: center;
    gap: 20px;
}

.home_page_top_banner_cert_learn_more_a {
    font-size: 18px;
    text-decoration: none;
    color: rgba(254, 200, 14, 1);
    border-bottom: 1px solid rgba(254, 200, 14, 1);
    transition: .2s;
    min-width: fit-content;
}

.home_page_top_banner_cert_learn_more_a:hover {
    color: rgba(254, 202, 14, 0.435);
    border-bottom: 1px solid rgba(254, 202, 14, 0.435);
}

.home_page_top_banner_cert_img_indiv_div {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home_page_top_banner_cert_img_indiv_div_img {
    position: relative;
    width: auto;
    height: 140px;
    /* Set the desired height */
    display: flex;
    align-items: center;
    object-fit: contain;
}

.home_page_top_banner_cert_img_indiv_div_img>span {
    position: unset !important;
}

.home_page_top_banner_cert_img_indiv_div_img img {
    position: relative !important;
    height: 100% !important;
    /* Make the image fill the height */
    width: auto !important;
    /* Let the width adjust automatically */
}

.home_page_top_banner_cert_img_indiv_div p {
    color: white;
    font-size: 18px;
}

.home_page_top_banner_digital_econ_div {
    background-image: url('../../../resources/Mask group (4) (1).png');
    background-size: cover;
    background-position: center;
    background-position-y: top;
    height: 700px;
    text-align: left;
}

.home_page_top_banner_digital_econ_text_top_div {
    padding-top: 270px;
    display: flex;
    justify-content: center;
}

.home_page_top_banner_digital_econ_text_div {
    display: flex;
    align-items: center;
    gap: 50px;
    width: 610px;
}

.home_page_top_banner_digital_econ_img_div {
    min-width: 90px;
    max-width: 90px;
}

#hp_tp_bn_cert_sub_title_2 {
    display: none;
}

.home_page_top_banner_digital_econ_text_p {
    color: rgba(255, 255, 255, 1);
    font-size: 40px;
    font-weight: 600;
    line-height: 55px;
}

.home_page_top_banner_digital_econ_learn_more_a {
    text-decoration: none;
    color: white;
    background-color: rgba(254, 202, 14, 0.4);
    border: 1px solid rgba(254, 200, 14, 1);
    border-radius: 5px;
    padding: 10px 35px;
    transition: .2s;
}

.home_page_top_banner_digital_econ_learn_more_a:hover {
    text-decoration: none;
    color: rgba(254, 200, 14, 1);
    background-color: transparent;
}

.home_page_top_banner_training_top_inner_div {
    padding: 150px 150px;
    display: grid;
    grid-template-columns: 65% 35%;
    justify-content: space-between;
    align-items: flex-start;
}

.home_page_top_banner_training_header_top_div {
    display: flex;
    align-items: center;
    gap: 20px;
}

.home_page_top_banner_training_header_div {
    color: rgba(0, 97, 151, 1);
}

.home_page_top_banner_training_header_div p {
    font-size: 32px;
}

.home_page_top_banner_training_header_div h1 {
    font-size: 36px;
    line-height: 30px;
}

.home_page_top_banner_training_below_header_div {
    position: absolute;
    width: 40%;
    font-size: 22px;
}

.home_page_top_banner_training_link_img_top_div {
    margin-top: 75px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.home_page_top_banner_training_link_div {
    width: fit-content;
}

.home_page_top_banner_training_link_text_p {
    color: rgba(13, 60, 151, 1);
    padding-bottom: 5px;
    font-size: 22px;
    border-bottom: 1px solid rgba(13, 60, 151, 1);
}

.home_page_top_banner_training_badge_img_div {
    position: relative;
    width: 500px;
    height: 300px;
    aspect-ratio: 1;
}

.home_page_top_banner_training_upcoming_class_top_div {
    justify-self: flex-end;
    align-self: flex-start;

    background-color: white;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    width: 430px;
    height: 520px;
}

.home_page_top_banner_training_upcoming_class_header_div {
    width: 100%;
    border-bottom: 1px solid rgba(215, 215, 215, 1);
}

.home_page_top_banner_training_upcoming_class_header_div p {
    color: rgba(0, 97, 151, 1);
    font-size: 20px;
}

.home_page_top_banner_training_upcoming_class_course_filter_events_top_div {
    margin: 15px 50px;
}

.home_page_top_banner_training_upcoming_class_course_filter {
    border-radius: 4px;
    border: 1px solid rgb(154, 154, 154);
    border-bottom: 2px solid rgb(181, 181, 181);
    height: 50px;
    width: 100%;
    padding-left: 10px;
    font-size: 18px;

    margin-top: 10px;
}

.home_page_top_banner_training_upcoming_class_search_button {
    height: 50px;
    width: 100%;
    background-color: rgba(0, 97, 151, 1);
    text-align: center;
    border: 0px;
    border-radius: 0px;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 10px;
}

.home_page_top_banner_training_upcoming_class_search_button p {
    font-size: 16px;
    margin: auto;
}

.home_page_top_banner_training_upcoming_class_course_filter_events_separ_div {
    border-top: 1px solid rgba(215, 215, 215, 1);
}

.home_page_top_banner_training_upcoming_class_course_header_div {
    display: grid;
    grid-template-columns: 30% 5% 65%;
    justify-content: space-between;
    justify-content: flex-start;
    margin: 0px 30px;
    margin-top: 5px;
    padding: 5px 0px;
    align-items: center;
}

.home_page_top_banner_training_upcoming_class_course_header_div p {
    margin: 0px;
}

.home_page_top_banner_training_upcoming_class_course_date_div {
    color: rgba(0, 97, 151, 1);
    margin: auto;
    padding: 4px 15px;
    border-radius: 5px;
}

.home_page_top_banner_training_upcoming_class_course_divider {
    border-left: 1px solid rgb(184, 184, 184);
    height: 60%;
}

.home_page_top_banner_training_upcoming_class_course_list_outside_div {
    overflow-y: auto;
    height: 130px;
}

.home_page_top_banner_training_upcoming_class_view_all_div {
    width: fit-content;
    text-align: center;
}

.home_page_top_banner_training_upcoming_class_view_all_div p {
    color: rgba(13, 60, 151, 1);
}

.home_page_top_banner_training_upcoming_class_list_head_div {
    margin: 15px 50px;
    margin-bottom: 0px;
}

@media screen and (max-width: 1650px) {
    .home_page_top_banner_training_top_inner_div {
        /* padding: 150px 150px; */
        grid-template-columns: 70% 30%;
    }
}

@media screen and (max-width: 1550px) {
    .home_page_top_banner_training_top_inner_div {
        padding: 150px 100px;
        grid-template-columns: 65% 35%;
    }


    .home_page_top_banner_training_below_header_div {
        width: 35%;
        font-size: 20px;
    }
}

@media screen and (max-width: 1450px) {
    .home_page_top_banner_training_link_img_top_div {
        margin-top: 90px;
    }

    .home_page_top_banner_training_badge_img_div {
        width: 450px;
    }

    .home_page_top_banner_training_link_text_p {
        font-size: 20px;
    }
}

@media screen and (max-width: 1350px) {
    .home_page_top_banner_training_top_inner_div {
        padding: 150px 100px;
        grid-template-columns: 100%;
    }

    .home_page_top_banner_training_below_header_div {
        width: 50%;
        font-size: 22px;
    }

    .home_page_top_banner_training_upcoming_class_top_div {
        display: none;
    }

    .home_page_top_banner_digit_econ_inner_div {
        padding-top: 170px;
    }


    .home_page_top_banner_standard_img_div {
        min-width: 250px;
        max-width: 250px;
    }

    #hp_tp_bn_stand_text {
        font-size: 19px;
    }

    .home_page_top_banner_standard_inner_div {
        padding-top: 160px;
    }
}

@media screen and (max-width: 1000px) {
    .home_page_top_banner_audit_img_div {
        height: 90px;
    }

    .home_page_top_banner_training_top_inner_div {
        padding: 150px 100px;
    }

    .home_page_top_banner_training_below_header_div {
        width: 60%;
        font-size: 20px;
    }

    .home_page_top_banner_training_badge_img_div {
        width: 400px;
    }

    .home_page_top_banner_training_link_img_top_div {
        margin-top: 110px;
    }


    .home_page_top_banner_cert_inner_div {
        padding-top: 170px;
    }

    .home_page_top_banner_cert_link_imgs_div {
        margin-top: 60px;
        flex-direction: column-reverse;
        gap: 40px;
    }

    .home_page_top_banner_cert_imgs_div {
        width: inherit;
        gap: 20px;
    }


    .home_page_top_banner_standard_img_div {
        min-width: 220px;
        max-width: 220px;
    }

    #hp_tp_bn_stand_text {
        font-size: 18px;
        margin-top: 0px;
    }

    #hp_tp_bn_stand_learn_more_div {
        margin-top: 20px;
    }
}

@media screen and (max-width: 900px) {
    .home_page_top_banner_training_top_inner_div {
        padding: 150px 70px;
    }


    .home_page_top_banner_training_below_header_div {
        width: 70%;
        font-size: 18px;
    }

    .home_page_top_banner_training_link_text_p {
        font-size: 18px;
    }


    .home_page_top_banner_training_badge_img_div {
        width: 370px;
    }

    #hp_tp_bn_dig_econ_learn_more {
        margin-top: 40px;
    }

    #hp_tp_bn_cert_sub_title {
        display: none;
    }

    .home_page_top_banner_cert_inner_div {
        padding-top: 200px;
    }

    .home_page_top_banner_audit_img_div {
        display: none;
    }

    #hp_tp_bn_audit_sub_title {
        margin-bottom: 70px;
    }

    #hp_tp_bn_audit_learn_more {
        margin-top: 70px;
    }

    .home_page_top_banner_standard_img_div {
        display: none;
    }

    #hp_tp_bn_stand_text {
        font-size: 19px;
    }
}

@media screen and (max-width: 800px) {
    .home_page_top_banner_training_below_header_div {
        width: 70%;
        font-size: 18px;
    }

    .home_page_top_banner_training_link_text_p {
        font-size: 18px;
    }

    .home_page_top_banner_training_badge_img_div {
        min-width: 350px;
        position: relative;
        left: 50px;
    }

    .home_page_top_banner_training_link_div {
        min-width: max-content;
    }
}

@media screen and (max-width: 720px) {
    .home_page_top_banner_training_below_header_div {
        width: 70%;
        font-size: 18px;
    }

    .home_page_top_banner_training_link_text_p {
        font-size: 20px;
    }

    .home_page_top_banner_training_badge_img_div {
        display: none;
    }

    .home_page_top_banner_training_below_header_div {
        position: relative;
    }

    .home_page_top_banner_training_link_img_top_div {
        margin-top: 0px;
    }

    .home_page_top_banner_training_header_div p {
        font-size: 30px;
    }

    .home_page_top_banner_training_header_div h1 {
        font-size: 34px;
        line-height: 40px;
    }

    .home_page_top_banner_digital_econ_text_top_div {
        margin: 0px 20px;
    }

    .home_page_top_banner_digital_econ_text_div {
        gap: 50px;
        width: 550px;
    }

    .home_page_top_banner_digital_econ_img_div {
        min-width: 80px;
        max-width: 80px;
    }

    .home_page_top_banner_digital_econ_text_p {
        font-size: 32px;
        line-height: 45px;
    }

    #hp_tp_bn_dig_econ_text {
        display: none;
    }

    #hp_tp_bn_dig_econ_learn_more {
        margin-top: 70px;
    }

    .home_page_top_banner_cert_title_p {
        margin-top: 50px;
    }

    .home_page_top_banner_digit_econ_inner_div {
        padding-top: 220px;
    }

    #hp_tp_bn_stand_title {
        margin-top: 20px;
    }
}

@media screen and (max-width: 600px) {
    .home_page_top_banner_cert_inner_div {
        padding-top: 220px;
    }

    .home_page_top_banner_cert_link_imgs_div {
        margin-top: 50px;
    }

    .home_page_top_banner_cert_imgs_div {
        display: none;
    }

    #hp_tp_bn_cert_sub_title_2 {
        display: initial;
    }

    #hp_tp_bn_stand_text {
        display: none;
    }

    #hp_tp_bn_stand_title {
        margin-top: 50px;
    }

    #hp_tp_bn_stand_learn_more {
        margin-top: 30px;
    }

    .home_page_top_banner_sub_title_img_icon_div {
        display: none;
    }
}

@media screen and (max-width: 500px) {
    .home_page_top_banner_training_top_inner_div {
        padding: 150px 50px;
    }

    .home_page_top_banner_training_header_div p {
        font-size: 28px;
    }

    .home_page_top_banner_training_below_header_div {
        width: 90%;
    }

    .home_page_top_banner_training_header_top_icon_div {
        display: none;
    }

    .home_page_top_banner_training_header_div h1 {
        font-size: 32px;
        line-height: 40px;
    }

    .home_page_top_banner_digital_econ_text_top_div {
        margin: 0px 15px;
    }

    .home_page_top_banner_digital_econ_text_div {
        gap: 30px;
    }

    .home_page_top_banner_digital_econ_img_div {
        min-width: 60px;
        max-width: 60px;
    }

    .home_page_top_banner_digital_econ_text_p {
        font-size: 28px;
        line-height: 35px;
    }

    #hp_tp_bn_audit_top {
        padding-top: 180px;
    }
}