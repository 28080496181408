.WhitePaperAdminMainDiv {
    padding: 20px
}


.WhitePaperAdminSingleWhitepaper {
    height: 50px;
    text-align: center;
}

.WhitePaperAdminSingleWhitepaper:nth-child(odd) {
    background-color: #ad928d;
}

.WhitePaperAdminList {
    width: 100%;
    overflow: auto;
    height: 300px;
}

.WhitePaperAdminSingleDeleteButton {
    background-color: #AD2005;
    color: white;
    width: 100px;
    height: 30px;
    cursor: pointer;
    margin: 10px;
}

.WhitePaperAdminSingleEditButton {
    background-color: #AD2005;
    color: white;
    width: 100px;
    height: 30px;
    cursor: pointer;
    margin: 10px;
}

.WhitePaperAdminSingleNewButton {
    background-color: #AD2005;
    color: white;
    width: 200px;
    height: 40px;
    cursor: pointer;
}

.WhitePaperAdminSingleWhitepaperButtons {
    text-align: center;
    width: 200px;
    /* border: solid 2px black; */
}

.WhitePaperAdminNewUploadForm {
    display: flex;
    height: fit-content;
    flex-direction: column;
    gap: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
}

.WhitePaperAdminNewUploadFormEdit {
    position: fixed;
    top: 25%;
    left: 25%;
    width: 50%;
    z-index: 1001;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    height: fit-content;
    background-color: #ffffff;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);

    border-radius: 10px;
    border: 2px solid #AD2005;
}

.WhitePaperAdminNewUploadLabel {
    display: flex;
    gap: 15px;
    align-items: center;
}

.WhitePaperAdminNewUploadLabelVertical {
    display: flex;
    gap: 15px;
    flex-direction: column;
}

#WhitePaperAdminNewUploadIDDescription {
    height: 200px;
}