.standard_the_seven_layers_header_menu_buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 7px;
}

.standard_the_seven_layers_header_menu_indiv_button {
    background-color: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
}

.standard_the_seven_layers_header_menu_hr {
    border: #f5f5f5 solid 1px;
}

.standard_the_seven_layers_header_menu_indiv_button:hover {
    opacity: 0.7;
}

.standard_the_seven_layers_header_menu_indiv_button:active {
    opacity: 1;
}


.standard_the_seven_layers_full_div_pyramid_only {
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.standard_pyramid_full_column {
    display: flex;
    flex-direction: column;
    width: 350px;
    max-height: 450px;
}

.standard_pyramid_column_a {
    position: relative;
    min-height: 55px;
    width: 107%;
    left: -12px;
    top: -2px;
    object-fit: contain;
}

.standard_pyramid_column_b {
    position: relative;
    top: -5px;
    min-height: 50px;
    object-fit: contain;
}

.standard_pyramid_column_c {
    position: relative;
    top: -6px;
    z-index: 4;
    min-height: 50px;
    width: 95%;
    left: 10px;
    object-fit: contain;
}

.standard_pyramid_column_d {
    position: relative;
    top: -15px;
    z-index: 3;
    min-height: 65px;
    object-fit: contain;

}

.standard_pyramid_column_e {
    position: relative;
    top: -45px;
    z-index: 2;
    min-height: 85px;
    object-fit: contain;

}

.standard_pyramid_column_f {
    position: relative;
    top: -100px;
    z-index: 1;
    min-height: 110px;
    object-fit: contain;
}

.standard_pyramid_column_g {
    position: relative;
    top: -180px;
    z-index: 0;
    min-height: 130px;
    width: 102%;
    left: 0px;
    object-fit: contain;
    /* background-color: black; */
}

.standard_the_seven_layers_full_div_both_cols_outer_div {
    display: flex;
    column-gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    row-gap: 80px;
}

.standard_the_seven_layers_full_div_both_cols_outer_div_cols_only {
    display: flex;
    column-gap: 30px;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 13px;
}

.standard_the_seven_layers_full_div_indiv_col_full {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-evenly;
    width: 400px;

}

.standard_the_seven_layers_full_div_indiv_item_in_col {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: left;
    width: 100%;
}

.standard_the_seven_layers_full_div_indiv_item_in_col_text {
    width: 80%;
}

.standard_the_seven_layers_full_div_indiv_item_in_col_icon {
    min-width: 30px;
}


.standard_the_seven_layers_full_div_subsection_menu_class {
    display: flex;
    gap: 10px;
    justify-content: left;
    padding-bottom: 20px;
    flex-wrap: wrap;
}

.standard_the_seven_layers_full_div_subsection_menu_buttons {
    background-color: #AD2225;
    border: none;
    height: 40px;
    color: white;
    width: 150px;
    cursor: pointer;
}

.standard_the_seven_layers_full_div_subsection_menu_buttons:hover {
    opacity: 0.7;
}

.standard_the_seven_layers_full_div_subsection_menu_buttons:active {
    opacity: 1;
}


.standard_the_seven_layers_header_menu_buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 7px;
}

@media screen and (max-width: 1080px) {
    .standard_the_seven_layers_header_menu_buttons {
        justify-content: center;
        flex-wrap: wrap;

        gap: 7px;
    }
}

@media screen and (max-width: 1080px) {
    .standard_the_seven_layers_header_menu_buttons {
        justify-content: center;
        flex-wrap: wrap;
        gap: 7px;
    }
}

@media screen and (max-width: 400px) {
    .standard_the_seven_layers_header_menu_buttons {
        justify-content: space-around;
        /* flex-wrap: wrap;
        gap: 7px; */
    }
}


@media screen and (max-width: 428px) {
    .standard_pyramid_full_column {
        display: none;
    }
}