.user_dashboard_home_top_div {
    margin: 150px 200px;
    display: grid;
    grid-template-columns: 75% 20%;
    justify-content: space-between;
}

.user_dashboard_home_main_div {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.user_dashboard_home_right_div {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

@media screen and (max-width: 1650px) {
    .user_dashboard_home_top_div {
        margin: 150px 150px;
    }
}

@media screen and (max-width: 1500px) {
    .user_dashboard_home_top_div {
        margin: 150px 100px;
    }
}

@media screen and (max-width: 1100px) {
    .user_dashboard_home_top_div {
        margin: 150px 60px;
    }
}

@media screen and (max-width: 950px) {
    .user_dashboard_home_top_div {
        margin: 150px 40px;
    }
}

@media screen and (max-width: 900px) {
    .user_dashboard_home_top_div {
        grid-template-columns: 70% 25%;
    }
}

@media screen and (max-width: 750px) {
    .user_dashboard_home_top_div {
        margin: 150px 60px;
    }

    .user_dashboard_home_right_div {
        margin-top: 40px;
    }

    .user_dashboard_home_top_div {
        grid-template-columns: 100%;
    }
}

@media screen and (max-width: 550px) {
    .user_dashboard_home_top_div {
        margin: 150px 40px;
    }
}

@media screen and (max-width: 490px) {
    .user_dashboard_home_top_div {
        margin: 150px 30px;
    }
}

@media screen and (max-width: 440px) {
    .user_dashboard_home_top_div {
        margin: 150px 0px;
    }
}