.Testimonial_new_body_v3 {
    width: 100%;
    padding-bottom: 3%; 
    height: 100%;
    display: flex;
    align-items: center;
    justify-content:space-between;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    flex-direction: row;
}

.Testimonial_new_person_v3{
    display: flex;
    width: 20%;
    align-items: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    flex-direction: row;
    /* margin-right: 8%; */
}

  #Testimonial_new_img_v3{
      width: 20%;
      /* margin-right: 10%; */
      min-width: 80px;
  }

  .Testimonial_new_name_and_title_v3{
    align-items: left;
    text-align: left;
  } 

  .Testimonial_new_body_v3 h3 {
    color: #222;
    font-weight: 100;
    letter-spacing: 0.2px;
    margin-bottom: 4px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 17px;
  }
  
  .Testimonial_new_body_v3 h4 {
    text-transform: uppercase;
    margin-top: 0;
    padding-top: 0;
    font-weight: 500;
    color: #787878;
    font-size: 14px;
  }
  
  .Testimonial_new_body_v3 p {
    font-weight: 100 !important;
    line-height: 29px !important;
    color: #222;
    font-size: 15px;
    font-family: sans-serif;
    max-height: 67px;
  }
  
  .Testimonial_new_comment_v3:before {
    content: "“";
    color: #aaa;
    font-size: 26px;
    font-family: monospace;
    font-weight: 100;
  }
  
  .Testimonial_new_comment_v3:after {
    content: "”";
    color: #aaa;
    font-size: 26px;
    font-family: monospace;
    font-weight: 100;
    line-height: 0;
  }

  /* @media screen and (max-width: 1555px) {

    .Testimonial_new_person_v3{
        margin-right: 3%;
        display: block;
        width: 20%;
        align-items: center;
        justify-content: center;
        -webkit-flex-wrap: unset;
        flex-wrap: unset;
        flex-direction: unset;
        text-align: center;
    }

    .Testimonial_new_name_and_title_v3{
        align-items: center;
        text-align: center;
      } 

    .Testimonial_new_comment_v3{
        padding-left: 3%;
    }

    #Testimonial_new_img_v3{
        margin-right: 0%;
    }
} */

@media screen and (max-width: 1555px) {

    .Testimonial_new_body_v3 {
        width: 100%;
        padding-bottom: 3%; 
        height: 100%;
        display: block;
        align-items: center;
        justify-content:center;
        -webkit-flex-wrap: unset;
        flex-wrap: unset;
        flex-direction: unset;
    }

    .Testimonial_new_person_v3{
        justify-content: center;
        margin-left: 10%;
        width: 80%;
        padding-bottom: 20px;
        padding-top: 20px;

    }

    .Testimonial_new_name_and_title_v3{
        width: 100%;
      } 

      #Testimonial_new_img_v3{
        max-width: 100px;
        margin-right: 2%;      
    }
}

@media screen and (max-width: 356px) {

    .Testimonial_new_body_v3 {
        width: 100%;
        padding-bottom: 3%; 
        height: 100%;
    }

    .Testimonial_new_person_v3{
        justify-content: center;
        margin-left: 10%;
        width: 80%;
        padding-bottom: 20px;
        padding-top: 50px;

    }

    .Testimonial_new_name_and_title_v3{
        width: 100%;
      } 
}