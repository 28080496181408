.member_all_div_margin {
    margin: 0px 10%;
}

.shareExperienceWholeDiv {
    margin-top: 75px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
}

.MemberAreaAnouncements {
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    height: fit-content;
    align-items: center;
    display: flex;
    margin-bottom: 50px;
}

.MemberAreaAnouncementsTopTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding-right: 70px;
}

.MemberAreaAnouncementsTop {
    width: 100%;
    margin: 30px;
}

.MemberAreaSingleAnouncement {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    gap: 15px;
}

.MemberAreaSingleAnouncementTexts {
    width: 80%;
}

.MemberAreaAnoucementsList {
    overflow: auto;
    height: 400px;
}

.membershipAnnouncementPopUpWhole {
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    width: 300px;
    height: fit-content;
    display: none;
    z-index: 10000;
    position: fixed;
    top: 25%;
    left: 25%;
    width: 50%;
    z-index: 1001;
    background-color: rgb(235, 235, 235);
    flex-direction: column;
    padding: 15px;
    border-radius: 15px;
}

.shareYourExperienceButtonMainpage {
    width: 75%;
    height: 50px;
    background-color: #AD2225;
    font-size: 14px;
    border: none;
    cursor: pointer;
}

.shareYourExperienceButtonMainpage:hover {
    background-color: #ab4b4d;
}

.shareYourExperienceButtonMainpage:active {
    background-color: #000000;
}

.user_dashboard_corp_mem_invite_notific_top_div {
    width: 600px;
    z-index: 100;
    position: fixed;
    top: 80px;
    left: 50%;
    margin-left: -300px;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: flex-start;
    padding: 10px;
    background-color: white;
    border-radius: 10px;
}

.user_dashboard_corp_mem_invite_notific_inner_div {
    display: flex;
    gap: 50px;
    align-items: center;
    color: green;
    padding: 10px 40px;
}

.user_dashboard_corp_mem_invite_notific_inner_div button {
    color: black;
    font-size: 17px;
    background-color: white;
    border: 1px solid gray;
    padding: 10px 20px;
    cursor: pointer;
}

.user_dashboard_corp_mem_invite_notific_inner_div button:hover {
    color: green;
    border: 1px solid green;
}

@media screen and (max-width: 1263px) {
    .MemberAreaSingleAnouncementIcon {
        display: none;
    }

    .member_all_div_margin {
        margin: 0px 7%;
    }
}

@media screen and (max-width: 900px) {
    .member_all_div_margin {
        margin: 0px 5%;
    }


    .user_dashboard_corp_mem_invite_notific_top_div {
        width: 500px;
        margin-left: -250px;
    }
}

@media screen and (max-width: 600px) {
    .user_dashboard_corp_mem_invite_notific_top_div {
        width: 400px;
        margin-left: -200px;
    }
}

@media screen and (max-width: 500px) {
    .user_dashboard_corp_mem_invite_notific_top_div {
        width: 250px;
        margin-left: -125px;
    }

    .user_dashboard_corp_mem_invite_notific_inner_div {
        width: 200px;
        flex-direction: column;
        gap: 10px;
    }
}