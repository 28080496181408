.training_board_individ_card_div{
    /* margin-top: 50px; */
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    /* flex-wrap: wrap; */
    /* row-gap: 30px; */
    /* column-gap: 3%; */
}

.training_board_individ_card_div{
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    width: auto;
}       

.training_board_individ_social_card_div{
    display: none;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    background-color: #D82027;
    color: white;
}

.training_board_individ_social_individ_card_div{
    width: 16px;
    height: 16px;
}


.about_us_board_directors_individ_social_individ_link_card_div{
  text-decoration: none;
}

.training_board_individ_social_card_div a:link, 
.training_board_individ_social_card_div a:visited, 
.training_board_individ_social_card_div a:hover, 
.training_board_individ_social_card_div a:active{
    color: white;
}
