.dc_for_who_by_who_text_logo_div{
    display: flex;
    gap: 2%;
}
.dc_for_who_by_who_text_logo_div_img{
    position: relative;
    width: 200%;
    background-color: #F0F0F0;
    padding: 20px;
    height: fit-content;
}

@media screen and (max-width: 1250px) {
.dc_for_who_by_who_text_logo_div_img{
    width:280%;
}
}
@media screen and (max-width: 1000px) {
    .dc_for_who_by_who_text_logo_div{
        flex-direction: column;
        align-items: center;
}
.dc_for_who_by_who_text_logo_div_img{
    width:40%;
}
}
@media screen and (max-width: 600px) {
.dc_for_who_by_who_text_logo_div_img{
    width:60%;
}
}
@media screen and (max-width: 400px) {
.dc_for_who_by_who_text_logo_div_img{
    width:75%;
}
}




