.certification_professional-top-div{
    height: 500px;
    background-image:url("../../../resources/certificationProfessional.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.certification_professional_outer{
    align-self: center;
    padding-top: 170px;
    width: 40%;
    height: 400px;
}

.certification_professional-top-div_explain{
    padding-top: 20px;
}

.certification_professional-top-div_explain_text{
    text-align: center;

    font-size: 18px;
    line-height: 30px;
}

@media screen and (max-width: 1244px) {

    .certification_professional_outer{
        width: 70%;
    }
}

@media screen and (max-width: 761px) {

    .certification_professional_outer{
        width: 80%;
    }
}

@media screen and (max-width: 620px) {

    .certification_professional_outer{
        width: 90%;
    }
}

@media screen and (max-width: 519px) {

    .certification_professional_outer{
        width: 95%;
    }
}