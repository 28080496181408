.about_us_description_div {
  margin-top: 50px;
  display: flex;
  align-items: center;
  gap: 7%;
}

.about_us_mission_header {
  font-size: 19px;
}

@media screen and (max-width: 1350px) {
  .about_us_description_div {
    flex-direction: column;
    row-gap: 30px;
  }
}