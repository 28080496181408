.Podcasts_guests_header_1 {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 16px;
    margin-bottom: 10px;
    justify-content: center;

}

.Podcasts_guests_header_2 {
    font-size: 30px;
    text-align: center;
    margin-top: 0px;
}

.Podcasts_guest_indiv_Whole {
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 260px;
    align-items: stretch;
    text-align: center;
    justify-content: flex-end;
    box-shadow: rgba(0, 0, 0, 1) 0px 4px 12px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
}

.Podcasts_guest_indiv_name {
    font-size: 16px;
    text-align: center;
}

.Podcasts_guest_indiv_Title {
    font-size: 15px;
    /* margin-bottom: 20px;    */
}

.Podcasts_guests_whole {
    padding-bottom: 40px;
    margin-top: 50px
}

.Podcasts_guets_list_all {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: space-around;
}