#nav_body {
    font-size: 0.95rem;
    line-height: 1.6;
    min-height: 80px;
    width: 100%;
    backdrop-filter: blur(10px);
    color: #666;
    position: fixed;
    z-index: 100;
    transition: 1s;
    height: 140px;
}

#idca_nav_logo {
    width: 150px;
}


#myTopnav {
    margin-top: 45px;
}

#myTopnavWhite {
    margin-top: 30px;
}

#nav_body_white {
    font-size: 0.95rem;
    line-height: 1.6;
    height: 100px;
    min-height: 80px;
    width: 100%;
    backdrop-filter: blur(10px);
    color: #666;
    position: fixed;
    z-index: 100;
    top: 0px;
    transition: 1s;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
}

#nav_header_nav_white {
    color: #424242;
}

#idca_nav_logo_white {
    width: 90px;
    top: 20px;
    position: absolute;
}

#idca_nav_logo {
    top: 10px;
    position: absolute;
}

#nav_logo_img_men_id_white {
    width: 100px
}

/* Safari 11+ */
@media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) and (stroke-color:transparent) {
        #nav_body {
            -webkit-backdrop-filter: blur(10px);
        }

    }
}

/* Safari 10.1 */
@media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
        #nav_body {
            -webkit-backdrop-filter: blur(10px);
        }
    }
}

/* Safari 6.1-10.0 (but not 10.1) */
@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0) {
    #nav_body {
        -webkit-backdrop-filter: blur(10px);
    }
}

ul {
    list-style: none;
    /* margin: 0;
    padding: 0; */
    box-sizing: border-box;
}

.nav_menu {
    box-sizing: border-box;
}

#nav_header_nav {
    /* padding: 2rem 0; */
    /* background-color: white; */
    margin: 0;
    box-sizing: border-box;
}

.nav_nav {
    max-width: 85%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    box-sizing: border-box;
}

.nav_nav_div_top_2>li {
    display: inline-block;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 500;
}

.nav_nav_div_top_1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    float: left;
}


.nav_menu>li,
.nav_menu>a {
    display: inline-block;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.nav_nav_div_top_2 {
    display: inline-block;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.nav_menu li {
    position: relative;
}

.nav_menu a {
    text-decoration: none;
    color: inherit;
    margin: 0;
    box-sizing: border-box;
    padding: 10px 0.7rem;
    display: block;
    transition: background-color .22s ease, color 0.22s ease;
}

.nav_menu .nav_img_logo_small_link:hover {
    background-color: transparent;
}

.nav_menu a:hover {
    /* border-radius: 7px; */
    background-color: #AD2225;
    color: white;
}


.nav_logo_h {
    font-size: 2rem;
    color: black;
}

.nav_logo_h>a {
    color: black;
    text-decoration: none;
}

.nav_logo_h>a:hover {
    color: black;
    text-decoration: none;
}

.nav_submenu {
    position: absolute;
    background-color: rgb(58, 58, 58);
    z-index: 50;
    margin: 0;
    padding: 0;
    width: 180px;
    left: 0;
    display: none;
}

/* Submenue font size */
.nav_submenu>li {
    font-size: 15px;
}

.nav_submenu a:hover {
    border-radius: 0px;
}

.nav_submenu a {
    border-radius: 0px;
    color: white;
}

.nav_submenu2 {
    position: absolute;
    background-color: rgb(88, 88, 88);
    z-index: 50;
    margin: 0;
    padding: 0;
    width: 180px;
    left: 100%;
    top: 0;
    display: none;
}

.nav_submenu2>li {
    font-size: 13px;
}

.nav_menu li:hover>.nav_submenu {
    display: block;
}

.nav_submenu li:hover>.nav_submenu2 {
    display: block;
    /* grid-template-columns: auto; */
}

.nav_menu_img {
    width: 20px;
    padding: 10px;
    display: none;
    border-radius: 7px;
    cursor: pointer;
}

.nav_menu_img_white {
    width: 20px;
    padding: 10px;
    display: none;
    border-radius: 7px;
    cursor: pointer;
}

.navBar_image_menu_icon {
    width: 25px;
    height: 25px;
    padding-left: 3px;
    padding-top: 3px;
}

.nav_menu_img:hover {
    background-color: gray;
}

.nav_menu_img_white:hover {
    background-color: gray;
}

.nav_logo_img_men {
    display: none;
}

.navbar_contact_us_button_div {
    background-color: rgba(187, 32, 39, 1);
    padding: 13px 20px;
    margin-left: 10px;
    cursor: pointer;
}

.navbar_contact_us_button_div p {
    margin: 0px;
}

#navbar_profile_icon_li_id a:hover {
    background-color: transparent;
}

.navbar_contact_us_form_top_div {
    /* position: fixed; */
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    width: 400px;
    height: 450px;
    /* width: 100%; */
    /* height: 100%; */
    z-index: 1000;
    overflow-y: auto;

    padding: 40px 60px;
    margin-bottom: 20px;
}

.navbar_contact_us_select_interest {
    width: 97%;
    height: 50px;
    margin-top: 10px;
    font-family: Saira;
    font-size: 17px;
}

.navbar_contact_us_submit_button {
    padding-bottom: 10px;
    padding-top: 10px;
}

@media screen and (max-width: 1060px) {

    .nav_logo_h {
        font-size: 1.5rem;
    }

    .nav_menu a {
        padding: 0.5rem;
    }
}

@media screen and (max-width: 1400px) {
    .nav_nav {
        max-width: 90%;
    }
}

/* was at 990px */
@media screen and (max-width: 1300px) {

    .nav_nav {
        margin-left: 0px;
    }

    .nav_nav_div_top_1 {
        padding-left: 0px;
        width: fit-content;
        align-items: left;
    }

    .nav_menu.responsive_nav_men {
        display: block;
    }

    #nav_body {
        backdrop-filter: blur(0px);
    }

    .nav_nav {
        display: none;
    }

    .nav_menu {
        display: none;
    }

    .nav_logo_h {
        display: none;
    }

    .nav_logo_h.nav_logo_responsive {
        display: initial;
        padding-left: 20px;
        padding-top: 1%;
    }

    .nav_menu_img {
        position: absolute;
        top: 40px;
        right: 40px;
        width: 30px;
        display: initial;
        float: right;
    }

    .nav_menu_img_white {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 30px;
        display: initial;
        float: right;
    }

    .nav_logo_img_men {
        display: block;
        width: 70%;
    }

    #nav_logo_img_men_id {
        width: 150px;
    }

    .nav_nav li {
        float: none;
        display: block;
        text-align: left;

    }

    .nav_nav .nav_submenu {
        float: none;
        position: relative;
        width: 100%;
        z-index: 150;
        text-align: left;

    }

    .nav_submenu .nav_submenu2 {
        float: none;
        width: 100%;
        text-align: left;
    }


    .nav_submenu2 {
        position: unset;
    }

    .nav_menu li:hover>.nav_submenu {
        display: block;
    }

    .nav_submenu li:hover>.nav_submenu2 {
        display: grid;
        grid-template-columns: auto;
    }

}