.data_center_training_individual_courses_2_top_all_div {
    height: 400px;
    background-size: cover;
    background-position: center;
    background-position-y: top;
    margin-bottom: 15px;
}

.data_center_training_individual_courses_2_top_title_img {
    position: relative;
    top: 170px;
    margin-left: 15%;
    display: flex;
    align-items: center;
    gap: 50px;
}

.data_center_training_individual_courses_2_top_img {
    position: relative;
    width: 120px;
    height: 120px;
}

.data_center_training_individual_courses_2_title {
    font-size: 38px;
    width: 90%;
    margin: 0px;
}


@media screen and (max-width: 1000px) {
    .data_center_training_individual_courses_2_top_img {
        width: 110px;
        height: 100px;
    }

    .data_center_training_individual_courses_2_title {
        font-size: 34px;
        width: 85%;
    }
}

@media screen and (max-width: 900px) {
    .data_center_training_individual_courses_2_top_title_img {
        top: 160px;
        margin-left: 10%;
        gap: 30px;
    }

    .data_center_training_individual_courses_2_title {
        font-size: 32px;
        width: 80%;
    }
}

@media screen and (max-width: 470px) {
    .data_center_training_individual_courses_2_top_img {
        display: none;
    }

    .data_center_training_individual_courses_2_top_title_img {
        margin-left: 7%;
    }

    .data_center_training_individual_courses_2_title {
        font-size: 30px;
        width: 75%;
    }
}