.membership_landing_exclusive_events_header_div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.membership_landing_exclusive_events_header_img {
    min-width: 80px;
    width: 100px;
}

.membership_landing_exclusive_events_header_divider_line {
    border-right: 2px solid gray;
    height: 100px;
    margin-right: 50px;
    margin-left: 30px;
}

.membership_landing_exclusive_events_header_text h2 {
    font-size: 30px;
}

.membership_landing_exclusive_events_individual_a {
    box-shadow: 0px 0px 41.3189px rgba(0, 0, 0, 0.31);
    width: 250px;
    text-decoration: none;
}

.membership_landing_exclusive_events_all_events {
    margin-top: 40px;
    padding-bottom: 40px;
    display: flex;
    justify-content: center;
    gap: 50px;
    flex-wrap: wrap;
}

.membership_landing_exclusive_events_all_text_div {
    padding: 10px 0px;
    width: 250px;
}

.membership_landing_exclusive_events_individual_text {
    display: grid;
    grid-template-columns: 15% 80%;
    gap: 10px;
    align-items: center;
    overflow: hidden;
    text-overflow: clip;
}


.membership_landing_exclusive_events_individual_text p {
    margin: 7px 0px;
}