.data-center-training-individual-courses-top-div{
    height: 530px;
    /* background-image: url("../../../resources/DataCenterTrainingClassColor.png"),url("../../../resources/DataCenterTrainingClass.png"); */
    /* background-size: 100% 530px; */
    background-size: cover;
    /* background-attachment: fixed; */
    background-position: center;
    background-position-y:top;
    margin-bottom: 15px;
}

.data_center_training_individual_courses_breadcrumb_description{
    position: relative;
    top: 150px;
    left: 15%;
    width: 38%;
}

.data_center_training_individual_courses_title{
    font-size: 40px;
    line-height: 50px;
    margin: 0px;
    width: 80%;
}
.data_center_training_individual_courses_description{
    font-size: 16px;
    margin-top: 10px;
    line-height: 20px;
}


@media screen and (max-width: 1500px) {
.data_center_training_individual_courses_title{
    width: 95%;
}
}


@media screen and (max-width: 1350px) {
.data_center_training_individual_courses_title{
    width: 105%;
}
}


@media screen and (max-width: 1250px) {
.data_center_training_individual_courses_breadcrumb_description{
    width: 70%;
}
}



@media screen and (max-width: 800px) {
.data_center_training_individual_courses_title{
    font-size: 36px;
    line-height: 44px;
}
.data_center_training_individual_courses_description{
    font-size: 15px;
    line-height: 19px;
}
}
@media screen and (max-width: 600px) {
.data_center_training_individual_courses_breadcrumb_description{
    top: 140px;
}
.data_center_training_individual_courses_title{
    font-size: 34px;
    line-height: 40px;
}
.data_center_training_individual_courses_description{
    font-size: 14px;
    margin-bottom: 5px;
}
}
@media screen and (max-width: 530px) {
.data_center_training_individual_courses_breadcrumb_description{
    top: 130px;
    left: 5%;
    width: 90%;
}
.data_center_training_individual_courses_title{
    font-size: 30px;
    line-height: 40px;
    width: 100%;
}
.data_center_training_individual_courses_description{
    font-size: 13px;
    line-height: 17px;
    margin-bottom: 5px;
}
.data_center_training_individual_courses_breadcrumb_description h4{
    font-size: 14px;
}
}
@media screen and (max-width: 380px) {
.data_center_training_individual_courses_breadcrumb_description{
    top: 135px;
    left: 5%;
    width: 90%;
}
.data_center_training_individual_courses_title{
    font-size: 28px;
    line-height: 35px;
}
.data_center_training_individual_courses_breadcrumb_description h4{
    font-size: 12px;
}
}
@media screen and (max-width: 380px) {
.data_center_training_individual_courses_breadcrumb_description h4{
    font-size: 11px;
}
}