.payment_checkout_form_cart_itme_top_div {
    display: grid;
    grid-template-columns: 47% 47%;
    justify-content: space-between;
}

.payment_top_breadcrum {
    display: flex;
    align-items: center;
    gap: 10px;
}

.payment_div_for_all {
    box-shadow: 0px 4.68043px 32.763px rgba(0, 0, 0, 0.23);
}

.payment_event_individual_div {
    cursor: pointer;
    text-align: center;
    background-color: rgb(239, 239, 239);
    color: gray;
    box-shadow: 0px 3.42257px 23.1023px rgba(0, 0, 0, 0.1);
    width: fit-content;
    padding: 5px 40px;
    border-radius: 50px;
}

.payment_cart_item_info_and_price_saving_div {
    box-shadow: 0px 3.42257px 23.1023px rgba(0, 0, 0, 0.1);
    padding: 20px 30px;
    height: fit-content;
}

.payment_cart_item_info_and_price_div {
    display: flex;
    gap: 30px;
    justify-content: center;
    align-items: center;
}

.payment_cart_item_info_and_price_title {
    font-size: 20px;
    margin: 10px 0px;
}

.payment_cart_item_info_and_price_full_name {
    font-size: 15px;
    margin: 0px;
}

.payment_cart_itme_line_div {
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    width: 75%;
    border-top: 1px solid;
}

#payment_promo_code_input_field {
    padding: 10px;
    border: 1px solid rgb(219, 217, 217);
}

input::-webkit-input-placeholder {
    font-family: Saira, Arial, Helvetica, sans-serif;
}

input:-moz-placeholder {
    font-family: Saira, Arial, Helvetica, sans-serif;
}

.payment-form-promo-code-button {
    margin-left: 10px;
    background: #AD2225;
    /* font-family: Arial, sans-serif; */
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 5px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    /* display: block; */
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
}

.payment-form-promo-code-button:hover {
    filter: contrast(115%);
}


#payment_terms_and_condition_checkbox_id:checked {
    accent-color: #AD2225;
}


#payment_terms_and_condition_checkbox_id {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.payment_membership_plan_select {
    width: 300px;
    margin-top: 50px;
    border: 0px;
    border-bottom: 1px solid black;
    font-size: 18px;
}

.payment_next_back_button {
    padding: 12px 45px;
    border: 1px solid black;
    cursor: pointer;
}

.payment_next_back_button:hover {
    background-color: #AD2225;
    color: white;
    border: 1px solid white;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.payment-course-before-loading-spinner {
    margin: auto;
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #AD2225;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}

@media screen and (max-width: 1500px) {
    .payment_cart_item_info_and_price_image_div {
        display: none;
    }
}

@media screen and (max-width: 1000px) {
    .payment_cart_item_info_and_price_image_div {
        display: inherit;
        min-width: 50px;
    }

    .payment_checkout_form_cart_itme_top_div {
        justify-content: center;
        grid-template-columns: 80%;
        gap: 20px;
    }

    .payment_cart_item_info_and_price_saving_div {
        grid-row: 1;
    }
}

@media screen and (max-width: 700px) {
    .payment_cart_item_info_and_price_image_div {
        display: none;
    }
}

@media screen and (max-width: 400px) {
    .payment_checkout_form_cart_itme_top_div {
        justify-content: center;
        grid-template-columns: 90%;
        gap: 20px;
    }

    .payment-form-promo-code-button {
        font-size: 14px;
        padding: 5px 10px;
    }
}

@media screen and (max-width: 370px) {
    .payment_checkout_form_cart_itme_top_div {
        justify-content: center;
        grid-template-columns: 100%;
        gap: 20px;
    }
}