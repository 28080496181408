.application_audit_placeholder_img_div_1 {
    /* background-image: url('../../resources/Group 4867-min.png');
    background-size: cover;
    background-position: center; */
    height: 390px;
    margin-top: 4%;
}

.application_audit_placeholder_img_div_2 {
    position: absolute;
    overflow: hidden;
    z-index: -1;
    height: 390px;
    width: 100%;
}