.Indiv_User_User_Detail_Whole_Div {
    margin-bottom: 30px;
    padding: 50px;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1)
}

.Indiv_User_User_Detail_Top_Pic_And_Name {
    display: flex;
    align-items: center;
    gap: 70px;
}

.Indiv_User_User_Detail_user_info_button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Indiv_User_users_info_list {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}

.individual_user_individual_course_data_div_title {
    padding: 0px 20px;
    text-align: left;
    display: grid;
    grid-template-columns: 20% 20% 15% 25% 15%;
    justify-content: space-between;
}

.individual_user_individual_course_data {
    margin-right: 150px;
}

.individual_user_individual_course_data_top_clipboard_div {
    position: relative;
}

.individual_user_individual_course_add_zoom_div {
    position: absolute;
    text-align: center;
    right: 40px;
    top: 10px;
}

.individual_user_individual_course_add_zoom_div p {
    margin: 0px;
    font-size: 13px;
}

.individual_user_individual_course_clipboard_div {
    position: absolute;
    text-align: center;
    right: -46px;
    top: 10px;
}

.individual_user_individual_course_clipboard_div p {
    margin: 0px;
    font-size: 13px;
}

.individual_user_individual_course_data_div {
    text-align: center;
    display: grid;
    grid-template-columns: 10% 10% 10% 20% 10% 15% 15% 8% 2%;
    align-items: center;
}

.individual_user_individual_course_data_div_line {
    width: 100%;
    height: 2px;
    border-bottom: 1px solid black;
}


@media screen and (max-width: 1000px) {
    .Indiv_User_User_Detail_Top_Pic_And_Name {
        flex-direction: column;
        gap: 20px;
    }

    .Indiv_User_users_info_list {
        flex-direction: column;
        justify-content: flex-start;
    }

    .Indiv_User_User_Detail_Whole_Div {
        padding: 30px;
    }
}