.user_portal_right_linkedin_div {
    margin-top: 30px;
    background-color: #E6E6E6;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 20px;
    cursor: pointer;
}

.user_portal_right_linkedin_join {
    color: #006197;
    font-size: 40px;
    font-weight: bold;
    margin: 15px;
    text-align: center;
}

.user_portal_right_linkedin_community {
    color: #006197;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin: 0px;
}

@media screen and (max-width: 1600px) {
    .user_portal_right_linkedin_div {
        padding: 20px 10px;
    }

    .user_portal_right_linkedin_join {
        font-size: 36px;
    }
}

@media screen and (max-width: 1350px) {
    .user_portal_right_linkedin_join {
        font-size: 32px;
    }

    .user_portal_right_linkedin_community {
        font-size: 16px;
    }
}

@media screen and (max-width: 1200px) {
    .user_portal_right_linkedin_join {
        font-size: 32px;
    }

    .user_portal_right_linkedin_community {
        font-size: 16px;
    }

    .user_portal_right_linkedin_img_div {
        display: none;
    }
}

@media screen and (max-width: 750px) {
    .user_portal_right_linkedin_join {
        font-size: 40px;
    }

    .user_portal_right_linkedin_community {
        font-size: 18px;
    }

    .user_portal_right_linkedin_img_div {
        display: inherit;
    }

    .user_portal_right_linkedin_div {
        padding: 20px;
    }
}