.individual_podcast_top_img_div {
    display: flex;
    gap: 3%;
    align-items: flex-start;
}

.indiviudal_podcast_date_div_podcast {
    margin-top: 0px;
    width: 80px;
    height: 80px;
    padding: 0px 5px;
    text-align: center;
    background-color: #AD2225;
}

.indiv_podcast_where_to_listen_whole_div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    background-color: #F5F5F5;
    margin-top: 2%;
    padding: 0px 2%
}

@media screen and (max-width: 1000px) {
    .individual_podcast_top_img_div {
        flex-direction: column;
        gap: 10px;
        align-items: center;
    }
}

@media screen and (max-width: 700px) {
    .individual_podcast_title_podcast {
        margin-top: 0px;
        font-size: 22px;
    }

    .individual_podcast_season_podcast {
        font-size: 15px;
        margin-bottom: 5px;
    }

    .indiviudal_podcast_date_day_podcast {
        font-size: 24px;
    }

    .indiviudal_podcast_date_div_podcast {
        margin-top: 0px;
        width: 75px;
        height: 75px;
        padding: 0px 5px;
        text-align: center;
        background-color: #AD2225;
    }
}

@media screen and (max-width: 450px) {
    .individual_podcast_title_podcast {
        font-size: 20px;
    }

    .individual_podcast_season_podcast {
        font-size: 15px;
        margin-bottom: 2px;
    }

    .indiviudal_podcast_date_day_podcast {
        font-size: 22px;
    }

    .indiviudal_podcast_date_month_year_podcast {
        font-size: 13px;
    }

    .indiviudal_podcast_date_div_podcast {
        margin-top: 0px;
        width: 75px;
        height: 70px;
        padding: 0px 5px;
        text-align: center;
        background-color: #AD2225;
    }

    .indiviudal_podcast_date_month_year_podcast {
        margin: 0px;
    }

    .indiv_podcast_where_to_listen_whole_div {
        flex-direction: column;
        padding-bottom: 10px;
    }
}

@media screen and (max-width: 360px) {
    .indiviudal_podcast_date_month_year_podcast {
        font-size: 12px;
    }

}