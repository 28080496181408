.news_page_news_letter_sign_up_div{
    background-image: url('../../../resources/Group 4769.png');
    background-size: cover;
    background-position: center;
    height: 200px;
    object-fit: cover;
    width: 100%;
}

.news_page_news_letter_sign_up_smaller_div{
    padding-top: 45px;
}
/* .news_page_news_letter_sign_up_div::before{
  background-color: #AD2225;
  content: '';
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
} */

.news_page_news_letter_sign_up_title{
    font-size: 20px;
}


.news_page_news_letter_sign_up_input{
    background-color: transparent;
    border: 1px solid white;
    border-top: transparent;
    border-left: transparent;
    border-right: transparent;
    outline: none;
    height: 20px;
}
.news_page_news_letter_sign_up_submit{
    width: 140px;
}


.news_page_news_letter_sign_up_label{
    font-size: 15px;
    line-height: 0px;
}


@media screen and (max-width: 850px) {
.news_page_news_letter_sign_up_input{
    width: 100px;
}
.news_page_news_letter_sign_up_submit{
    width: 100px;
    height: 45px;
}
.news_page_news_letter_sign_up_label_div{
    align-items: center;
}
}

@media screen and (max-width: 410px) {
    .news_page_news_letter_sign_up_div{
    height: 180px;
}
.news_page_news_letter_sign_up_title{
    font-size: 17px;
}
    .news_page_news_letter_sign_up_label{
        font-size: 14px;
    }
.news_page_news_letter_sign_up_input{
    width: 80px;
}
.news_page_news_letter_sign_up_submit{
    width: 80px;
    height: 40px;
}
}