.news_page_idca_news_div{
    width: 22%;
    min-width: 220px;
    height: fit-content;
    padding: 15px 22px;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
}

.news_page_all_div{
    margin-top: 130px;
}

@media screen and (max-width: 1250px) {
.news_page_idca_news_div{    
    min-width: 200px;
}
}

@media screen and (max-width: 1150px) {
.news_page_idca_news_div{    
    display: none;
}
.news_page_all_news_small_div{
    margin-left: 15%;
    margin-right: 15%;
}
}
@media screen and (max-width: 625px) {
.news_page_all_news_small_div{
    margin-left: 10%;
    margin-right: 10%;
}
}
@media screen and (max-width: 500px) {
    .news_page_all_news_small_div{
    margin-left: 3.5%;
    margin-right: 3.5%;
    }
}