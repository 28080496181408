.user_portal_right_top_all_div {
    padding: 25px 20px;
    box-shadow: 0px 0px 15px #6f6f6f57;
    border-radius: 8px;
}

.user_portal_right_email_inner_div {
    margin-top: 30px;
}

.user_portal_right_email_sig_gmail_html_div {
    display: flex;
    gap: 10px;
}

.user_portal_right_email_sig_gmail_html_indiv_div {
    cursor: default;
    text-align: center;
    width: 70%;
    background-color: #F1F1F1;
    color: #888888;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.user_portal_right_email_sig_content_div {
    padding: 10px;
    background-color: #F1F1F1;
    max-height: fit-content;
}

.user_portal_right_email_sig_carousel {
    background-color: white;
    max-height: 130px;
}

.user_portal_right_email_sig_content_inner_div {
    padding: 20px;
    padding-bottom: 20px;
    position: relative;
}

.user_portal_right_email_sig_content_inner_div_img {
    position: relative;
    width: 80px;
    height: 80px;
}

.user_portal_right_email_sig_content_inner_copy_div {
    border: 1px solid #888888;
    border-radius: 4px;
    width: fit-content;
    height: fit-content;
    padding: 5px;
    padding-bottom: 0px;

    position: absolute;
    top: 15px;
    right: 25px;
    cursor: pointer;
}

#clipboard_copy_message {
    position: fixed;
    top: 120px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #434343;
    color: rgb(195, 195, 195);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: none;
}

.clipboard_copy_message_hidden {
    display: none;
}

@media screen and (max-width: 1300px) {
    .user_portal_right_email_sig_content_inner_copy_div {
        padding: 0px;
        border: 0px;
    }
}

@media screen and (max-width: 1050px) {
    .user_portal_right_email_sig_content_inner_copy_div {
        left: 20px;
        top: 10px;
    }
}

@media screen and (max-width: 750px) {
    .user_portal_right_email_sig_content_inner_copy_div {
        right: 40px;
        left: auto;
        top: 20px;
    }
}