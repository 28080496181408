.home_page_certification_top_div{
    background-color: #FCF4F4;
    padding-top: 50px;
    padding-bottom: 50px;
}

.home_page_certification_div{
    display: flex;
    column-gap: 10%;
    align-items: center;
}

.Audit_AuditAndCertification_left_side_image{
    min-width: 450px;
    max-width: 500px;
}


@media screen and (max-width: 1500px) {
.Audit_AuditAndCertification_left_side_image{
    min-width: 400px;
}
}
@media screen and (max-width: 1300px) {
.Audit_AuditAndCertification_left_side_image{
    min-width: 350px;
}
}

@media screen and (max-width: 950px) {
    .home_page_certification_div{
    display: flex;
    flex-direction:column;
    align-items: center;
    row-gap: 30px;
}
.Audit_AuditAndCertification_left_side_image{
    min-width: 420px;
}
}
@media screen and (max-width: 550px) {
.Audit_AuditAndCertification_left_side_image{
    min-width: 380px;
}
}
@media screen and (max-width: 450px) {
.Audit_AuditAndCertification_left_side_image{
    min-width: 300px;
}
}
@media screen and (max-width: 380px) {
.Audit_AuditAndCertification_left_side_image{
    min-width: 280px;
}
}