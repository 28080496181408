.IndividualPersonExclusiveSpeakerCard {
    display: flex;
    flex-direction: column;
    background-color: #8e73a3;
    width: 230px;
    overflow: hidden;
    border-radius: 20px;
}

.IndividualPersonExclusiveSpeakerCardTextOnly {
    padding: 10px;
    text-align: center;
}

.IndividualPersonExclusiveSpeakerCards {
    display: flex;
    justify-content: center;
    gap: 100px;
    flex-wrap: wrap;
}

@media screen and (max-width: 770px) {

    .IndividualPersonExclusiveSpeakerCards {

        flex-direction: column;
        align-items: center;
    }
}