.technical_charrettes_upcoming_events_three_event_div {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: space-around;

    justify-items: center;
    text-align: center;
}

.technical_charrettes_upcoming_events_event_div {
    width: 250px;
    cursor: pointer;
    text-decoration: none;
}

.technical_charrettes_upcoming_events_event_div p {
    color: #AD2225;
    line-height: 0px;
}

.technical_charrettes_upcoming_events_all_events {
    text-decoration: none;
    margin-top: 30px;
}

.technical_charrettes_upcoming_events_all_events_div {
    width: 200px;
    background-color: #4C0067;

}

.technical_charrettes_upcoming_events_all_events_div p {
    line-height: 50px;
    font-size: 17px;
}