.upcoming_podcast_host_top_div_card {
    display: grid;
    grid-template-columns: 45% 45%;
    justify-content: space-between;
    row-gap: 30px;
}

.upcoming_podcast_host_div_card {
    box-shadow: 0px 9.43923px 63.7148px rgba(0, 0, 0, 0.1);
    height: min-content;
}

.upcoming_podcast_host_div_card_inner {
    padding: 40px 45px;
}

.upcoming_podcast_host_div_card_inner h3 {
    font-size: 25px;
    line-height: 45px;
    margin: 0px;
}

.upcoming_podcast_host_div_card_inner h4 {
    font-size: 22px;
    line-height: 40px;
    margin: 0px;
    margin-bottom: 20px;
}

.upcoming_podcast_host_div_card_inner p{
    margin-bottom: 40px;
}

.upcoming_podcast_host_div_card_inner a{
    font-size: large;
    text-decoration: none;
    padding: 10px 25px;
    border-width: 1px;
    border-style: solid;
    cursor: pointer;
}


.upcoming_podcast_host_div_card_title {
    display: flex;
    align-items: center;
    gap: 40px;
    padding-left: 35px;
    padding-right: 35px;
    background-size: 10px;
    margin-top: 40px;
}

.upcoming_podcast_host_div_card_title_background {
    background-color: #EEEEEE;
    width: 31.5%;
    height: 80px;
    z-index: -1;
    margin-top: 20px;
    position: absolute;
}

.upcoming_podcast_host_div_card_title {
    font-size: 19px;
}

@media screen and (max-width: 1586px) {
    .upcoming_podcast_host_div_card_title {
        gap: 10px;
        flex-direction: column;
    }
}

@media screen and (max-width: 1404px) {
    .upcoming_podcast_host_div_card_title {
        gap: 10px;
        flex-direction: column;
    }

    .upcoming_podcast_host_div_card_title h2 {
        margin: 0px;
    }

    .upcoming_podcast_host_div_card_title {
        font-size: 18px;
    }

    .upcoming_podcast_host_div_card_inner h3 {
        font-size: 25px;
    }
}

@media screen and (max-width: 1282px) {

    .upcoming_podcast_host_top_div_card {
        margin-bottom: -10px;
    }

    .upcoming_podcast_host_div_card_title {
        gap: 10px;
        flex-direction: row;
    }

    .upcoming_podcast_host_div_card_title h2 {
        margin: unset;
    }

    .upcoming_podcast_host_div_card_title {

        font-size: 18px;
    }

    .upcoming_podcast_host_div_card_inner h3 {

        font-size: 25px;
    }

    .upcoming_podcast_host_top_div_card {
        grid-template-columns: unset;
        /* grid-template-rows: 45% 45%; */
        justify-content: space-between;
    }

    .upcoming_podcast_host_div_card_title_background {
        background-color: #EEEEEE;
        width: 70%;
        height: 80px;
    }

    .upcoming_podcast_host_div_card {
        margin-bottom: 17px;
    }
}

@media screen and (max-width: 631px) {
    .upcoming_podcast_host_div_card_title {
        gap: 10px;
        flex-direction: column;
    }

    .upcoming_podcast_host_div_card_title h2 {
        margin: 0px;
    }
}

@media screen and (max-width: 625px) {
    .upcoming_podcast_host_div_card_title_background {
        width: 80%;
    }

    .upcoming_podcast_host_div_card_title {
        font-size: 16px;
    }

    .upcoming_podcast_host_div_card_inner h3 {

        font-size: 32px;
    }
}

@media screen and (max-width: 500px) {
    .upcoming_podcast_host_div_card_title_background {
        width: 93%;
    }
}

@media screen and (max-width: 379px) {
    .upcoming_podcast_host_div_card {
        margin-bottom: 120px;
    }
}