.audit_page_top_image_dc_top {
  /* background-image: url("../../../resources/Group 4874-min.png"); */
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 580px;
}

.audit_page_top_image_dc_top_bg_img {
  position: absolute;
  overflow: hidden;
  z-index: -1;
  height: 580px;
  width: 100%;
}

.audit_page_top_image_dc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 170px;
}

.audit_page_top_dc_section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 60%;
}

.audit_page_dc_top_larg_title {
  font-size: 36px;
  line-height: 42px;
  margin: 0px;
}

.audit_page_top_dc_section p {
  margin-top: 5px;
}

.audit_page_top_dc_section a {
  background-color: #AD2225;
  padding: 10px 20px;
  width: fit-content;
}

.audit_page_top_image_dc_img {
  height: 40%;
  width: 25%;
  max-width: 330px;
  min-width: 250px;
}

@media screen and (max-width: 1390px) {
  .audit_page_top_dc_section {
    width: 70%;
  }
}

@media screen and (max-width: 1200px) {
  .audit_page_dc_top_larg_title {
    font-size: 33px;
  }

  .audit_page_dc_top_larg_description {
    font-size: 15px;
  }
}

@media screen and (max-width: 1050px) {
  .audit_page_top_image_dc_top {
    height: 530px;
  }

  .audit_page_top_image_dc_top_bg_img {
    height: 530px;
  }

  .audit_page_dc_top_larg_title {
    margin-bottom: 30px;
  }

  .audit_page_dc_top_larg_description {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .audit_page_top_image_dc_img {
    height: 40%;
    width: 25%;
    min-width: 200px;
  }
}

@media screen and (max-width: 500px) {
  .audit_page_top_image_dc div {
    font-size: 15px;
  }

  .audit_page_top_image_dc_img {
    min-width: 170px;
  }

  .audit_page_dc_top_larg_title {
    font-size: 30px;
  }
}

@media screen and (max-width: 400px) {
  .audit_page_top_image_dc_img {
    min-width: 150px;
  }

  .audit_page_dc_top_larg_title {
    font-size: 30px;
  }
}

@media screen and (max-width: 350px) {
  .audit_page_top_image_dc_img {
    min-width: 140px;
  }

  .audit_page_dc_top_larg_title {
    font-size: 28px;
  }
}

@media screen and (max-width: 325px) {
  .audit_page_top_image_dc_img {
    min-width: 130px;
  }

  .audit_page_dc_top_larg_title {
    font-size: 26px;
  }
}