.news_page_podcast_webinar_top_div {
    margin-top: 70px;
    display: grid;
    grid-template-columns: 50% 5% 40%;
    justify-content: space-between;
}

.news_section_podcast_webinar_divide_verticle {
    border-left: 2px solid rgb(185, 185, 185);
    margin: 0px 20px;
    height: 50%;
    align-self: center;
}

.news_page_podcast_all_series_div {
    margin-top: 40px;
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
}

.news_page_podcast_indiv_series_div {
    border-radius: 10px;
    overflow: hidden;
    width: 200px;
    filter: drop-shadow(0px 0px 23.7373px rgba(0, 0, 0, 0.25));
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start; */
    gap: 0px;
    /* justify-content: space-between; */
    align-items: center;
    height: fit-content;
}

.news_page_podcast_indiv_series_episode_div {
    background-color: #BB2027;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.news_page_podcast_indiv_series_div p {
    margin: 0px;
    line-height: 25px;
}

.news_page_podcast_indiv_series_name_div {
    background-color: #292929;
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.news_page_podcast_indiv_series_div h5 {
    font-size: 18px;
    width: 150px;
}

.news_page_webinar_indiv_upcoming_a {
    text-decoration: none;
}

.news_page_webinar_indiv_upcoming_div {
    display: flex;
    gap: 15px;
    background-color: #DDDDDD;
    border-radius: 10px;
    height: 100px;
    overflow: hidden;
}

.news_page_webinar_indiv_upcoming_div {
    line-height: 18px;
    align-self: center;
}

.news_page_webinar_indiv_upcoming_div h4 {
    margin-top: 22px;
    margin-bottom: 10px;
}


@media screen and (max-width: 1050px) {
    .news_page_webinar_top_div {
        margin-top: 50px;
    }

    .news_page_podcast_webinar_top_div {
        margin-top: 70px;
        display: grid;
        grid-template-columns: 100%;
        justify-content: space-between;
    }
}