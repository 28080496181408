
.Podcasts_Speaker_guest_indiv_Whole{
    display: flex;
    flex-direction: column;
    width: 250px;
    height: 326px;
    align-items:stretch;
    text-align: center;
    justify-content: flex-end;
    box-shadow: rgba(0, 0, 0, 1) 0px 4px 12px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
}

.Podcasts_Speaker_guest_indiv_name{
font-size: 16px;
text-align: center;
}

.Podcast_Speaker_guest_indiv_Title{
    font-size: 15px;
    margin-bottom: 20px;
    
}

.Podcasts_guests_whole{
    padding-bottom: 40px;
}

.Podcasts_guets_list_all{
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: space-around;
}

.IndividualPodcastSpeakerBioSection{
    display: grid;
    grid-template-columns: 250px 70%;
    gap: 10%;
    align-items: center;
}

.IndividualPodcastSpeakerWholeDiv{
    padding-top: 180px;
}

/* Style the list */
ul.Indiv_Podcast_indiv_Speaker_breadcrumb {
    margin-left: 0px;
    padding-left: 0px;
    margin-bottom: 30px;
  }
  
  /* Display list items side by side */
  ul.Indiv_Podcast_indiv_Speaker_breadcrumb li {
    display: inline;
    font-size: 14px;
  }
  
  /* Add a slash symbol (/) before/behind each list item */
  ul.Indiv_Podcast_indiv_Speaker_breadcrumb li+li:before {
    padding: 8px;
    color: black;
    content: "/\00a0";
  }
  
  /* Add a color to all links inside the list */
  ul.Indiv_Podcast_indiv_Speaker_breadcrumb li a {
    color: #AD2225;
    text-decoration: none;
  }
  
  /* Add a color on mouse-over */
  ul.Indiv_Podcast_indiv_Speaker_breadcrumb li a:hover {
    opacity: 0.7;
    text-decoration: underline;
  }

  .podcast_speaker_podcast_lists{
    display: flex;
    flex-direction: column;
     gap: 50px;
     width: 100%;
     padding: 15px;
      box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1)
  }
.podcast_speaker_podcast_more_time_time{
    width: '80px';
    text-align: 'center'
}

@media screen and (max-width: 1000px) {
    .IndividualPodcastSpeakerBioSection{
        gap: 5%
    }
}


@media screen and (max-width: 900px) {
    .IndividualPodcastSpeakerBioSection{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .IndividualPodcastSpeakerCard{
        max-width: 250px;
    }
}


@media screen and (max-width: 835px) {
    .podcast_speaker_individual_time{
        display: none;
    }
    .podcast_speaker_individual_image{
        display: none;
    }
}



@media screen and (max-width: 500px) {
    .podcast_speaker_podcast_lists{
         padding-right: 0px;
    }
    .podcast_speaker_podcast_more_link{
        margin-right: 20px;
    }
    .podcast_speaker_podcast_eisode_title{
        font-size: 16px;
    }
}

@media screen and (max-width: 430px) {
    .podcast_speaker_podcast_eisode_title{
        font-size: 16px;
    }
    /* .podcast_speaker_individual_date_one{
        display: none;
    } */

    .podcast_speaker_podcast_more_time_time{
        display: none;
    }
}


@media screen and (max-width: 340px) {
   .podcast_speaker_podcast_more_link
{
    display: none;
}
}

