.individualEventGallarySingleImage {
    height: fit-content;
    margin: 30px;
    /* width: 100%; */
}

.individualEventGallaryGroupImage {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.individualEventGallaryCoursel {
    height: fit-content;

}