.StandardsDownload_Form_Whole_Page_Div{
    margin-bottom: 20px;
}

.data_center_training_about_our_courses_large_header{
    line-height: 35px;
}

.ae360InfinityImage{
    min-width: 300px;
    max-width: 300px;
    text-align: center;
}

.StandardsDownload_Form_Whole_Div_outer{
    display: flex;
    justify-content: space-between;
    /* width: 100%; */
    /* padding: 0px; */
    gap: 4%;
}

.StandardsDownload_Form_Whole_Div_text_div{
    width: 60%;
    max-width: 520px;
}

.about_our_courses_text_data_center_training{
    order: 1;
}
.StandardsDownload_Form_Whole_Div{
    box-shadow: 2px 4px 27px rgba(0, 0, 0, 0.25);
    padding: 50px;
    order: 2;
    height: fit-content;
}

.StandardsDownload_Form_Form_Div{
    padding-top: 50px;
    display: grid;
    grid-template-columns: 47% 47%;
    justify-content: space-between;
}


.StandardsDownload_Form_input{
    border:none;
    border-bottom: 1px solid black;
    /* padding: 5px 10px; */
    outline: none;
    font-size: 15px;
    font-family: Saira;
    font-style: normal;
    font-weight: normal;
    max-width: 300px;
}

.StandardsDownload_Form_label_class{
    font-size: 15px;
}


@media screen and (max-width: 990px) {
.StandardsDownload_Form_Whole_Div_outer{
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
}
.StandardsDownload_Form_Whole_Div_text_div{
    width: 100%;
}
}

@media screen and (max-width: 450px) {
.StandardsDownload_Form_Whole_Div{
    padding: 25px;
}
.StandardsDownload_Form_label_class{
    font-size: 14px;
}
}