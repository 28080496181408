.dashboard_calculations_header_fields_div {
    display: flex;
    justify-content: space-between;
}

.dashboard_calculations_header_field_button {
    cursor: pointer;
}

.dashboard_calculations_header_field_button:hover {
    color: #AD2225;
}

.dashboard_calculations_header_field_button:focus {
    color: #AD2225;
}