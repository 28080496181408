.Indiv_Webinar_Detail_text_image{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 50px;
}

.Indiv_Webinar_Detail_Text_only{
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    padding: 5%;
}

.Indiv_Webinar_Detail_right{
    display: flex;
    flex-direction: column;
    /* grid-template-columns: 100%; */
    row-gap: 50px;
    max-width: 500px;
    min-width: 400px;
    width: 40%;
}

@media screen and (max-width: 1360px) {
    .Indiv_Webinar_Detail_text_image{
        flex-direction: column;
        align-items: center;
    }
    .Indiv_Webinar_Detail_right{
        /* order: 0; */
        flex-direction: column;
        max-width: unset;
        min-width: unset;
        width: 100%;
    }
    .Indiv_Webinar_Detail_right_image{
        display: none;
    }
}