.symposia_calendar_of_event_top_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.symposia_calendar_of_event_top_div h2 {
    font-size: 30px;
}

.symposia_calendar_of_event_top_div button {
    background: #4C0067;
    box-shadow: 5px 10px 50px rgba(255, 255, 255, 0.2);
    cursor: pointer;
    border: 0px;
    width: 150px;
    font-size: 17px;
}