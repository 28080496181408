.certification_2_0_cert_sds_inside_top_div {
    padding: 20px 20px;
}

.certification_2_0_cert_sds_cert_breakdown_header_div {
    display: flex;
    align-items: center;
    gap: 20px;
}

.certification_2_0_cert_sds_cert_breakdown_text_img_div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 25px;
}

.certification_2_0_cert_sds_cert_breakdown_img_div {
    position: relative;
    min-width: 150px;
    min-height: 220px;
    max-width: 150px;
    max-height: 220px;
}

@media screen and (max-width: 600px) {
    .certification_2_0_cert_sds_cert_breakdown_img_div {
        display: none;
    }
}