.data_center_training_videos {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    flex-direction: row;
    padding-top: 70px;
}


.data-center-training-videos-section {
    height: 480px;
    background-image: linear-gradient(0deg, rgba(173, 34, 37, 0.8), rgba(173, 34, 37, 0.8)), url("../../../resources/Additional_Vide_Card_BG.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-origin: content-box;
    background-size: cover;
    margin-bottom: 80px;
}


.data_center_training_additional_videos_small_header {
    font-size: 20px;
    color: black;
}


.data_center_training_additional_videos_large_header {
    font-size: 25px;
}

@media screen and (max-width: 1407px) {

    .dataCenterTraining_video3 {
        display: none;
    }

}

@media screen and (max-width: 993px) {

    .dataCenterTraining_video2 {
        display: none;
    }

}

@media screen and (max-width: 430px) {

    .data_center_training_videos {
        padding-top: 15px;
    }

    .data-center-training-videos-section {
        height: 350px;
        margin-bottom: 80px;
    }


}

@media screen and (max-width: 396px) {

    .data_center_training_videos {
        padding-top: 15px;
    }

    .data-center-training-videos-section {
        height: 350px;
        margin-bottom: 50px;
    }

}

@media screen and (max-width: 355px) {

    .data_center_training_videos {
        padding-top: 10px;
    }

    .data-center-training-videos-section {
        height: 330px;
        margin-bottom: 30px;
    }

    .data_center_training_additional_videos_small_header {
        font-size: 18px;
        color: black;
    }


    .data_center_training_additional_videos_large_header {
        font-size: 23px;
    }

}