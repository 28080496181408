.member_user_dashb_jobs_filter_div {
    width: 300px;
    padding: 10px 20px;

    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
}

.member_user_dashb_jobs_top_div {
    display: flex;
    gap: 50px;
}