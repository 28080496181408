.webinar_new_upcoming_events_top_div {
    margin-top: 50px;
    margin-bottom: 50px;
    border-top: 1px solid gray;
}

.webinar_new_upcoming_events_header_p {
    background-color: #BB2027;
    padding: 10px 40px;
    font-size: 18px;
    width: fit-content;
    margin-top: 0px;
}

.webinar_new_upcoming_events_indiv_0 {
    background-color: #F1F1F1;
}

.webinar_new_upcoming_events_indiv_div {
    display: grid;
    grid-template-columns: 65% 2% 15% 2% 10%;
    align-items: center;
    justify-content: space-between;
    padding: 40px 40px;
}

.webinar_new_upcoming_events_indiv_img_text_div {
    display: flex;
    gap: 20px;
}

.webinar_new_upcoming_events_indiv_img_text_div h4 {
    font-size: 22px;
    color: #006197;
    margin: 0px;
}

.webinar_new_upcoming_events_indiv_img_div {
    min-width: 195px;
    max-width: 195px;
}

.webinar_new_upcoming_events_indiv_img_div {
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    height: 160px;
}

.webinar_new_upcoming_events_indiv_img_div p {
    position: absolute;
    top: 0px;
    right: 15px;
    background-color: #BB2027;
    padding: 2px 15px;
    border-radius: 10px;
}

.webinar_new_upcoming_events_indiv_text_div {
    overflow: hidden;
}

.webinar_new_upcoming_events_indiv_speaker_div {
    overflow-x: auto;
    margin-bottom: 10px;
}

.webinar_new_upcoming_events_indiv_divider_div {
    border-left: 1px solid #606060;
    height: 100%;
}

.webinar_new_upcoming_events_indiv_icon_text_div {
    display: flex;
    gap: 15px;
    align-items: center;
}

.webinar_new_upcoming_events_indiv_icon_text_div p {
    margin: 10px 0px;
    font-size: 15px;
}

.webinar_new_upcoming_events_indiv_register_div {
    text-decoration: none;
    color: #006197;
    border: 1px solid #006197;
    padding: 5px 20px;
    font-size: 14px;
    text-align: center;
}

.webinar_new_upcoming_events_speaker_all_div {
    display: flex;
    overflow: auto;
    width: 100%;
}

.webinar_new_upcoming_events_speaker_indiv_and_details_top_div {
    position: relative;
}

.webinar_new_upcoming_events_speaker_indiv_div {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}

/* .webinar_new_upcoming_events_speaker_indiv_details_div {
    display: none;
    position: absolute;
    background-color: #FFFFFFB2;
    border-radius: 10px;
    border: 1px solid border #CACACA;
    top: -100px;
    z-index: 5;
    overflow: visible;
} */

.webinar_new_upcoming_filter_top_div {
    margin: 50px 0px;
    display: flex;
    align-items: center;
    gap: 50px;
}

.webinar_new_upcoming_select_topic {
    width: 300px;
    height: 50px;
    font-size: 16px;
    padding: 0px 10px;
    border: 1px solid rgb(173, 173, 173);
    border-top: 2px solid rgb(173, 173, 173);
    border-radius: 2px;
}

.webinar_new_upcoming_events_page_number_top_div {
    display: flex;
    justify-content: center;
}

.webinar_new_upcoming_events_page_number_div {
    margin-top: 25px;
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
    color: #006197;
    border-bottom: 1px solid #006197;
}

.webinar_new_upcoming_events_page_number_div div {
    cursor: pointer;
}

.webinar_new_upcoming_events_page_number_div p {
    cursor: pointer;
}

@media screen and (max-width: 1300px) {
    .webinar_new_upcoming_events_indiv_div {
        display: grid;
        grid-template-columns: 60% 2% 15% 2% 13%;
        align-items: center;
        justify-content: space-between;
        padding: 40px 40px;
    }
}

@media screen and (max-width: 1050px) {
    .webinar_new_upcoming_events_indiv_div {
        grid-template-columns: 50% 2% 20% 2% 18%;
        padding: 30px 40px;
    }

    .webinar_new_upcoming_events_indiv_img_div {
        display: none;
    }
}

@media screen and (max-width: 900px) {
    .webinar_new_upcoming_events_indiv_div {
        grid-template-columns: 65% 2% 25%;
        row-gap: 20px;
    }

    .webinar_new_upcoming_events_indiv_divider_last_div {
        display: none;
    }

    .webinar_new_upcoming_events_indiv_img_text_div h4 {
        font-size: 20px;
    }

    .webinar_new_upcoming_events_indiv_icon_div {
        display: none;
    }

    .webinar_new_upcoming_events_indiv_register_div {
        padding: 10px 25px;
        font-size: 16px;
        width: fit-content;
    }
}