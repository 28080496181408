.login_landing_page_top_div {
    padding: 50px 100px;
    margin: 150px auto;
    margin-bottom: 70px;
    box-shadow: 0px 3.42257px 23.1023px rgba(0, 0, 0, 0.1);
    width: fit-content
}

.login_landing_page_data_fields_two_in_row_div {
    display: flex;
    gap: 7%;
    justify-content: space-between;
}

@media screen and (max-width: 650px) {
    .login_landing_page_data_fields_two_in_row_div {
        flex-direction: column;
    }

    .login_landing_page_top_div {
        padding: 50px 50px;
        margin: 150px auto;
        margin-bottom: 70px;
        box-shadow: 0px 3.42257px 23.1023px rgba(0, 0, 0, 0.1);
    }
}