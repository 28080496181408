.latam_football_cup_result_table_table_div {
    background-image: url('../../../resources/Group_Globe.png');
    background-position: center;
    background-size: cover;
    padding: 50px 0px;

    display: flex;
    align-items: center;
}

.latam_football_cup_result_table_matchup_div {
    max-width: 1000px;
    margin: auto;
}

.latam_football_cup_result_trophyPic_text_div {
    display: grid;
    grid-template-columns: 45% 45%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
}

@media screen and (max-width: 1100px) {
    .latam_football_cup_result_trophyPic_text_div {
        grid-template-columns: 80%;
        justify-content: center;
    }
}

@media screen and (max-width: 700px) {
    .latam_football_cup_result_table_table_div {
        flex-direction: column;
    }

    .latam_football_cup_result_table_table_cup_image {
        display: none;
    }
}