.cloud_audit_placeholder_img_div_1 {
    /* background-image: url('../../resources/Group 4868-min.png'); */
    background-size: cover;
    background-position: center;
    height: 390px;
    margin-top: 4%;
}

.cloud_audit_placeholder_img_div_2 {
    position: absolute;
    z-index: -1;
    overflow: hidden;
    height: 390px;
    width: 100%;
}