.member_nav_nav {
    max-width: 85%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    box-sizing: border-box;
}

#navBar_image_menu:hover {
    background-color: white;
}

/* was at 990px */
@media screen and (max-width: 1300px) {

    .member_nav_nav {
        margin-left: 0px;
    }

    .member_nav_nav li {
        float: none;
        display: block;
        text-align: left;

    }

    .member_nav_nav .nav_submenu {
        float: none;
        position: relative;
        width: 100%;
        z-index: 150;
        text-align: left;

    }
}