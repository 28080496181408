.PointRequestsAdminPannel {
    width: 100%;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    padding: 30px;
    margin-bottom: 50px;
    height: fit-content;
}

.PointRequestsAdminPannelReloadButton:hover {
    background-color: beige;
}

.PointRequestsAdminPannelReloadButton {
    background-color: transparent;
    padding: 10px;
    height: fit-content;
}