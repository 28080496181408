.Audit_Right_Left_Row_Card_Data_Center_Section_indiv_flex_img {
    position: relative;
    min-width: 40%;
    max-width: 40%;
    aspect-ratio: 3/2;
}

.Audit_Right_Left_Row_title_small_flex {
    display: flex;
    column-gap: 10px;
    align-items: center;
    margin-bottom: 0px;
    margin-top: 0px;
    font-size: 14px;
}

.Audit_Right_Left_Row_title_large_flex {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 30px;
    line-height: 50px;
    text-align: left;
}

.Audit_Right_Left_Row_Card_Data_Center_Section_indiv_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 546px;
    column-gap: 5%;
    width: 90%;

}


.Audit_Right_Left_Row_Card_Data_Center_Section_indiv_Right_side {
    width: 100%;
    text-align: justify;
}


.Audit_Right_Left_Row_Card_Data_Center_Section_indiv_button1 {
    background-color: #AD2225;
    color: white;
    border: none;
    width: 151px;
    height: 50px;
    cursor: pointer;
}

.Audit_Right_Left_Row_Card_Data_Center_Section_indiv_button2 {
    background-color: white;
    color: #AD2225;
    border: solid 1px #AD2225;
    width: 151px;
    height: 50px;
    cursor: pointer;
}

.Audit_Right_Left_Row_Card_Data_Center_Section_indiv_button2:hover {
    opacity: 0.5;
}

.Audit_Right_Left_Row_Card_Data_Center_Section_indiv_button2:active {
    opacity: 1.0;
}

.Audit_Right_Left_Row_Card_Data_Center_Section_indiv_button1:hover {
    opacity: 0.7;
}

.Audit_Right_Left_Row_Card_Data_Center_Section_indiv_button1:active {
    opacity: 1.0;
}

.Audit_Right_Left_Row_Card_Data_Center_Section_indiv_buttons_flex {
    display: flex;
    gap: 20px;
}

@media screen and (max-width: 1300px) {
    .Audit_Right_Left_Row_Card_Data_Center_Section_indiv_flex_img {
        width: 35%;
    }

    .Audit_Right_Left_Row_Card_Data_Center_Section_indiv_flex {
        width: 100%;
    }
}

/* @media screen and (max-width: 1111px) {

    .Audit_Right_Left_Row_Card_Data_Center_Section_indiv_flex{
        flex-direction: column;
    }
} */

@media screen and (max-width: 1111px) {
    .Audit_Right_Left_Row_Card_Data_Center_Section_indiv_flex_img {
        display: none;
    }

    .Audit_Right_Left_Row_Card_Data_Center_Section_indiv_Right_side {
        text-align: left;
    }

    .Audit_Right_Left_Row_Card_Data_Center_Section_indiv_flex {
        width: 100%;
    }

}

@media screen and (max-width: 629px) {
    .Audit_Right_Left_Row_Card_Data_Center_Section_indiv_flex {
        height: 600px;
    }

}

@media screen and (max-width: 421px) {
    .Audit_Right_Left_Row_Card_Data_Center_Section_indiv_flex {
        height: 670px;
    }

}


@media screen and (max-width: 375px) {
    .Audit_Right_Left_Row_Card_Data_Center_Section_indiv_flex {
        height: 730px;
    }

}


@media screen and (max-width: 346px) {
    .Audit_Right_Left_Row_Card_Data_Center_Section_indiv_flex {
        height: 800px;
    }

}