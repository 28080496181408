.about_us_board_directors_card_div{
    margin-top: 50px;
    display: flex;
    justify-content: center;
        flex-wrap: wrap;
    row-gap: 30px;
    column-gap: 3%;
}

.about_us_board_directors_individ_card_div{
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
    /* row-gap: 30px; */
}       

.about_us_board_directors_individ_social_card_div{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 35px;
    background-color: #D82027;
    color: #FFFFFF;
}

.about_us_board_directors_individ_social_individ_card_div{
    width: 16px;
    height: 16px;
}


.about_us_board_directors_individ_social_individ_link_card_div{
  text-decoration: none;
}

.about_us_board_directors_individ_social_card_div a:link, 
.about_us_board_directors_individ_social_card_div a:visited, 
.about_us_board_directors_individ_social_card_div a:hover, 
.about_us_board_directors_individ_social_card_div a:active{
    color: white;
}
