.Contact-us-top-div{
    height: 530px;
    background-image: url("../../../resources/contact_us_top_banner.jpg");
    background-size: cover;
    background-position: center;
    margin-bottom: 15px;
}

.Contact-us-top-div-text{
  position: absolute;
  left: 100px;
  text-align: left;
  margin-top: 0px;
  padding-top: 250px;
  font-size: 35px;
}

.Contact-us-breadcrumb{
    padding-left: 0px;
    margin-left: 15%;
    display: flex;
    flex-wrap: wrap;
}

/* Style the list */
ul.Contact-us-breadcrumb {
  margin-bottom: 30px;

}

/* Display list items side by side */
ul.Contact-us-breadcrumb li {
  display: inline;
  font-size: 14px;
  
}

/* Add a slash symbol (/) before/behind each list item */
ul.Contact-us-breadcrumb li+li:before {
  padding: 8px;
  color: black;
  content: "/\00a0";
  
}

/* Add a color to all links inside the list */
ul.Contact-us-breadcrumb li a {
  color: #AD2225;
  text-decoration: none;
  
}

/* Add a color on mouse-over */
ul.Contact-us-breadcrumb li a:hover {
  /* color: #01447e; */
  opacity: 0.7;
  text-decoration: underline;
}

@media screen and (max-width: 1100px) {
  .Contact-us-top-div-text{
    position: absolute;
    width: 80%;
    left: 5%;
    text-align: left;
    margin-top: 0px;
    padding-top: 250px;
    font-size: 35px;
  }
}

@media screen and (max-width: 961px) {
  .Contact-us-top-div-text{
    font-size: 30px;
  }
}

@media screen and (max-width: 961px) {
  .Contact-us-top-div-text-break{
    display: none;
  }
  .Contact-us-top-div-text{
    /* padding-top: 200px; */
    padding-right: 5%;
    padding-left: 5%;
  }
}

@media screen and (max-width: 592px) {
  .Contact-us-top-div-text{
    font-size: 28px;
  }
}