.AuditContact_Form_Whole_Div{
    /* width: 550px; */
    /* max-width: 650px; */
    box-shadow: 2px 4px 27px rgba(0, 0, 0, 0.25);
    padding: 50px;
    order: 2;
}

.ContactUsPage_Form_indiv_item_list{
    display: flex;
    flex-direction: column;
    height: 100px;
    gap: 10px;
}


.ContactUsPage_Form_indiv_item_list select{
    border: none;
    outline: none;
    overflow-x: scroll
}
.ContactUsPage_Form_indiv_item_list select option:checked { 
    background-color: white; 
    color: #AD2225;
}


.ContactUsPage_Form_indiv_item_list select option{
    display: inline-block;
    margin: 0px 10px;
    outline: none;
}

.ContactUsPage_Form_indiv_item_list select option::before {
  content: "\2610";
  font-size: 20px;
  width: 1.3em;
  text-align: center;
  display: inline-block;
}
.ContactUsPage_Form_indiv_item_list select option:checked::before {
  content: "\2611";
  font-size: 20px;
  color: #AD2225;
}

#ContactUsPage_Form_indiv_item_list_layer_6{
    /* display: flex;   
    align-items: center;
    width: 45px; */
}
#ContactUsPage_Form_indiv_item_list_layer_7{
    /* display: flex; */
}