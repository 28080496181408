.news_section_top_div {
    margin-top: 30px;
    margin-bottom: 50px;
}

.news_section_top_div a {
    text-decoration: none;
    color: black;
}

.news_section_news_all_div {
    display: grid;
    grid-template-columns: 65% 5% 25%;
    justify-content: space-between;
    /* gap: 20px; */
    margin-top: 30px;
}

.news_section_news_top_three_div {
    display: grid;
    grid-template-columns: 65% 30%;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
}

.news_section_first_news_top_div {
    width: 100%;
}

.news_section_first_news_img_div {
    position: relative;
    height: 350px;
    overflow: hidden;
    border-radius: 10px;
}

.news_section_second_third_news_top_div {
    width: 100%;
}

.news_section_second_third_news_img_div {
    position: relative;
    height: 150px;
    overflow: hidden;
    border-radius: 10px;
}

.news_section_second_third_news_divide {
    border-top: 2px solid rgb(185, 185, 185);
    margin: 20px 0px;
}

.news_section_three_four_news_divide_verticle {
    border-left: 2px solid rgb(185, 185, 185);
    margin: 0px 20px;
    height: 100%;
}

.news_section_last_four_news_top_div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}

.news_section_last_four_news_img_div {
    position: relative;
    height: 100px;
    min-width: 100px;
    overflow: hidden;
    border-radius: 10px;
}

.news_page_all_subject_div {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: flex-start;
    border-top: 1px solid rgb(163, 163, 163);
}

.news_page_indiv_subject_div {
    position: relative;
    top: -1px;
    background: #CFCFCF;
    padding: 0px 20px;
    width: 150px;
    border: 0px;
}

.news_page_indiv_subject_div:hover {
    background: #0d3b97bf;
}

.news_page_indiv_subject_div p {
    line-height: 15px;
}

.newsCategoryCarouselItem {
    padding-right: 20px;
}

@media screen and (max-width: 1250px) {
    .news_section_news_all_div {
        grid-template-columns: 100%;
    }

    .news_section_three_four_news_divide_verticle {
        width: 100%;
        border-top: 1px solid rgb(185, 185, 185);
        border-left: 0px;
        margin: 0px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .news_section_last_four_news_outer_top_div {
        display: grid;
        grid-template-columns: 45% 45%;
        justify-content: space-between;
    }

    .news_page_all_subject_div {
        display: none;
    }
}

@media screen and (max-width: 700px) {
    .news_section_last_four_news_outer_top_div {
        grid-template-columns: 100%;
    }

    .news_section_news_top_three_div {
        grid-template-columns: 100%;
    }

    .news_section_second_third_news_outer_top_div {
        display: grid;
        grid-template-columns: 45% 45%;
        justify-content: space-between;
    }

    .news_section_second_third_news_divide {
        display: none;
    }
}