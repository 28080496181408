.all_events_individual_div {
  width: 300px;
}

@media screen and (max-width: 1600px) {
  .all_events_individual_div {
    width: 270px;
  }
}

@media screen and (max-width: 1415px) {
  .all_events_individual_div {
    width: 240px;
  }
}

@media screen and (max-width: 1260px) {
  .all_events_individual_div {
    width: 270px;
  }
}

@media screen and (max-width: 1050px) {
  .all_events_individual_div {
    width: 240px;
  }
}

@media screen and (max-width: 940px) {
  .all_events_individual_div {
    width: 270px;
  }

  .event_filter_all_filter_ul {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 690px) {
  .all_events_individual_div {
    width: 240px;
  }
}

@media screen and (max-width: 620px) {
  .all_events_individual_div {
    width: 270px;
  }
}




.event_filter_all_filter_top_div {
  position: absolute;
  background-color: white;
  box-shadow: 0px 27px 27px rgba(0, 0, 0, 0.1);
  top: 74px;
  left: 0px;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 4%;
  width: 90%;
}

.event_filter_all_filter_ul {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  flex-wrap: wrap;
  padding-bottom: 10px;
}

.event_filter_all_filter_sub_ul {
  position: relative;
}



/* The event_filter_all_filter_label */
.event_filter_all_filter_label {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.event_filter_all_filter_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.event_filter_all_filter_checkbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  border: 1px solid #BDBDBD;
}

/* On mouse-over, add a grey background color */
.event_filter_all_filter_label:hover input~.event_filter_all_filter_checkbox {
  background-color: rgb(238, 235, 235);
}

/* When the checkbox is checked, add a blue background */
.event_filter_all_filter_label input:checked~.event_filter_all_filter_checkbox {
  border-width: 0px;
  background-color: #AD2225;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
}

/* Create the event_filter_all_filter_checkbox/indicator (hidden when not checked) */
.event_filter_all_filter_checkbox:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the event_filter_all_filter_checkbox when checked */
.event_filter_all_filter_label input:checked~.event_filter_all_filter_checkbox:after {
  display: block;
}

/* Style the event_filter_all_filter_checkbox/indicator */
.event_filter_all_filter_label .event_filter_all_filter_checkbox:after {
  left: 5px;
  top: 1px;
  width: 4px;
  height: 7px;
  border: solid white;
  border-width: 0 2px 2.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}




.event_filter_all_filter_calendar {
  border-width: 0px;
  width: 270px;
  height: fit-content;
}