.individual_chat_top_div {
    width: 100%;
}

.individual_chat_individual_messages_member_invite_div {
    margin: 20px 0px;
}

.individual_chat_individual_messages_invite_button {
    background: linear-gradient(rgb(0, 168, 0), green);
    font-size: 16px;
    border: 0px;
    border-radius: 20px;
    padding: 10px;
    cursor: pointer;
}