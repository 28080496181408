.about_us_technical_committee_card_div{
    margin-top: 50px;
    display: flex;
    justify-content: space-evenly;
        flex-wrap: wrap;
    row-gap: 40px;
    column-gap: 2%;
}

.about_us_technical_committee_individ_card_div{
    padding: 25px;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
}       