.news_page_top_news_div {
    width: 100%;
}

.news_page_top_news_description {
    font-size: 14px;

}

.news_page_top_news_time_icon {
    height: 20px;
    width: 20px;
}

.news_page_top_news_author_date {
    line-height: 0px;
}

.news_page_top_news_less_div {
    display: grid;
    grid-template-columns: 48% 48%;
    grid-template-rows: auto;
    grid-template-areas:
        "L R";
    justify-content: space-between;
    margin-top: 30px;
}

.news_page_top_news_less_div_small {
    place-self: stretch stretch;
    position: relative;
    width: 100%;
    height: 100%;
}


@media screen and (max-width: 1250px) {
    .news_page_top_news_title {
        font-size: 20px;
    }

    .news_page_top_news_description {
        font-size: 13px;
    }

    .news_page_top_news_author_date {
        font-size: 12.5px;
    }

    .news_page_top_news_time_icon {
        height: 15px;
        width: 15px;
    }
}

@media screen and (max-width: 770px) {
    .news_page_top_news_less_div {
        display: initial;
    }

    .news_page_top_news_less_div_small {
        margin-top: 30px;
        width: 100%;
        /* height: 270px; */
    }
}

@media screen and (max-width: 700px) {
    .news_page_top_news_title {
        font-size: 18px;
    }

    .news_page_top_news_description {
        font-size: 12px;
    }

    .news_page_top_news_author_date {
        font-size: 12px;
    }

    .news_page_top_news_time_icon {
        height: 15px;
        width: 15px;
    }

    .news_page_top_news_div {
        width: 100%;
        margin: auto;
    }
}

@media screen and (max-width: 550px) {
    .news_page_top_news_less_div_small {
        height: 250px;
    }
}


@media screen and (max-width: 490px) {
    .news_page_top_news_less_div_small {
        height: 240px;
    }

    .news_page_top_news_div {
        /* width: 440px; */
    }
}

@media screen and (max-width: 470px) {
    .news_page_top_news_div {
        /* width: 420px; */
    }
}

@media screen and (max-width: 450px) {
    .news_page_top_news_less_div_small {
        height: 220px;
    }

    .news_page_top_news_line {
        display: none;
    }

    .news_page_top_news_description {
        line-height: 17px;
    }

    .news_page_top_news_div {
        width: 100%;
    }
}

@media screen and (max-width: 410px) {
    .news_page_top_news_less_div_small {
        height: 220px;
    }

    .news_page_top_news_title {
        font-size: 16px;
    }

    .news_page_top_news_description {
        line-height: 15px;
        font-size: 11px;
    }

    .news_page_top_news_author_date {
        font-size: 11px;
    }

    .news_page_top_news_time_icon {
        height: 10px;
        width: 10px;
    }

    .news_page_top_news_div {
        width: 100%;
    }
}

@media screen and (max-width: 390px) {
    .news_page_top_news_less_div_small {
        height: 190px;
    }

    .news_page_top_news_div {
        /* width: 330px; */
    }
}

@media screen and (max-width: 350px) {
    .news_page_top_news_less_div_small {
        height: 180px;
    }

    .news_page_top_news_div {
        /* width: 300px; */
    }
}