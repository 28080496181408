.Globe-hp-whole {
    background: #232323;
    background-image: url("../../../resources/Globe_bg.png");
    height: 561px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
}

.Globe-hp-pic {
    position: relative;
    min-width: 450px;
    aspect-ratio: 1;
}

.Globe-hp-header2_text {
    font-size: 30px;
}

.Globe-hp-text {
    font-size: 17px;
}

.Globe_hp_dsc {
    margin-right: 10%;
}

.countdowns_hp_globe {
    font-size: 40px;
}

.Globe_Home_stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
}

.Globe_Home_stat_indiv_whole {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Globe_Home_stat_indiv {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    align-items: center;
}

.countdowns_hp_globe {
    width: 120px;
}

.Globe_Home_stat_indiv_text {
    font-size: 17px;
}

@media screen and (max-width: 1470px) {
    .Globe_Home_stats {
        gap: 10px;
        justify-content: space-around;
        /* padding-bottom: 80px; */
    }

    .countdowns_hp_globe {
        font-size: 30px;
        width: 100px;

    }
}

@media screen and (max-width: 1350px) {
    .Globe-hp-pic {
        display: none;
    }

    .countdowns_hp_globe {
        font-size: 40px;
        width: 120px;
    }

    .Globe_hp_dsc {
        margin-left: 10%;
    }
}

@media screen and (max-width: 940px) {
    .countdowns_hp_globe {
        font-size: 30px;
        width: 100px;
    }
}

@media screen and (max-width: 903px) {
    .countdowns_hp_globe {
        font-size: 28px;
        width: 100px;
    }
}

@media screen and (max-width: 803px) {
    .Globe-hp-whole {
        height: 650px;
        gap: 30px;
    }
}

@media screen and (max-width: 576px) {
    .Globe-hp-whole {
        height: 700px;
        gap: 20px;
    }

    .Globe-hp-header2_text {
        font-size: 25px;
    }

}


@media screen and (max-width: 508px) {

    .countdowns_hp_globe {
        font-size: 25px;
        width: 100px;
    }

    .Globe-hp-text {
        font-size: 14px;
    }

    .Globe-hp-whole {
        height: 800px;
        gap: 20px;
    }
}



@media screen and (max-width: 405px) {
    .Globe_Home_stats {
        gap: 20px;
    }

    .Globe-hp-whole {
        height: 1000px;
        gap: 20px;
    }
}

@media screen and (max-width: 331px) {
    .Globe_Home_stats {
        gap: 15px;
    }

    .Globe-hp-whole {
        height: 1050px;
        gap: 20px;
    }
}

@media screen and (max-width: 305px) {


    .Globe-hp-whole {
        height: 1100px;
        gap: 20px;
    }
}