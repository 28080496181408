.Audit_AuditAndCertification_header{
    font-size: 30px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    text-align: left;
}

.Audit_AuditAndCertification_header_small{
    margin-top: 0px; 
    font-size: 14px;
    margin-bottom: 0px;
    text-align: left;
}

.Audit_AuditAndCertification_Whole_div{
    display: flex;
    justify-content: space-between;
    gap: 3%;
    flex-wrap: wrap;
}

.Audit_AuditAndCertification_left_side_div{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 60%;
    text-align: justify;
}

.Audit_AuditAndCertification_left_side_image{
    width: 100px;
}

@media screen and (max-width: 1313px) {
    .Audit_AuditAndCertification_left_side_div{
        width: 50%;
    }
}

@media screen and (max-width: 1035px) {
    .Audit_AuditAndCertification_Whole_div{
        display: flex;
        flex-direction: column;
        align-items: center;
        column-gap: 30px;
    }

    .Audit_AuditAndCertification_left_side_div{
        width: 100%;
        align-items: center;
    }

    .Audit_AuditAndCertification_contact_us_form{
        padding-top: 50px;
    }
}

@media screen and (max-width: 570px) {
 .Audit_AuditAndCertification_left_side_div_text{
     text-align: left;
 }
 .Audit_AuditAndCertification_header{
     text-align: center;
 }

}