.standards_page_placeholder_img_div_1 {
    background-image: url('../../resources/ecobg 1.png'), url('../../resources/daniele-levis-pelusi-Vo6A7rwmAJk-unsplash 3.png');
    background-size: cover;
    background-position: center;
    height: 390px;
    margin-top: 4%;
}

.standards_page_placeholder_img_div_2 {
    background-image: url('../../resources/image 32.png');
    background-size: cover;
    background-position: center;
    height: 390px;
    margin-top: 4%;
}

.standards_page_placeholder_img_text_div_1 {
    font-size: 36px;
    line-height: 45px;
    text-align: center;
    margin: auto;
    padding-top: 8%;
}

.standards_page_placeholder_img_text_div_2 {
    font-size: 36px;
    line-height: 45px;
    text-align: center;
    margin: auto;
    padding-top: 8%;
}

.standards_page_download_standards_div {
    margin-top: 2%;
    display: flex;
    align-items: center;
    gap: 10%;
    padding: 3% 4%;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
}

.standards_page_download_standards_div p {
    font-size: 16px;
}

.standards_page_technical_committee_div {
    background: #F5F5F5;
    padding-top: 8%;
    padding-bottom: 3%;
}

.standards_page_mehdi_quote_div p {
    font-size: 17px;
}

.standards_page_download_standards_download_h3 {
    font-size: 24px;
}


@media screen and (max-width: 1400px) {
    .standards_page_technical_committee_div {
        padding-top: 10%;
    }
}

@media screen and (max-width: 1200px) {
    .standards_page_technical_committee_div {
        padding-top: 13%;
    }

    .standards_page_mehdi_quote_div p {
        font-size: 15px;
    }
}

@media screen and (max-width: 1000px) {
    .standards_page_technical_committee_div {
        padding-top: 14%;
    }

    .standards_page_mehdi_quote_div p {
        font-size: 14px;
    }
}

@media screen and (max-width: 890px) {
    .standards_page_technical_committee_div {
        padding-top: 2%;
    }

    .standards_page_mehdi_quote_div {
        display: none;
    }
}

@media screen and (max-width: 800px) {
    .standards_page_placeholder_img_text_div_2 {
        font-size: 33px;
        line-height: 40px;
        padding-top: 9%;
    }
}

@media screen and (max-width: 680px) {
    .standards_page_download_standards_div {
        flex-direction: column;
        padding: 7% 4%;
    }

    .standards_page_download_standards_div p {
        font-size: 15px;
    }
}

@media screen and (max-width: 600px) {
    .standards_page_placeholder_img_text_div_2 {
        font-size: 30px;
    }
}

@media screen and (max-width: 500px) {
    .standards_page_placeholder_img_text_div_2 {
        font-size: 28px;
        line-height: 36px;
        padding-top: 10%;
    }
}

@media screen and (max-width: 430px) {
    .standards_page_placeholder_img_text_div_1 {
        font-size: 33px;
        line-height: 40px;
    }

    .standards_page_placeholder_img_text_div_2 {
        font-size: 25px;
        line-height: 33px;
        padding-top: 16%;
    }

    .phone_remove {
        display: none;
    }
}

@media screen and (max-width: 370px) {
    .standards_page_placeholder_img_text_div_1 {
        font-size: 30px;
        line-height: 38px;
        padding-top: 13%;
    }
}