.MemberEventIndividualTopDivBGImage {
    height: 600px;
    background-image: url("../../resources/idca-dc-1.jpg");
    position: relative;
    background-size: cover;
    background-position: center;
    margin-bottom: 15px;
}

.member_event_individual_title {
    font-size: 40px;
}

.member_event_individual_date_mode {
    line-height: 0px;
    font-size: 30px;
}

.member_event_individual_mode {
    line-height: 0px;
    font-size: 25px;
}

.member_event_individual_location {
    font-size: 25px;
}

.member_event_individual_description_venue_div {
    margin-top: 50px;
    margin-bottom: 50px;
    display: grid;
    grid-template-columns: 45% 45%;
    justify-content: space-between;
}

.member_event_individual_description_venue_h3 {
    text-decoration: underline;
}

/* was at 990px */
@media screen and (max-width: 900px) {
    .member_event_individual_description_venue_div {
        display: grid;
        grid-template-columns: 100%;
    }

    .member_event_individual_title {
        font-size: 35px;
    }

    .member_event_individual_date_mode {
        font-size: 25px;
    }

    .member_event_individual_mode {
        font-size: 22px;
    }

    .member_event_individual_location {
        font-size: 22px;
    }
}

@media screen and (max-width: 600px) {
    .member_event_individual_title {
        font-size: 30px;
    }

    .member_event_individual_date_mode {
        font-size: 22px;
    }

    .member_event_individual_mode {
        font-size: 18px;
    }

    .member_event_individual_location {
        font-size: 18px;
    }
}