.consulting_page_service_individual_div {
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    gap: 3%;
    padding: 2.5%;
    /* padding: 0% 2%; */
    /* padding-bottom: 2.5%; */
    margin: 3% 0px;
}

.consulting_page_service_individual_div_img {
    position: relative;
    min-width: 320px;
    object-fit: contain;
    aspect-ratio: 1.5;
}

/* .consulting_page_service_individual_div img {
    min-width: 320px;
} */

/* @media screen and (max-width: 1500px) {
    .consulting_page_service_individual_div {
        flex-direction: column;
    }
} */

@media screen and (max-width: 1350px) {
    .consulting_page_service_individual_div h3 {
        font-size: 18px;
    }

    .consulting_page_service_individual_div h4 {
        font-size: 15.5px;
        margin-bottom: 0px;
    }

    .consulting_page_service_individual_div p {
        font-size: 14px;
    }

    .consulting_page_service_individual_div img {
        width: 300px;
    }
}

@media screen and (max-width: 1150px) {
    .consulting_page_service_individual_div {
        padding: 3% 5%;
    }

    .consulting_page_service_individual_div h3 {
        font-size: 20px;
    }

    .consulting_page_service_individual_div h4 {
        font-size: 16.5px;
    }

    .consulting_page_service_individual_div p {
        font-size: 15px;
    }

    .consulting_page_service_individual_div img {
        width: 320px;
    }

    .consulting_page_service_individual_div a {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 650px) {
    .consulting_page_service_individual_div h3 {
        font-size: 18px;
    }

    .consulting_page_service_individual_div h4 {
        font-size: 15.5px;
    }

    .consulting_page_service_individual_div p {
        font-size: 14px;
    }

    .consulting_page_service_individual_div img {
        width: 300px;
    }
}

@media screen and (max-width: 450px) {
    .consulting_page_service_individual_div {
        min-width: 260px;
    }
}