.MembershipEmailSignUpInput {
    border: 0px;
    border-bottom: 1px solid black;
    background-color: transparent;
    height: 30px;
    font-size: 18px;
}

.MembershipEmailSignUpInput:focus {
    outline: none;
}

.MembershipEmailSignUpInput::placeholder {
    font-size: 15px;
    color: #AD2225;
}

.MembershipEmailSignUpOuterDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
}

.MembershipEmailSignUpOuterDivSignUpButton {
    background-color: #000000B8;
    padding-left: 23px;
    padding-right: 23px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
}

@media screen and (max-width: 950px) {
    .MembershipEmailSignUpOuterDiv {
        gap: 15px;
        flex-direction: column;
        padding: 30px;
    }
}