.data-center-training-top-div {
  height: 480px;
  /* background-image: url("../../../resources/Data center training page.png"); */
  /* background-size: 100% 530px; */
  background-size: cover;
  /* background-attachment: fixed; */
  background-position: center;
  background-position-y: top;
  margin-bottom: 15px;
}

.data_center_training_top_img_bg_div {
  position: absolute;
  overflow: hidden;
  z-index: -1;
  width: 100%;
  height: 480px;
}

.training_top_larg_title {
  line-height: 10px;
}

.data-center-training-top-div_slogan {
  display: flex;
  flex-direction: column;
  padding-top: 250px;
  gap: 20px;
  margin-top: 0px;
  justify-content: center;
  font-size: 40px;
  text-align: center;
}

.dctraining_top_dc_section {
  display: flex;
  flex-direction: column;
  padding-top: 250px;
  gap: 20px;
}


.data-center-training-breadcrumb {
  padding-left: 0px;
  margin-left: 0px;
}

/* Style the list */
ul.data-center-training-breadcrumb {
  margin-bottom: 30px;
}

/* Display list items side by side */
ul.data-center-training-breadcrumb li {
  display: inline;
  font-size: 14px;
}

/* Add a slash symbol (/) before/behind each list item */
ul.data-center-training-breadcrumb li+li:before {
  padding: 8px;
  color: black;
  content: "/\00a0";
}

/* Add a color to all links inside the list */
ul.data-center-training-breadcrumb li a {
  color: #AD2225;
  text-decoration: none;
}

/* Add a color on mouse-over */
ul.data-center-training-breadcrumb li a:hover {
  /* color: #01447e; */
  opacity: 0.7;
  text-decoration: underline;
}

.data-center-training-top-count-up-div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 24px;
  width: fit-content;
  float: right;
  position: relative;
  top: 410px;
  right: 50px;
  background-image: url("../../../resources/DataCenterTrainingClassColor.png");
}

.data-center-training-top-count-up-individ-div {
  margin: 20px;
  padding: 10px;
  /* background-image: ; */
}

/* .data-center-training-top-count-up-individ-number{
    margin: 0px;
    line-height: 0px;
} */

.data-center-training-top-count-up-individ-text {

  font-size: 20px;
  margin-bottom: 5px;
}

@media screen and (max-width: 890px) {
  .data-center-training-top-div_slogan {
    font-size: 30px;
  }

}

@media screen and (max-width: 678px) {
  .data-center-training-top-div_slogan_break {
    display: none;
  }

}


@media screen and (max-width: 550px) {
  .data-center-training-top-div_slogan {
    font-size: 25px;
  }

}

@media screen and (max-width: 403px) {
  .data-center-training-top-div_slogan {
    font-size: 20px;
  }

}