.IndividualEventTestimonialSingleItem {
    height: 200px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}

.IndividualEventTestimonialSingleItemText {
    /* display: flex;
    align-items: flex-end;
    justify-content: center; */
    width: 90%;
    text-align: right;
    padding-right: 15px;
}