.MemberAreaFeaturedIntelligence {
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    height: fit-content;
    margin-top: 75px;
}

.FeaturedIntelCard {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
}

.FeaturedIntelCardText {
    text-align: left;
}

.FeaturedIntelCardButtons {
    display: flex;
    width: 150px;
    height: 50px;
}

.FeaturedIntelCardDownloadButton {
    background-color: #AD2225;
    color: white;
    padding: 10px;
    width: 150px;
    cursor: pointer;
}

.FeaturedIntelCardPreviewButton {
    background-color: #8D8D8D;
    color: white;
    padding: 10px;
    width: 150px;
    cursor: pointer;
}

.FeaturedIntelCardDownloadButton:hover {
    background-color: #ab4b4d;
}

.FeaturedIntelCardPreviewButton:hover {
    background-color: #cfcfcf;
}