.member_notification_top_div {
    display: flex;
    box-shadow: 0px 9.43923px 63.7148px rgba(0, 0, 0, 0.1);
}

.member_notification_left_side_div {
    width: 320px;
}

.left_and_right_side_divide_div {
    border: 1px solid gray;
}