.IDCA_Services_Cards_header_small{
    display: flex;
    align-items: center;
    font-size: 16px;
    gap: 5px;
    margin-bottom: 0px;
}

.IDCA_Services_Cards_header_Large{
    font-size: 25px;
    margin-top: 0px;
}


.IDCA_Services_Cards_headers{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.IDCA_Services_Card_Carousel_homepage{
    margin-bottom: 50px;
}