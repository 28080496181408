.webinar_new_description_div {
    margin-top: 70px;
    margin-bottom: 70px;
    width: 60%;
}

.webinar_new_description_div h3 {
    color: #006197;
}

.webinar_new_description_div p {
    line-height: 28px;
}

@media screen and (max-width: 800px) {
    .webinar_new_description_div {
        width: 70%;
    }
}

@media screen and (max-width: 500px) {
    .webinar_new_description_div {
        width: 80%;
    }
}

@media screen and (max-width: 450px) {
    .webinar_new_description_div {
        width: 90%;
    }
}

@media screen and (max-width: 400px) {
    .webinar_new_description_div {
        width: 92%;
    }
}