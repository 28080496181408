.dashboard_home_user_top_div {
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 70px;

}

.dashboard_home_user_courses_list_div {
    width: 100%;
    display: grid;
    grid-template-columns: 30% 30% 30%;
    row-gap: 50px;
    justify-content: space-around;
}

.dashboard_home_user__individual_courses_div {
    width: 250px;
    margin: auto;
    box-shadow: 0px 3.42257px 23.1023px rgba(0, 0, 0, 0.1);
    padding: 10px;
}

.dashboard_home_user_certificates_list_top_div {
    width: 100%;
    display: grid;
    grid-template-columns: 30% 30% 30%;
    justify-content: space-around;
}

@media screen and (max-width: 1230px) {
    .dashboard_home_user_top_div {
        margin-right: 2%;
        margin-left: 2%;
    }

    .dashboard_home_user__individual_courses_div {
        width: 220px;
    }
}

@media screen and (max-width: 1050px) {
    .dashboard_home_user__individual_courses_div {
        width: 250px;
    }
}

@media screen and (max-width: 950px) {
    .dashboard_home_user_certificates_list_top_div {
        grid-template-columns: 40% 40%;
    }

    .dashboard_home_user_courses_list_div {
        grid-template-columns: 40% 40%;
    }
}

@media screen and (max-width: 650px) {
    .dashboard_home_user__individual_courses_div {
        width: 220px;
    }
}

@media screen and (max-width: 600px) {
    .dashboard_home_user_certificates_list_top_div {
        grid-template-columns: 80%;
    }

    .dashboard_home_user_courses_list_div {
        grid-template-columns: 80%;
    }

    .dashboard_home_user__individual_courses_div {
        width: 250px;
    }
}

@media screen and (max-width: 780px) {
    #user_course_progress_bar_courses_entire_home_div_id {
        margin-left: 5%;
        margin-right: 5%;
    }
}

@media screen and (max-width: 550px) {
    #user_course_progress_bar_courses_entire_home_div_id {
        margin-left: 2%;
        margin-right: 5%;
    }
}