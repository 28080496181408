.data_center_training_road_map_div {
    background-color: #FCF4F4;
}

.data_center_training_road_map_bg_img_div {
    position: absolute;
    overflow: hidden;
    z-index: -1;
    width: 100%;
    height: 100%;
}

.data_center_training_road_map_all_titles_center {
    margin-top: 25px;
    padding-top: 15px;
}

.data_center_training_road_map_small_title {
    padding-top: 10px;
    font-size: 20px;
}

.data_center_training_road_map_big_title {
    font-size: 25px;
}

.data_center_training_road_map_text_div {
    font-size: 16px;
    width: 75%;
    position: relative;
    overflow: hidden;
}

.data_center_training_road_map_show_more_button {
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    text-align: center;
    margin: 0;
    padding-bottom: 30px;
    padding-top: 5px;

    background: linear-gradient(rgb(255, 255, 255, 0), #FCF4F4 25%);

    cursor: pointer;
    border: transparent;
    font-size: 14px;
}


@media screen and (max-width: 650px) {
    .data_center_training_road_map_text {
        font-size: 14px;
    }
}