.User_Indiv_Courses_Courses_Detail_Whole_Div h3 {
    font-size: 22px;
}

.User_Indiv_Courses_Courses_Detail_Whole_Div p {
    font-size: 18px;
}

#indiv_course_breakdown_date {
    margin-top: 5px;
    font-size: 14px;
}

.indiv_course_have_not_received_zoom_invite {
    background-color: #AD2225;
    border: 0px;
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
}

.indiv_course_have_not_received_zoom_invite:hover {
    background-color: #ad2224b5;
    border: 0px;
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
}

.indiv_course_request_response_message_top_div {
    display: flex;
    justify-content: center;
}

.indiv_course_request_response_message_div {
    position: absolute;
    top: 0px;
    width: fit-content;
    margin: auto;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 20px;
    color: green;
}

.indiv_course_user_zoom_join_button {
    padding: 20px 50px;
    background-color: #1a64ff;
    border-radius: 20px;
}