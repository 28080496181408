.training_calendar_top_div {
    background-image: url('../../../resources//waiting-room-with-monitors 1 (1).png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    height: 400px;
}

.training_calendar_top_inner_div {
    padding-top: 150px;
}

.training_calendar_top_inner_div h1 {
    font-size: 48px;
    line-height: 55px;
    font-weight: 900;
}

.training_calendar_top_inner_div h3 {
    font-size: 30px;
    line-height: 40px;
    font-weight: 100;
}

@media screen and (max-width: 500px) {
    .training_calendar_top_inner_div {
        padding-top: 170px;
    }

    .training_calendar_top_inner_div h3 {
        display: none;
    }
}