.user_course_progress_bar_courses_div {
    display: grid;
    grid-template-columns: 11% 11% 11% 11% 11% 11% 11% 11% 11%;
    margin-top: 70px;
    margin-bottom: 50px;
}

.user_course_suggestion_to_take_div {
    margin-top: 50px;
    margin-bottom: 50px;
    display: grid;
    grid-template-columns: 30% 30% 30%;
    justify-content: space-between;
    width: 100%;
}

.user_course_progress_bar_courses_individual_name {
    position: relative;
    top: -3px;
    left: 35px;
}


.user_course_progress_bar_courses_individual_finished {
    background: #AD2225;
}

.user_course_progress_bar_courses_individual_finished:before {
    border-left: 25px solid #AD2225;
}

.user_course_progress_bar_courses_individual_in_progress {
    background: gray;
}

.user_course_progress_bar_courses_individual_in_progress:before {
    border-left: 25px solid gray;
}

.user_course_progress_bar_courses_individual_not_taken {
    background: #95959585;
}

.user_course_progress_bar_courses_individual_not_taken:before {
    border-left: 25px solid #95959585;
}


.user_course_progress_bar_courses_individual_div {
    width: 95%;
    height: 50px;
    position: relative;
}

.user_course_progress_bar_courses_individual_div:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 25px solid white;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
}

.user_course_progress_bar_courses_individual_div:before {
    content: "";
    position: absolute;
    right: -25px;
    bottom: 0;
    width: 0;
    height: 0;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
}

@media screen and (max-width: 1420px) {
    .user_course_progress_bar_courses_individual_name {
        top: -2px;
        left: 30px;
        font-size: 15px;
    }
}

@media screen and (max-width: 1300px) {
    .user_course_progress_bar_courses_individual_name {
        top: -2px;
        left: 30px;
        font-size: 14px;
    }
}

@media screen and (max-width: 1200px) {
    .user_course_progress_bar_courses_individual_name {
        top: 0px;
        left: 30px;
        font-size: 15px;
    }

    .user_course_progress_bar_courses_individual_name_r_sign {
        display: none;
    }
}

@media screen and (max-width: 780px) {
    .user_course_progress_bar_courses_individual_name {
        left: 28px;
        font-size: 14px;
    }

    .user_course_progress_bar_courses_individual_name_r_sign {
        display: none;
    }
}

@media screen and (max-width: 700px) {
    .user_course_progress_bar_courses_individual_name {
        font-size: 15px;
    }
}

@media screen and (max-width: 600px) {
    .user_course_progress_bar_courses_div {
        display: grid;
        grid-template-columns: 18% 18% 18% 18% 18%;
        row-gap: 10px;
        margin-top: 70px;
        margin-bottom: 50px;
    }

    .user_course_progress_bar_courses_individual_name {
        top: -1px;
        left: 35px;
        font-size: 15px;
    }
}

@media screen and (max-width: 450px) {
    .user_course_progress_bar_courses_individual_name {
        top: 0px;
        left: 30px;
        font-size: 14px;
    }
}

@media screen and (max-width: 400px) {
    .user_course_progress_bar_courses_individual_name {
        left: 28px;
    }
}