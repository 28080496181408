.audit-top-div {
  top: 0px;
  position: relative;
  height: 530px;
  /* background-image: url("../../../resources/Servers_Audit_Top.jpg"); */
  /* background-size: 100% 530px; */
  background-size: cover;
  /* background-attachment: fixed; */
  background-position: center;
  margin-bottom: 15px;
}

.audit_top_bg_img_div {
  position: absolute;
  width: 100%;
  height: 530px;
  z-index: -1;
  overflow: hidden;
}

/* Style the list */
ul.audit-breadcrumb {
  margin-bottom: 30px;
}

/* Display list items side by side */
ul.audit-breadcrumb li {
  display: inline;
  font-size: 14px;
}

/* Add a slash symbol (/) before/behind each list item */
ul.audit-breadcrumb li+li:before {
  padding: 8px;
  color: black;
  content: "/\00a0";
}

/* Add a color to all links inside the list */
ul.audit-breadcrumb li a {
  color: #AD2225;
  text-decoration: none;
}

/* Add a color on mouse-over */
ul.audit-breadcrumb li a:hover {
  /* color: #01447e; */
  opacity: 0.7;
  text-decoration: underline;
}


.audit-top-count-up-individ-text {
  padding: 0px;
  margin: 0px;
  font-size: 20px;
  margin-bottom: 5px;
}

.audit-breadcrumb {
  padding-left: 0px;
}