.home_page_lates_news_top_div {
    margin-top: 70px;
}

.home_page_lates_news_top_div a {
    text-decoration: none;
    color: black;
}

.home_page_lates_news_category_news_top_div {
    display: grid;
    grid-template-columns: 30% 70%;
    justify-content: space-between;
}

.home_page_lates_news_category_top_div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    gap: 10px;
}

.home_page_lates_news_category_top_div h3 {
    font-size: 24px;
    margin-bottom: 15px;
}

.home_page_lates_news_category_top_div select {
    margin-bottom: 10px;
    background-color: white;
    border: 0px;
    border-bottom: 1px solid #5A5A5A;
    width: 140px;
    font-size: 17px;
}

.home_page_lates_news_category_top_div button {
    background-color: white;
    border: 1px solid #BB2027;
    border-radius: 10px;
    padding: 7px 40px;
    cursor: pointer;
}

.home_page_lates_news_category_top_div button:hover {
    background-color: #BB2027;
    border: 1px solid #BB2027;
    color: white;
    border-radius: 10px;
    padding: 7px 40px;
}

@media screen and (max-width: 1100px) {
    .home_page_lates_news_category_news_top_div {
        grid-template-columns: 25% 75%;
    }
}

@media screen and (max-width: 1000px) {
    .home_page_lates_news_category_news_top_div {
        grid-template-columns: 100%;
        gap: 40px;
    }

    .home_page_lates_news_category_top_div {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .home_page_lates_news_category_top_div p {
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .home_page_lates_news_category_top_div {
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }
}