.All_news_filter_box_whole_returned_div {
    max-width: 3000px;
}

.All_News_Filter_Box_div {
    width: 100%;
    height: 100px;
    background-color: white;
    display: flex;
    align-items: center;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    flex-direction: row;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    justify-content: space-around;
}

.All_News_Filter_Box_Category_options {
    width: 200px;
    text-align: left;
}

.All_News_Filter_Box_Category_Section {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    flex-direction: row;
}

.all-news-searchbox {
    height: 32px;
    font-size: 18px;
    border: 0;
    outline: none;
}

.all-news-items-grid {
    display: grid;
    grid-template-columns: repeat(4, auto);
    column-gap: 20px;
    row-gap: 20px;
    justify-content: space-between;
    margin-left: 20px;
}

.all-news-list-of-news {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    flex-direction: row;

}

.all-news-IDCANews-div {
    width: 16%;
    /* border: 1px solid; */
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    flex-direction: row;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px;
    margin-top: 30px;
    padding: 1.5%;
}

.all-news-index-buttons {
    margin-top: 3%;
    margin-bottom: 5%;
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.all-news-NON-IDCANews-div {
    width: 80%;
}

.all-news-index-individual-button {
    width: 50px;
    height: 50px;
    background-color: #9E9E9E;
    border: 0;
    font-size: 16px;
    margin-right: 5px;
    cursor: pointer;
}

.all-news-index-individual-button:hover {
    opacity: 0.9;
}

@media screen and (max-width: 2015px) {
    .all-news-items-grid {
        display: grid;
        grid-template-columns: repeat(3, auto);
    }
}

@media screen and (max-width: 1515px) {
    .all-news-items-grid {
        display: grid;
        grid-template-columns: repeat(2, auto);
    }
}

@media screen and (max-width: 953px) {
    .all-news-items-grid {
        display: grid;
        grid-template-columns: repeat(1, auto);
    }

    .All_News_Filter_Box_div {
        height: 150px;
    }
}

@media screen and (max-width: 953px) {
    .All_News_Filter_Box_div {
        height: 150px;
    }

    .all-news-searchbox-section {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 890px) {
    .all-news-list-of-news {
        display: flex;
        align-items: flex-start;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        flex-direction: column;
    }

    .all-news-items-grid {
        order: 1;
        margin-left: 0px;

    }

    .all-news-IDCANews-div {
        order: 2;
        width: 90%;
        align-items: center;
        margin-bottom: 50px;
        position: relative;
        left: 50%;
        transform: translate(-50%);
        /* margin-right: 300px; */
    }

    .all-news-NON-IDCANews-div {
        width: unset;
        position: relative;
        left: 50%;
        transform: translate(-50%);
    }


}