.members_admin_panel_sort_members_div {
    display: flex;
    gap: 40px;
}

.member_admin_panel_list_of_members_div {
    border-radius: 10px;
    display: grid;
    grid-template-columns: 10% 10% 15% 5% 15% 15% 10%;
    justify-content: space-between;
}

.member_admin_panel_list_of_members_div p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.members_admin_panel_search_input {
    border: 0px;
    border-bottom: 1px solid black;
    height: 25px;
    font-size: 18px;
}