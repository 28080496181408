.user_dashboard_home_upcoming_class_parking_detail {
    max-width: 160px;
}


.user_course_sponsor_img {
    width: 150px;
    height: 45px;
    object-fit: contain;
}

@media screen and (max-width: 600px) {
    .user_dashboard_home_upcoming_class_parking_detail {
        max-width: 140px;
    }
}

@media screen and (max-width: 500px) {
    .user_dashboard_home_upcoming_class_parking_detail {
        max-width: 120px;
    }
}