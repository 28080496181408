.Membership-new-top-divTopHeader {
    background-image: url('../../resources/MembershipTopHeader.png');
    background-size: cover;
    background-position: center;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 100px;
}

.Membership-new-top-divTopHeaderSignupButton {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 11.76px;
    padding-bottom: 11.76px;
    background-color: transparent;
    border: solid white 2px;
    border-radius: 5px;
    width: fit-content;
    cursor: pointer;
}

.Membership-new-top-divTopHeader h1 {
    font-size: 40px;
}

.Membership-new-top-divTopHeaderSignupButton:hover {
    background-color: black;
}

.Membership-new-top-divTopHeaderSignupButton:active {
    background-color: gray;
}

@media screen and (max-width: 1134px) {
    .Membership-new-top-divTopHeader h1 {
        font-size: 35px;
    }
}