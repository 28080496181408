.news_page_news_letter_subscribe_bg_div {
    background-image: url('../../../resources/global-connections (1) 1 (3).png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 10px;
    height: 400px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.news_page_news_letter_subscribe_bg_text_div {
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(20px);
    border-radius: 24.1225px;
    width: 80%;
    padding: 50px;
}

.news_page_news_letter_subscribe_h1_text {
    font-size: 34px;
    line-height: 40px;
}

.news_page_news_letter_subscribe_h3_text {
    font-size: 22px;
    line-height: 30px;
}

.news_page_news_letter_subscribe_p_text {
    color: #0F00B8;
    margin-top: 25px;
}

#news_page_news_letter_subscribe_email_input {
    height: 60px;
    width: 100%;
    background-color: #52A6B2;
    border: 0px;
    border-radius: 5px;
    font-size: 22px;
    padding-left: 20px;
    color: white;
}

#news_page_news_letter_subscribe_email_input::placeholder {
    font-size: 30px;
    color: white;
    padding: 10px;
}

.news_page_news_letter_subscribe_header_text_div {
    display: grid;
    grid-template-columns: 45% 45%;
    justify-content: space-between;
}

.news_page_news_letter_subscribe_email_top_div {
    position: relative;
}

.news_page_news_letter_subscribe_email_submit {
    position: absolute;
    right: 20px;
    top: 10px;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 5px;
    width: 150px;
    height: 40px;
    color: white;
    font-size: 22px;
    cursor: pointer;
}

@media screen and (max-width: 1000px) {
    .news_page_news_letter_subscribe_header_text_div {
        display: grid;
        grid-template-columns: 80%;
        justify-content: space-between;
    }

    .news_page_news_letter_subscribe_h1_text {
        font-size: 28px;
    }

    .news_page_news_letter_subscribe_h3_text {
        display: none;
    }


    #news_page_news_letter_subscribe_email_input {
        font-size: 20px;
    }

    #news_page_news_letter_subscribe_email_input::placeholder {
        font-size: 24px;
    }

    .news_page_news_letter_subscribe_p_text {
        display: none;
    }

    .news_page_news_letter_subscribe_bg_text_div {
        width: 80%;
        padding: 30px;
    }

    .news_page_news_letter_subscribe_email_top_div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .news_page_news_letter_subscribe_email_submit {
        position: unset;
    }
}

@media screen and (max-width: 600px) {
    .news_page_news_letter_subscribe_bg_text_div {
        background: transparent;
        backdrop-filter: blur(20px);
        border-radius: 0px;
        width: 100%;
        /* padding: 50px; */
    }
}