.user_portal_course_book_large_div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.user_portal_course_book_large_img_div {
    width: 300px;
    height: 260px;
    position: relative;
}

.user_portal_course_book_large_title_div {
    font-size: 18px;
    color: #006197;
}

.user_portal_course_book_large_password_text {
    margin-top: 30px;
    margin-bottom: 0px;
    font-size: 18px;
}

.user_portal_course_book_large_password_inner_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.user_portal_course_book_large_password_inner_div p {
    margin: 0px;
}

.user_portal_course_book_large_password_hidden_div {
    display: flex;
    gap: 0px;
    align-items: center;
}

.user_portal_course_book_large_password_hidden_div div {
    margin: 0px;
}

.user_portal_course_book_large_password_div {
    background-color: transparent;
    width: 100%;
}

.user_portal_course_book_large_password_action_div {
    display: flex;
    gap: 10px;
}