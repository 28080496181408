.training_calendar_filter_top_header_div {
    margin-top: 50px;
    border-bottom: 1px solid #606060;
}

.training_calendar_filter_top_top_div {
    background-color: #F1F1F1;
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 30px 50px;
}

.training_calendar_filter_top_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    row-gap: 30px;
    align-items: center;
}

.training_calendar_filter_select {
    background-color: transparent;
    border: 0px;
    border-bottom: 1px solid #5A5A5A;
    width: 250px;
    font-size: 18px;
}

.training_calendar_filter_inperson_reset_button_div {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    align-items: center;
}

.training_calendar_filter_inperson_reset_button_div button {
    background-color: #C4C4C4;
    color: white;
    padding: 10px 30px;
    border-radius: 5px;
    border: 0px;
    font-size: 16px;
}

.training_calendar_filter_inperson_reset_button_div button:hover {
    background-color: #c4c4c4bc;
    color: white;
    padding: 10px 30px;
    border-radius: 5px;
    border: 0px;
    font-size: 16px;
}

.training_calendar_filter_inperson_reset_button_partition_div {
    border-left: 1px solid #606060;
    height: 70px;
}

.training_calendar_filter_inperson_div {
    display: flex;
    gap: 30px;
}

.training_calendar_filter_inperson_inner_div {
    display: flex;
    gap: 10px;
    align-items: center;
}

.training_calendar_filter_inperson_inner_div input {
    width: 17px;
    height: 17px;
    border: 10px solid #D9D9D9;
}

.training_calendar_upcoming_events_header_div {
    display: grid;
    grid-template-columns: 13% 2% 35% 2% 10% 2% 10% 2% 20% 2%;
    align-items: center;
    justify-items: start;
    width: 100%;
}

.training_calendar_upcoming_events_header_div_top {
    display: grid;
    grid-template-columns: 87% 13%;
    align-items: center;
    justify-items: space-between;
    padding: 10px 15px;
    border-radius: 5px;
}

.training_calendar_upcoming_events_apply_now {
    color: #006197;
    border: 1px solid #006197;
    text-align: center;
    padding: 5px 15px;
    max-width: 100px;
    transition: .3s;
}

.training_calendar_upcoming_events_apply_now:hover {
    background-color: #006197;
    color: white;
    border: 1px solid #006197;
}


.training_calendar_upcoming_events_divider {
    border-left: 1px solid gray;
    height: 80%;
}

.training_calendar_upcoming_events_indiv_a {
    text-decoration: none;
    width: 100%;
}

.training_calendar_upcoming_events_indiv_1 {
    background-color: #F1F1F1;
}

.training_calendar_upcoming_events_date_div {
    color: #006197;
    padding: 5px 20px;
    border-radius: 5px;
    text-align: center;
    gap: 10px;
    align-items: center;
}

.training_calendar_upcoming_events_date_div p {
    margin: 0px;
}

.training_calendar_upcoming_events_date_0 {
    background-color: #F1F1F1;
}

.training_calendar_upcoming_events_date_1 {
    background-color: white;
}

.training_calendar_upcoming_events_seats_all {
    color: white;
    border-radius: 5px;
    padding: 5px 20px;
    width: 75px;
    text-align: center;
}

.training_calendar_upcoming_events_seats_available {
    background-color: #01900f;
}

.training_calendar_upcoming_events_seats_limited {
    background-color: #b9910d;
}

.training_calendar_upcoming_events_seats_unavailable {
    background-color: #a71414;
}

.training_calendar_upcoming_load_more_dates_div {
    text-align: center;
}

.training_calendar_upcoming_load_more_dates {
    margin-top: 50px;
    background-color: transparent;
    border: 0px;
    cursor: pointer;
    color: #006197;
    font-size: 18px;
}

@media screen and (max-width: 1300px) {
    .training_calendar_upcoming_events_header_div {
        grid-template-columns: 13% 2% 33% 2% 15% 2% 7% 2% 20% 2% 10%;
    }

    .training_calendar_upcoming_events_header_div p {
        text-overflow: ellipsis;
    }
}

@media screen and (max-width: 1100px) {
    .training_calendar_upcoming_events_header_div {
        grid-template-columns: 20% 2% 40% 2% 15% 2% 15% 2% 5%;
    }

    .training_calendar_upcoming_events_header_div_top {
        grid-template-columns: 84% 16%;
    }

    .training_calendar_upcoming_events_apply_now {
        padding: 3px 8px;
        font-size: 15px;
    }

    .training_calendar_upcoming_events_header_duration {
        display: none;
    }
}

@media screen and (max-width: 800px) {
    .training_calendar_upcoming_events_header_div {
        grid-template-columns: 20% 2% 35% 2% 15% 2% 25%;
    }

    .training_calendar_upcoming_events_header_div_top {
        grid-template-columns: 100%;
    }

    .training_calendar_upcoming_events_header_seats {
        display: none;
    }

    .training_calendar_upcoming_events_apply_now_div {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .training_calendar_upcoming_events_header_div {
        grid-template-columns: 25% 4% 40% 4% 30%;
    }

    .training_calendar_upcoming_events_header_virtual {
        display: none;
    }

    .training_calendar_upcoming_events_header_year {
        display: none;
    }

    .training_calendar_upcoming_events_date_div {
        padding: 5px 10px;
    }
}

@media screen and (max-width: 800px) {
    .training_calendar_filter_inperson_reset_button_partition_div {
        display: none;
    }
}