.Testimonial_Card {
  background: #fafafa;
  margin-top: -6%;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid #ddd;
  height: 270px;
  -moz-box-shadow: 0 0 3px #ccc;
  -webkit-box-shadow: 0 0 3px #ccc;
  box-shadow: 0 0 3px #ccc;
}

.Testimonial_Card_h3 {
  font-weight: 100;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 17px;
}

.Testimonial_Card_h4 {
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
  font-size: 14px;
}

.Testimonial_Card_p {
  font-weight: 100 !important;
  line-height: 29px !important;
  font-size: 15px;
  max-height: 67px;

}

.Testimonial_Card_p:before {
  content: "“";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
}

.Testimonial_Card_p:after {
  content: "”";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
  line-height: 0;
}

.Testimonial_Card_img {
  position: relative;
  width: 20%;
  aspect-ratio: 1.0;
  object-fit: cover;
  border-radius: 50%;
  min-width: 80px;
}

@media screen and (max-width: 1464px) {
  .Testimonial_Card {
    width: 80%;
  }
}

@media screen and (max-width: 1400px) {
  .Testimonial_Card {
    width: 85%;
  }
}

@media screen and (max-width: 1400px) {
  .Testimonial_Card {
    height: 190px;
  }
}

@media screen and (max-width: 714px) {
  .Testimonial_Card {
    height: 240px;
  }
}

@media screen and (max-width: 603px) {
  .Testimonial_Card {
    height: 260px;
  }
}

@media screen and (max-width: 555px) {
  .Testimonial_Card {
    height: 290px;
  }
}

@media screen and (max-width: 477px) {
  .Testimonial_Card {
    height: 320px;
  }
}

@media screen and (max-width: 440px) {
  .Testimonial_Card {
    height: 340px;
  }
}

@media screen and (max-width: 385px) {

  .Testimonial_Card h3 {
    font-size: 15px;
  }

  .Testimonial_Card h4 {
    font-size: 12px;
  }

  .Testimonial_Card p {
    font-size: 14px;
  }

  .Testimonial_Card p:before {
    font-size: 20px;
  }

  .Testimonial_Card p:after {
    font-size: 20px;
  }
}


@media screen and (max-width: 355px) {

  .Testimonial_Card h3 {
    font-size: 15px;
  }

  .Testimonial_Card h4 {
    font-size: 12px;
  }

  .Testimonial_Card p {
    font-size: 14px;
    line-height: 25px !important;

  }

  .Testimonial_Card p:before {
    font-size: 20px;
  }

  .Testimonial_Card p:after {
    font-size: 20px;
  }
}