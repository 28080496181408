.certification_2_0_g_levels_top_div {
    margin-top: 50px;
}

.certification_2_0_g_levels_indiv_img_text_div {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    padding: 20px 20px;
}

.certification_2_0_g_levels_indiv_img_div {
    position: relative;
    min-width: 100px;
    min-height: 135px;
}