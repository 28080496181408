.data_center_training_road_map_courses_div {
    margin: auto;
}

.dc_RoadMap_arrow_transition_class {
    transition: 0.7s;
}

#data_center_training_road_map_courses_two_together_dces {
    /* margin-bottom: 10px; */
}

#data_center_training_road_map_courses_two_together_dcos {
    margin-top: 20px;
}


/* Arrow Div 1 */
/*  */
/*  */
#data_center_training_road_map_arrow_div_1 {
    position: relative;
    transition: 0.7s;
}

#data_center_training_road_map_arrow_div_1 #one_dot {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 12.5px;
    width: 12.5px;
    background-color: #942826;
    border-radius: 50%;
    display: inline-block;
}

#data_center_training_road_map_arrow_div_1 #one_line1 {
    position: absolute;
    top: 4.5px;
    left: 12.2px;
    width: 115px;
    height: 3px;
    background: #942826;
}

/* Up Arrow */
#data_center_training_road_map_arrow_div_1 #one_line_up1 {
    position: absolute;
    top: -75px;
    left: 124px;
    height: 79.43px;
    width: 3.02px;

    background: linear-gradient(#3166AF, #942826);
}

#data_center_training_road_map_arrow_div_1 #one_line_up2 {
    position: absolute;
    top: -75px;
    left: 126px;
    width: 45px;
    height: 3.61px;
    background: linear-gradient(to right, #3166AF, #124280);
}

#data_center_training_road_map_arrow_div_1 #one_arrow_up {
    position: absolute;
    top: -81px;
    left: 168px;
    width: 16.14px;
    height: 16.14px;
    color: #124280;
}

/* Down Arrow */
#data_center_training_road_map_arrow_div_1 #one_line_down1 {
    position: absolute;
    top: 7px;
    left: 124px;
    height: 79.43px;
    width: 3.02px;

    background: #942826;
}

#data_center_training_road_map_arrow_div_1 #one_line_down2 {
    position: absolute;
    top: 83px;
    left: 124px;
    width: 45px;
    height: 3.61px;
    background: linear-gradient(to right, #942826, #C53625);
}

#data_center_training_road_map_arrow_div_1 #one_arrow_down {
    position: absolute;
    top: 77px;
    left: 166px;
    width: 16.14px;
    height: 16.14px;
    color: #C53625;
}


/* Arrow Div 2 */
/*  */
/*  */
#data_center_training_road_map_arrow_div_2 {
    position: relative;
    top: -90px;
    /* left: -90px; */
    transition: 0.7s;
}

/* Up Arrow */
#data_center_training_road_map_arrow_div_2 #two_dot1 {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 12.5px;
    width: 12.5px;
    background-color: #5B2580;
    border-radius: 50%;
    display: inline-block;
    transition: 0.7s
}

#data_center_training_road_map_arrow_div_2 #two_line_up1 {
    position: absolute;
    top: 4.5px;
    left: 12.2px;
    width: 34px;
    height: 3px;
    background: #5B2580;
    transition: 0.7s
}

#data_center_training_road_map_arrow_div_2 #two_line_up2 {
    position: absolute;
    top: 5px;
    left: 45px;
    height: 82px;
    width: 3.02px;

    background: linear-gradient(#5B2580, #288235);
    transition: 0.7s
}

/* Down Arrow */
#data_center_training_road_map_arrow_div_2 #two_line_down2 {
    position: absolute;
    top: 86px;
    left: 45px;
    height: 82px;
    width: 3.02px;

    background: linear-gradient(#288235, #914816);
    transition: 0.7s
}

#data_center_training_road_map_arrow_div_2 #two_line_down1 {
    position: absolute;
    top: 165px;
    left: 12.2px;
    width: 34px;
    height: 3px;
    background: #914816;
    transition: 0.7s
}

#data_center_training_road_map_arrow_div_2 #two_dot2 {
    position: absolute;
    top: 160px;
    left: 0px;
    height: 12.5px;
    width: 12.5px;
    background-color: #914816;
    border-radius: 50%;
    display: inline-block;
    transition: 0.7s
}

/* Arrow Last */
#data_center_training_road_map_arrow_div_2 #two_line1 {
    position: absolute;
    top: 85px;
    left: 46px;
    width: 185px;
    height: 3.61px;

    background: #288134;
}

#data_center_training_road_map_arrow_div_2 #two_line2 {
    position: absolute;
    top: 85px;
    left: 231px;
    height: 71.38px;
    width: 3.61px;
    background: #288134;
}

#data_center_training_road_map_arrow_div_2 #two_arrow {
    position: absolute;
    top: 153px;
    left: 225px;
    width: 16.14px;
    height: 16.14px;
    color: #288134;
    transform: rotate(90deg);
}



/* Arrow Div 3 */
/*  */
/*  */
#data_center_training_road_map_arrow_div_3 {
    position: relative;
    top: -90px;
    left: -95px;
    transition: 0.7s;
}

/* Up Arrow */
#data_center_training_road_map_arrow_div_3 #three_arrow_up {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 16.14px;
    height: 16.14px;
    color: #1E3459;
    transform: rotate(180deg);
}

#data_center_training_road_map_arrow_div_3 #three_line_up1 {
    position: absolute;
    top: 6px;
    left: 12.2px;
    width: 134.64px;
    height: 3.61px;
    background: #1E3459;
}

#data_center_training_road_map_arrow_div_3 #three_line_up2 {
    position: absolute;
    top: 6px;
    left: 147px;
    width: 3.23px;
    height: 79.43px;

    background: linear-gradient(#1E3459, #288235);
}

/* Down Arrow */
#data_center_training_road_map_arrow_div_3 #three_arrow_down {
    position: absolute;
    top: 158px;
    left: 0px;
    width: 16.14px;
    height: 16.14px;
    color: #CD1417;
    transform: rotate(180deg);
}

#data_center_training_road_map_arrow_div_3 #three_line_down1 {
    position: absolute;
    top: 164px;
    left: 12.2px;
    width: 134.64px;
    height: 3.61px;
    background: #CD1417;
}

#data_center_training_road_map_arrow_div_3 #three_line_down2 {
    position: absolute;
    top: 88px;
    left: 147px;
    width: 3.23px;
    height: 79.43px;

    background: linear-gradient(#288235, #CD1417);
}

/* Line Last */
#data_center_training_road_map_arrow_div_3 #three_line1 {
    position: absolute;
    top: 85px;
    left: 147px;
    width: 181.62px;
    height: 3.61px;

    background: #288134;
}

#data_center_training_road_map_arrow_div_3 #three_line2 {
    position: absolute;
    top: 5px;
    left: 325px;
    height: 80.42px;
    width: 3.61px;
    background: #288134;
}

#data_center_training_road_map_arrow_div_3 #three_dot {
    position: absolute;
    top: 3px;
    left: 321px;
    height: 12.5px;
    width: 12.5px;
    background-color: #288134;
    border-radius: 50%;
    display: inline-block;
}





/* Arrow Div 4 */
/*  */
/*  */
#data_center_training_road_map_arrow_div_4 {
    position: relative;
    top: -7px;
    transition: 0.7s;
}

/* Arrow */
#data_center_training_road_map_arrow_div_4 #four_arrow {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 16.14px;
    height: 16.14px;
    color: #1D3359;
    transform: rotate(180deg);
}

#data_center_training_road_map_arrow_div_4 #four_line {
    position: absolute;
    top: 6.5px;
    left: 13px;
    width: 111.14px;
    height: 3.61px;

    background: #1D3359;
}


/* Line Up */
#data_center_training_road_map_arrow_div_4 #four_line_up3 {
    position: absolute;
    top: -73px;
    left: 121px;
    width: 3.23px;
    height: 79.43px;
    background: linear-gradient(#1D3359, #060606);
}

#data_center_training_road_map_arrow_div_4 #four_line_up2 {
    position: absolute;
    top: -75px;
    left: 121px;
    width: 134.64px;
    height: 3.61px;

    background: #1D3359;
}

#data_center_training_road_map_arrow_div_4 #four_dot1 {
    position: absolute;
    top: -80px;
    left: 255px;
    height: 12.5px;
    width: 12.5px;
    background-color: #1D3359;
    border-radius: 50%;
    display: inline-block;
}


/* Line Down */
#data_center_training_road_map_arrow_div_4 #four_line_down1 {
    position: absolute;
    top: 10px;
    left: 121px;
    width: 3px;
    height: 79px;
    background: linear-gradient(#060606, #CC1318);
}

#data_center_training_road_map_arrow_div_4 #four_line_down2 {
    position: absolute;
    top: 89px;
    left: 121px;
    width: 134.64px;
    height: 3.61px;

    background: #CC1318;
}

#data_center_training_road_map_arrow_div_4 #four_dot2 {
    position: absolute;
    top: 85px;
    left: 255px;
    height: 12.5px;
    width: 12.5px;
    background-color: #CC1318;
    border-radius: 50%;
    display: inline-block;
}






.data_center_training_road_map_courses_two_together_div {
    transition: 0.7s;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#line_dces_dcie {
    width: 44px;
    height: 4px;

    background: linear-gradient(to right, #2768BD 14.06%, #2E153F 86.98%);
}

#line_dces_dcos {
    width: 44px;
    height: 4px;

    background: linear-gradient(to right, #EB422E 0%, #904816 66.15%);
}

.data_center_training_road_map_courses_grid_div {
    margin: auto;
    width: fit-content;
    display: grid;
    grid-template-columns: 90px 170px 190px 380px 150px 160px;
    /* grid-template-rows: 25% auto auto; */
    /* justify-items: center; */
    align-items: center;
    row-gap: 40px;
}

.data_center_training_road_map_courses_top_div {
    display: flex;
    flex-direction: row;
    align-items: center
}

.data_center_training_road_map_courses_indiv_div {
    width: fit-content;
    position: relative;
    transition: 0.7s;
}

.data_center_training_road_map_courses_indiv_div a {
    cursor: pointer;
}

.data_center_training_road_map_courses_indiv_text_div {
    position: absolute;
    top: -5px;
    text-align: center;
    font-size: 30px;
}

.data_center_training_road_map_courses_indiv_text_small {
    margin-top: 0px;
    padding-top: 0px;
    font-size: 17px;
}

.data_center_training_road_map_courses_end {
    font-family: Saira;
    font-style: normal;
    font-weight: 600;
    color: #D69186;
}


.data_center_training_road_map_arrow_img {
    transition: 0.7s;
    height: 180px;
}

.data_center_training_road_map_arrow_img_1 {
    position: absolute;
    transition: 0.7s;
    height: 180px;
}

.data_center_training_road_map_courses_dce_dcm_div {
    justify-self: center;
}

#data_center_training_road_map_courses_dces_div {
    position: relative;
}

#data_center_training_road_map_courses_dcie_div {
    position: relative;
}


#data_center_training_road_map_courses_dcos_div {
    position: relative;
}

#data_center_training_road_map_courses_dcom_div {
    position: relative;
}

#data_center_training_road_map_courses_dctp_div {
    top: 170px;
    left: 5px;
}

#data_center_training_road_map_courses_dce_div {
    margin-bottom: 20px;
}

#data_center_training_road_map_courses_start {
    font-size: 24px;
}

#data_center_training_road_map_courses_end {
    font-size: 24px;
}


@media screen and (max-width: 1250px) {
    .road_map_individual_img_class {
        width: 130px;
        position: relative;
    }

    .data_center_training_road_map_courses_grid_div {
        grid-template-columns: 80px 145px 160px 320px 85px 130px;
        row-gap: 30px;
    }

    #data_center_training_road_map_courses_dctp_div {
        top: 147px;
    }

    #data_center_training_road_map_courses_start {
        font-size: 22px;
    }

    .data_center_training_road_map_courses_end {
        font-size: 22px;
    }

    .data_center_training_road_map_courses_indiv_text_title {
        margin-bottom: 7px;
        font-size: 23px;
    }

    .data_center_training_road_map_courses_indiv_text_small {
        font-size: 15px;
    }

    #data_center_training_road_map_arrow_div_1 #one_line1 {
        width: 80px;
    }

    #data_center_training_road_map_arrow_div_1 #one_line_up1 {
        left: 89px;
    }

    #data_center_training_road_map_arrow_div_1 #one_line_up2 {
        left: 91px;
    }

    #data_center_training_road_map_arrow_div_1 #one_arrow_up {
        left: 133px;
    }

    #data_center_training_road_map_arrow_div_1 #one_line_down1 {
        left: 89px;
    }

    #data_center_training_road_map_arrow_div_1 #one_line_down2 {
        left: 89px;
    }

    #data_center_training_road_map_arrow_div_1 #one_arrow_down {
        left: 131px;
    }


    #data_center_training_road_map_arrow_div_2 #two_line1 {
        width: 100px;
    }

    #data_center_training_road_map_arrow_div_2 #two_line2 {
        left: 146px;
    }

    #data_center_training_road_map_arrow_div_2 #two_arrow {
        left: 140px;
    }


    #data_center_training_road_map_arrow_div_4 #four_line {
        width: 95px;
    }

    #data_center_training_road_map_arrow_div_4 #four_line_up3 {
        left: 104.86px;
    }

    #data_center_training_road_map_arrow_div_4 #four_line_up2 {
        left: 104.86px;
        width: 120px;
    }

    #data_center_training_road_map_arrow_div_4 #four_dot1 {
        left: 220px;
    }

    #data_center_training_road_map_arrow_div_4 #four_line_down1 {
        left: 104.86px;
    }

    #data_center_training_road_map_arrow_div_4 #four_line_down2 {
        left: 104.86px;
        width: 120px;
    }

    #data_center_training_road_map_arrow_div_4 #four_dot2 {
        left: 220px;
    }

    #data_center_training_road_map_arrow_div_3 #three_line_up1 {
        width: 120px;
    }

    #data_center_training_road_map_arrow_div_3 #three_line_up2 {
        left: 132px;
    }

    #data_center_training_road_map_arrow_div_3 #three_line_down1 {
        width: 120px;
    }

    #data_center_training_road_map_arrow_div_3 #three_line_down2 {
        left: 132px;
    }

    #data_center_training_road_map_arrow_div_3 #three_line1 {
        left: 132px;
        width: 116px;
    }

    #data_center_training_road_map_arrow_div_3 #three_line2 {
        height: 83px;
        left: 245px;
    }

    #data_center_training_road_map_arrow_div_3 #three_dot {
        left: 241px;
    }
}

.dc_road_map_courses_mobile_view_img {
    display: none;
}

@media screen and (max-width: 950px) {
    .data_center_training_road_map_courses_grid_div {
        display: none;
    }

    .dc_road_map_courses_mobile_view_img {
        display: initial;
        margin: 0px 20%;
    }

    .dc_road_map_courses_mobile_view_img_img {
        width: 60%;
    }
}

@media screen and (max-width: 600px) {
    .dc_road_map_courses_mobile_view_img {
        margin: 0px 15%;
    }

    .dc_road_map_courses_mobile_view_img_img {
        width: 70%;
    }
}

@media screen and (max-width: 450px) {
    .dc_road_map_courses_mobile_view_img {
        margin: 0px 5%;
    }

    .dc_road_map_courses_mobile_view_img_img {
        width: 90%;
    }
}

@media screen and (max-width: 350px) {
    .dc_road_map_courses_mobile_view_img {
        margin: 0px 0px;
    }

    .dc_road_map_courses_mobile_view_img_img {
        width: 100%;
    }
}