.nonmember_message_component_benefits_div {
    width: 450px;
    background: linear-gradient(135deg, #9198e5ae 50%, rgba(0, 170, 255, 0.689));
    /* background: linear-gradient(#AD2225, #9198e5); */
    padding: 30px 40px;
    /* padding-left: 0px; */
    border-radius: 20px;
    height: fit-content;
    margin-top: 50px;
    margin-bottom: 50px;
    cursor: pointer;
}

.nonmember_message_component_benefits_div:hover {
    position: relative;
    bottom: 5px;
    transition-duration: 1s;
    background: linear-gradient(135deg, #9198e588 50%, rgba(2, 164, 245, 0.425));
    /* background: linear-gradient(#AD2225, #9198e5); */
}

.nonmember_message_component_benefits_ul {
    padding: 0px;
}

.nonmember_message_component_benefits_ul li {
    font-size: 16px;
    margin-bottom: 1em;
    list-style-type: none;
    padding: .25em 0 0 3em;
    position: relative;
    font-size: 18px;
}

.nonmember_message_component_benefits_ul li:before {
    content: " ";
    display: block;
    border: solid .8em rgb(0, 180, 66);
    border-radius: .8em;
    height: 0;
    width: 0;
    position: absolute;
    left: .5em;
    top: 40%;
    margin-top: -.5em;
}

.nonmember_message_component_benefits_ul li:after {
    content: " ";
    display: block;
    width: .3em;
    height: .6em;
    border: solid #fff;
    border-width: 0 .2em .2em 0;
    position: absolute;
    left: 1em;
    top: 40%;
    margin-top: -.2em;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}