.user_portal_right_social_media_div {
    display: flex;
    justify-content: center;
}

.user_portal_right_social_inner_media_div {
    display: flex;
    gap: 10px;
}

.user_portal_right_social_media_indiv {
    width: 30px;
    height: 30px;
    position: relative;
}

@media screen and (max-width: 1300px) {
    .user_portal_right_social_media_indiv {
        width: 25px;
        height: 25px;
    }
}

@media screen and (max-width: 750px) {
    .user_portal_right_social_media_indiv {
        width: 30px;
        height: 30px;
    }
}