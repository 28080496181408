.home_page_services_all_div {
    margin-top: 50px;
    height: fit-content;
    /* display: flex;
    justify-content: space-between; */
}

.home_page_services_individual_div {
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.15);
    width: 570px;
    height: 500px;
    display: flex;
}

.home_page_services_individual_img_div {
    position: relative;
    min-width: 170px;
}

.home_page_services_individual_red_divide {
    border-left: 5px solid #BB2027;
}

.home_page_services_individual_text_div {
    padding: 20px 30px;
}

.home_page_services_individual_learn_more {
    text-decoration: none;
    border: 1px solid #BB2027;
    color: #BB2027;
    padding: 10px 40px;
    border-radius: 10px;
}

.home_page_services_individual_learn_more:hover {
    color: white;
    background-color: #BB2027;
}

.home_page_services_individual_course_div {
    background-color: #F1F1F1;
    border-radius: 10px;
    padding: 15px 30px;
    margin: 10px 0px;
    cursor: pointer;
}

.home_page_services_individual_data_center_p {
    color: #BB2027;
    font-size: 22px;
    margin: 0px;
}

.home_page_services_individual_cloud_p {
    color: #0083CD;
    font-size: 22px;
    margin: 0px;
}

.home_page_services_individual_cyber_p {
    color: #F48500;
    font-size: 22px;
    margin: 0px;
}

.home_page_services_individual_course_div span {
    color: black;
    font-size: 18px;
}

@media screen and (max-width: 870px) {
    .home_page_services_individual_div {
        width: 380px;
    }

    .home_page_services_individual_img_div {
        display: none;
    }
}

@media screen and (max-width: 420px) {
    .home_page_services_individual_div {
        width: 350px;
    }

    .home_page_services_individual_img_div {
        display: none;
    }
}