.All_news_Card_page_top_news_less_div_small{
    position: relative; 
    width: 550px;
    height: 366px; 
    background: red;
    text-align: left;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    flex-direction: row;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px;
}

@media screen and (max-width:3578px) {
    .All_news_Card_page_top_news_less_div_small{
        margin-top: 30px;
        width: 500px;
        height: 333px;
    }
}


@media screen and (max-width:3266px) {
    .All_news_Card_page_top_news_less_div_small{
        width: 450px;
        height: 300px;
    }
}
@media screen and (max-width:2953px) {
    .All_news_Card_page_top_news_less_div_small{
        width: 400px;
        height: 266px;
    }
}

@media screen and (max-width:2640px) {
    .All_news_Card_page_top_news_less_div_small{
        width: 350px;
        height: 233px;
    }
}

@media screen and (max-width:2330px) {
    .All_news_Card_page_top_news_less_div_small{
        width: 300px;
        height: 200px;
    }
}

@media screen and (max-width:2015px) {
    .All_news_Card_page_top_news_less_div_small{
        width: 400px;
        height: 266px;
    }
}

@media screen and (max-width:1984px) {
    .All_news_Card_page_top_news_less_div_small{
        width: 350px;
        height: 233px;
    }
}

@media screen and (max-width:1750px) {
    .All_news_Card_page_top_news_less_div_small{
        width: 300px;
        height: 200px;
    }
}

@media screen and (max-width:1515px) {
    .All_news_Card_page_top_news_less_div_small{
        width: 420px;
        height: 280px;
    }
}

@media screen and (max-width:1390px) {
    .All_news_Card_page_top_news_less_div_small{
        width: 380px;
        height: 253px;
    }
}

@media screen and (max-width:1265px) {
    .All_news_Card_page_top_news_less_div_small{
        width: 350px;
        height: 233px;
    }
}

@media screen and (max-width:1172px) {
    .All_news_Card_page_top_news_less_div_small{
        width: 300px;
        height: 200px;
    }
}

/* @media screen and (max-width:1172px) {
    .All_news_Card_page_top_news_less_div_small{
        width: 280px;
        height: 186px;
    }
} */

@media screen and (max-width:953px) {
    .All_news_Card_page_top_news_less_div_small{
        width: 500px;
        height: 333px;
    }
}

@media screen and (max-width:829px) {
    .All_news_Card_page_top_news_less_div_small{
        width: 450px;
        height: 300px;
    }
}

@media screen and (max-width:750px) {
    .All_news_Card_page_top_news_less_div_small{
        width: 400px;
        height: 266px;
    }
}

@media screen and (max-width:673px) {
    .All_news_Card_page_top_news_less_div_small{
        width: 100%;
        aspect-ratio: 3/2;
        min-width: none;
    }
}

@media screen and (max-width:429px) {
    .All_news_Card_page_top_news_less_div_small{
        width: 350px;
        height: 233px;
    }
}

@media screen and (max-width:380px) {
    .All_news_Card_page_top_news_less_div_small{
        width: 300px;
        height: 200px;
    }
}