.IndividualEventsUpcomingEventsCardGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}

.IndividualEventsUpcomingEventsCardIndiv {
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    padding: 20px;
    background-color: rgb(81, 78, 78);
    border-radius: 10px;
    margin-bottom: 50px;

}