.user_dashboard_home_journey_title {
    color: #959FB2;
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    margin: 0px;
}


/* Bar Progress */
.user_dashboard_home_journey_bar_div {
    margin-top: 30px;
    border-radius: 10px;
    height: 12px;
    box-shadow: 0px 0px 5px rgba(100, 100, 100, 0.51);
    position: relative;
}

.user_dashboard_home_journey_bar_green_div {
    background-color: #5AC950;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 12px;
    width: 0%;
}

.user_dashboard_home_journey_bar_gray_div {
    background-color: gray;
    border-radius: 10px;
    position: relative;
    top: -12px;
    z-index: -1;
    height: 12px;
    width: 100%;
}


/* Courses Progress */
.user_dashboard_home_journey_div {
    margin-top: 50px;
    padding: 10px 5px;
    padding-left: 0px;
    box-shadow: 0px 0px 10px rgba(100, 100, 100, 0.51);
}

.user_dashboard_home_journey_inner_div {
    display: grid;
    grid-template-columns: 10% 11% 11% 11% 11% 11% 11% 11% 12%;
    position: relative;
    left: 11px;
}

.user_dashboard_home_journey_div_individual_div {
    width: 95%;
    height: 120px;
    position: relative;
}

.user_dashboard_home_journey_div_individual_div:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 25px solid white;
    border-top: 60px solid transparent;
    border-bottom: 60px solid transparent;
}

#user_dashboard_home_journey_div_individual_div_first {
    width: 95%;
}

#user_dashboard_home_journey_div_individual_div_first:after {
    border-left: 0px;
}

.user_dashboard_home_journey_div_individual_div:before {
    content: "";
    position: absolute;
    right: -25px;
    bottom: 0;
    width: 0;
    height: 0;
    border-top: 60px solid transparent;
    border-bottom: 60px solid transparent;
}

#user_dashboard_home_journey_div_individual_div_last:before {
    border-top: 0px;
    border-bottom: 0px;
}

.user_course_progress_bar_courses_individual_DCIS {
    background: #AD2225;
}

.user_course_progress_bar_courses_individual_DCIS:before {
    border-left: 25px solid #AD2225;
}

.user_course_progress_bar_courses_individual_DCOS {
    background: #C53626;
}

.user_course_progress_bar_courses_individual_DCOS:before {
    border-left: 25px solid #C53626;
}

.user_course_progress_bar_courses_individual_DCES {
    background: #2768BD;
}

.user_course_progress_bar_courses_individual_DCES:before {
    border-left: 25px solid #2768BD;
}

.user_course_progress_bar_courses_individual_DCIE {
    background: #5B2580;
}

.user_course_progress_bar_courses_individual_DCIE:before {
    border-left: 25px solid #5B2580;
}

.user_course_progress_bar_courses_individual_DCOM {
    background: #914815;
}

.user_course_progress_bar_courses_individual_DCOM:before {
    border-left: 25px solid #914815;
}

.user_course_progress_bar_courses_individual_DCTP {
    background: #267a3f;
}

.user_course_progress_bar_courses_individual_DCTP:before {
    border-left: 25px solid #267a3f;
}

.user_course_progress_bar_courses_individual_DCM {
    background: #86282b;
}

.user_course_progress_bar_courses_individual_DCM:before {
    border-left: 25px solid #86282b;
}

.user_course_progress_bar_courses_individual_DCE {
    background: #2f4870;
}

.user_course_progress_bar_courses_individual_DCE:before {
    border-left: 25px solid #2f4870;
}

.user_course_progress_bar_courses_individual_DCA {
    background: #747474;
}

.user_course_progress_bar_courses_individual_DCA:before {
    border-left: 25px solid #747474;
}

.user_dashboard_home_journey_div_individual_div_individual_name {
    color: #606060;
    position: relative;
    left: 35px;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 0px;
    margin-top: 20px;
}

#user_dashboard_home_journey_div_individual_div_individual_name_id_first {
    left: 15px;
}

.user_dashboard_home_journey_div_individual_div_individual_full_name {
    color: #606060;
    font-size: 13px;
    width: 90%;
    position: relative;
    top: -10px;
    left: 35px;
}

#user_dashboard_home_journey_div_individual_div_individual_full_name_id_first {
    left: 15px;
}

.user_dashboard_home_journey_next_step {
    position: absolute;
    top: -70px;
    background-color: white;
    /* border: 1px solid #CACACA; */
    border-radius: 15px;
    padding: 10px;
    width: 130px;
    display: none;
    z-index: 10000;
    box-shadow: 0px 0px 10px rgba(85, 85, 85, 0.317);
}

/* .user_dashboard_home_journey_next_step:after {
    content: "";
    position: absolute;
    box-shadow: 0px 0px 10px black;
    left: 40px;
    bottom: -100px;
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-top: 60px solid transparent;
    border-bottom: 40px solid white;
    transform: rotate(180deg);
} */

.user_dashboard_home_journey_next_step p {
    margin: 0px;
    font-size: 15px;
    text-align: center;
}

@media screen and (max-width: 1650px) {
    .user_dashboard_home_journey_div_individual_div_individual_name {
        top: 18px;
        left: 35px;
        font-size: 18px;
        margin-top: 20px;
    }

    .user_dashboard_home_journey_div_individual_div_individual_full_name {
        display: none;
    }

    #user_dashboard_home_journey_div_individual_div_individual_name_id_last {
        left: 30px;
    }

    .user_dashboard_home_journey_div_individual_div {
        width: 95%;
        height: 100px;
    }

    .user_dashboard_home_journey_div_individual_div:after {
        border-top: 50px solid transparent;
        border-bottom: 50px solid transparent;
    }


    .user_dashboard_home_journey_div_individual_div:before {
        border-top: 50px solid transparent;
        border-bottom: 50px solid transparent;
    }
}

@media screen and (max-width: 1300px) {
    .user_dashboard_home_journey_div_individual_div_individual_name {
        top: 18px;
        left: 32px;
        font-size: 17px;
        margin-top: 20px;
    }
}

@media screen and (max-width: 1200px) {
    .user_dashboard_home_journey_div_individual_div_individual_name {
        font-size: 18px;
    }
}

@media screen and (max-width: 1050px) {
    .user_dashboard_home_journey_div_individual_div_individual_name {
        font-size: 17px;
        left: 29px;
    }

    #user_dashboard_home_journey_div_individual_div_individual_name_id_first {
        left: 10px;
    }

    .user_dashboard_home_journey_inner_div {
        grid-template-columns: 7% 11% 11% 12% 12% 11% 11% 11% 13%;
        left: 7px;
    }
}

@media screen and (max-width: 900px) {
    .user_dashboard_home_journey_div_individual_div_individual_name {
        font-size: 16px;
        left: 28px;
        top: 20px;
    }

    #user_dashboard_home_journey_div_individual_div_individual_name_id_first {
        left: 5px;
    }

    #user_dashboard_home_journey_div_individual_div_individual_name_id_last {
        left: 27px;
    }

    .user_dashboard_home_journey_inner_div {
        grid-template-columns: 5% 12% 11% 13% 13% 11% 10% 10% 14%;
    }

    .user_dashboard_home_journey_div_individual_div {
        width: 97%;
    }
}

@media screen and (max-width: 800px) {
    .user_dashboard_home_journey_div_individual_div_individual_name {
        font-size: 16px;
        left: 20px;
        top: 20px;
        transform: rotate(90deg);
    }

    #user_dashboard_home_journey_div_individual_div_individual_name_id_first {
        left: 5px;
        top: 10px;
    }

    #user_dashboard_home_journey_div_individual_div_individual_name_id_last {
        left: 12px;
        top: 27px;
    }

    .user_dashboard_home_journey_inner_div {
        grid-template-columns: 5% 12% 11% 13% 13% 11% 10% 10% 14%;
    }
}