.CoursesSectionCybersecurityTraining{
    background-color: #FAFAFA;
    height: 700px;
}

.CoursesSectionCybersecurityTrainingCarousel{
    width: 70%;
    z-index: 0;
}

@media screen and (max-width: 1550px) {
   
    .CoursesSectionCybersecurityTrainingCarousel{
        width: 90%;
    }
}

@media screen and (max-width: 1417px) {
   
    .CoursesSectionCybersecurityTrainingCarousel{
        width: 80%;
    }
}

@media screen and (max-width: 1157px) {
   
    .CoursesSectionCybersecurityTrainingCarousel{
        width: 90%;
    }
}

@media screen and (max-width: 1073px) {
   
    .CoursesSectionCybersecurityTrainingCarousel{
        width: 80%;
    }
}

@media screen and (max-width: 865px) {
   
    .CoursesSectionCybersecurityTrainingCarousel{
        width: 85%;
    }
}