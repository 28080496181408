.user_dashboard_home_upcoming_class_all_div {
    margin-top: 40px;
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
    gap: 150px;
}

.user_dashboard_home_upcoming_class_image_div {
    border-radius: 10px;
    padding: 15px 30px;
    width: 200px;
    height: 200px;
    margin-bottom: 30px;
}

#portal_home_upcoming_img_DCIS {
    background-color: #942A2F;
}

#portal_home_upcoming_img_DCES {
    background-color: #37588B;
}

#portal_home_upcoming_img_DCOS {
    background-color: #C53626;
}

#portal_home_upcoming_img_DCIE {
    background-color: #5B2580;
}

#portal_home_upcoming_img_DCOM {
    background-color: #914815;
}

#portal_home_upcoming_img_DCTP {
    background-color: #2E7B45;
}

.user_dashboard_home_upcoming_class_image_inner_div {
    position: relative;
    width: 100%;
    height: 100%;
}

.user_dashboard_home_upcoming_class_detail_all_div {
    width: fit-content;
}

.user_dashboard_home_upcoming_class_zoom_link {
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.user_dashboard_home_upcoming_class_detail_div {
    display: flex;
    border-bottom: 1px solid #D9D9D9;
}

.user_dashboard_home_upcoming_class_detail_icon_div {
    display: flex;
    align-items: center;
    gap: 20px;
    color: #006197;
    width: 300px;
}

.user_dashboard_home_upcoming_class_detail_icon_div p {
    font-weight: bold;
}

@media screen and (max-width: 1600px) {
    .user_dashboard_home_upcoming_class_all_div {
        gap: 100px;
    }

    .user_dashboard_home_upcoming_class_detail_icon_div {
        width: 250px;
    }
}

@media screen and (max-width: 1400px) {
    .user_dashboard_home_upcoming_class_image_div {
        padding: 15px 30px;
        width: 150px;
        height: 150px;
    }

    .user_dashboard_home_upcoming_class_all_div {
        margin-top: 30px;
        gap: 80px;
    }

    .user_dashboard_home_upcoming_class_detail_icon_div {
        width: 200px;
    }
}

@media screen and (max-width: 1200px) {
    .user_dashboard_home_upcoming_class_image_div {
        padding: 10px 20px;
        width: 130px;
        height: 130px;
    }

    .user_dashboard_home_upcoming_class_all_div {
        gap: 50px;
    }
}

@media screen and (max-width: 1200px) {
    .user_dashboard_home_upcoming_class_all_div {
        margin-top: 40px;
        flex-direction: column;
    }
}