.WhatWillLearnCompleteDiv{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    padding-bottom: 40px;
}

.WhatWillLearn_course_what_points_whole_div{
    width: 70%;
    display: flex;
    justify-content: space-between;
    gap: 50px;
}

.WhatWillLearn_course_what_points_indiv{
    display: flex;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
}

.WhatWillLearn_course_what_points_col1_div{
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;

}

.WhatWillLearn_course_what_points_col2_div{
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
}

/* *************************** */

.WhatWillLearn_course_what_points_whole_div_2{
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 50px;
}
/* *************************** */

.WhatWillLearn_course_what_points_indiv_text_only{
    width: 100%;
}

.WhatWillLearn_Brochure_download{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding-top: 0px;

}

.WhatWillLearn_Brochure_download_button:hover{
    opacity: 0.9;
}

.WhatWillLearn_Brochure_download_button:active{
    opacity: 1;
}

.WhatWillLearn_Brochure_download_img{
    height: fit-content;
    width: 150px;
}

.WhatWillLearn_Brochure_download_img img{
    width: 100%;
  filter: blur(1px);
  -webkit-filter: blur(1px);
}

@media screen and (max-width: 1146px) {
    .WhatWillLearn_course_what_points_whole_div{
        width: 100%;
    }
    .WhatWillLearn_Brochure_download{
        justify-content: center;
        width: 100%;
    }
}

@media screen and (max-width: 580px) {
    .WhatWillLearn_course_what_points_whole_div{
        width: 100%;
        flex-direction: column;
        gap: 15px
    }

    .WhatWillLearn_Brochure_download{
        padding-top: 20px;
    }
    
    
}