.user_dashb_member_list_individual_div {
    display: grid;
    grid-template-columns: 10% 30% 30% 30%;
    align-items: center;
    height: 90px;
    cursor: pointer;
}

.user_dashb_member_list_individual_a_tag {
    text-decoration: none;
}

.user_dashb_member_list_individual_div h4 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.user_dashb_member_list_column_list_div {
    display: grid;
    grid-template-columns: 10% 30% 30% 30%;

    align-items: center;
}

.user_dashb_member_list_individual_divider {
    border: 1px solid gray;
    width: 100%;
}

.user_dashb_member_list_change_page_div {
    margin-top: 30px;
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
}