.GlobalMembershipBenefitsCountUpwholeDiv {
    background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url('../../../resources/MembershipBenefitsCountUp.png');

    background-position: center;
    height: fit-content;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.GlobalMembershipBenefitsCountUpTextHeader {
    font-size: 30px;
    text-align: center;
    max-width: 750px;
    line-height: 1.5;
}

.GlobalMembershipBenefitsCountUpsingleItem {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.GlobalMembershipBenefitsCountUpAllItems {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
}

.GlobalMembershipBenefitsCountUpsingleItem p {
    width: 80%;
    text-align: center;
}



@media screen and (max-width: 1100px) {

    .GlobalMembershipBenefitsCountUpAllItems {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .GlobalMembershipBenefitsCountUpTextHeader {
        font-size: 25px;

    }

}