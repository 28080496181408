.GlobalDigitalResearchEESG {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 50px;
    padding-bottom: 50px;
}

#EESG {
    background: url("../../resources/jason-leung-UMncYEfO9-U-unsplash 1-min.png");
    background-color: #D9D9D9;
}