.Webinar_Guests_header_1{
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 16px;
    margin-bottom: 10px;
    justify-content: center;

}
.Webinar_Guests_header_2{
    font-size: 30px;
    text-align: center;
    margin-top: 0px;
}

.Webinar_Geusts_indiv_Whole{
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 260px;
    align-items:stretch;
    text-align: center;
    justify-content: flex-end;
    box-shadow: rgba(0, 0, 0, 1) 0px 4px 12px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
}

.Webinar_Geusts_indiv_name{
font-size: 16px;
text-align: center;
}

.Webinar_Geusts_indiv_Title{
    font-size: 15px;
    margin-bottom: 0px;   
}

.Webinar_Guests_whole{
    padding-bottom: 40px;
}

.Webinar_Guests_list_all{
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: space-around;
}