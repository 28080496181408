.consulting_top_div{
    height: 530px;
    background-image: url("../../../resources/DigitalEconomyTop_color.png"),url("../../../resources/advisory_banner.jpg");
    background-size: cover;
    background-position: center;
    margin-bottom: 15px;
    text-align: center;
}
.consulting_top_div h1{
    padding-top: 13%;
    margin: 0px;
}
.consulting_top_div p{
    width: 45%;
    line-height: 28px;
    margin: auto;
}

@media screen and (max-width: 1600px) {
.consulting_top_div h1{
    padding-top: 16%;
}
}
@media screen and (max-width: 1150px) {
.consulting_top_div h1{
    padding-top: 18%;
}
.consulting_top_div p{
    width: 50%;
}
}
@media screen and (max-width: 1000px) {
.consulting_top_div h1{
    padding-top: 20%;
}
}
@media screen and (max-width: 850px) {
.consulting_top_div h1{
    padding-top: 23%;
}
}
@media screen and (max-width: 750px) {
.consulting_top_div h1{
    padding-top: 27%;
}
.consulting_top_div p{
    width: 60%;
}
}
@media screen and (max-width: 600px) {
.consulting_top_div h1{
    padding-top: 33%;
}
.consulting_top_div p{
    width: 65%;
}
}
@media screen and (max-width: 500px) {
.consulting_top_div h1{
    font-size: 30px;
    padding-top: 45%;
}
.consulting_top_div p{
    font-size: 15px;
    line-height: 25px;
    width: 75%;
}
}
@media screen and (max-width: 400px) {
.consulting_top_div h1{
    padding-top: 50%;
}
}