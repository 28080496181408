.Certification_Professional_Main_whole_div{
    background-image: url("../../../resources/Upcoming_Podcast_bg.png");
    height: 1250px;
    margin-bottom: -20px;
}

.Certification_Professional_Main_Text_outer_div{
    display: flex;
    justify-content: center;
}

.Certification_Professional_Main_Title{
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    font-size: 24px;
    padding-top: 50px;
}

.Certification_Professional_Main_Text{
    font-size: 18px;
    text-align: center;
    max-width: 902px;
    margin-top: 25px;
}

.Certification_Professional_Main_item_whole{
    margin-top: 50px;
    width: 231px;
    height: 460px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 4px 12px;
}

.Certification_Professional_Main_item_whole_link{
    text-decoration: none;
    color: black;
}

.Certification_Professional_Main_item_text{
    margin-left: 20px;
    padding-top: 0px;
    width: 195.35px;
}

.Certification_Professional_Main_item_img_size{
    background-size: cover;
        height: 155px;
display: flex;
justify-content: center;
text-align: center;
flex-direction: column;
}

.Certification_Professional_Main_item_img1{
    background-image: url('../../../resources/PCertificates1.png');
}

.Certification_Professional_Main_item_img2{
    background-image: url('../../../resources/PCertificates2.png');
}

.Certification_Professional_Main_item_img3{
    background-image: url('../../../resources/PCertificates3.png');
}

.Certification_Professional_Main_item_img4{
    background-image: url('../../../resources/PCertificates4.png');
}

.Certification_Professional_Main_item_img5{
    background-image: url('../../../resources/PCertificates5.png');
}

.Certification_Professional_Main_item_text_header{
    font-size: 28px;
}

.Certification_Professional_Main_items_whole{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 130px;
}

@media screen and (max-width: 1361px) {

.Certification_Professional_Main_items_whole{
    gap: 80px
}

.Certification_Professional_Main_whole_div{
    height: 1300px;
}   
}

@media screen and (max-width: 1219px) {

.Certification_Professional_Main_items_whole{
    gap: 50px
}
}

@media screen and (max-width: 1132px) {

    .Certification_Professional_Main_items_whole{
        gap: 80px
    }
    
    .Certification_Professional_Main_whole_div{
        height: 1900px;
    }   
}

    

@media screen and (max-width: 774px) {


    .Certification_Professional_Main_items_whole{
        gap: 30px
    } 
    
}

@media screen and (max-width: 702px) {

.Certification_Professional_Main_items_whole{
        flex-direction: column;
        text-align: center;
        align-items: center;
        gap: 0px
    } 

    .Certification_Professional_Main_whole_div{
        height: 2800px;
    }  

}

@media screen and (max-width: 370px) {
        .Certification_Professional_Main_whole_div{
            height: 2900px;
        }
    }
        
