.african_webinar_information_form_div {
    display: grid;
    grid-template-columns: 60% 40%;
}

.african_webinar_form_top_div {
    justify-self: end;
    position: relative;
    top: -100px;
    width: 370px;
    height: 700px;
}

.african_webinar_ethiopia_speaker_description {
    text-align: center;
    width: 80%;
    margin-bottom: 50px;
}

#african_webinar_top_description_title {
    font-size: 45px;
    width: 700px;
}

.african_webinar_information_form_partner_div {
    display: flex;
    gap: 20px;
    align-items: center;
}

@media screen and (max-width: 1550px) {
    .african_webinar_form_top_div {
        width: 350px;
    }

    #african_webinar_top_description_title {
        font-size: 42px;
    }
}


@media screen and (max-width: 1300px) {
    .african_webinar_information_form_div {
        grid-template-columns: 100%;
    }

    .african_webinar_form_top_div {
        justify-self: center;
        top: 0px;
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 950px) {
    #african_webinar_top_description_title {
        font-size: 40px;
        width: 100%;
    }
}

@media screen and (max-width: 720px) {
    #african_webinar_top_description_title {
        width: 400px;
    }
}

@media screen and (max-width: 550px) {
    #african_webinar_top_description_title {
        font-size: 38px;
    }

    .african_webinar_information_form_partner_div {
        justify-content: center;
        gap: 10px;
    }
}

@media screen and (max-width: 530px) {
    #african_webinar_top_description_title {
        font-size: 38px;
    }
}

@media screen and (max-width: 430px) {
    .african_webinar_form_top_div {
        width: 320px;
        height: 1020px;
    }

    #african_webinar_top_description_title {
        width: 100%;
    }
}