.IndividualEventBannerIndiv {
    padding-top: 20px;
    position: relative;
    /* background-size: cover;
    background-position: center; */
    height: 600px;
    /* padding-bottom: 50px; */
}

.IndividualEventBannerIndiv_img {
    position: absolute;
    top: 0px;
    width: 100%;
    object-fit: cover;
    /* overflow: hidden; */
    height: 600px;
    /* z-index: 10; */
}