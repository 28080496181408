.about_us_top_div_1 {
    height: 530px;
    /* background-image: url("../../../resources/about_us_top_banner.jpg"); */
    /* background-size: 100% 530px; */
    background-size: cover;
    /* background-attachment: fixed; */
    background-position: center;
    /* background-position-y: -5px; */
    margin-bottom: 15px;
}

.about_us_top_div_img_div {
    position: absolute;
    overflow: hidden;
    z-index: -1;
    width: 100%;
    height: 530px;
}