.Certification_Main_whole_div{
    background-image: url("../../../resources/Upcoming_Podcast_bg.png");
    height: 1250px;
    margin-bottom: -20px;
}

.Certification_Main_Text_outer_div{
    display: flex;
    justify-content: center;
}

.Certification_Main_Title{
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    font-size: 24px;
    padding-top: 50px;
}

.Certification_Main_Text{
    font-size: 18px;
    text-align: center;
    max-width: 902px;
    margin-top: 25px;
}

.Certification_Main_item_whole{
    margin-top: 50px;
    width: 231px;
    height: 460px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 4px 12px;
}

.Certification_Main_item_whole_link{
    text-decoration: none;
    color: black;
}

.Certification_Main_item_text{
    margin-left: 20px;
    padding-top: 0px;
    width: 195.35px;
}

.Certification_Main_item_img_size{
    background-size: cover;
height: 155px;
display: flex;
justify-content: center;
text-align: center;
flex-direction: column;
}

.Certification_Main_item_img1{
    background-image: url('../../../resources/DCESbg.png');
}

.Certification_Main_item_img2{
    background-image: url('../../../resources/DCOSbg.png');
}

.Certification_Main_item_img3{
    background-image: url('../../../resources/DCTPbg.png');
}

.Certification_Main_item_img4{
    background-image: url('../../../resources/DCISbg.png');
}

.Certification_Main_item_img5{
    background-image: url('../../../resources/DCMbg.png');
}

.Certification_Main_item_text_header{
    font-size: 30px;
}

.Certification_Main_items_whole{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 130px
}

@media screen and (max-width: 1361px) {

.Certification_Main_items_whole{
    gap: 80px
}
.Certification_Main_whole_div{
    height: 1300px;
} 
}

@media screen and (max-width: 1219px) {

.Certification_Main_items_whole{
    gap: 50px
}
}

@media screen and (max-width: 1132px) {
    
    .Certification_Main_whole_div{
        height: 1900px;
    } 
}
    
@media screen and (max-width: 731px) {
    
.Certification_Main_items_whole{
    gap: 30px
} 
}

@media screen and (max-width: 702px) {
    .Certification_Main_whole_div{
        height: 2800px;
    }   
    
.Certification_Main_items_whole{
    flex-direction: column;
    text-align: center;
    align-items: center;
    gap: 0px
} 
}
    


@media screen and (max-width: 333px) {
    /* .Certification_Main_Title{
        padding-top: 300px;
} */

}