.MembershipBenefitsCategoriesSingleCard {
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.MembershipBenefitsCategoriesAllCards {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    gap: 15px;
    margin-top: 50px;
    margin-bottom: 50px;

}

@media screen and (max-width: 715px) {
    .MembershipBenefitsCategoriesAllCards {
        display: flex;
        flex-direction: column;
    }
}