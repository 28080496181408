.member_setting_top_div {
    padding: 5% 5%;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    margin-bottom: 50px;
    height: fit-content;
}

.dashboard_setting_fields_name_and_company_info_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10%;
}

.dashboard_setting_info_box {
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    display: grid;
    grid-template-columns: 40% 40%;
    justify-content: center;
    padding: 20px;
}

.dashboard_setting_listOfSkills {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
}

.dashboard_setting_skill_indiv {

    padding: 10px;
    cursor: pointer;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    border: #AD2225 1px solid;
}

.dashboard_setting_save_button_div {
    width: 100%;
}

.dashboard_setting_save_button {
    width: 100px;
    float: right;
    background-color: #AD2225;
    cursor: pointer;
    border-width: 1px;
}

.dashboard_setting_fields_image_name_div {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 40% 60%;
}

.dashboard_setting_fields_name_div {
    display: flex;
    gap: 40px;
}

.dashboard_setting_address_state_info_div {
    display: grid;
    grid-template-columns: 50% 50%;
}

.dashboard_setting_address_input {
    border-top: transparent;
    border-left: transparent;
    border-right: transparent;
    outline: none;
    height: 25px;
    width: 75%;
    font-size: 16px;
}

.dashboard_setting_profile_news_separate_line {
    border-bottom: 2px solid;
    width: 75%;
    margin: auto;
    margin-top: 60px;
    margin-bottom: 50px;
}

.plusButtonAddCertificateSettings {
    width: 40px;
    height: 40px;
    background-color: #AD2225;
    border-radius: 10px;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.plusButtonAddCertificateSettings:active {
    background-color: #ac585a;
}

.dashboard_home_user_certificates_Others_indiv {
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    padding: 15px;
    text-align: center;
}

.AddCertificateMemberSettings {
    width: 150px;
    height: 40px;
    background-color: #AD2225;
    border-radius: 10px;
    border: none;
    font-size: 13px;
    cursor: pointer;
}

.AddCertificateMemberSettings:active {
    background-color: #ac585a;

}

@media screen and (max-width: 650px) {
    .dashboard_setting_fields_image_name_div {
        grid-template-columns: 100%;
        align-items: center;
    }

    .dashboard_setting_fields_name_div {

        display: grid;
        grid-template-columns: 50% 50%;
        gap: 0px
    }
}