.related_news_div {
    width: 90%;
    margin-top: 5%;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    padding-top: 1%;
    padding-left: 5%;
    padding-right: 5%;

}
.related_news_carousel {
    width: 100%;
    padding-bottom: 2%;
}

.indiv_news_cards_related_whole {
    display: flex;
    gap: 9%;
    width: 100%;
    margin-bottom: 7%;
}

.news_related_link{
    position: relative; 
    width: 50%;
    aspect-ratio: 3/2;
    text-align: left;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    flex-direction: row;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px;
}

@media screen and (max-width: 820px) {
    .indiv_news_cards_related_whole {
        gap: 2%;      
    }
}


@media screen and (max-width: 670px) {
    .related_news_div{
        display: none;
    }
}