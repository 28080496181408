.Indiv_Podcast_indiv_host_description{
    padding: 1% 3%;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
}

.Indiv_Podcast_indiv_host_name_icon{
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 20px;
}

.upcoming_podcast_host_title_slogan_div{
    align-self: flex-start;
    margin-top: 5%;
    margin-left: 10%;
}


.upcoming_podcast_host_title_slogan_div h1 {
    font-size: 55px;
    font-weight: 900;
    line-height: 70px;
    margin: 0px;
    margin-bottom: 10px;
}

.upcoming_podcast_host_title_slogan_div h3 {
    font-size: 32px;
    line-height: 50px;
    margin: 0px;
    margin-bottom: 20px;
}


@media screen and (max-width: 1350px) {
    .upcoming_podcast_host_title_slogan_div {
    margin-left: 0%;
    }
}


@media screen and (max-width: 650px) {
.upcoming_podcast_host_title_slogan_div h1 {
    font-size: 50px;
    line-height: 65px;
}

.upcoming_podcast_host_title_slogan_div h3 {
    font-size: 27px;
    line-height: 45px;
}
}
@media screen and (max-width: 550px) {
.upcoming_podcast_host_title_slogan_div h1 {
    font-size: 45px;
    line-height: 55px;
}

.upcoming_podcast_host_title_slogan_div h3 {
    font-size: 25px;
    line-height: 40px;
}
}
@media screen and (max-width: 430px) {
.upcoming_podcast_host_title_slogan_div h1 {
    font-size: 40px;
    font-weight: 600;
    line-height: 50px;
}

.upcoming_podcast_host_title_slogan_div h3 {
    font-size: 23px;
    line-height: 35px;
}
}
@media screen and (max-width: 370px) {
.upcoming_podcast_host_title_slogan_div h1 {
    font-size: 35px;
    line-height: 45px;
}

.upcoming_podcast_host_title_slogan_div h3 {
    font-size: 21px;
    line-height: 30px;
}
}