.individual_event_filter_blur{
    background-color: white;
}

.individual_event_page_card_seasts_remaining{
    position: relative;
    top: -30px;
    right: 5%;
    float: right;
    margin: 0px;
box-shadow: inset 4px 4px 20px 2px rgba(0, 0, 0, 0.4);
text-align: center;
padding: 7px 10%;
width: fit-content;
}

.individual_event_page_card_seasts_remaining_none{
    background: #AD2225;
}
.individual_event_page_card_seasts_remaining_some{
    background: #CCAA52;
}

.individual_event_2_top_div{
    display: flex;
    gap: 7%;
    border-radius: 5%;
    border-width: 1px;
    /* border-style: solid; */
}

.individual_event_2_big_date{
    border-radius: 5%;
    /* background-color: #d1991f; */
}