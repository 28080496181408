.news_page_youtube_shorts_top_header_div {
    margin-bottom: 30px;
}

.news_page_youtube_shorts_top_hot_takes_div {
    position: relative;
    top: -1px;
    background: #BB2027;
    padding: 2px 25px;
    width: fit-content;
}

.news_page_youtube_shorts_top_hot_takes_div p {
    line-height: 10px;
}

.news_page_youtube_shorts_all_div {
    display: flex;
    margin-bottom: 100px;
}

.youtubeShortsCarouselItem {
    padding-right: 40px;
    height: 390px;
}