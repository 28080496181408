.idca_admin_label {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.dashboard_idca_admin_users_whole_info_list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    margin: 10px;
    flex-wrap: wrap;
}

.dashboard_idca_admin_users_info_list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 4px;
}

.Dashboard_IDCA_Admin_Update_User_Detail_fields_only {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}

.Dashboard_IDCA_Admin_Update_User_Detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.Dashboard_IDCA_Admin_Update_User_Detail_find_user_button {
    width: 50%;
    min-width: 100px;
    max-width: 200px;
}

.Dashboard_IDCA_Admin_Update_User_Detail_find_user_buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
}