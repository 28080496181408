.Webinar-top-div{
    height: 600px;
    background-image: url("../../../resources/webinar_top_banner.png");
    position: relative;
    background-size: cover;
    background-position: center;
    margin-bottom: 15px;
}

/* Style the list */
ul.Webinar-breadcrumb {
  margin-bottom: 30px;
  padding-left: 0px;
}

/* Display list items side by side */
ul.Webinar-breadcrumb li {
  display: inline;
  font-size: 14px;
}

/* Add a slash symbol (/) before/behind each list item */
ul.Webinar-breadcrumb li+li:before {
  padding: 8px;
  color: black;
  content: "/\00a0";
}

/* Add a color to all links inside the list */
ul.Webinar-breadcrumb li a {
  color: #AD2225;
  text-decoration: none;
}

/* Add a color on mouse-over */
ul.Webinar-breadcrumb li a:hover {
  /* color: #01447e; */
  opacity: 0.7;
  text-decoration: underline;
}

.Webinar-top-count-up-div{
    display: flex;
    align-items: center; 
    justify-content: center;
    width: 100%;
    font-size: 24px;
    width: fit-content;
    float: right;
    position: relative;
    top: 410px;
    right: 50px;
    background-image: url("../../../resources/DataCenterTrainingClassColor.png");
}

.Webinar-top-count-up-individ-div{
    margin: 20px;
    padding: 10px;
    /* background-image: ; */
}

/* .Webinar-top-count-up-individ-number{
    margin: 0px;
    line-height: 0px;
} */

.Webinar-top-count-up-individ-text{

    font-size: 20px;
    margin-bottom: 5px;
}