.MembershipSignupBenefits {
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    background-color: #eaeaea;
    padding-bottom: 20px;
    height: fit-content;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
}

.MembershipSignupPlansWholeDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MembershipSignupIndivPlan {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MembershipSignupIndivAllPlans {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 80px;
}

.MembershipSignupIndivPlanLengthHeader {
    border: solid 1px;
    width: 150px;
    text-align: center;
    height: 40px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.MembershipSignupIndivPlanSelectAPlanButton {
    width: 150px;
    border: none;
    height: 40px;
    margin-bottom: 30px;
    background-color: Gray;
    cursor: pointer;

}

.MembershipSignupIndivPlanSelectAPlanButton:hover {
    background-color: #AD2225;

}

.MembershipSignupTypeButton {
    width: 200px;
    height: 50px;
    border: solid 1px;
    background-color: #AD2225;
    font-family: Saira;
    font-style: normal;
    font-weight: 500;
    color: White;
    cursor: pointer;
}

.MembershipSignupTypeButtonSelected {
    width: 200px;
    height: 50px;
    border: solid 1px #AD2225;
    background-color: white;
    font-family: Saira;
    font-style: normal;
    font-weight: 500;
    color: #AD2225;
    cursor: pointer;
}

.vl {
    border-left: 6px solid #AD2225;
    height: 100px;
}