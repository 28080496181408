.MembershipIDCAExclusives_header {
    margin-top: 50px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: left;
}

.MembershipIDCAExclusives_headerAndText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.MembershipIDCAExclusives_headerTitle {
    font-size: 28px;
}

.MembershipIDCAExclusives_virtcalline {
    margin-left: 30px;
    margin-right: 30px;
    border-left: 2px solid #ABABAB;
    height: 70px;
}

.MembershipIDCAExclusives_pagepara {
    font-size: 16px;
    display: inline-flex;
    align-items: left;
    justify-content: left;
}

.MembershipIDCAExclusives_Text {
    font-size: 18px;
    max-width: 450px;
}

.MembershipIDCAExclusives_Whole {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.MembershipIDCAExclusives_CarouselSingle {
    display: grid;
    grid-template-columns: 45% 45%;
    gap: 10px;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
    padding-top: 50px;
    width: 100%;

}

.MembershipIDCAExclusives_Carousel {
    width: 75%;
}



@media screen and (max-width: 1250px) {
    .MembershipIDCAExclusives_Whole {
        flex-direction: column;
    }

    .MembershipIDCAExclusives_Text {
        text-align: center;
    }

    .MembershipIDCAExclusives_headerAndText {
        align-items: center;
    }

    .MembershipIDCAExclusives_Carousel {
        width: 100%;
    }
}

@media screen and (max-width: 680px) {
    .MembershipIDCAExclusives_headerTitle {
        font-size: 20px;
    }

    .MembershipIDCAExclusives_pagepara {
        font-size: 14px;
    }

    .MembershipIDCAExclusives_CarouselSingle {
        display: flex;
        flex-direction: column;
    }
}