.symposia_individual_events_who_how_when_indiv_inner_div {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    justify-content: center;

}

.symposia_individual_events_who_how_when_indiv_boxes {
    border: 4px dotted white;
    text-align: center;
    padding: 0px 30px;

}

.symposia_individual_events_who_how_when_indiv_boxes h2 {
    font-size: 32px;
}

.symposia_individual_events_who_how_when_indiv_boxes p {
    font-size: 22px;
    line-height: 30px;
}

@media screen and (max-width: 1306px) {
    .symposia_individual_events_who_how_when_indiv_boxes h2 {
        font-size: 30px;
    }

    .symposia_individual_events_who_how_when_indiv_boxes p {
        font-size: 15px;
        line-height: 30px;
    }
}

@media screen and (max-width: 1000px) {

    .symposia_individual_events_who_how_when_indiv_boxes h2 {
        font-size: 28px;
    }

    .symposia_individual_events_who_how_when_indiv_boxes p {
        font-size: 13px;
        line-height: 30px;
    }
}

@media screen and (max-width: 900px) {

    .symposia_individual_events_who_how_when_indiv_inner_div {
        display: flex;
        flex-direction: column;
        justify-content: center;

    }

    .symposia_individual_events_who_how_when_indiv_boxes p {
        font-size: 15px;
        line-height: 30px;
    }
}