.TopCoursesCardBody{
    height: fit-content;
    width: 255px;
    background-color: white;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
}

.TopCoursesCardImgSection{
    padding-top: 17px;
    width: 228px;
    position: relative;
    text-align: center;
    color: white;
}

.TopCoursesCardTitle{
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 71px;
}

.TopCoursesCardTitleName{
    left: 10%;
    font-size: 20px;
    transform: translate(0%, 0%);
    margin-bottom: 0px;
    padding-left: 10%;
    padding-right: 10%;
    text-align: left;
    font-size: 15.5px;
}
.TopCoursesCardLength{
    display: flex;
    text-align: center;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    left: 10%;
    transform: translate(10%, 0%);
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.TopCoursesCardLengthText{
    padding-left: 5px;
    font-size: 14px;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.TopCoursesCardLengthIcon{
    margin-bottom: -12px;
    padding-bottom: 0px;
}

.data-center-top-courses-carousel-individ-course-line{
    width: 85%;
    border: none;
    height: 0.5px;
    background: #EEEEEE;
}

.data-center-top-courses-carousel-individ-course-read-more{
    text-decoration-color: #AD2225;
    display: flex;
    text-align: center;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    /* left: 10%; */
    transform: translate(58%, -20%);
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.TopCoursesCardCourseDescription{
    font-size: 14px;
    text-align: left;
    left: 10%;
    transform: translate(10%, 0%);
    padding-right: 17%;
}

