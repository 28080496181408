.certification_2_0_content_top_div {
    display: flex;
    justify-content: space-between;
    gap: 50px;
}

.certification_2_0_left_content_div {
    margin-top: 30px;
    width: calc(100% - 380px);
}

.certification_2_0_left_content_description_text {
    font-size: 19px;
    line-height: 30px;
}

.certification_2_0_right_content_div {
    position: relative;
    top: -71px;
    max-width: 380px;
    min-width: 380px;
}

@media screen and (max-width: 1350px) {
    .certification_2_0_right_content_div {
        max-width: 340px;
        min-width: 340px;
    }

    .certification_2_0_left_content_div {
        width: calc(100% - 340px);
    }
}

@media screen and (max-width: 1200px) {
    .certification_2_0_content_top_div {
        flex-direction: column;
        align-items: center;
        gap: 80px;
    }

    .certification_2_0_left_content_div {
        width: calc(100%);
    }

    .certification_2_0_right_content_div {
        top: -40px;
    }
}

@media screen and (max-width: 600px) {
    .certification_2_0_right_content_div {
        width: 340px;
    }
}