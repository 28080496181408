.membership_pricing_benefits_top_prof_corp_div {
    display: flex;
    justify-content: center;
}

.membership_pricing_benefits_top_prof_corp_button {
    background-color: transparent;
    border: 1px solid gray;
    text-align: center;
    padding: 5px 150px;
    cursor: pointer;
}

.membership_pricing_benefits_top_prof_corp_bottom_line {
    border: 1px solid gray;
    width: 100%;
}

.membership_pricing_benefits_top_prof_corp_button h2 {
    font-size: 18px;
}

.membership_pricing_benefits_corp_request_button_div {
    text-align: center;
    width: 300px;
    margin: auto;
    margin-top: 50px;
    border-radius: 10px;
    box-shadow: 0px 10px 27px 20px rgba(0, 0, 0, 0.1);

    padding: 5px;
    background-image: radial-gradient(#AD2225, #6c1516);
    cursor: pointer;
}

.membership_pricing_benefits_prof_different_plans_top_div {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    justify-content: center;
    margin-top: 50px;
}

.membership_pricing_benefits_prof_different_plans_indiv_div {
    font-size: 16px;
    text-align: center;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    height: fit-content;
    height: 400px;
}

.membership_pricing_benefits_prof_different_plans_indiv_div a:hover {
    background-color: #AD2225;
    color: white;
}

.membership_pricing_benefits_prof_different_plans_indiv_div a {
    text-decoration: none;
    border: 1px solid #AD2225;
    padding: 10px 40px;
    border-radius: 5px;
    color: #AD2225;
}

.membership_pricing_benefits_prof_different_sign_up {
    margin-top: 68px;
}

.membership_pricing_benefits_prof_different_plans_price_div {
    font-size: 34px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.membership_pricing_benefits_prof_different_plans_indiv_year {
    font-size: 36px;
    padding: 10px;
    margin-bottom: 30px;
}

.membership_pricing_benefits_prof_different_plans_saving {
    margin-bottom: 60px;
}

.membership_pricing_benefits_list_top_div {
    margin-top: 50px;
}

.membership_pricing_benefits_list_h2 {
    font-size: 26px;
    font-weight: 900;
    text-align: center;
    padding-top: 50px;
}

.membership_pricing_benefits_list_div {
    display: grid;
    grid-template-columns: 45% 45%;
    justify-content: space-between;
}

.membership_pricing_benefits_list_indiv_div {
    display: grid;
    grid-template-columns: 25px 95%;
    align-items: center;
    gap: 10px;
}

.membership_pricing_benefits_list_indiv_div p {
    font-size: 18px;
    line-height: 30px;
}


@media screen and (max-width: 1250px) {
    .membership_pricing_benefits_prof_different_plans_top_div {
        grid-template-columns: 220px 220px 220px 220px;
    }

    .membership_pricing_benefits_top_prof_corp_button {
        padding: 5px 100px;
    }

    .membership_pricing_benefits_top_prof_corp_bottom_line {
        display: none;
    }
}

@media screen and (max-width: 1100px) {
    .membership_pricing_benefits_prof_different_plans_top_div {
        grid-template-columns: 250px 250px;
    }
}

@media screen and (max-width: 800px) {

    .membership_pricing_benefits_top_prof_corp_button {
        padding: 0px 70px;
    }
}

@media screen and (max-width: 700px) {
    .membership_pricing_benefits_list_div {
        grid-template-columns: 90%;
    }

    .membership_pricing_benefits_top_prof_corp_button {
        padding: 0px 50px;
    }

    .membership_pricing_benefits_top_prof_corp_button h2 {
        font-size: 17px;
    }
}

@media screen and (max-width: 600px) {
    .membership_pricing_benefits_prof_different_plans_top_div {
        grid-template-columns: 250px;
        gap: 20px
    }
}

@media screen and (max-width: 450px) {
    .membership_pricing_benefits_top_prof_corp_button {
        padding: 0px 30px;
    }

    .membership_pricing_benefits_top_prof_corp_button h2 {
        font-size: 16px;
        line-height: 20px;
    }
}