.news_page_events_top_div {
    background: #3B4B63;
    padding-bottom: 20px;
    margin-bottom: 50px;
}

.news_page_events_theme_img_div {
    margin-top: 30px;
    padding-bottom: 50px;
    display: flex;
    justify-content: space-between;
    gap: 30px;
    flex-wrap: wrap;
}

.news_page_events_theme_indiv_img_div {
    width: 300px;
    cursor: pointer;
}

.news_page_events_theme_header_div {
    position: relative;
    top: -1px;
    background: white;
    padding: 2px 25px;
    width: fit-content;
}

.news_page_events_theme_header_div p {
    line-height: 10px;
    color: #3B4B63;
}

.news_page_events_speaker_events_top_div {
    margin-bottom: 40px;
    display: grid;
    grid-template-columns: 35% 5% 55%;
    justify-content: space-between;
}

.news_page_events_speaker_text_div {
    margin-bottom: 40px;
}

.news_page_events_speaker_text_div h3 {
    font-size: 26px;
}

.news_page_events_speaker_indiv_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.news_page_events_speaker_indiv_div h4 {
    margin: 0px;
    margin-top: 10px;
    font-size: 17px;
    font-weight: bolder;
}

.news_page_events_speaker_indiv_div h6 {
    margin: 0px;
    font-size: 13px;
    font-weight: 100;
}

.news_page_events_speaker_indiv_div h5 {
    margin: 0px;
    font-size: 15px;
    font-weight: bolder;
}

.news_page_events_speaker_img_div {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    width: 120px;
    height: 120px;
}

@media screen and (max-width: 1050px) {
    .news_page_events_speaker_events_top_div {
        grid-template-columns: 100%;
        gap: 20px;
    }
}

.news_page_events_upcoming_indiv_div {
    width: 210px;
}

.news_page_events_upcoming_indiv_div a {
    text-decoration: none;
    color: white;
    border: 1px solid white;
    padding: 7px 40px;
    border-radius: 7px;
}

.news_page_events_upcoming_indiv_div a:hover {
    background-color: rgba(255, 255, 255, 0.202);
}

.news_page_events_upcoming_indiv_img_div {
    position: relative;
    height: 120px;
    border-radius: 10px;
    overflow: hidden;
}

.news_page_events_upcoming_indiv_title_div {
    font-size: 18px;
    margin-bottom: 20px;
}

.news_page_events_upcoming_events_top_div {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    margin-top: 40px;
}