.loyalty_how_to_earn_point_top_div {
    background: #606060;
    padding: 50px;
}

.loyalty_how_to_earn_point_indiv_div {
    text-align: center;
    width: 250px;
}

.loyalty_how_to_earn_point_indiv_top_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    row-gap: 30px;
}