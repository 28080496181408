.webinar_new_educational_top_div {
    display: grid;
    grid-template-columns: 100%;
    justify-content: space-between;
}

#webinar_new_educational_text_div {
    margin-top: 0px;
    margin-bottom: 0px;
    width: 100%;
}

.webinar_new_educational_img {
    position: relative;
    max-width: 500px;
    height: 120px;
    overflow: hidden;
}

.webinar_new_educational_event_div {
    background-color: #F4F4F4;
    border-radius: 10px;
    padding: 40px 30px;
    text-align: left;
}

.webinar_new_educational_event_div h3 {
    color: #006197;
}

.webinar_new_educational_event_description_top_div {
    display: flex;
    gap: 20px;
}

.webinar_new_educational_event_registration {
    text-decoration: none;
    color: #006197;
    border: 1px solid #006197;
    padding: 7px 50px;
    font-size: 14px;
    text-align: center;
}

.webinar_new_educational_event_speaker_indiv_div {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid #006197;
}

.webinar_new_educational_event_speaker_indiv_detail_div {
    text-align: center;
}

#webinar_new_educational_event_speaker_indiv_name {
    color: black;
    margin-top: 10px;
    margin-bottom: 10px;
}

.webinar_new_educational_event_speaker_indiv_detail_div p {
    font-size: 14px;
    color: #006197;
    margin: 0px;
}

@media screen and (max-width: 1250px) {
    .webinar_new_educational_top_div {
        display: grid;
        grid-template-columns: 100%;
        justify-content: space-between;
    }
}