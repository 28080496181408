.individual_courses_become_idca_certified_lower_div {
    background-image: url("../../../resources/individual_training_red_bg.png"), url("../../../resources/Training_Individual_Become_IDCA_Certified_bg.png");
    background-size: cover;
    background-position: center;
    margin-bottom: -30px;
    height: 100%;
    margin-top: -20px;
    margin-bottom: 50px;
}

#become_idca-certified-form-submit-button {
    cursor: pointer;
    width: 288px;
    height: 50px;
    background: #303030;
    border: transparent;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
}

.individual_courses_become_idca_certified_lower_div_row_div {
    display: grid;
    grid-template-columns: 15% 15% 15% 15%;
    gap: 7%;
    justify-content: space-between;
}

.individual_courses_become_idca_certified_lower_div_input_box {
    border-top: transparent;
    border-left: transparent;
    border-right: transparent;
    background: transparent;
    border-bottom: 1px solid white;
    outline: none;
    height: 25px;
    font-size: 16px;
    width: 100%;
    padding: 0px;
}

.individual_courses_become_idca_certified_lower_div_input_box option {
    color: #424242;
}

.individual_courses_become_idca_certified_lower_div_consent_div {
    margin-top: 2%;
    display: flex;
    align-content: end;
}

@media screen and (max-width: 1450px) {
    .individual_courses_become_idca_certified_lower_div_row_div {
        display: grid;
        grid-template-columns: 17% 17% 17% 17%;
        gap: 7%;
        justify-content: space-between;
    }
}

@media screen and (max-width: 1250px) {
    .individual_courses_become_idca_certified_lower_div_row_div {
        display: grid;
        grid-template-columns: 20% 20% 20%;
        gap: 7%;
        justify-content: space-between;
    }

    .individual_courses_become_idca_certified_lower_div_consent_div {
        margin-top: 5%;
    }

}

@media screen and (max-width: 1150px) {
    .individual_courses_become_idca_certified_lower_div_row_div {
        display: grid;
        grid-template-columns: 25% 25% 25%;
        gap: 7%;
        justify-content: space-between;
    }

    .individual_courses_become_idca_certified_lower_div_consent_div {
        margin-top: 7%;
    }
}

@media screen and (max-width: 850px) {
    .individual_courses_become_idca_certified_lower_div_row_div {
        display: grid;
        grid-template-columns: 30% 30% 30%;
        gap: 7%;
        justify-content: space-between;
    }

    .individual_courses_become_idca_certified_lower_div_consent_div {
        margin-top: 10%;
    }
}

@media screen and (max-width: 700px) {
    .individual_courses_become_idca_certified_lower_div_row_div {
        display: grid;
        grid-template-columns: 40% 40%;
        gap: 7%;
        justify-content: space-between;
    }

    .individual_courses_become_idca_certified_lower_div_consent_div {
        margin-top: 110px;
    }
}

@media screen and (max-width: 420px) {
    .individual_courses_become_idca_certified_lower_div_row_div {
        display: grid;
        grid-template-columns: 45% 45%;
        gap: 7%;
        justify-content: space-between;
    }

    #become_idca-certified-form-submit-button {
        width: 260px;
    }

    .individual_courses_become_idca_certified_lower_div_consent_div {
        /* margin-top: 25%; */
    }
}

@media screen and (max-width: 360px) {
    #become_idca-certified-form-submit-button {
        width: 230px;
    }

    .individual_courses_become_idca_certified_lower_div_consent_div {
        /* margin-top: 30%; */
    }
}

@media screen and (max-width: 330px) {
    #become_idca-certified-form-submit-button {
        width: 210px;
    }
}