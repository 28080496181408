.symposiaWorldTopBannerIndiv {
    padding-top: 20px;
    background-image: url("../../resources/Group 6339 (4)-min.png");
    background-size: cover;
    background-position: center;
    height: 510px;
    padding-bottom: 50px;
    margin: 0px;
}

.SymposiaWorldNewMainWholeDiv {
    background-image: url("../../resources/Group 6386 (3) 2.png");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.SymposiacountUpEachSmallDiv {
    font-size: 25px;
}

.SymposiaWorldUpcomingEvent_div {
    background-image: url("../../resources/Rectangle 1036 (1)-min.png");
    background-size: cover;
    background-position: center;
    height: 400px;
    margin: auto;
    padding-top: 5px;
}

.SymposiaWorldUpcomingEvent_div h4 {
    font-size: 30px;
}

.SymposiaWorldUpcomingEvent_div h3 {
    font-size: 40px;
    line-height: 40px;
}

.SymposiaWorldUpcomingEvent_date {
    font-size: 24px;
}

.SymposiaWorldUpcomingEvent_venue {
    font-size: 28px;
}

.SymposiaWorldUpcomingEvent_location {
    font-size: 24px;
    width: 280px;
    line-height: 28px;
}

@media screen and (max-width: 500px) {
    .SymposiaWorldUpcomingEvent_div h4 {
        font-size: 26px;
    }

    .SymposiaWorldUpcomingEvent_div h3 {
        font-size: 36px;
        line-height: 36px;
    }

    .SymposiaWorldUpcomingEvent_date {
        font-size: 20px;
    }

    .SymposiaWorldUpcomingEvent_venue {
        font-size: 28px;
    }

    .SymposiaWorldUpcomingEvent_location {
        font-size: 23px;
        width: 280px;
        line-height: 28px;
    }
}