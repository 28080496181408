.membership_detail_login_section_top_div {
    margin-top: 20px;
    margin-bottom: 50px;
    box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    display: grid;
    grid-template-columns: 50% 50%;
}

.membership_detail_login_section_header_top_div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-bottom: 40px;
}

.membership_detail_login_section_header_top_div h2 {
    text-align: left;
}

.membership_detail_login_section_non_mem_div {
    background-color: #F3F3F3;
    display: flex;
    align-items: center;
}

.membership_detail_login_section_non_mem_inner_div {
    margin: auto;
    text-align: center;
}

.membership_detail_login_section_login_div {
    padding: 80px 70px;
}

.membership_detail_login_section_buttons {
    border: 1px solid #AD2225;
    background-color: transparent;
    font-size: 17px;
    padding: 10px 30px;
    border-radius: 8px;
    width: 150px;
}

.membership_detail_login_section_email_pass_input {
    height: 30px;
    border: 0px;
    border-bottom: 1px solid gray;
    font-size: 18px;
}

.membership_detail_login_section_login_div_input_button_inner_div {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.membership_detail_login_section_login_div_input_button_inner_div button {
    margin: auto;
    margin-top: 40px;
    cursor: pointer;
}

.membership_detail_login_section_loggged_in_div {
    padding: 70px 0px;
}

.membershipo_detail_login_section_request_form_two_div {
    display: grid;
    grid-template-columns: 40% 40%;
    justify-content: space-between;
}

@media screen and (max-width: 950px) {
    .membership_detail_login_section_top_div {
        grid-template-columns: 100%;
    }

    .membership_detail_login_section_non_mem_div {
        padding: 80px;
    }

    #data_center_contact_us_page_form_id_membership {
        margin: 0px;
    }
}

@media screen and (max-width: 700px) {
    .membershipo_detail_login_section_request_form_two_div {
        grid-template-columns: 100%;
        text-align: left;
    }
}