.Audit_Main_Slogan_section {
    /* background-image: url("../../resources/audit_slogan.png"); */
    background-size: cover;
    background-position: center;
    height: 370px;
    display: grid;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.Audit_Main_Slogan_section_bg_img {
    position: absolute;
    height: 370px;
    width: 100%;
    z-index: -1;
}

.Audit_Main_Slogan_section_text {
    width: 100%;
    line-height: 40px;
    font-size: 24px;
}

.Audit_Main_Slogan_section_header {
    font-size: 30px;
    line-height: 40px;
}

/* Audit */
.Audit_services_certification_all_section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px;
    margin: auto;
    flex-wrap: wrap;
}

.Audit_services_Card_certification_indiv_button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50px;
    width: 150px;
    background-color: #AD2225;
    margin-top: 30px;
}

.Audit_services_Card_certification_indiv_button:hover {
    opacity: 0.8;
    cursor: pointer;
}


/* Benefits of IDCA */
.Audit_Main_Benefits_of_IDCA_MAinDiv {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;
}

.Audit_Main_Benefits_of_IDCA_Card_indiv {
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    align-items: center;
    font-size: 16px;
    justify-content: center;
    width: 290px;
    height: 110px;
    text-align: center;
}

@media screen and (max-width: 550px) {
    .Audit_Main_Slogan_section_text {
        font-size: 21px;
    }

    .Audit_Main_Slogan_section_header {
        font-size: 27px;
    }
}

/* @media screen and (max-width: 780px) {
    .Audit_services_certification_all_section{
            flex-direction: row;
            width: 100%;
            margin: auto;
            justify-content: center;

            background-color: aqua;
    }
 } */

.Audit_services_Card_certification_indiv_button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50px;
    width: 150px;
    background-color: #AD2225;
    margin-top: 30px;
}