.member_admin_corporate_mem_title_div {
    display: flex;
    justify-content: space-around;
}

.add_to_corporate_membership_form_email_check_button {
    margin: auto;
    margin-top: 40px;
    padding: 0px 30px;
    background-color: #AD2225;
    border: 0px;
    cursor: pointer;
}

.add_to_corporate_membership_form_email_check_button p {
    font-size: 16px;
}