.individual_top_news_list_styling {
    list-style-type: square;
}

.individual_top_news_quote_styling {
    position: relative;
    padding-left: 1em;
    border-left: 0.2em solid #AD2225;
    /* font-family: 'Roboto', serif;
    font-size: 2.4em;
    line-height: 1.5em;
    font-weight: 100; */
}

.individual_top_news_quote_styling:before,
.individual_top_news_quote_styling:after {
    /* font-family: Calibri; */
    color: #AD2225;
    font-size: 34px;
}

.individual_top_news_quote_styling:before {
    content: '\201e  '
}

.individual_top_news_quote_styling:after {
    content: ' \201c';
}

.individual_top_news_quote_styling cite {
    font-size: 50%;
    text-align: center;
    top: 50%
}

.individual_top_news_quote_styling cite:before {
    content: ' \2015 '
}

.individual_top_news_div {
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    padding-bottom: 2%;
}

.individual_top_news_img {
    width: 100%;
    margin-bottom: -4px;
}

.individual_top_news_div2 {
    margin-bottom: 0px;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.individual_top_news_line_divider {
    border-color: rgba(128, 128, 128, 0.075);
    margin-top: 0px;
}

.individual_top_news_body {
    margin-top: 5%;
    margin-left: 5%;
    margin-right: 5%;
}

.individual_top_news_print_text {
    display: flex;
    gap: 3%;
}

.individual_top_news_print_text1 {
    display: flex;
    align-items: center;
    gap: 10px;
}

.individual_top_news_red_gray_body {
    display: flex;
    background: rgba(173, 34, 37, 1);
    padding-left: 4px;
}

.individual_top_news_gray_body {
    background: rgba(250, 250, 250, 1);
    align-items: center;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 3%;
    padding-right: 3%;

}

.individual_top_news_gray_body_text {
    margin-top: auto;
    margin-bottom: auto;
}

/* Social Media Section */

.individual_top_news_social_media_section {
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
    width: 40%;
    margin-right: 0%;
    right: 0px;
}

.individual_top_news_small_logos {
    display: flex;
    flex-wrap: wrap;
    width: 100px;
    justify-content: space-between;
    align-items: center;
    margin-left: 18px;
}

/* Date Published in top news */

.individual_top_news_time_icon {
    margin-right: 10px;
}

.individual_top_news_date_Published_section {
    display: flex;
    flex-wrap: wrap;
    width: 20%;
    align-items: center;
    justify-content: left;
}

/* Author Section */

.individual_top_news_author_section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;
}

.individual_top_news_author_name_by {
    margin-left: 10px;
}

.individual_top_news_author_img {
    position: relative;
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.has-text-centered-img {
    position: relative;
    width: 100%;
    height: 320px;

}


@media screen and (max-width: 1405px) {

    .individual_top_news_text_formatting {
        font-size: 14px;
    }

    .individual_top_news_small_logo_individual {
        font-size: 10px;
    }

    .individual_top_news_author_img {
        width: 40px;
        height: 40px
    }
}


@media screen and (max-width: 1300px) {
    .individual_top_news_small_text {
        font-size: 12px;
        margin-top: 0%;
    }

    .individual_top_news_time_icon {
        width: 20px;
        height: 20px;
    }

    .individual_top_news_title_text {
        font-size: 20px;
    }

    #individual_top_news_social_media_text_formatting {
        display: none;
    }
}

@media screen and (max-width: 1068px) {
    .individual_top_news_social_media_section {
        width: 100px;
    }

    .individual_top_news_date_Published_section {
        width: 150px;
    }
}

@media screen and (max-width: 615px) {
    .individual_top_news_author_img {
        display: none;
    }

    .individual_top_news_author_name_by {
        margin-left: 0px;
    }
}

@media screen and (max-width: 490px) {
    .individual_top_news_text_formatting {
        font-size: 12px;
    }

    .individual_top_news_date_Published_section {
        justify-content: center;
    }

    .individual_top_news_time_icon {
        display: none;
    }


    .individual_top_news_author_section {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: left;
        width: 150px;
    }

    .individual_top_news_author_img {
        border-radius: 50%;
        width: 50px;
        height: 50px;
    }

}