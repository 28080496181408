.podcast-individual-courses-top-div {
  height: 530px;
  background-image: url("../../../resources/DataCenterTrainingClassColor.png"), url("../../../resources/infinity-paradigm-podcast-bg.jpg");
  background-size: cover;
  background-position: center;
  margin-bottom: 15px;
}

.podcast_top_section {
  display: flex;
  flex-direction: column;
  padding-top: 200px;
  gap: 20px;
}

.podcast_top_large_title {
  font-size: 40px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.podcast_top_announce_large_title {
  font-size: 40px;
  font-weight: 800;
  margin-bottom: 0px;
  margin-top: 0px;
}

.podcast_top_text_top {
  margin-top: 0px;
  width: 55%;
  text-align: justify;
}


@media screen and (max-width: 1500px) {
  .podcast_top_text_top {
    width: 70%;
  }
}

@media screen and (max-width: 1000px) {
  .podcast_top_text_top {
    width: 80%;
  }
}

@media screen and (max-width: 800px) {
  .podcast_top_text_top {
    width: 100%;
  }
}


@media screen and (max-width: 400px) {
  .podcast_top_section {
    gap: 10px;
  }

  .podcast_top_large_title {
    margin-top: 0px;
  }
}

@media screen and (max-width: 287px) {
  .podcast_top_large_title {
    font-size: 30px;
  }

  .podcast_top_text_top {
    text-align: left
  }
}