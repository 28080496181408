.membership_detail_about_section_top_top_div {
    margin-top: 50px;
    margin-bottom: 50px;
    box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.15);
    padding: 30px 70px;
}

.membership_detail_about_section_top_div {
    display: grid;
    grid-template-columns: 60% 40%;
    justify-content: space-between;
    align-items: center;
}

.membership_detail_about_section_loyalty_img_div {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 30px;
    /* display: grid;
    grid-template-columns: 15% 15% 15% 15% 15%;
    justify-content: space-between; */
    align-items: center;
}

.membership_detail_about_section_header_top_div {
    display: flex;
    align-items: center;
    gap: 40px;
    margin-bottom: 20px;
}

.membership_detail_about_section_header_img {
    width: 70px;
    min-width: 70px;
}

.membership_memberloginArea_detail_about_section_header_img {
    width: 50px;
    min-width: 50px;
}

.membership_detail_about_section_header_line_between {
    height: 50px;
    border: 0px;
    border-right: 2px solid gray;
}

.membership_detail_about_section_main_img {
    position: relative;
    width: 250px;
    height: 250px;
    justify-self: right;
}

@media screen and (max-width: 1200px) {
    .membership_detail_about_section_top_div {
        grid-template-columns: 100%;
    }

    .membership_detail_about_section_main_img {
        margin-top: 30px;
        justify-self: center;
    }
}

@media screen and (max-width: 1150px) {
    .membership_detail_about_section_loyalty_img_div {
        grid-template-columns: 22% 22% 22%;
    }
}

@media screen and (max-width: 1000px) {
    .membership_detail_about_section_loyalty_img_div {
        grid-template-columns: 25% 25% 25%;
    }
}

@media screen and (max-width: 800px) {
    .membership_detail_about_section_loyalty_img_div {
        grid-template-columns: 30% 30% 30%;
    }
}

@media screen and (max-width: 420px) {
    .membership_detail_about_section_loyalty_img_div {
        grid-template-columns: 40% 40%;
    }
}

@media screen and (max-width: 700px) {
    .membership_detail_about_section_header_top_div h2 {
        font-size: 22px;
    }
}

@media screen and (max-width: 500px) {
    .membership_detail_about_section_header_top_div h2 {
        font-size: 20px;
    }

    .membership_detail_about_section_top_div p {
        font-size: 15px;
    }
}