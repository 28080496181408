.certification_2_0_videos_top_div {
    margin-top: 70px;
    padding-bottom: 10px;
    background-color: #F4F4F4;
}

.certification_2_0_videos_img_div {
    position: relative;
    width: 100%;
    aspect-ratio: 1.9;
    top: -30px;
}

.certification_2_0_videos_top_div p {
    text-align: center;
    font-size: 18px;
    margin-top: 0px;
    color: #006197;
}