.Testimonial_new_carousel{
    width: 100%;
}

.Testimonial-new-head{
    padding-top: 20px;
    font-family: Saira;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 250%;
    color: #D82027;
}

.Testimonial_new_p{
    font-family: Saira;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    color: #212121;
}

.Testimonial_new_header{
    padding-left: 0%;
}

@media screen and (max-width: 1411px) {
    .Testimonial_new_header{
        margin-left: 5%;
    }
}