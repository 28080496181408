.individual_course_2_description_top_div {
    margin-top: 50px;
    box-shadow: 0px 0px 46px -5px rgba(0, 0, 0, 0.1);
}

.individual_course_2_description_top_head_button {
    width: 100%;
    background-color: #F9F9F9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin: 0px;
    border: 0px;
    padding: 10px 40px;
}

.individual_course_2_description_who_what_top_div {
    padding: 10px 40px;
    padding-bottom: 30px;
}

.individual_course_2_description_view_calendar {
    background-color: #006197;
    border: 1px solid #006197;
    padding: 10px 30px;
    width: fit-content;
    transition: .2s;
}

.individual_course_2_description_view_calendar:hover {
    background-color: white;
    color: #006197;
}

.why_take_course_h2 {
    margin: 0px;
    font-size: 18px;
}

.why_take_course_h3 {
    margin: 0px;
}

.why_take_course_h4 {
    margin: 0px;
    font-size: 17px;
}