.GlobalDigitalResearchTopBuyNowButton {
    background: #BB2027;
    border: none;
    width: 100px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 11.76px;
    padding-bottom: 11.76px;
    cursor: pointer;
}

.GlobalDigitalResearchTopBuyNowButton:hover {
    background: black;
}

.GlobalDigitalResearchTopBuyNowButton:active {
    background: gray;
}

.GlobalDigitalResearchTopButtons {
    display: flex;
    gap: 50px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.GlobalDigitalResearchTopButtonsSingleButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}