.navbar_2023_top_div {
    position: fixed;
    max-height: 140px;
    z-index: 3000;
    width: 100%;
    /* transition: 0.5s; */
}

.navbar_2023_top_div_noscroll {
    backdrop-filter: blur(10px);
}

.navbar_2023_top_div_scroll {
    background-color: #FFFFFF;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
}

/* .navbar_2023_top_div:hover {
    position: fixed;
    max-height: 150px;
    z-index: 3000;
    width: 100%;
} */

.navbar_2023_nav {
    padding: 10px 7.5%;
}

.navbar_2023_nav_div_top_1 {
    display: grid;
    grid-template-columns: 10% 60%;
    justify-content: space-between;
}

.navbar_2023_nav_logo_img {
    height: 150px;
    width: 150px;
}

.navbar_2023_nav_menu_line_sep {
    border: 1px solid gray;
    width: auto;
}

.navbar_2023_nav_menu {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    padding: 0px;

    align-items: center;

    margin: 12px 0px;
}

.navbar_2023_nav_menu a {
    text-decoration: none;
    color: #ffffff;
    font-size: 16px;
    margin: 0px;
    line-height: 0px;
}

.navbar_2023_nav_menu_color_noscroll a {
    color: #ffffff;
}

.navbar_2023_nav_menu_color_scroll a {
    color: #000000;
}

.navbar_2023_nav_menu_login_contact_div {
    display: flex;
    align-items: center;
    gap: 30px;
}

.navbar_2023_nav_menu_contact_us {
    background-color: #BB2027;
    padding: 0px 20px;
    color: white;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
}

#navbar_2023_nav_menu_contact_us_a_tag:hover {
    border-bottom: 0px;
}

.navbar_2023_nav_menu_contact_us p {
    font-size: 13px;
    line-height: 10px;
}

.navbar_2023_nav_menu a:hover {
    border-bottom: 3px solid #AD2225;
}

.navbar_2023_top_div header {
    margin: 0px;
    padding: 0px;
    height: 140px;
}

.navbar_2023_dropdown_top_div {
    background-color: #000000;
    margin: 0px;
    padding: 0px;
}

.navbar_2023_dropdown_red_divide {
    border: 3px solid #AD2225;
    margin: 0px;
    padding: 0px;
    position: fixed;
    width: 100%;
    top: 140px;
}

.navbar_2023_dropdown_func_top_div {
    position: fixed;

    top: 146px;
    left: 0px;
    height: 500px;
    overflow-y: scroll;

    background-color: white;
    padding: 30px 50px;

    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
}

.navbar_2023_dropdown_func_top_div_2 {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    justify-content: space-between;
}

.navbar_2023_dropdown_func_top_div h1 {
    font-size: 30px;
    margin: 0px;
}

.navbar_2023_dropdown_func_event_event_studio_div {
    display: flex;
    justify-content: space-between;
    margin: 40px 0px;
    gap: 10px;
}

.navbar_2023_dropdown_func_event_event_studio {
    display: flex;
    background-color: #E3E4E3;
    height: 40px;
    align-items: center;
    padding: 0px 10px;
    gap: 10px;
    border-radius: 15px;
}

.navbar_2023_dropdown_func_event_3_year_slogan {
    background-color: #BB2027;
    color: white;
    text-align: center;
    width: 100%;
    border-radius: 15px;
    margin: 30px 0px;
}

.navbar_2023_dropdown_func_event_3_year_slogan p {
    padding: 10px 10px;
}

.navbar_2023_dropdown_func_event_borchure_download {
    width: 100%;
    background-color: #006197;
    color: white;
    height: 100px;
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.navbar_2023_dropdown_func_event_borchure_download_inner {
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.navbar_2023_dropdown_func_event_borchure_download_text_div p {
    font-size: 14px;
}

.navbar_2023_dropdown_func_event_borchure_download_text_div h3 {
    font-size: 22px;
    margin: 0px;
    line-height: 10px;
    padding-bottom: 15px;
}

.navbar_2023_dropdown_func_event_world_title_div {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 15px;
    color: #0D3C97;
}

.navbar_2023_dropdown_func_event_world_title_div h1 {
    margin-bottom: 0px;
    line-height: 35px;
}

.navbar_2023_dropdown_func_event_world_title_div p {
    margin-top: 0px;
    line-height: 10px;
}

.navbar_2023_dropdown_func_event_studio_top_div {
    background-color: #BB2027;
    height: fit-content;
}

.navbar_2023_dropdown_func_event_studio_inner {
    padding: 20px 30px;
}

.navbar_2023_dropdown_func_event_studio_divider {
    border-top: 1px solid white;
    width: 80%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.navbar_2023_dropdown_func_event_studio_header {
    display: flex;
    gap: 20px;
    align-items: center;
}

.navbar_2023_dropdown_func_event_studio_header h1 {
    margin: 0px;
    padding-top: 30px;
    line-height: 0px;
}

.navbar_2023_dropdown_func_event_studio_header p {
    margin: 0px;
}

.navbar_2023_dropdown_func_event_learn_more_data_center_div {
    margin: 40px 0px;
}

.navbar_2023_dropdown_func_event_learn_more_data_center {
    border-bottom: 2px solid #0D3C97;
    color: #0D3C97;
    padding-bottom: 5px;
    font-weight: 900;
    font-size: 18px;
    text-decoration: none;
}

.navbar_2023_dropdown_func_event_learn_more_data_center:hover {
    border-bottom: 2px solid #BB2027;
    color: #BB2027;
    text-decoration: none;
}

.navbar_2023_dropdown_func_event_learn_more_data_center_red {
    border-bottom: 2px solid #BB2027;
    color: #BB2027;
    padding-bottom: 5px;
    font-weight: 900;
    font-size: 18px;
    text-decoration: none;
}

.navbar_2023_dropdown_func_event_learn_more_data_center_red:hover {
    border-bottom: 2px solid #0D3C97;
    color: #0D3C97;
    text-decoration: none;
}

.navbar_2023_dropdown_func_event_learn_more_cloud {
    border: 1px solid #0083CD;
    color: #0083CD;
    padding: 10px 45px;
    border-radius: 10px;
    text-decoration: none;
}

.navbar_2023_dropdown_func_event_learn_more_cloud:hover {
    color: white;
    background-color: #0082cdc1;
}

.navbar_2023_dropdown_func_event_learn_more_cyber {
    border: 1px solid #E27B01;
    color: #E27B01;
    padding: 10px 45px;
    border-radius: 10px;
    text-decoration: none;
}

.navbar_2023_dropdown_func_event_learn_more_cyber:hover {
    color: white;
    background-color: #e27d01d6;
}

.navbar_2023_dropdown_func_event_courses_data_center {
    text-decoration: none;
}

.navbar_2023_dropdown_func_event_courses_data_center_indiv {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    align-items: center;
    color: #000000;
    border-bottom: 1px solid #ABABAB;
}

.navbar_2023_dropdown_func_event_courses_data_center_indiv:hover {
    color: #BB2027;
}

.navbar_2023_dropdown_func_event_courses_data_center_name_img {
    display: flex;
    gap: 15px;
    align-items: center;
}

.navbar_2023_dropdown_func_event_courses_cloud_list_div {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.navbar_2023_dropdown_func_event_cloud_cyber_divide {
    border-top: 1px solid gray;
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
}

.navbar_2023_dropdown_func_event_certification_list_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    column-gap: 7px;
    align-items: center;
}

.navbar_2023_dropdown_func_event_upcoming_training_top_div {
    margin-top: 30px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.navbar_2023_dropdown_func_event_upcoming_training_inner_div {
    display: flex;
    justify-content: space-between;
}

.navbar_2023_dropdown_func_event_upcoming_training_inner_div select {
    width: 40%;
    border: 0px;
    border-bottom: 1px solid #989898;
    height: 25px;
    font-size: 15px;
}

.navbar_2023_dropdown_func_top_div_3 {
    display: grid;
    grid-template-columns: 30% 40% 20%;
    justify-content: space-between;
}

.navbar_2023_dropdown_func_event_upcoming_training_search {
    text-decoration: none;
    background-color: #006197;
    padding: 10px 60px;
    border-radius: 10px;
}

.navbar_2023_dropdown_func_event_upcoming_training_search:hover {
    background-color: white;
    color: #006197;
    border: 1px solid #006197;
}

.navbar_2023_dropdown_func_certificate_indiv_img_icon_div {
    position: relative;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 7px solid #E6E6E6;
    background-color: #A5A5A5;
}

@media screen and (max-width: 1350px) {
    .navbar_2023_nav_div_top_1 {
        grid-template-columns: 10% 70%;
    }
}

@media screen and (max-width: 1300px) {
    .navbar_2023_dropdown_func_top_div_2 {
        grid-template-columns: 45% 45%;
    }

    .navbar_2023_dropdown_func_top_div_2_event_pic_div {
        display: none;
    }

    .navbar_2023_membership_dropdown_description_first_div {
        display: none;
    }

    .navbar_2023_dropdown_func_certificate_list_indiv_infite_paradigm {
        display: none;
    }

    .navbar_2023_dropdown_func_top_div_3 {
        grid-template-columns: 45% 45%;
    }
}

@media screen and (max-width: 800px) {
    .navbar_2023_dropdown_func_top_div_2 {
        grid-template-columns: 45% 45%;
    }

    .navbar_2023_dropdown_func_event_upcoming_training_top_overall_div {
        display: none;
    }

    /* .navbar_2023_nav_mobile_mene_top_div {
        min-width: 75%;
    } */
}

@media screen and (max-width: 550px) {

    .navbar_2023_nav_mobile_menu_logo_img_div {
        left: 10px;
    }

    /* .navbar_2023_nav_mobile_mene_top_div {
        min-width: 100%;
    } */
}

.navbar_2023_dropdown_func_certificate_list_indiv_div {
    display: grid;
    grid-template-columns: 15% 80%;
    align-items: center;
    justify-items: left;
    justify-content: space-between;
}


.navbar_2023_nav_mobile {
    display: none;
}

.navbar_2023_dropdown_func_certificate_indiv_title:hover {
    color: #BB2027;
}

@media screen and (max-width: 1300px) {
    .navbar_2023_top_div {
        max-height: 100px;
    }

    .navbar_2023_nav {
        display: none;
    }

    .navbar_2023_nav_mobile {
        display: initial;
    }
}

.navbar_2023_nav_mobile_menu_top_login_close_div {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    /* padding: 0px 50px; */
}

.navbar_2023_nav_mobile_menu_logo_img_div {
    position: absolute;
    right: 20px;
    top: 20px;
    height: 100px;
    width: 100px;
}

.navbar_2023_nav_mobile_logo_responsive {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 50px;
    background-color: white;
}

.navbar_2023_nav_mobile_mene_top_entire_div {
    background-color: white;
}

.navbar_2023_nav_mobile_menu_icon_button {
    border: 0px;
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    right: 50px;
}

.navbar_2023_nav_mobile_logo_img {
    position: relative;
    top: 20px;
    height: 100px;
    width: 100px;
}

.navbar_2023_nav_mobile_mene_top_div {
    width: 65%;
    max-width: 350px;

    box-shadow: 4px 0px 40px rgba(0, 0, 0, 0.15);
}

.navbar_2023_nav_mobile_menu_title {
    background-color: #C4C4C4;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    margin: 0px;
}

.navbar_2023_nav_mobile_menu_top_div {
    position: relative;
    height: 100vmax;
    background-color: white;
}

.navbar_2023_nav_mobile_menu {
    margin: 0px;
    padding: 0px;

}


.navbar_2023_nav_mobile_menu li {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding-left: 20px;
    border-bottom: 1px solid #DDDDDD;
}

.navbar_2023_nav_mobile_menu a {
    text-decoration: none;
    color: black;
    width: 100%;
}

.navbar_2023_nav_mobile_menu_forward_arrow_button {
    border: 0px;
    background-color: #F1F1F1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 100%;
    cursor: pointer;
}

.navbar_2023_nav_mobile_menu_back_arrow {
    position: absolute;
    left: 20px;
    background-color: transparent;
    border: 0px;
    cursor: pointer;
}