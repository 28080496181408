.MembershipCommunityMainInfo_InfoBoxShad {
    box-shadow: 2px 4px 27px rgba(0, 0, 0, 0.1);
    padding: 0px;
    margin-bottom: 10px;
}

.MembershipCommunityInfo_icon {
    size: 55;
}

.MembershipCommunityMainInfo_h1_ {
    font-size: 30px;
}

.MembershipCommunityMainInfo_table1_header {
    margin-left: auto;
    margin-right: auto;
}

.MembershipCommunityMainInfo_virtcal_line_header {
    margin-left: 30px;
    margin-right: 30px;
    border-left: 2px solid #ABABAB;
    height: 70px;
}

.MembershipCommunityMainInfo_p1heading_ {
    text-align: center;
    margin: 5px;
    color: #ffffff;
    font-size: 17px;
    line-height: 1.4;
    padding: 10px;
}

.MembershipCommunityMainInfo_tablesection1_ {
    background-color: #5A5A5A;
    width: 150px;
    border: 0ch;
}

.MembershipCommunityInfo_Infopara_margin {
    display: flex;
    font-size: 16px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 25px;
    align-items: center;
    line-height: 1.8;
}

@media screen and (max-width: 680px) {
    .MembershipCommunityMainInfo_h1_ {
        font-size: 20px;
    }

    .MembershipCommunityMainInfo_virtcal_line_header {
        height: 50px;
    }

    .MembershipCommunityInfo_Infopara_margin {
        font-size: 12px;
    }

    .MembershipCommunityMainInfo_p1heading_ {
        font-size: 14px;
    }

    .MembershipCommunityMainInfo_tablesection1_ {
        width: 100px;
    }
}

@media screen and (max-width: 320px) {
    .MembershipCommunityMainInfo_h1_ {
        font-size: 14px;
    }

    .MembershipCommunityMainInfo_virtcal_line_header {
        display: none;
    }

    .MembershipCommunityInfo_Infopara_margin {
        font-size: 10px;
    }

    .MembershipCommunityMainInfo_p1heading_ {
        font-size: 10px;
    }

    .MembershipCommunityMainInfo_tablesection1_ {
        width: 70px;
    }
}