.memberMileageTable {
    width: 100%;
    /* border: 2px solid; */
    /* border-radius: 5px; */
}

.memberMileageBackNextButtons {
    background-color: white;
    border: 1px solid black;
    padding: 10px 30px;
    cursor: pointer;
}

#memberMileageBackButton {
    margin-right: 10px;
}

.memberMileageTableRow:nth-child(odd) {
    border: 2px solid;
    background-color: #F1F1F1;
}

.memberMileageTableHeader {
    border-bottom: 2px solid gray;
    height: 50px;
    font-size: 17px;
}

.mileagepointsRewardsRowButton {
    background-color: #9e1717;
    color: white;
    min-width: 100px;
    font-size: 15px;
    height: 40px;
    cursor: pointer;
    border: 0px;
}

.memberMileageTableData {
    /* border: 2px solid; */
    text-align: center;
    height: 50px;
}

.memberMileageRequestPointRow {
    border-radius: 10px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;
    font-size: 18px;
}

.mileagepointsRewardsRow {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    align-items: center;
}


.mileagepointsRewardsRowInfo {
    display: flex;
    gap: 15px;
    align-items: center;
}

#memberMileageLinkInputID {
    border: 0px;
    border-bottom: 2px solid rgb(61, 61, 61);
    font-size: 15px;
    margin-left: 20px;
}

#memberMileagePlatformInputID {
    border: 0px;
    border-bottom: 2px solid rgb(61, 61, 61);
    margin-left: 20px;
    font-size: 15px;
    width: 150px;
}

#memberMileageProofInputID {
    margin-left: 20px;
    width: 200px;
}


.FeaturedIntelCardDownloadButton {
    border: 0px;
    padding: 15px 25px;
    font-size: 14px;
}