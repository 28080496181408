.digital_economy_2024_taskforce_top_div {
    box-shadow: 0px 0px 10px rgba(111, 111, 111, 0.351);
}

.digital_economy_2024_taskforce_inner_margin {
    padding: 20px 50px;
}

.digital_economy_2024_taskforce_heading_div {
    width: calc(100% - 100px);
    background-color: #F9F9F9;
}

.digital_economy_2024_taskforce_header_h4 {
    font-size: 32px;
    color: #006197;
    margin: 0px;
}

.digital_economy_2024_taskforce_text_p {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 40px;
}

.digital_economy_2024_taskforce_all_people_div {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 50px;
    flex-wrap: wrap;
}

.digital_economy_2024_taskforce_indiv_people_div {
    position: relative;
    width: 210px;
    height: 250px;
}

.digital_economy_2024_taskforce_indiv_people_detail_div {
    position: absolute;
    bottom: 0px;
    width: 100%;
    background-color: rgb(0, 60, 94, 65%);
    backdrop-filter: blur(2px);
    color: white;
    text-align: center;
}

.digital_economy_2024_taskforce_indiv_people_detail_div p {
    margin: 5px 0px;
}

.digital_economy_2024_taskforce_indiv_people_detail_title_div {
    font-size: 15px;
}

@media screen and (max-width: 700px) {
    .digital_economy_2024_taskforce_header_h4 {
        font-size: 30px;
    }

    .digital_economy_2024_taskforce_heading_div {
        width: calc(100% - 60px);
    }

    .digital_economy_2024_taskforce_inner_margin {
        padding: 20px 30px;
    }

    .digital_economy_2024_taskforce_text_p {
        font-size: 17px;
        line-height: 25px;
    }

    .digital_economy_2024_taskforce_all_people_div {
        justify-content: center;
    }
}

@media screen and (max-width: 500px) {
    .digital_economy_2024_taskforce_header_h4 {
        font-size: 28px;
        line-height: 38px;
    }

    .digital_economy_2024_taskforce_heading_div {
        width: calc(100% - 40px);
    }

    .digital_economy_2024_taskforce_inner_margin {
        padding: 20px 20px;
    }
}