.Podcasts_guests_header_2_parters_img {
    position: relative;
    width: 100%;
    aspect-ratio: 2.7;
}

@media screen and (max-width: 850px) {
    .podcast_page_image_podcast {
        display: none;
    }
}

@media screen and (max-width: 700px) {
    .podcast_page_title_podcast {
        font-size: 17px;
    }

    .podcast_page_duration_podcast {
        display: none;
    }
}

@media screen and (max-width: 500px) {
    .podcast_page_more_button_podcast {
        display: none;
    }

    .podcast_page_date_day_podcast {
        font-size: 24px;
    }

    .podcast_page_season_podcast {
        font-size: 14px;
    }
}

@media screen and (max-width: 400px) {
    .podcast_page_date_podcast {
        display: none;
    }

    .podcast_page_title_podcast {
        font-size: 16px;
    }
}

.membership_landing_exclusive_events_header_div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.membership_landing_exclusive_events_header_img {
    min-width: 80px;
    width: 100px;
}

.membership_landing_exclusive_events_header_divider_line {
    border-right: 2px solid gray;
    height: 100px;
    margin-right: 50px;
    margin-left: 30px;
}

.membership_landing_exclusive_events_header_text h2 {
    font-size: 30px;
}

.membership_landing_exclusive_events_individual_a {
    box-shadow: 0px 0px 41.3189px rgba(0, 0, 0, 0.31);
    width: 250px;
    text-decoration: none;
}

.membership_landing_exclusive_events_all_events {
    margin-top: 40px;
    padding-bottom: 40px;
    display: grid;
    grid-template-columns: 30% 30% 30%;
    justify-content: space-between;
    gap: 20px;
}

.membership_landing_exclusive_events_all_text_div {
    padding: 10px 30px;
}

.membership_landing_exclusive_events_individual_text {
    display: flex;
    gap: 10px;
    align-items: center;
}

.membership_landing_exclusive_events_individual_text p {
    margin: 7px 0px;
}

@media screen and (max-width: 1120px) {
    .membership_landing_exclusive_events_all_events {
        grid-template-columns: 40% 40%;
    }
}

@media screen and (max-width: 650px) {
    .membership_landing_exclusive_events_all_events {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

    }
}