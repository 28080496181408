.list_of_notifications_individual_div {
    padding: 10px;
    cursor: pointer;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid gray;
    border-top: 1px solid gray;
}

.list_of_notifications_individual_photo_div {
    min-width: 50px;
}

.list_of_notifications_individual_name {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list_of_notifications_individual_unread_dot {
    height: 10px;
    width: 10px;
    background-color: rgb(8, 128, 2);
    border-radius: 50%;
    display: inline-block;
}