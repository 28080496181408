.news_page_div_margin {
    margin: 150px 150px;
    margin-bottom: 50px;
}

.news_journal_top_new_img {
    position: relative;
    width: 100%;
    height: 300px;
}


@media screen and (max-width: 1250px) {
    .news_page_div_margin {
        margin: 150px 80px;
    }
}

@media screen and (max-width: 700px) {
    .news_page_div_margin {
        margin: 150px 50px;
    }

    .news_journal_top_img {
        display: none;
    }
}

@media screen and (max-width: 400px) {
    .news_page_div_margin {
        margin: 150px 20px;
    }

    .news_journal_top_img {
        display: none;
    }
}