.About_our_courses_Cloud{
    background-image: url("../../../resources/about_our_courses_bg.png");
    height: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    flex-direction: row;
}


#aboutOurCourses_CloudTraining_video{
    order: 2;
    width: 40%;
    height: 320px;
    min-width: 400px;
    max-width: 600px
}

.about_our_courses_text_Cloud_training{
    order: 1;
    width: 30%;
    font-size: 16px;
    margin-right: 50px;
    min-width: 500px;
    color: #303030;
}
.Cloud_training_about_our_courses_small_header{
    font-size: 20px;
    color: black;
}


.Cloud_about_our_courses_large_header{
    font-size: 25px;
}


.checkBoxCloudTraining{
    width: 12px;
}

.header-line{
    padding-bottom: 4px;
}

@media screen and (max-width: 1200px) {
    .About_our_courses_Cloud{
        justify-content: center;
    }

}


@media screen and (max-width: 1120px) {
   
.about_our_courses_text_Cloud_training{
    margin-left: 0px;
}


    #aboutOurCourses_CloudTraining_video{
        margin-right: 50px;
        margin-left: 50px;
        width: 550px;
    }

    
    .About_our_courses_Cloud{
    height: 700px;
    width: 100%;
    }
    
    .data-center-training-large-header{
    text-align: center;
    }
    
    .data_center_training_small_header{
    display: none;
    }

}

@media screen and (max-width: 675px) {
    #aboutOurCourses_CloudTraining_video{
        width: 550px;
        margin-right: 0px;
        margin-left: 0px;
    }
} 

@media screen and (max-width: 572px) {

    #aboutOurCourses_CloudTraining_video{
        width: 90%;
        height: 250px;
        margin-right: 0px;
        margin-left: 0px;
    }
    
    .about_our_courses_text_Cloud_training{
        order: 1;
        width: 90%;
        font-size: 16px;
        /* text-align: center; */
        color: #303030;
        min-width: 0px;
        margin-right: 0px;
        padding: 0px;
    }
   
} 

@media screen and (max-width: 437px) {

    #aboutOurCourses_CloudTraining_video{
        min-width: 0px;
        height: 200px;
    }
    
    .about_our_courses_text_Cloud_training{
        order: 1;
        width: 90%;
        font-size: 15px;
        color: #303030;
        min-width: 0px;
        margin-right: 0px;
        padding: 0px;
    }

    .checkBoxCloudTraining{
        font-size: 14px;
    }
   
} 
