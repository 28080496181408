.course_detail_indiv_div {
    display: flex;
    justify-content: space-between;
}

.course_detail_title_p {
    color: #006197;
}

.course_detail_header_p {
    font-size: 20px;
    margin-top: 40px;
}

.course_detail_divider_div {
    border-bottom: 1px solid rgba(128, 128, 128, 0.564);
    width: 100%;
}

.course_detail_indiv_register_now_p {
    background-color: #006197;
    border: 1px solid #006197;
    padding: 10px 20px;
    transition: 0.2s;
}

.course_detail_indiv_register_now_p:hover {
    background-color: white;
    color: #006197;
    padding: 10px 20px;
}