.user_dashboard_home_profile_div {
    padding: 40px 50px;
    box-shadow: 0px 0px 15px #6f6f6f57;
    border-radius: 8px;
}

.user_dashboard_home_profile_top_img_div {
    position: relative;
    width: 120px;
    height: 120px;
}

.user_dashboard_home_profile_top_img {
    border-radius: 50%;
}

.user_dashboard_home_profile_top_img_upload_div {
    color: #B3B3B3;
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
}

.user_dashboard_home_profile_top_img_upload_p {
    color: #B3B3B3;
    font-size: 15px;
}

.user_dashboard_home_profile_top_info_div {
    position: relative;
    display: flex;
    gap: 50px;
    align-items: center;
}

.user_dashboard_home_profile_top_name {
    font-size: 30px;
    line-height: 38px;
    font-weight: bold;
    margin: 0px;
}

.user_dashboard_home_profile_top_company {
    font-size: 28px;
    line-height: 38px;
    font-weight: bold;
    margin: 10px 0px;
    margin-top: 15px;
    color: #BB2027;
}

.user_dashboard_home_profile_top_title {
    font-size: 22;
    margin: 0px;
    color: #606060;
}

.user_dashboard_home_profile_divider {
    border-left: 1px solid gray;
    height: 50px;
}

.user_dashboard_home_profile_certifs_div {
    display: flex;
    background-color: #F1F1F1;
    width: fit-content;
    padding: 10px 20px;
    border-radius: 10px;
}

.user_dashboard_home_profile_certifs_img {
    height: 70px;
    width: 70px;
}

.user_dashboard_home_profile_top_detail_divider {
    margin: 20px auto;
    border-top: 1px solid #60606057;
    width: 90%;
}

.user_dashboard_home_profile_top_show_more_less {
    position: absolute;
    right: 0px;
    bottom: -15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 15px;
}

#user_dashboard_home_profile_top_show_more_less_icon {
    box-shadow: 0px 0px 5px #60606057;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s;
}

#user_dashboard_home_profile_detail_id {
    display: none;
}

.user_dashboard_home_profile_detail_line_div {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

#user_dashboard_home_profile_detail_input_company {
    width: 350px;
}

#user_dashboard_home_profile_detail_input_title {
    width: 350px;
}

.user_dashboard_home_profile_detail input {
    border: 0px;
    border-radius: 5px;
    height: 35px;
    width: 225px;
    padding: 0px 15px;
    font-size: 16px;
    font-family: Saira;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.318);
}

.user_dashboard_home_profile_detail p {
    color: #606060;
}

.user_dashboard_home_profile_top_detail_save_div {
    margin-left: 50px;
    cursor: pointer;
    text-decoration: underline;
    transition: .3s;
}

.user_dashboard_home_profile_top_detail_save_div:hover {
    color: #7c7c7c;
}

.user_dashboard_home_profile_detail_reset_password {
    border: 0px;
    border-radius: 5px;
    height: 35px;
    width: 265px;
    font-size: 16px;
    font-family: Saira;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.318);

    transition: .3s;
    cursor: pointer;

    /* background-color: white; */
}

.user_dashboard_home_profile_detail_reset_password:hover {
    background-color: white;
}

#user_dashboard_home_profile_password_reset {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: green;
    padding: 50px;
    background-color: white;
    border-radius: 10px;
}


@media screen and (max-width: 1650px) {
    .user_dashboard_home_profile_top_img_div {
        width: 100px;
        height: 100px;
    }

    .user_dashboard_home_profile_top_img_upload_div {
        width: 120px;
    }

    .user_dashboard_home_profile_top_img_upload_p {
        width: 100px;
    }

    .user_dashboard_home_profile_top_name {
        font-size: 27px;
        line-height: 35px;
    }

    .user_dashboard_home_profile_top_company {
        font-size: 24px;
        line-height: 35px;
    }

    .user_dashboard_home_profile_certifs_img {
        height: 60px;
        width: 60px;
    }

    .user_dashboard_home_profile_top_title {
        font-size: 20;
    }

    .user_dashboard_home_profile_detail input {
        width: 200px;
        font-size: 15px;
    }

    .user_dashboard_home_profile_detail_reset_password {
        width: 220px;
        font-size: 15px;
    }

    #user_dashboard_home_profile_detail_input_company {
        width: 300px;
    }

    #user_dashboard_home_profile_detail_input_title {
        width: 300px;
    }
}


@media screen and (max-width: 1350px) {
    .user_dashboard_home_profile_top_info_div {
        gap: 30px;
    }

    .user_dashboard_home_profile_top_img_div {
        width: 90px;
        height: 90px;
    }

    .user_dashboard_home_profile_top_img_upload_div {
        width: 100px;
    }

    .user_dashboard_home_profile_top_img_upload_p {
        width: 100px;
        font-size: 13px;
    }

    .user_dashboard_home_profile_top_name {
        font-size: 22px;
        line-height: 30px;
    }

    .user_dashboard_home_profile_top_company {
        font-size: 22px;
        line-height: 30px;
    }

    .user_dashboard_home_profile_certifs_img {
        height: 50px;
        width: 50px;
    }

    .user_dashboard_home_profile_top_title {
        font-size: 16px;
    }

    .user_dashboard_home_profile_detail input {
        width: 150px;
        font-size: 14px;
    }

    .user_dashboard_home_profile_detail_reset_password {
        width: 170px;
        font-size: 14px;
    }

    #user_dashboard_home_profile_detail_input_company {
        width: 240px;
    }

    #user_dashboard_home_profile_detail_input_title {
        width: 240px;
    }
}

@media screen and (max-width: 1200px) {
    .user_dashboard_home_profile_detail input {
        width: 145px;
    }

    .user_dashboard_home_profile_detail_reset_password {
        width: 160px;
    }
}

@media screen and (max-width: 1020px) {
    .user_dashboard_home_profile_top_info_div {
        gap: 20px;
    }

    .user_dashboard_home_profile_top_img_div {
        width: 75px;
        height: 75px;
    }

    .user_dashboard_home_profile_top_img_upload_div {
        width: 100px;
    }

    .user_dashboard_home_profile_top_img_upload_p {
        width: 120px;
        font-size: 12px;
        line-height: 15px;
    }

    .user_dashboard_home_profile_top_name {
        font-size: 22px;
        line-height: 28px;
    }

    .user_dashboard_home_profile_top_company {
        font-size: 20px;
        line-height: 27px;
        margin-top: 10px;
    }

    .user_dashboard_home_profile_certifs_div {
        padding: 10px 13px;
    }

    .user_dashboard_home_profile_certifs_img {
        height: 45px;
        width: 45px;
    }

    .user_dashboard_home_profile_top_title {
        font-size: 15px;
    }

    .user_dashboard_home_profile_detail input {
        width: 135px;
        font-size: 14px;
    }

    .user_dashboard_home_profile_detail_reset_password {
        width: 150px;
        font-size: 14px;
    }

    #user_dashboard_home_profile_detail_input_company {
        width: 220px;
    }

    #user_dashboard_home_profile_detail_input_title {
        width: 220px;
    }
}

@media screen and (max-width: 900px) {
    .user_dashboard_home_profile_div {
        padding: 40px 35px;
    }

    .user_dashboard_home_profile_top_info_div {
        flex-wrap: wrap;
        align-items: flex-start;
    }

    .user_dashboard_home_profile_top_img_upload_all_div {
        width: 30%;
    }

    .user_dashboard_home_profile_top_personal_info_div {
        width: 60%;
    }

    .user_dashboard_home_profile_divider {
        display: none;
    }

    .user_dashboard_home_profile_top_img_div {
        width: 80px;
        height: 80px;
    }


    .user_dashboard_home_profile_top_img_upload_div {
        width: 100px;
    }

    .user_dashboard_home_profile_certifs_div {
        padding: 10px 17px;
    }

    .user_dashboard_home_profile_certifs_img {
        height: 50px;
        width: 50px;
    }

    .user_dashboard_home_profile_detail_line_div {
        flex-direction: column;
        justify-content: space-between;
    }

    .user_dashboard_home_profile_detail input {
        width: 60%;
        font-size: 14px;
    }

    #user_dashboard_home_profile_detail_input_company {
        width: 60%;
    }

    #user_dashboard_home_profile_detail_input_title {
        width: 60%;
    }
}

@media screen and (max-width: 490px) {
    .user_dashboard_home_profile_top_info_div {
        gap: 40px;
    }

    .user_dashboard_home_profile_top_img_upload_all_div {
        width: 25%;
    }

    .user_dashboard_home_profile_top_personal_info_div {
        width: 60%;
    }

    .user_dashboard_home_profile_div {
        padding: 40px 40px;
    }

    .user_dashboard_home_profile_top_name {
        font-size: 22px;
        line-height: 28px;
    }

    .user_dashboard_home_profile_top_company {
        font-size: 20px;
        line-height: 27px;
        margin-top: 10px;
    }

    .user_dashboard_home_profile_certifs_div {
        padding: 10px 13px;
    }

    .user_dashboard_home_profile_detail input {
        width: 85%;
    }

    .user_dashboard_home_profile_detail_reset_password {
        width: 150px;
    }

    #user_dashboard_home_profile_detail_input_company {
        width: 85%;
    }

    #user_dashboard_home_profile_detail_input_title {
        width: 85%;
    }
}

@media screen and (max-width: 390px) {
    .user_dashboard_home_profile_top_info_div {
        gap: 30px;
    }

    .user_dashboard_home_profile_top_img_div {
        width: 75px;
        height: 75px;
    }

    .user_dashboard_home_profile_top_img_upload_div {
        width: 80px;
    }

    .user_dashboard_home_profile_top_img_upload_p {
        width: 80px;
        font-size: 10px;
        line-height: 15px;
    }

    .user_dashboard_home_profile_top_img_upload_all_div {
        width: 25%;
    }

    .user_dashboard_home_profile_top_personal_info_div {
        width: 60%;
    }
}