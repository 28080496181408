.latam_cup_top_div {
    height: 480px;
    background-image: url("../../../resources/LATAMHEADERIMAGE.png");
    background-size: cover;
    background-position: right;
    margin-bottom: 15px;
}

@media screen and (max-width: 1550px) {
    .latam_cup_top_div {
        height: 420px;
    }
}

@media screen and (max-width: 1250px) {
    .latam_cup_top_div {
        height: 360px;
    }
}

@media screen and (max-width: 850px) {
    .latam_cup_top_div {
        height: 345px;
    }
}


@media screen and (max-width: 380px) {
    .latam_cup_top_div {
        height: 320px;
    }
}