.about-cloud-training-and-contact-training-icons-img {
    position: relative;
    width: 100px;
    height: 100px;
}

.about-cloud-training-and-contact {
    display: flex;
    /* margin-left: 10%;
    margin-right: 10%; */
}

.about-cloud-training-and-contact-training-div {
    margin-right: 7%;
}

.about-cloud-training-and-contact-form-div {
    margin-top: 20px;
    margin-bottom: 20px;
}

.about-cloud-training-and-contact-training-icons-div {
    margin-top: 50px;
    margin-bottom: 50px;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: 175px 175px;
    grid-template-areas:
        "L1 M1 R1"
        "L2 M2 R2";
    text-align: center;
    justify-content: center;
}

.about-cloud-training-and-contact-training-icon-inside-div {
    width: 150px;

}

.about-cloud-training-and-contact-training-chiller-icon-div {
    grid-area: L1;
}

.about-cloud-training-and-contact-training-operation-icon-div {
    grid-area: M1;
}

.about-cloud-training-and-contact-training-efficiency-icon-div {
    grid-area: R1;
}

.about-cloud-training-and-contact-training-security-icon-div {
    grid-area: L2;
}

.about-cloud-training-and-contact-training-availability-icon-div {
    grid-area: M2;
}

.about-cloud-training-and-contact-training-structure-cabling-icon-div {
    grid-area: R2;
}

.about-cloud-training-and-contact-training-icon-text1 {
    /* line-height: 17px; */
    margin-top: 10px;
    margin-bottom: 12px;
}

.about-cloud-training-and-contact-training-icon-text2 {
    line-height: 18px;
    margin-top: 0px;
    margin-bottom: 0px;
}


@media screen and (max-width: 1200px) and (min-width: 1000px) {
    .about-cloud-training-and-contact-training-icons-div {
        grid-template-columns: 50% 50%;
        grid-template-rows: 180px 180px 180px;
        grid-template-areas:
            "L1 R1"
            "L2 R2"
            "L3 R3";
    }

    .about-cloud-training-and-contact-training-chiller-icon-div {
        grid-area: L1;
    }

    .about-cloud-training-and-contact-training-operation-icon-div {
        grid-area: R1;
    }

    .about-cloud-training-and-contact-training-efficiency-icon-div {
        grid-area: L2;
    }

    .about-cloud-training-and-contact-training-security-icon-div {
        grid-area: R2;
    }

    .about-cloud-training-and-contact-training-availability-icon-div {
        grid-area: L3;
    }

    .about-cloud-training-and-contact-training-structure-cabling-icon-div {
        grid-area: R3;
    }
}

@media screen and (max-width: 1000px) {
    .about-cloud-training-and-contact {
        flex-direction: column;
    }

    .about-cloud-training-and-contact-form-div {
        margin: auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 550px) {
    .about-cloud-training-and-contact-training-icons-div {
        grid-template-columns: 50% 50%;
        grid-template-rows: 190px 190px 190px;
        grid-template-areas:
            "L1 R1"
            "L2 R2"
            "L3 R3";
    }

    .about-cloud-training-and-contact-training-chiller-icon-div {
        grid-area: L1;
    }

    .about-cloud-training-and-contact-training-operation-icon-div {
        grid-area: R1;
    }

    .about-cloud-training-and-contact-training-efficiency-icon-div {
        grid-area: L2;
    }

    .about-cloud-training-and-contact-training-security-icon-div {
        grid-area: R2;
    }

    .about-cloud-training-and-contact-training-availability-icon-div {
        grid-area: L3;
    }

    .about-cloud-training-and-contact-training-structure-cabling-icon-div {
        grid-area: R3;
    }
}

@media screen and (max-width: 385px) {
    .about-cloud-training-and-contact-training-icons-div {
        grid-template-rows: 200x 200px 200px;
    }

    .about-cloud-training-and-contact-training-icon-inside-div {
        width: 100px;
    }
}