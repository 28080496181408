.Upcoming_Podcasts_Whole_Div{
    background-image: url("../../../resources/Upcoming_Podcast_bg.png");
    background-size: cover;
    background-position: center;
    height: fit-content;
    padding-top: 20px;
    padding-bottom: 30px;
    margin-bottom: 50px;
}

.Upcoming_Podcasts_header_1{
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 20px;
    margin-bottom: 10px;
    justify-content: center;
}
.Upcoming_Podcasts_header_2{
    font-size: 30px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.Upcoming_Podcasts_next_img{
    width: 20%;
    box-shadow: rgb(71, 71, 71) 0px 4px 12px;
}

.Upcoming_Podcasts_next_desc{
    width: 50%;
}

.Upcoming_Podcasts_next_div{
    margin-left: 50px;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content:center
}

.Upcoming_Podcasts_ComingSoon_Div{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto;
    height: 200px;
    background-color: white;
    width: 50%;
    justify-content: center;
    box-shadow: rgb(71, 71, 71) 0px 4px 12px;
    margin-top: 70px;
}
.Upcoming_Podcasts_ComingSoon_Text{
text-align: center;
font-size: 30px;
line-height: 50px;
padding-right: 20px;
padding-left: 20px;
}

.Upcoming_Podcasts_ComingSoon_Pic{
    display: flex;
    justify-content: center;
    align-items: center;
}



@media screen and (max-width: 1200px) {
    .Upcoming_Podcasts_next_desc{
        width: 100%;
    }

    .Upcoming_Podcasts_next_img{
        width: 50%;
    }
    
    .Upcoming_Podcasts_next_div{
        margin-left: 50px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 0px;
        justify-content:center;
        align-items: center;
        text-align: center;
        padding: 0px;
        margin: 30px;
    }
 }

@media screen and (max-width: 1061px) {
   .Upcoming_Podcasts_ComingSoon_Text{
       font-size: 25px;
   }
}

@media screen and (max-width: 890px) {
    .Upcoming_Podcasts_ComingSoon_Text{
        font-size: 20px;
    }
   }

@media screen and (max-width: 731px) {
    .Upcoming_Podcasts_ComingSoon_Div{

        width: 80%;
 
    }
}

@media screen and (max-width: 382px) {
    .Upcoming_Podcasts_ComingSoon_Div{

        width: 85%;
 
    }

    .Upcoming_Podcasts_ComingSoon_Text{
        font-size: 18px;
    }
}

@media screen and (max-width: 300px) {
    .Upcoming_Podcasts_ComingSoon_Div{

        width: 90%;
 
    }

    .Upcoming_Podcasts_ComingSoon_Text{
        font-size: 17px;
    }
}