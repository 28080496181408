.individual_event_top_description_div_top{
    position: relative;
    top: -150px;
}
.individual_event_top_description_div{
    width: 70%;
    margin: auto;
    padding: 3% 3%;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
    background-color: white;
}
.individual_event_page_seasts_remaining{
    position: relative;
    left: 75%;
    margin: 0px;
box-shadow: inset 4px 4px 20px 2px rgba(0, 0, 0, 0.4);
text-align: center;
padding: 7px 5%;
width: fit-content;
}
.individual_event_page_seasts_remaining_none{
    background: #AD2225;
}
.individual_event_page_seasts_remaining_some{
    background: #CCAA52;
}

.cindividual_event_top_description_div_text{
    margin-top: 5%;
}

.cindividual_event_top_description_div_img{
    max-width: 300px;
    min-width: 300px;
}

@media screen and (max-width: 1330px) {
.individual_event_top_description_div{
    width: 77%;
}
}
@media screen and (max-width: 1250px) {
.individual_event_top_description_div{
    width: 85%;
}
}
@media screen and (max-width: 1100px) {
.individual_event_top_description_div{
    width: 92%;
}
}
@media screen and (max-width: 1030px) {
.individual_event_top_description_div{
    width: 100%;
}
.cindividual_event_top_description_div_text{
    font-size: 15px;
}
}
@media screen and (max-width: 900px) {
.individual_event_top_description_div{
    flex-direction: column;
}
.cindividual_event_top_description_div_img{
 align-self: center;
}
}
@media screen and (max-width: 900px) {
.individual_event_top_description_div{
    flex-direction: column;
}
.cindividual_event_top_description_div_img{
 align-self: center;
}
}
@media screen and (max-width: 750px) {
.individual_event_top_description_div{
    padding: 5% 5%;
    left: -20px;
}
}
@media screen and (max-width: 750px) {
.individual_event_top_description_div{
    left: -20px;
}
}
@media screen and (max-width: 650px) {
.individual_event_top_description_div{
    top: -100px;
    width: 90%;
    left: 0px;
}
}
@media screen and (max-width: 500px) {
.individual_event_top_description_div{
    width: 90%;
    left: -5px;
    padding: 6%;
}
}
@media screen and (max-width: 400px) {
.individual_event_top_description_div{
    top: -50px;
}
}
