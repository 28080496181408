
.Idca_news_link_to_indiv_news{
    text-decoration: none;
    color: unset;
}

@media screen and (max-width:950px) {
    .Idca_news_header_component{
        font-size: 20px;
    }
    
    .Idca_news_title_component{
        font-size: 15px;
    }
    
}

@media screen and (max-width:822px) {
    .Idca_news_header_component{
        font-size: 18px;
    }
    
    .Idca_news_title_component{
        font-size: 13px;
    } 
    .idca-news-component-date{
        font-size: 12px;
    }
}

@media screen and (max-width:890px) {
    .Idca_news_header_component{
        font-size: 19px;
    }
    
    .Idca_news_title_component{
        font-size: 13px;
    } 
    .idca-news-component-date{
        font-size: 12px;
    }
}
