.navbar_2023_dropdown_func_event_upcoming_event_div {
    display: flex;
    gap: 15px;
    /* align-items: center; */
    background-color: #DDDDDD;
}

.navbar_2023_dropdown_func_event_upcoming_event_a_tag {
    text-decoration: none;
}

.navbar_2023_dropdown_func_event_upcoming_event_div_text h4 {
    line-height: 5px;
}

.navbar_2023_dropdown_func_event_dig_univ_img_header {
    display: flex;
    gap: 20px;
    align-items: center;
}

.navbar_2023_dropdown_func_event_dig_univ_img_icon_div_big {
    width: 70px;
    height: 70px;
    background: linear-gradient(129.68deg, #0B3296 24.44%, #B90B13 89.21%);
    border-radius: 50%;
    border: 7px solid #E6E6E6;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar_2023_dropdown_func_event_dig_univ_img_icon_div_small {
    position: relative;
    width: 50px;
    height: 50px;
}

.navbar_2023_dropdown_func_event_dig_univ_events_studio_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.navbar_2023_dropdown_func_event_podcast_episode_indiv_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: #F1F1F1;
}

.navbar_2023_dropdown_func_event_indiv_ep_white {
    background-color: white;
}

.navbar_2023_dropdown_func_event_podcast_episode_indiv_div p {
    margin: 10px 0px;
}

.navbar_2023_dropdown_func_top_div_4 {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    justify-content: space-between;
}