.digital_economy_2024_about_all_div {
    margin-top: 70px;
    display: flex;
    gap: 150px;
}

.digital_economy_2024_about_title_div {
    display: flex;
    gap: 100px;
    align-items: center;
}

.digital_economy_2024_about_title_div h3 {
    font-size: 38px;
    color: #959FB2;
    min-width: fit-content;
    margin-bottom: 20px;
    margin-top: 0px;
}

.digital_economy_2024_about_title_div div {
    width: 100%;
    border-bottom: 1px solid #959FB2;
}

.digital_economy_2024_about_text_div p {
    font-size: 20px;
    line-height: 30px;
}

.digital_economy_2024_about_image_carousel {
    width: 520px;
    /* height: 500px; */
}

.digital_economy_2024_about_image_carousel_img_text_outer_div {
    padding: 10px;
}

.digital_economy_2024_about_image_carousel_img_text_div {
    border-radius: 15px;
    box-shadow: 0px 0px 5px rgba(57, 57, 57, 0.247);
    overflow: hidden;
}

.digital_economy_2024_about_image_carousel_div {
    width: 800px;
}

.digital_economy_2024_about_image_carousel_img_text_div {
    width: 500px;
    height: 420px;
}

.digital_economy_2024_about_image_carousel_text {
    width: 100%;
    background-color: #F4F4F4;
    color: #006197;
    margin: 0px;
    padding: 10px;
    font-size: 20px;
}

.digital_economy_2024_about_image_carousel_img_div {
    position: relative;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1450px) {
    .digital_economy_2024_about_all_div {
        gap: 70px;
    }

    .digital_economy_2024_about_title_div div {
        display: none;
    }

    .digital_economy_2024_about_image_carousel {
        width: 470px;
    }

    .digital_economy_2024_about_image_carousel_div {
        width: 600px;
    }

    .digital_economy_2024_about_image_carousel_img_text_div {
        width: 450px;
        height: 380px;
    }
}

@media screen and (max-width: 1200px) {
    .digital_economy_2024_about_all_div {
        gap: 20px;
        flex-direction: column;
        align-items: center;
    }

    .digital_economy_2024_about_title_div div {
        display: initial;
    }

    .digital_economy_2024_about_image_carousel {
        width: 520px;
    }

    .digital_economy_2024_about_image_carousel_div {
        width: 520px;
    }

    .digital_economy_2024_about_image_carousel_img_text_div {
        width: 500px;
        height: 420px;
    }
}

@media screen and (max-width: 700px) {
    .digital_economy_2024_about_title_div div {
        display: none;
    }

    .digital_economy_2024_about_image_carousel {
        width: 470px;
    }

    .digital_economy_2024_about_image_carousel_div {
        width: 470px;
    }

    .digital_economy_2024_about_image_carousel_img_text_div {
        width: 450px;
        height: 380px;
    }

    .digital_economy_2024_about_title_div h3 {
        font-size: 34px;
        margin-bottom: 10px;
    }

    .digital_economy_2024_about_text_div p {
        font-size: 18px;
        line-height: 27px;
    }
}

@media screen and (max-width: 500px) {
    .digital_economy_2024_about_image_carousel {
        width: 360px;
    }

    .digital_economy_2024_about_image_carousel_div {
        width: 360px;
    }

    .digital_economy_2024_about_image_carousel_img_text_div {
        width: 340px;
        height: 300px;
    }

    .digital_economy_2024_about_image_carousel_text {
        font-size: 16px;
    }

    .digital_economy_2024_about_title_div h3 {
        font-size: 31px;
    }
}