.Cloud-training-top-div {
  height: 500px;
  /* background-image: url("../../../resources/CloudTrainingTop.svg");
  background-size: cover;
  background-position: center; */
  background-position-y: top;
  margin-bottom: 15px;
}

.cloud_training_top_bg_img_div {
  position: absolute;
  overflow: hidden;
  z-index: -1;
  width: 100%;
  height: 500px;
}

.Cloud-training-breadcrumb {
  padding-left: 0px;
  margin-left: 10%;
  display: flex;
  flex-wrap: wrap;
}

/* Style the list */
ul.Cloud-training-breadcrumb {
  margin-bottom: 30px;

}

/* Display list items side by side */
ul.Cloud-training-breadcrumb li {
  display: inline;
  font-size: 14px;

}

/* Add a slash symbol (/) before/behind each list item */
ul.Cloud-training-breadcrumb li+li:before {
  padding: 8px;
  color: black;
  content: "/\00a0";

}

/* Add a color to all links inside the list */
ul.Cloud-training-breadcrumb li a {
  color: #AD2225;
  text-decoration: none;

}

/* Add a color on mouse-over */
ul.Cloud-training-breadcrumb li a:hover {
  /* color: #01447e; */
  opacity: 0.7;
  text-decoration: underline;
}