.data_center_training_top_4_events_div{
    display: grid;
    grid-template-columns: 23% 23% 23% 23%;
    gap: 2.5%;
    justify-content: space-between;
    margin-top: 100px;
}

@media screen and (max-width: 1250px) {
    .data_center_training_top_4_events_div{
    grid-template-columns: 33% 33% 33%;
}
#data_center_training_top_4_individual_div_3{
    display: none;
}

}
@media screen and (max-width: 920px) {
    .data_center_training_top_4_events_div{
    grid-template-columns: 40% 40%;
    justify-content: space-between;
    gap: 3%
}
#data_center_training_top_4_individual_div_2{
    display: none;
}
}

@media screen and (max-width: 850px) {
    .data_center_training_top_4_events_div{
    grid-template-columns: 45% 45%;
}
}

@media screen and (max-width: 680px) {
    .data_center_training_top_4_events_div{
    grid-template-columns: 48% 48%;
}
}

@media screen and (max-width: 470px) {
    .data_center_training_top_4_events_div{
    grid-template-columns: 70%;
    justify-content: center;
}
}

@media screen and (max-width: 350px) {
    .data_center_training_top_4_events_div{
    grid-template-columns: 80%;
    justify-content: center;
}
}