.individual_course_2_syllabus_day_title_div {
    margin-top: 20px;
    margin-bottom: 15px;
    background-color: #F4F4F4;
    padding: 0px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.individual_course_2_syllabus_day_title_div p {
    line-height: 0px;
}