.footer_whole_div_bg{
    background-color: #303030;
    width: 100%;
}

.footer_whole_div{
    display: flex;
    flex-wrap: wrap;
    padding-top: 80px;
    padding-bottom: 80px;
    justify-content: space-between;
}

/* Left Div */
.footer_left_div{
    width: 400px;
    height: 100%;
    margin-right: 30px;
}

.footer_all_social_media{
    display: flex;
    gap: 10px;
}

/* Middle Div */
.footer_middle_div{
    width: 210px;
    text-align: left;
    height: 100%;
}

.footer_middle_2_div{
    width: 170px;
    text-align: left;
}

.footer_links_div{
    text-decoration: none;
    color: white;
    font-size: 16px;
}

/* Right Div */
.footer_right_div{
    width: 200px;
    height: 100%;
    margin-left: 0px;
}

.footer_contact_us_indiv{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}

.footer_contact_right_div_info{
    display: flex;
    flex-direction: column;
}

.footer_contact_us_indiv_address{
    width: 100%;
}

.footer_copyRight{
    width: 100%;
    height: 66px;
    background-color: #212121;
    display: flex;
    justify-content: center;
    align-items: center;
}


@media screen and (max-width: 1262px) {
    .footer_contact_right_div_info{
        flex-direction: row;
        gap: 20px;
        justify-content: center;
    }
    .footer_right_div{
        width: 100%;
        height: 100%;
        margin-left: 0px;
        padding-top: 50px;

    }
    .footer_right_div_h4_contact_us{
        display: none;
    }
}

@media screen and (max-width: 1005px) {
    .footer_contact_right_div_info{
        flex-direction: column;
        gap: unset;
        justify-content: left;
    }
    .footer_right_div{
        width: 200px;
        height: 100%;
        margin-left: 0px;
        padding-top: 0px;
    }
    .footer_right_div_h4_contact_us{
        display: block;
    }
}

@media screen and (max-width: 724px) {
    .footer_contact_right_div_info{
        flex-direction: row;
        gap: 40px;
        justify-content: center;
    }
    .footer_right_div{
        width: 100%;
        height: 100%;
        margin-left: 0px;
        padding-top: 50px;

    }
    .footer_right_div_h4_contact_us{
        display: none;
    }

    .footer_contact_us_indiv_address{
        display: none;
    }
    .footer_contact_us_indiv_tel{
        display: none;
    }
    .footer_contact_us_indiv_email{
        display: none;
    }
}
@media screen and (max-width: 423px) {
    .footer_all_social_media{
        justify-content: center;
        gap: 40px;
    }
}
