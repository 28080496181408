.standard_page_esr_ESR_h3 {
    width: 75%;
    font-size: 30px;
}

.standard_page_esr_all_div p {
    font-size: 16px;
}


@media screen and (max-width: 1200px) {
    .standard_page_esr_ESR_h3 {
        width: 80%;
        font-size: 30px;
    }

    .standard_page_esr_all_div p {
        font-size: 15px;
    }
}

@media screen and (max-width: 1000px) {
    .standard_page_esr_ESR_h3 {
        font-size: 28px;
        line-height: 35px;
    }

    .standard_page_esr_all_div p {
        font-size: 15px;
    }
}

@media screen and (max-width: 800px) {
    .standard_page_esr_ESR_h3 {
        width: 100%;
    }

    .standard_page_esr_all_div p {
        font-size: 14px;
    }

    .standard_page_esr_all_div img {
        /* transform: rotate(90deg); */
    }
}