
/* Style the list */
ul.about_us_top_nav {
    width: 100%;
    /* margin: auto; */
  text-align: center;
}

/* Display list items side by side */
ul.about_us_top_nav li {
  display: inline-block;
  font-size: 16px;
  margin-left: 32px;
  margin-right: 32px;
}

/* Add a color to all links inside the list */
ul.about_us_top_nav li a {
  color: #303030;
  text-decoration: none;
}

/* Add a color on mouse-over */
ul.about_us_top_nav li a:hover {
  display: initial;
  color: #AD2225;
}

#about_us_top_nav_line_id{
  margin-left: 25%;
  margin-right: 25%;
    width: 100%;
    height: 1px;
    border: black;
}

@media screen and (max-width: 640px) {
#about_us_top_nav_id{
  display: none;

}
}

#about_us_top_nav_id{
  z-index: 3;
}