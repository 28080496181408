.ContactUsPage_Form_Whole_Page_Div {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px;
    margin: 0px;
    gap: 30px;
}

.ContactUsPage_Form_Whole_Div_outer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ContactUsPage_Form_Whole_Div {
    box-shadow: 2px 4px 27px rgba(0, 0, 0, 0.25);
    padding: 50px;
    width: 800px;
}


.ContactUsPage_Form_header {
    padding: 0px;
    margin: 0px;
    font-size: 22px;
}

.ContactUsPage_Form_Form_Outer {
    display: flex;
    flex-direction: column;
}

.ContactUsPage_Form_Form_Div {
    padding-top: 50px;
    display: grid;
    grid-template-columns: 50% 50%;
    width: 900px;
    /* column-gap: 200px; */
}

.ContactUsPage_Form_indiv_item {
    display: flex;
    flex-direction: column;
    height: 100px;
    gap: 10px;
}

.ContactUsPage_Form_input {
    border: none;
    border-bottom: 1px solid black;
    /* padding: 5px 10px; */
    outline: none;
    font-size: 15px;
    font-family: Saira;
    font-style: normal;
    font-weight: normal;
    max-width: 300px;
}

.ContactUsPage_Form_select {
    border: 1px solid black;
    /* padding: 5px 10px; */
    outline: none;
    max-width: 300px;
}

.ContactUsPage_Form_textArea {
    /* width: 100%; */
    height: 180px;
    padding: 30px;
    box-shadow: inset 2px 4px 10px rgba(0, 0, 0, 0.25);
    border: none;
    font-size: 15px;
    font-family: Saira;
    font-style: normal;
    font-weight: normal;
}

.ContactUsPage_Form_submitButton {
    border: none;
    background-color: #ad2225;
    margin-top: 50px;
    height: 50px;
    cursor: pointer;
}

.ContactUsPage_Form_submitButton_disabled {
    border: none;
    background-color: #cfbbbc;
    margin-top: 50px;
    height: 50px;
    cursor: default;
}

.ContactUsPage_Form_submitButton:hover {
    opacity: 0.7;
}

.ContactUsPage_Form_submitButton:active {
    opacity: 1;
}

.ContactUsPage_Form_IDCA_Info {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    text-align: center;
    align-self: center;
    margin-bottom: 20px;
    /* margin: 10px; */
}

.ContactUsPage_Form_IDCA_Info_indiv_a_tag {
    text-decoration: none;
}

.ContactUsPage_Form_IDCA_MAp {
    height: 200px;
    border: none;
    box-shadow: 2px 4px 27px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 1413px) {

    .ContactUsPage_Form_Form_Div {
        width: 700px;
    }

    .ContactUsPage_Form_Whole_Div {
        width: 650px;
    }
}

@media screen and (max-width: 1111px) {

    .ContactUsPage_Form_Form_Div {
        width: 600px;
    }

    .ContactUsPage_Form_Whole_Div {
        width: 550px;
    }

    .ContactUsPage_Form_input {
        max-width: 200px;
    }

    .ContactUsPage_Form_select {
        max-width: 200px;
    }
}

@media screen and (max-width: 922px) {

    .ContactUsPage_Form_Form_Div {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .ContactUsPage_Form_Whole_Div {
        width: 300px;
    }

    .ContactUsPage_Form_input {
        max-width: 300px;
    }

    .ContactUsPage_Form_select {
        max-width: 300px;
    }
}

@media screen and (max-width: 434px) {

    .ContactUsPage_Form_Form_Div {
        width: 200px;
        display: flex;
        flex-direction: column;
    }

    .ContactUsPage_Form_Whole_Div {
        width: 200px;
    }

    .ContactUsPage_Form_input {
        max-width: 200px;
    }

    .ContactUsPage_Form_select {
        max-width: 300px;
    }

    .ContactUsPage_Form_IDCA_Info {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        width: 90%;
    }

    .ContactUsPage_Form_header {
        font-size: 20px;
        text-align: left;
    }
}

@media screen and (max-width: 315px) {

    .ContactUsPage_Form_Form_Div {
        width: 180px;
        display: flex;
        flex-direction: column;
    }

    .ContactUsPage_Form_Whole_Div {
        width: 180px;
    }

    .ContactUsPage_Form_input {
        max-width: 200px;
    }

    .ContactUsPage_Form_select {
        max-width: 300px;
    }



}