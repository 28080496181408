.certification-top-div{
    height: 500px;
    background-image: url("../../../resources/DataCenterTrainingClassColor.png"),url("../../../resources/certifications_banner.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.certification-top-div_explain_outer_div{
    align-self: center;
    padding-top: 170px;
    width: 40%;
    height: 400px;
}

.certification-top-div_explain{
    /* background-color: white; */
    padding-top: 20px;
    
}

.certification-top-div_explain_text{
    text-align: center;
    font-size: 16px;
    line-height: 30px;
}



@media screen and (max-width: 1330px) {
.certification-top-div_explain_outer_div{
    width: 60%;
}
}

@media screen and (max-width: 816px) {
    .certification-top-div_explain_outer_div{
        width: 80%;
    }
    }

@media screen and (max-width: 542px) {
        .certification-top-div_explain_outer_div{
            width: 90%;
        }
}

@media screen and (max-width: 480px) {
    .certification-top-div_explain_outer_div{
            width: 95%;
            padding-top: 150px;
    }   
}

@media screen and (max-width: 393px) {
    .certification-top-div_explain_outer_div{
            padding-top: 130px;
    }   
}

@media screen and (max-width: 352px) {
    .certification-top-div_explain_text{
        font-size: 15px;
        line-height: 28px;
    }
    
}

@media screen and (max-width: 326px) {
    .certification-top-div_explain_text{
        font-size: 15px;
        line-height: 25px;
    }
    
}