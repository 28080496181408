.data_center_training_road_map_courses_div{
    margin: auto;
}

.dc_RoadMap_arrow_transition_class{
    transition: 0.7s;
}


/* Arrow Div 1 */
/*  */
/*  */
#cloud_training_road_map_arrow_div_1 {
    position: relative;
    transition: 0.7s;
}

#cloud_training_road_map_arrow_div_1 #one_dot {
    position: absolute;
    top: 0px;
    left: 0px;
  height: 12.5px;
  width: 12.5px;
  background-color: #64AFE2;
  border-radius: 50%;
  display: inline-block;
}
#cloud_training_road_map_arrow_div_1 #one_line1{
    position: absolute;
    top: 4.5px;
    left: 12.2px;
    width: 115px;
    height: 3px;
    background: #64AFE2;
}

/* Up Arrow */
#cloud_training_road_map_arrow_div_1 #one_line_up1{
    position: absolute;
    top: -75px;
    left: 124px;
    height: 79.43px;
    width: 3.02px;

    background: linear-gradient(#1089C6, #64AFE2);
}

#cloud_training_road_map_arrow_div_1 #one_line_up2{
    position: absolute;
    top: -75px;
    left: 126px;
    width: 45px;
    height: 3.61px;
    background: #1089C6;
}

#cloud_training_road_map_arrow_div_1 #one_arrow_up{
    position: absolute;
    top: -81px;
    left: 168px;
    width: 16.14px;
    height: 16.14px;
    color: #1089C6;
}

/* Down Arrow */
#cloud_training_road_map_arrow_div_1 #one_line_down1{
    position: absolute;
    top: 7px;
    left: 124px;
    height: 79.43px;
    width: 3.02px;

    background: linear-gradient(#64AFE2, #405B6B);
}

#cloud_training_road_map_arrow_div_1 #one_line_down2{
    position: absolute;
    top: 83px;
    left: 124px;
    width: 45px;
    height: 3.61px;
    background: #405B6B;
}

#cloud_training_road_map_arrow_div_1 #one_arrow_down{
    position: absolute;
    top: 77px;
    left: 166px;
    width: 16.14px;
    height: 16.14px;
    color: #405B6B;
}


/* Arrow Div 2 */
/*  */
/*  */
#cloud_training_road_map_arrow_div_2 {
    position: relative;
    top: -90px;
    /* left: -90px; */
    transition: 0.7s;
}

/* Up Arrow */
#cloud_training_road_map_arrow_div_2 #two_dot1 {
    position: absolute;
    top: 0px;
    left: 0px;
  height: 12.5px;
  width: 12.5px;
  background-color: #99A79D;
  border-radius: 50%;
  display: inline-block;
  transition: 0.7s
}
#cloud_training_road_map_arrow_div_2 #two_line_up1{
    position: absolute;
    top: 4.5px;
    left: 12.2px;
    width: 34px;
    height: 3px;   
    background: #99A79D;
  transition: 0.7s
}

#cloud_training_road_map_arrow_div_2 #two_line_up2{
    position: absolute;
    top: 5px;
    left: 45px;
    height: 82px;
    width: 3.02px;

    background: linear-gradient(#99A79D, #0D575F);
  transition: 0.7s
}

/* Down Arrow */
#cloud_training_road_map_arrow_div_2 #two_line_down2{
    position: absolute;
    top: 86px;
    left: 45px;
    height: 82px;
    width: 3.02px;

    background: linear-gradient(#0D575F, #345587);
  transition: 0.7s
}
#cloud_training_road_map_arrow_div_2 #two_line_down1{
    position: absolute;
    top: 165px;
    left: 12.2px;
    width: 34px;
    height: 3px;   
    background: #345587;
  transition: 0.7s
}
#cloud_training_road_map_arrow_div_2 #two_dot2 {
    position: absolute;
    top: 160px;
    left: 0px;
  height: 12.5px;
  width: 12.5px;
  background-color: #345587;
  border-radius: 50%;
  display: inline-block;
  transition: 0.7s
}

/* Arrow Last */
#cloud_training_road_map_arrow_div_2 #two_line1{
    position: absolute;
    top: 85px;
    left: 46px;
    width: 185px;
    height: 3.61px;

    background: #0D575F;
}
#cloud_training_road_map_arrow_div_2 #two_line2{
    position: absolute;
    top: 85px;
    left: 231px;
    height: 71.38px;
    width: 3.61px;
    background: #0D575F;
}
#cloud_training_road_map_arrow_div_2 #two_arrow{
    position: absolute;
    top: 153px;
    left: 225px;
    width: 16.14px;
    height: 16.14px;
    color: #0D575F;
    transform: rotate(90deg);
}



/* Arrow Div 3 */
/*  */
/*  */
#cloud_training_road_map_arrow_div_3 {
    position: relative;
    top: -90px;
    left: -95px;
    transition: 0.7s;
}

/* Up Arrow */
#cloud_training_road_map_arrow_div_3 #three_arrow_up {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 16.14px;
    height: 16.14px;
    color: #1B325A;
    transform: rotate(180deg);
}
#cloud_training_road_map_arrow_div_3 #three_line_up1{
    position: absolute;
    top: 6px;
    left: 12.2px;
width: 134.64px;
height: 3.61px;
    background: #1B325A;
}

#cloud_training_road_map_arrow_div_3 #three_line_up2{
    position: absolute;
    top: 6px;
    left: 147px;
width: 3.23px;
height: 79.43px;

    background: linear-gradient(#1B325A, #0E585F);
}

/* Down Arrow */
#cloud_training_road_map_arrow_div_3 #three_arrow_down {
    position: absolute;
    top: 158px;
    left: 0px;
    width: 16.14px;
    height: 16.14px;
    color: #353082;
    transform: rotate(180deg);
}
#cloud_training_road_map_arrow_div_3 #three_line_down1{
    position: absolute;
    top: 164px;
    left: 12.2px;
width: 134.64px;
height: 3.61px;
    background: #353082;
}

#cloud_training_road_map_arrow_div_3 #three_line_down2{
    position: absolute;
    top: 88px;
    left: 147px;
width: 3.23px;
height: 79.43px;

    background: linear-gradient(#0E585F, #353082);
}

/* Line Last */
#cloud_training_road_map_arrow_div_3 #three_line1{
    position: absolute;
    top: 85px;
    left: 147px;
width: 181.62px;
height: 3.61px;

    background: #0E585F;
}
#cloud_training_road_map_arrow_div_3 #three_line2{
    position: absolute;
    top: 5px;
    left: 325px;
height: 80.42px;
width: 3.61px;
    background: #0E585F;
}
#cloud_training_road_map_arrow_div_3 #three_dot{
    position: absolute;
    top: 3px;
    left: 321px;
  height: 12.5px;
  width: 12.5px;
  background-color: #0E585F;
  border-radius: 50%;
  display: inline-block;
}





/* Arrow Div 4 */
/*  */
/*  */
#cloud_training_road_map_arrow_div_4 {
    position: relative;
    top: -7px;
    transition: 0.7s;
}

/* Arrow */
#cloud_training_road_map_arrow_div_4 #four_arrow{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 16.14px;
    height: 16.14px;
    color: #0B0B39;
    transform: rotate(180deg);
}
#cloud_training_road_map_arrow_div_4 #four_line{
    position: absolute;
    top: 6.5px;
    left: 13px;
width: 111.14px;
height: 3.61px;

    background: #0B0B39;
}


/* Line Up */
#cloud_training_road_map_arrow_div_4 #four_line_up3{
    position: absolute;
    top: -73px;
    left: 121px;
    width: 3.23px;
    height: 79.43px;
    background: linear-gradient(#1D335A , #0B0B39);
}
#cloud_training_road_map_arrow_div_4 #four_line_up2{
    position: absolute;
    top: -75px;
    left: 121px;
width: 134.64px;
height: 3.61px;

    background: #1D335A;
}
#cloud_training_road_map_arrow_div_4 #four_dot1 {
    position: absolute;
    top: -80px;
    left: 255px;
  height: 12.5px;
  width: 12.5px;
  background-color: #1D335A;
  border-radius: 50%;
  display: inline-block;
}


/* Line Down */
#cloud_training_road_map_arrow_div_4 #four_line_down1{
    position: absolute;
    top: 10px;
    left: 121px;
width: 3px;
height: 79px;
    background: linear-gradient(#0B0B39, #363083);
}
#cloud_training_road_map_arrow_div_4 #four_line_down2{
    position: absolute;
    top: 89px;
    left: 121px;
width: 134.64px;
height: 3.61px;

    background: #363083;
}
#cloud_training_road_map_arrow_div_4 #four_dot2 {
    position: absolute;
    top: 85px;
    left: 255px;
  height: 12.5px;
  width: 12.5px;
  background-color: #363083;
  border-radius: 50%;
  display: inline-block;
}






.data_center_training_road_map_courses_two_together_div{
    transition: 0.7s;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#line_cles_clie{
    width: 44px;
    height: 4px;

    background: linear-gradient(to right, #1089C6 14.06%, #98A69E 86.98%);
}

#line_cles_clos{
    width: 44px;
    height: 4px;

    background: linear-gradient(to right, #405D6C 0%, #335486 66.15%);
}

.data_center_training_road_map_courses_grid_div{
    margin: auto;
    width: fit-content;
    display: grid;
    grid-template-columns: 90px 170px 190px 380px 150px 160px;
    /* grid-template-rows: 25% auto auto; */
    /* justify-items: center; */
    align-items: center;
    row-gap: 40px;
}

.data_center_training_road_map_courses_top_div{
    display: flex;
    flex-direction: row;
    align-items: center

}

.data_center_training_road_map_courses_indiv_div{
    width: fit-content;
    position: relative;
    transition: 0.7s;
}

.data_center_training_road_map_courses_indiv_div a{
    cursor: pointer;
}

.data_center_training_road_map_courses_indiv_text_div{
    position: absolute;
    top: -5px;
    text-align: center;
    font-size: 30px;
}

.data_center_training_road_map_courses_indiv_text_small{
    margin-top: 0px;
    padding-top: 0px;
    font-size: 17px;
}

.cloud_training_road_map_courses_end{
    font-family: Saira;
    font-style: normal;
    font-weight: 600;
    color: #100C48;
}


.data_center_training_road_map_arrow_img{
    transition: 0.7s;
    height: 180px;
}

.data_center_training_road_map_arrow_img_1{
    position: absolute;
    transition: 0.7s;
    height: 180px;
}

.data_center_training_road_map_courses_dce_dcm_div{
    justify-self: center;
}

#data_center_training_road_map_courses_dces_div{
    position: relative;
}
#data_center_training_road_map_courses_dcie_div{
    position: relative;
}


#data_center_training_road_map_courses_dcos_div{
    position: relative;
}
#data_center_training_road_map_courses_dcom_div{
    position: relative;
}

#data_center_training_road_map_courses_dctp_div{
    top: 170px;
    left: 5px;
}

#data_center_training_road_map_courses_dce_div{
    margin-bottom: 20px;
}


#cloud_training_road_map_courses_start{
    color: #63AFE3;
}

@media screen and (max-width: 1250px) {
.road_map_individual_img_class{
    width: 130px;
}
.data_center_training_road_map_courses_grid_div{
    grid-template-columns: 80px 145px 160px 320px 85px 130px;
    row-gap: 30px;
}
#data_center_training_road_map_courses_dctp_div{
    top: 147px;
}
#cloud_training_road_map_courses_start{
    font-size: 22px;
}
.cloud_training_road_map_courses_end{
    font-size: 22px;
}
.data_center_training_road_map_courses_indiv_text_title{
    margin-bottom: 7px;
    font-size: 23px;
}
.data_center_training_road_map_courses_indiv_text_small{
    font-size: 15px;
}
#cloud_training_road_map_arrow_div_1 #one_line1{
    width: 80px;
}
#cloud_training_road_map_arrow_div_1 #one_line_up1{
    left: 89px;
}
#cloud_training_road_map_arrow_div_1 #one_line_up2{
    left: 91px;
}
#cloud_training_road_map_arrow_div_1 #one_arrow_up{
    left: 133px;
}
#cloud_training_road_map_arrow_div_1 #one_line_down1{
    left: 89px;
}
#cloud_training_road_map_arrow_div_1 #one_line_down2{
    left: 89px;
}
#cloud_training_road_map_arrow_div_1 #one_arrow_down{
    left: 131px;
}


#cloud_training_road_map_arrow_div_2 #two_line1{
    width: 100px;
}
#cloud_training_road_map_arrow_div_2 #two_line2{
    left: 146px;
}
#cloud_training_road_map_arrow_div_2 #two_arrow{
    left: 140px;
}


#cloud_training_road_map_arrow_div_4 #four_line{
    width: 95px;
}
#cloud_training_road_map_arrow_div_4 #four_line_up3{
    left: 104.86px;
}
#cloud_training_road_map_arrow_div_4 #four_line_up2{
    left: 104.86px;
    width: 120px;
}
#cloud_training_road_map_arrow_div_4 #four_dot1 {
    left: 220px;
}
#cloud_training_road_map_arrow_div_4 #four_line_down1{
    left: 104.86px;
}
#cloud_training_road_map_arrow_div_4 #four_line_down2{
    left: 104.86px;
    width: 120px;
}
#cloud_training_road_map_arrow_div_4 #four_dot2 {
    left: 220px;
}
#cloud_training_road_map_arrow_div_3 #three_line_up1{
    width: 120px;
}

#cloud_training_road_map_arrow_div_3 #three_line_up2{
    left: 132px;
}
#cloud_training_road_map_arrow_div_3 #three_line_down1{
    width: 120px;
}
#cloud_training_road_map_arrow_div_3 #three_line_down2{
    left: 132px;
}
#cloud_training_road_map_arrow_div_3 #three_line1{
    left: 132px;
    width: 116px;
}
#cloud_training_road_map_arrow_div_3 #three_line2{
    height: 83px;
    left: 245px;
}
#cloud_training_road_map_arrow_div_3 #three_dot{
    left: 241px;
}
}

.dc_road_map_courses_mobile_view_img{
    display: none;
}

@media screen and (max-width: 950px) {
.data_center_training_road_map_courses_grid_div{
    display: none;
}
.dc_road_map_courses_mobile_view_img{
    display: initial;
    margin: 0px 20%;
}
.dc_road_map_courses_mobile_view_img img{
    width: 60%;
}
}
@media screen and (max-width: 600px) {
.dc_road_map_courses_mobile_view_img{
    margin: 0px 15%;
}
.dc_road_map_courses_mobile_view_img img{
    width: 70%;
}
}
@media screen and (max-width: 450px) {
.dc_road_map_courses_mobile_view_img{
    margin: 0px 5%;
}
.dc_road_map_courses_mobile_view_img img{
    width: 90%;
}
}
@media screen and (max-width: 350px) {
.dc_road_map_courses_mobile_view_img{
    margin: 0px 0px;
}
.dc_road_map_courses_mobile_view_img img{
    width: 100%;
}
}