.home_page_top_outter_div {
    background-image: url('../../../resources/Mask group (40).png');
    background-position: center;
    height: 670px;
    background-size: cover;
}

.home_page_top_text_div {
    padding-top: 200px;
}

.home_page_top_text_div h2 {
    font-size: 28px;
}

.home_page_top_learn_more_a {
    width: fit-content;
    text-decoration: none;
}

.home_page_top_learn_more_a p {
    background: rgba(255, 255, 255, 0.2);
    border: 2px solid white;
    width: fit-content;
    padding: 10px 50px;
    border-radius: 10px;
}

.home_page_top_learn_more_a p:hover {
    background: rgba(255, 255, 255, 0.506);
    border: 2px solid white;
    width: fit-content;
    padding: 10px 50px;
    border-radius: 10px;
}