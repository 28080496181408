.data_center_audit_services_Card_Data_Center_Section_indiv_flex_img{
    width: 40%;
    aspect-ratio: 1.15;
    min-width: 300px;
}

.data_center_audit_services_title_small_flex{
    display: flex;
    column-gap: 10px;
    align-items: center;
    margin-bottom: 0px;
    margin-top: 0px;
}

.data_center_audit_services_title_large_flex{
    margin-top: 0px;
    font-size: 30px;
    text-align: left;
}

.data_center_audit_services_Card_Data_Center_Section_indiv_flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 5%;
    margin-bottom: 3%;
}

.data_center_audit_services_Card_Data_Center_Section_indiv_Right_side{
    width: 100%;
    text-align: justify;
}

.data_center_audit_services_Card_Data_Center_Section_indiv_flex{
    width: 90%;
}

@media screen and (max-width: 1300px) {
    .data_center_audit_services_Card_Data_Center_Section_indiv_flex_img{
        width: 50%;
    }
}

@media screen and (max-width: 1020px) {
    .data_center_audit_services_Card_Data_Center_Section_indiv_flex_img{
        width: 60%;
    }
}

@media screen and (max-width: 950px) {
    .data_center_audit_services_Card_Data_Center_Section_indiv_flex{
        flex-direction: column;
    }

.data_center_audit_services_Card_Data_Center_Section_indiv_Right_side{
    text-align: left;
}
.data_center_audit_services_Card_Data_Center_Section_indiv_flex{
    width: 100%;
}
}

@media screen and (max-width: 350px) {
.data_center_audit_services_Card_Data_Center_Section_indiv_flex_img{
    min-width: 270px;
}
}