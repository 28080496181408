.homepage_service_offerings_margin_div {
    margin: 0px 150px;
    margin-bottom: 50px;
}

.homepage_service_offerings_list_top_div {
    display: flex;
    justify-content: center;
    gap: 40px;
    flex-wrap: wrap;
}

.homepage_service_offerings_indiv_serv_top_div {
    width: 300px;
    /* height: 250px; */
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
}

.homepage_service_offerings_indiv_serv_header_div {
    background-color: rgba(218, 218, 218, 1);
    display: flex;
    gap: 15px;
    align-items: center;
    padding: 0px 20px;
}

.homepage_service_offerings_indiv_serv_header_div h2 {
    color: rgba(14, 43, 102, 1);
    font-size: 20px;
}

.homepage_service_offerings_indiv_serv_body_div {
    padding: 10px 20px;
}

.homepage_service_offerings_indiv_serv_body_div h3 {
    margin: 0px;
    font-size: 17px;
}

.homepage_service_offerings_indiv_serv_body_div p {
    font-size: 14px;
}

.homepage_service_offerings_title_line_div {
    border-bottom: 1px solid rgba(96, 96, 96, 1);
}

.homepage_service_offerings_title_div {
    background-color: rgba(187, 32, 39, 1);
    padding: 10px 20px;
    width: fit-content;
    margin-bottom: 50px;
}

.homepage_service_offerings_title_div p {
    margin: 0px;
}

@media screen and (max-width: 1300px) {
    .homepage_service_offerings_margin_div {
        margin: 0px 100px;
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 900px) {
    .homepage_service_offerings_margin_div {
        margin: 0px 70px;
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 500px) {
    .homepage_service_offerings_margin_div {
        margin: 0px 40px;
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 400px) {
    .homepage_service_offerings_margin_div {
        margin: 0px 30px;
        margin-bottom: 50px;
    }
}