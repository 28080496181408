.certification_2_0_cert_process_top_div {
    background-color: white;
    padding: 0px;
}

.certification_2_0_cert_process_text_1 {
    margin-bottom: 30px;
}

.certification_2_0_cert_process_text_2 {
    margin-top: 30px;
}

.certification_2_0_cert_process_apply_a {
    text-decoration: none;
}

.certification_2_0_cert_process_apply_div {
    border: 1px solid rgba(0, 97, 151, 1);
    color: rgba(0, 97, 151, 1);
    margin: 50px 0px;
    background: white;
    padding: 10px 40px;
    cursor: pointer;
    font-size: 16px;
    width: fit-content;
}

.certification_2_0_cert_process_modal_top_div {
    height: 500px;
    width: 100%;
    overflow: auto;
}

.certification_2_0_cert_process_img_div_img {
    cursor: zoom-in;
    position: relative;
    aspect-ratio: 1.7;
}

.certification_2_0_cert_process_img_div {
    padding: 30px 20px;
    background: #ECECEC;
}

.certification_2_0_cert_process_img_div_click {
    cursor: zoom-out;
    padding: 30px 20px;
    background: #ECECEC;
}

@media screen and (max-width: 1200px) {
    .certification_2_0_cert_process_apply_a {
        display: none;
    }
}