.Training_types_Data_Center_Training {
    display: grid;
    grid-template-columns: auto auto;
    gap: 40px;
    align-items: center;
    padding-bottom: 50px;
    padding-top: 50px;
}

.Training_types {
    margin-bottom: -20px;
}

.Training_types_header {
    font-size: 30px;
    line-height: 45px;
}


.Training_types_img {
    max-width: 500px;
}

.Training_types_Data_Center_Training_right_first {
    position: relative;
    width: 500px;
    height: 400px;
    object-fit: contain;
}

@media screen and (max-width: 1460px) {
    .Training_types_img {
        width: 420px;
        aspect-ratio: 1.54;
    }

    .Training_types_Data_Center_Training_right_first {
        width: 450px;
        height: 300px;
    }
}

@media screen and (max-width: 1327px) {
    .Training_types_Data_Center_Training {
        grid-template-columns: unset;
    }

    .Training_types_Data_Center_Training_right {
        display: none;
    }

}

.Training_types_Text {
    text-align: justify;
}

@media screen and (max-width: 512px) {
    .Training_types_Text {
        text-align: left;
    }
}

.Training_types_Button {
    background-color: #AD2225;
    border: none;
    color: white;
    width: 240px;
    height: 55px;
    font-size: 14px;
    cursor: pointer;
}

.Training_types_Button:hover {
    opacity: 0.9;
}

.Training_types_Button:active {
    opacity: 1;
}

@media screen and (max-width: 400px) {
    .Training_types_Data_Center_Training_left {
        text-align: center;
    }
}