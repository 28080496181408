.digital_universe_get_involved_form_header {
    font-size: 32px;
}

.digital_universe_get_involved_form_body_div {
    display: flex;
    justify-content: space-around;
    padding-bottom: 70px;
    gap: 40px;
    flex-wrap: wrap;
}

.digital_universe_get_involved_form_indiv_div h4 {
    font-size: 28px;
}

.digital_universe_get_involved_form_indiv_div {
    width: 350px;

    border: 4px dotted white;
    /* text-align: center; */
    padding: 0px 30px;
}

.digital_universe_get_involved_form_indiv_submit_div {
    width: 250px;
    font-size: 17px;
    line-height: 50px;
    background-color: #4C0067;
    border: 0px;
    margin: auto;
    margin-top: 50px;
    cursor: pointer;
}

.digital_universe_get_involved_form_indiv_two_div {
    display: grid;
    grid-template-columns: 45% 45%;
    justify-content: space-between;
}

.digital_universe_get_involved_form_indiv_field_input {
    height: 27px;
    font-size: 15px;
    background-color: transparent;
    color: white;
    width: 100%;
    border: 0px;
    border-bottom: 1px dotted white;
}

.digital_universe_get_involved_form_indiv_field_input_note {
    font-size: 15px;
    background-color: transparent;
    color: white;
    width: 100%;
    border: 1px dotted white;
    border-top: 0px;
    border-left: 0px;

}