.user_dashboard_home_next_step_top_div {
    padding: 40px 50px;
    box-shadow: 0px 0px 15px #6f6f6f57;
    border-radius: 8px;
    background-color: #006197;
    color: white;
}

.user_dashboard_home_next_step_top_inner_div {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 60% 5% 30%;
    justify-content: space-between;
}

.user_dashboard_home_next_step_name_img_div {
    display: flex;
    gap: 20px;
    align-items: center;
    margin: 30px 0px;
}

.user_dashboard_home_next_step_course_title {
    color: white;
    font-weight: bold;
    font-size: 20px;
    margin: 0px;
}

.user_dashboard_home_next_step_course_description {
    line-height: 28px;
    word-spacing: 1px;
    margin-bottom: 20px;
}

.user_dashboard_home_next_step_buy {
    color: white;
    border: 1px solid white;
    padding: 7px 40px;
    width: fit-content;
    cursor: pointer;
    transition: .3s;
}

.user_dashboard_home_next_step_buy:hover {
    color: #006197;
    background-color: white;
    border: 1px solid white;
}

.user_dashboard_home_next_step_divider_vert_div {
    border-left: 1px solid #003350;
}

.user_dashboard_home_next_step_detail_indiv_div {
    display: flex;
    justify-content: center;
}

.user_dashboard_home_next_step_detail_indiv_inner_div {
    width: 200px;
    display: flex;
    align-items: center;
    gap: 20px;
}

.user_dashboard_home_next_step_detail_indiv_inner_div p {
    margin: 10px 0px;
}

.user_dashboard_home_next_step_divider_horiz_div {
    border-top: 1px solid #003350;
    margin: 10px 0px;
}

@media screen and (max-width: 900px) {
    .user_dashboard_home_next_step_top_inner_div {
        gap: 10px;
        grid-template-columns: 100%;
    }
}