.About_our_courses_data_center_training {
    background-image: url("../../../resources/about_our_courses_bg_adobe_express.svg");
    height: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    flex-direction: row;
}

#aboutOurCourses_dataCenterTraining_video {
    order: 2;
    width: 40%;
    height: 320px;
    min-width: 400px;
    max-width: 600px
}

.about_our_courses_text_data_center_training {
    order: 1;
    width: 30%;
    font-size: 16px;
    margin-right: 50px;
    min-width: 500px;
    color: #303030;
}

.data_center_training_about_our_courses_small_header {
    font-size: 20px;
    color: black;
}


.data_center_training_about_our_courses_large_header {
    font-size: 25px;
}


.checkBoxdataCenterTraining {
    width: 12px;
}

.header-line {
    padding-bottom: 4px;
}

@media screen and (max-width: 1200px) {
    .About_our_courses_data_center_training {
        justify-content: center;
    }

}


@media screen and (max-width: 1120px) {

    .about_our_courses_text_data_center_training {
        margin-left: 0px;
    }


    #aboutOurCourses_dataCenterTraining_video {
        margin-right: 50px;
        margin-left: 50px;
        width: 550px;
    }


    .About_our_courses_data_center_training {
        height: 770px;
        width: 100%;
    }

    .data-center-training-large-header {
        text-align: center;
    }

    .data_center_training_small_header {
        display: none;
    }

}

@media screen and (max-width: 675px) {
    #aboutOurCourses_dataCenterTraining_video {
        width: 550px;
        margin-right: 0px;
        margin-left: 0px;
    }
}

@media screen and (max-width: 572px) {

    #aboutOurCourses_dataCenterTraining_video {
        width: 90%;
        height: 250px;
        margin-right: 0px;
        margin-left: 0px;
    }

    .about_our_courses_text_data_center_training {
        order: 1;
        width: 90%;
        font-size: 16px;
        /* text-align: center; */
        color: #303030;
        min-width: 0px;
        margin-right: 0px;
        padding: 0px;
    }

}

@media screen and (max-width: 437px) {

    #aboutOurCourses_dataCenterTraining_video {
        min-width: 0px;
        height: 200px;
    }

    .about_our_courses_text_data_center_training {
        order: 1;
        width: 90%;
        font-size: 15px;
        color: #303030;
        min-width: 0px;
        margin-right: 0px;
        padding: 0px;
    }

    .checkBoxTextdataCenterTraining {
        font-size: 14px;
    }

}

/* @media screen and (max-width: 530px) {
   .About_our_courses_data_center_training{
        width: 100%;
        padding-right: 0%;
        margin-right: 0%;
   }

    .about_our_courses_text_data_center_training{
        width: 80%;
        font-size: 14px;
        padding-left: 10%;
        padding-right: 0px;
        text-align: center;
    }
    .data_center_training_about_our_courses_description{
        width: 80%;
        text-align: center;
        margin-left: 10%;
    }
} */

/* @media screen and (max-width: 500px) {
    .About_our_courses_data_center_training{
         width: 100%;
         padding-right: 0%;
         margin-right: 0%;
         padding-left: 0%;
    }
 
     .about_our_courses_text_data_center_training{
         width: 70%;
         font-size: 14px;
         padding-left: 10%;
         padding-right: 0px;
         text-align: center;
     }
     .data_center_training_about_our_courses_description{
         width: 70%;
         text-align: center;
         margin-left: 15%;
     }
     #aboutOurCourses_dataCenterTraining_video{
        width: 330px;
        height: 200px;
        margin-right: 0px;
        margin-left: 0px;
        min-width: 0px;
     }
 } */

/* @media screen and (max-width: 407px) {
    #aboutOurCourses_dataCenterTraining_video{
        width: 330px;
        height: 200px;
        margin-right: 0px;
        margin-left: 0px;
        min-width: 0px;
    }
    .about_our_courses_text_data_center_training{
        order: 1;
        width: 50%;
        margin-right: 0px;
        padding-left: 0px;
        padding-right: 0px;
        text-align: center;
        color: #303030;
    }
    
    .data_center_training_about_our_courses_description{
        width: 70%;
        text-align: center;
        margin-left: 15%;
    }

    .checkBoxTextdataCenterTraining{
        width: 70%;
        margin-left: 15%;
        padding: 0px;
        text-align: center;
    }

} */

/* @media screen and (max-width: 365px) {
    #aboutOurCourses_dataCenterTraining_video{
        width: 300px;
        height: 190px;
        margin-right: 0px;
        margin-left: 0px;
        min-width: 0px;
    }
    .about_our_courses_text_data_center_training{
        order: 1;
        width: 50%;
        margin-right: 0px;
        padding-left: 0px;
        padding-right: 0px;
        text-align: center;
        color: #303030;
    }
    
    .data_center_training_about_our_courses_description{
        width: 60%;
        text-align: center;
        margin-left: 20%;
    }

    .checkBoxTextdataCenterTraining{
        width: 60%;
        margin-left: 20%;
        padding: 0px;
        text-align: center;
    }

} */