.Membership-sub-page-new-top-divTopHeader {
    background-image: url('../../resources/MembershipTopHeader.png');
    background-size: cover;
    background-position: center;
    padding-bottom: 100px;
    height: 130px;
}

.Membership-sub-page-new-top-div-inner-div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.Membership-sub-page-new-top-div-inner-div h1 {
    width: 50%;
    text-align: left;
    margin-left: 0px;
}

.Membership-sub-page-new-top-divTopHeaderSignupButton {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 11.76px;
    padding-bottom: 11.76px;
    background-color: transparent;
    border: solid white 2px;
    border-radius: 5px;
    width: 100px;
    cursor: pointer;
}

.Membership-sub-page-new-top-divTopHeader h1 {
    font-size: 40px;
}

.Membership-sub-page-new-top-divTopHeaderSignupButton:hover {
    background-color: black;
}

.Membership-sub-page-new-top-divTopHeaderSignupButton:active {
    background-color: gray;
}

@media screen and (max-width: 1134px) {
    .Membership-sub-page-new-top-divTopHeader h1 {
        font-size: 35px;
    }
}

@media screen and (max-width: 900px) {
    .Membership-sub-page-new-top-divTopHeader h1 {
        font-size: 30px;
    }

    .Membership-sub-page-new-top-divTopHeaderSignupButton {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 11.76px;
        padding-bottom: 11.76px;
        width: 100px;
        font-size: 15px;
    }
}

@media screen and (max-width: 450px) {
    .Membership-sub-page-new-top-divTopHeader h1 {
        font-size: 28px;
        width: 100%;
    }

    .Membership-sub-page-new-top-divTopHeaderSignupButton {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        width: 100px;
        font-size: 15px;
    }
}