.Traning_developers_cards_whole{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    /* width: 500px; */
    grid-template-columns: 270px  270px  270px 270px;
    width: 100%;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;

}

.Traning_developers_meet_team{
    background-image: url("../../../resources/meetTheTeam.png");
    width: 270px;
    height: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Traning_developers_meet_pic{
    margin-top: 30px;
}

.Traning_developers_meet_text{
    text-align: center;
    line-height: 35px;
}

.Traning_developers_meet_button{
    width: 138px;
    height: 44px;
    border: none;
    background-color: #212121;
    cursor: pointer;
}

.Traning_developers_meet_button:hover{
    opacity: 0.9;
}

.Traning_developers_meet_button:active{
    opacity: 1.0;
}