.membershipSubpagesForumTopics {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
}

.membershipSubpagesForumTopicsItems {
    display: grid;
    grid-template-columns: 20% 20% 20% 20%;
    grid-template-rows: 33% 33% 33%;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 30px;
    /* margin-bottom: 65px; */
}

@media screen and (max-width: 530px) {
    .membershipSubpagesForumTopicsItems {
        display: grid;
        grid-template-rows: 16% 16% 16% 16% 16% 16%;
        grid-template-columns: 50% 50%;
        width: 100%;
        justify-content: center;
        align-items: center;
        gap: 30px;
        /* margin-bottom: 50px; */
        margin-top: 20px;
        /* padding-bottom: 30px; */
    }
}