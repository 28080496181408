.Sucess-sub-top-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 900px;
    background-image: url("../../../resources/Group 4875-min.png");
    /* background-size: 100% 530px; */
    background-size: cover;
    /* background-attachment: fixed; */
    background-position: center;
    /* margin-bottom: 15px; */
}

.Sucess-sub-top-text-div {
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 380px;
    text-align: center;
    background-color: white;
    background-image: url("../../../resources/Testimonial Background.png");
    background-size: cover;
    background-position: center;
    padding: 40px;
    line-height: 40px;
    width: 70%;
}

.Sucess-sub-top-text-header {
    font-size: 25px;
    line-height: 37px;
}

@media screen and (max-width: 580px) {
    .Sucess-sub-top-text-div {
        padding: 20px;
        width: 85%;
    }

}

@media screen and (max-width: 500px) {
    .Sucess-sub-top-text-div {
        padding: 20px;
        line-height: 35px;
        width: 85%;
    }

    .Sucess-sub-top-text-header {
        font-size: 25px;
    }

}

@media screen and (max-width: 480px) {
    .Sucess-sub-top-text-div {
        padding: 20px;
        line-height: 35px;
        width: 85%;
        margin-top: 300px;
    }

    .Sucess-sub-top-text-header {
        font-size: 20px;

    }

}


@media screen and (max-width: 400px) {
    .Sucess-sub-top-text-div {
        padding: 5px;
        padding-top: 20px;
        line-height: 35px;
        width: 90%;
        margin-top: 300px;
        /* height: 500px; */
    }

    .Sucess-sub-top-text-header {
        text-align: center;
        font-size: 25px;
        /* height: 400px; */
        line-height: 40px;
    }

}


@media screen and (max-width: 300px) {
    .Sucess-sub-top-text-div {
        padding: 10px;
        line-height: 35px;
        width: 90%;
        margin-top: 300px;
        /* height: 500px; */
    }

    .Sucess-sub-top-text-header {
        font-size: 20px;
        /* height: 400px; */
        line-height: 40px;
    }

}