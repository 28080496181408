.digital_economy_2024_related_media_top_div {
    background-color: #0C364E;
    padding: 70px 0px;
    margin-top: 70px;
}

.digital_economy_2024_related_media_header_p {
    color: #959FB2;
    font-size: 32px;
    line-height: 45px;
    margin: 0px;
}

.digital_economy_2024_related_media_webinars_div {
    margin-top: 70px;
}