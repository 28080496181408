.digital_economy_first_section {
    -moz-box-shadow: 0 0 3px #ccc;
    -webkit-box-shadow: 0 0 3px #ccc;
    box-shadow: 0 0 20px #ccc;
    padding: 30px;
    padding-left: 30px;
    padding-right: 30px;

    display: flex;
    flex-direction: column;
    gap: 10%;
    align-items: center;
    margin-bottom: 30px;
    justify-content: center;
}

.digital_economy_first_section_text {
    text-align: justify;
    /* height: 80%; */
}

.digital_economy_first_section_img_div {
    height: 500px;
    text-align: center;
}


.digital_economy_first_section_img {
    height: 370px;
    width: 480px;
    margin: auto;
    position: relative;
}

.digital_economy_first_section_img_div h6 {
    margin: 0px;
}

.digital_economy_first_section_img_Carousel {
    width: 80%;
    padding: 0px;
    margin: 10px 0px;
    margin-top: 50px;
}

@media screen and (max-width: 1550px) {

    .digital_economy_first_section_img {
        height: 320px;
    }

    .digital_economy_first_section_img_div {
        height: 420px
    }

    .digital_economy_first_section_img {
        width: 420px;
    }
}

@media screen and (max-width: 1500px) {
    .digital_economy_first_section_img_div {
        /* height: 400px */
    }
}

@media screen and (max-width: 1450px) {
    .digital_economy_first_section_img_div {
        /* height: 350px */
    }

    .digital_economy_first_section_img {
        height: 300px;
        width: 390px;
    }
}

@media screen and (max-width: 1250px) {
    .digital_economy_first_section_img_div {
        /* height: 300px; */
        text-align: center;
    }

    .digital_economy_first_section_img {
        /* height: 92%; */
        width: 350px;
    }
}

@media screen and (max-width: 1110px) {
    .digital_economy_first_section_img_div {
        /* height: 250px; */
        text-align: center;
    }

    .digital_economy_first_section_img {
        /* width: 280px; */
    }
}

@media screen and (max-width: 900px) {
    .digital_economy_first_section_img_Carousel {
        display: none;
    }

    .digital_economy_first_section_img_div {
        height: 220px;
    }

    .digital_economy_first_section_img_div h6 {
        display: none;
    }

    .digital_economy_first_section_img {
        width: 230px;
    }
}

@media screen and (max-width: 800px) {
    .digital_economy_first_section_img_div {
        height: fit-content;
    }

    .digital_economy_first_section_img {
        width: 95%;
    }

    .digital_economy_first_section_img_Carousel {
        display: none;
    }
}


@media screen and (max-width: 1344px) {

    .digital_economy_first_section {

        display: flex;
        flex-direction: column;
        gap: 10%;
        align-items: center;
        margin-bottom: 30px;
    }

    .digital_economy_first_section_img {
        order: 2;
    }
}

@media screen and (max-width: 576px) {


    /* .digital_economy_first_section_img{
        width: 100%;
    } */
}