.contact_us_audit_main_div_whole {
    background-image: url("../../resources/audit_contact_us_img_color.png"), url("../../resources/audit_contact_us_img.png");
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 355px;
}

.contact_us_audit_parent_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 50px;
}

.contact_us_audit_header_large {
    font-size: 36px;
    line-height: 50px;
    margin: 40px 0px;
    margin-bottom: 0px;
}

.contact_us_audit_header_small {
    font-size: 18px;
}

.contact_us_audit_email_headers {
    width: 165px;
    font-size: 14px;
}

.contact_us_audit_emails_links {
    font-size: 14px;
    text-decoration: none;
}

.contact_us_audit_all_emails_div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.contact_us_audit_request_button {
    background-color: white;
    border: none;
    cursor: pointer;
    height: 50px;
    width: 235px;
    padding: 15px 80px;
}

.contact_us_audit_request_button:hover {
    opacity: 0.7;
}

.contact_us_audit_request_button:active {
    opacity: 1.0;
}


.contact_us_audit_email_header_divs {
    display: flex;
    align-items: center;
    gap: 10px;
}

.contact_us_audit_indiv_divs {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (max-width: 1400px) {
    .contact_us_audit_main_div_whole {
        height: 500px;
    }

    .contact_us_audit_parent_div {
        flex-direction: column;
    }
}

@media screen and (max-width: 877px) {
    .contact_us_audit_main_div_whole {
        height: 700px;
    }

    .contact_us_audit_all_emails_div {
        flex-direction: column;
    }

    .contact_us_audit_request_button {
        padding: 15px 30px
    }
}